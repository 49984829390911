import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_TieredMenu = _resolveComponent("TieredMenu")
  const _component_AddFilterDialog = _resolveComponent("AddFilterDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("i", {
      class: "pi pi-file-import text-purple-400 text-xl",
      style: {"cursor":"pointer"},
      onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.toggle && _ctx.toggle(...args))),
      "aria-haspopup": "true",
      "aria-controls": "overlay_tmenu"
    }),
    _createVNode(_component_TieredMenu, {
      ref: "menu",
      id: "overlay_tmenu",
      model: _ctx.items,
      popup: ""
    }, null, 8, ["model"]),
    _createVNode(_component_AddFilterDialog, {
      "display-dialog": _ctx.displayAddFilterDialog,
      "saving-in-progress": _ctx.savingInProgress,
      "header-text": _ctx.$t('labels.saveTemplateAs'),
      onCloseDialog: _ctx.onCloseAddDialog
    }, null, 8, ["display-dialog", "saving-in-progress", "header-text", "onCloseDialog"])
  ], 64))
}