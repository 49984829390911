<div class="m-2">
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <ScrollPanel style="height: calc(100vh - 34rem)">
            <div class="formgrid grid p-fluid mt-3 mb-3">
                <div class="field col">
                    <label
                        >{{ $t('labels.productGoogleAds.multipack') }}
                    </label>

                    <InputNumber
                        autocomplete="off"
                        v-model="v$.multipack.$model"
                        class="full-width"
                    />
                </div>
                <div class="field col">
                    <label>{{ $t('labels.productGoogleAds.isBundle') }} </label>

                    <app-checkbox v-model="v$.isBundle.$model" :binary="true" />
                </div>
                <div class="field col">
                    <label
                        >{{ $t('labels.productGoogleAds.identifierExists') }}
                    </label>

                    <app-checkbox
                        v-model="v$.identifierExists.$model"
                        :binary="true"
                    />
                </div>
            </div>
            <div class="formgrid grid p-fluid mb-3">
                <div class="field col">
                    <label>{{ $t('labels.productGoogleAds.label0') }} </label>
                    <InputText
                        icon="align-justify"
                        type="text"
                        v-model="v$.label0.$model"
                        v-on:keyup="onKeyUp('label0',$event)"
                    />
                    <small class="mb-3">
                        {{ $t('messages.numberOfCharsLeft', {value:
                        labelCharactersLeft?.label0}) }}</small
                    >
                </div>
                <div class="field col">
                    <label>{{ $t('labels.productGoogleAds.label1') }} </label>

                    <InputText
                        icon="align-justify"
                        type="text"
                        v-model="v$.label1.$model"
                        v-on:keyup="onKeyUp('label1',$event)"
                    />
                    <small class="mb-3">
                        {{ $t('messages.numberOfCharsLeft', {value:
                        labelCharactersLeft?.label1}) }}</small
                    >
                </div>
                <div class="field col">
                    <label>{{ $t('labels.productGoogleAds.label2') }} </label>

                    <InputText
                        icon="align-justify"
                        type="text"
                        v-model="v$.label2.$model"
                        v-on:keyup="onKeyUp('label2',$event)"
                    />
                    <small class="mb-3">
                        {{ $t('messages.numberOfCharsLeft', {value:
                        labelCharactersLeft?.label2}) }}</small
                    >
                </div>
            </div>
            <div class="formgrid grid p-fluid mb-3">
                <div class="col">
                    <label>{{ $t('labels.productGoogleAds.label3') }} </label>

                    <InputText
                        icon="align-justify"
                        type="text"
                        v-model="v$.label3.$model"
                        v-on:keyup="onKeyUp('label3',$event)"
                    />
                    <small class="mb-3">
                        {{ $t('messages.numberOfCharsLeft', {value:
                        labelCharactersLeft?.label3}) }}</small
                    >
                </div>

                <div class="field col">
                    <label>{{ $t('labels.productGoogleAds.label4') }} </label>

                    <InputText
                        icon="align-justify"
                        type="text"
                        v-model="v$.label4.$model"
                        v-on:keyup="onKeyUp('label4',$event)"
                    />
                    <small class="mb-3">
                        {{ $t('messages.numberOfCharsLeft', {value:
                        labelCharactersLeft?.label4}) }}</small
                    >
                </div>
                <div class="field col">
                    <label
                        >{{ $t('labels.productGoogleAds.promotionID') }}
                    </label>

                    <InputText
                        icon="align-justify"
                        type="text"
                        v-model="v$.promotionId.$model"
                        v-on:keyup="onKeyUp('promotionId',$event)"
                    />
                    <small class="mb-3">
                        {{ $t('messages.numberOfCharsLeft', {value:
                        labelCharactersLeft?.promotionId}) }}</small
                    >
                </div>
            </div>
            <div class="formgrid grid p-fluid mb-3">
                <div class="field col">
                    <label
                        >{{ $t('labels.productGoogleAds.adwordsRedirect') }}
                    </label>

                    <p-textarea
                        rows="10"
                        autocomplete="off"
                        v-model="v$.adwordsRedirect.$model"
                        v-on:keyup="onKeyUp('adwordsRedirect',$event)"
                    />
                    <small class="mb-3">
                        {{ $t('messages.numberOfCharsLeft', {value:
                        labelCharactersLeft?.adwordsRedirect}) }}</small
                    >
                </div>
            </div>
        </ScrollPanel>
        <div class="flex justify-content-end mt-3">
            <p-button
                :disabled="savingInProgress"
                severity="success"
                type="submit"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
            >
            </p-button>
        </div>
    </form>
</div>
