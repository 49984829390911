<loading v-model:active="loading" />
<PrimePanel class="panel-container-datatable">
    <template #header>
        <div class="w-full flex justify-content-between">
            <div class="flex align-items-center">
                <span class="font-bold"> {{$t('labels.products')}} </span>
                <p-button
                    class="ml-2"
                    @click="onAddNewProductClick"
                    size="small"
                    severity="success"
                >
                    {{ $t("buttons.addNewGeneric") }}
                </p-button>
            </div>
            <div class="mt-2">
                <SalesChannelSwitcher></SalesChannelSwitcher>
            </div>
            <p-multiselect
                class="text-sm"
                :modelValue="selectedColumns"
                ref="columnSelectorComponent"
                :options="columns"
                optionLabel="header"
                optionValue="field"
                optionDisabled="optionDisabled"
                @change="onToggleColumnVisibility"
                placeholder="Select Columns"
                style="min-width: 5rem; max-width: 20rem"
            >
                <template #footer>
                    <div class="w-full m-2 flex justify-content-between">
                        <p-button
                            severity="danger"
                            size="small"
                            @click="onCancelColumnSelection"
                        >
                            {{ $t("buttons.cancel") }}
                        </p-button>
                        <p-button
                            severity="success"
                            size="small"
                            @click="onConfirmColumnSelection"
                        >
                            {{ $t("buttons.confirm") }}
                        </p-button>
                    </div>
                </template>
            </p-multiselect>
        </div>
    </template>
    <DataTable
        class="p-datatable-sm text-sm"
        paginator
        :rows="10"
        lazy
        scrollable
        scrollHeight="calc(100vh - 25rem)"
        ref="dt"
        dataKey="id"
        :totalRecords="totalRecords"
        :value="products"
        :scrollable="true"
        :paginator-template="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
        :rows-per-page-options="[10,20,50,100,200]"
        :current-page-report-template="'{first} to {last} of {totalRecords}'"
        v-model:filters="filters"
        filterDisplay="row"
        @page="onPage($event)"
        @filter="onFilter($event)"
        @sort="onSort($event)"
        @state-save="onStateSave"
        @state-restore="onStateRestore"
        v-model:expandedRows="expandedRows"
        v-model:selection="selectedProducts"
        stateStorage="local"
        stateKey="products-list-state-session"
        @rowSelect="onRowSelect"
        @rowSelectAll="onRowSelectAll"
        @rowUnselect="onRowUnselect"
        @rowUnselectAll="onRowUnselectAll"
    >
        <Column
            :expander="true"
            style="min-width: 4rem; max-width: fit-content"
            class="tt-filter-menu-th"
            ><template #filter>
                <FilterMenu
                    parent-component-id="product-list"
                    :parent-filters="filters"
                    @clear-filters="clearFilters"
                    @apply-filters="applyFilters"
                /> </template
        ></Column>
        <Column
            selectionMode="multiple"
            style="min-width: 3rem; max-width: 6rem"
            ><template #header>
                <Badge :value="mergedSelection.length"></Badge>
                <i
                    v-if="mergedSelection.length > 0"
                    class="pi pi-times-circle text-red-600"
                    style="padding-left: 7px; cursor: pointer"
                    @click="clearSelection"
                ></i
                ><i
                    class="pi pi-cog p-overlay-badge text-green-600"
                    style="padding-left: 7px; cursor: pointer"
                    @click="bulkMenuToggle"
                    aria-haspopup="true"
                    aria-controls="overlay_tmenu"
                    v-badge.warning
                ></i>

                <TieredMenu
                    ref="bulkMenu"
                    id="overlay_tmenu"
                    :model="addBulkItems"
                    popup /></template
        ></Column>
        <Column
            field="articleNumber"
            :sortable="true"
            :header="$t('labels.articleNumber')"
            style="min-width: 13rem"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.articleNumber')"
                />
            </template>
            <template #body="{data}">
                <div class="flex align-content-center">
                    <div
                        class="sales-channel-dot w-1rem h-1rem mr-1"
                        :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData && !data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData && !data.shopwareData.active, 'bg-gray-200': !data?.shopwareData?.active}"
                    ></div>
                    <div
                        class="sales-channel-dot w-1rem h-1rem mr-1"
                        :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData && !data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData && !data.secondShopwareData.active, 'bg-gray-200': !data?.secondShopwareData?.active}"
                    ></div>
                    <span style="vertical-align: middle"
                        ><a
                            :href="data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id"
                            target="_blank"
                            >{{data.articleNumber}}</a
                        ></span
                    >
                    <span
                        class="ml-3"
                        v-if="showCommentsColumn && data.comments.length > 0"
                        style="cursor: pointer"
                        @click="showCommentsDialog(data)"
                    >
                        <i
                            class="pi pi-comments"
                            style="padding-right: 5px"
                        ></i>
                        {{ data.comments.length }}</span
                    >
                </div>
            </template>
        </Column>
        <Column style="min-width: 12rem">
            <template #header>
                {{$t('labels.articleNumber')}}
                <div
                    class="sales-channel-dot sales-channel-videodata w-1rem h-1rem ml-1"
                ></div>
            </template>
            <template #body="{data}">
                <span
                    >{{ data.customAttributes?.article_webshop_VDHarticleNumber
                    }}</span
                >
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('createdDate') !== -1"
            field="createdDate"
            dataType="date"
            :sortable="true"
            :header="$t('labels.createdAt')"
            style="min-width: 10rem"
        >
            <template #filter="{filterModel}">
                <Calendar
                    class="p-column-filter text-xs"
                    v-model="filterModel.value"
                    dateFormat="dd.mm.yy"
                />
            </template>
            <template #body="data">
                {{ new Date(data.data.createdDate).toLocaleDateString("de-DE")
                }}
            </template>
        </Column>
        <!--<Column
            v-if="visibleColumns.indexOf('teltecProductNumber') !== -1"
            :header="$t('labels.teltecProductNumber')"
            style="min-width: 13rem"
            field="teltecProductNumber"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.articleNumber')"
                />
            </template>
            <template #body="{data}">
                {{ data.coreData?.teltecProductNumber }}
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('videodataProductNumber') !== -1"
            :header="$t('labels.videodataProductNumber')"
            style="min-width: 13rem"
            field="videodataProductNumber"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.articleNumber')"
                />
            </template>
            <template #body="{data}">
                {{ data.coreData?.videodataProductNumber }}
            </template>
        </Column>-->
        <Column
            field="name"
            :sortable="true"
            style="min-width: 12rem"
            :header="$t('labels.articleName')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.name')"
                />
            </template>
            <template #body="{data}">
                <span style="vertical-align: middle"
                    >{{ data.name || data.shopwareData?.name }}</span
                >
                <span
                    v-if="data.shopwareData && !isValueEqualCaseInsensitive(data.name || null, data.shopwareData.name || null)"
                    class="pi pi-exclamation-triangle"
                    v-tooltip.top="$t('labels.inconsistentData') + ': ' + $t('labels.name')"
                ></span>
                <div class="mt-2">
                    <Tag
                        v-for="tag in data.tags"
                        severity="info"
                        class="mt-1 mr-1"
                    >
                        <span
                            >{{tag.toUpperCase().replace('TTC-PRODUCT-META-',
                            '')}}</span
                        >
                    </Tag>
                </div>
            </template>
        </Column>
        <Column style="min-width: 12rem">
            <template #header>
                {{$t('labels.articleName')}}
                <div
                    class="sales-channel-dot sales-channel-videodata w-1rem h-1rem ml-1"
                ></div>
            </template>
            <template #body="{data}">
                <span
                    >{{ data.customAttributes?.article_webshop_VDHname }}</span
                >
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('description') !== -1"
            field="description"
            style="min-width: 12rem"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.productDescription.webshopText')"
                />
            </template>
            <template #header>
                {{$t('labels.productDescription.webshopText')}}
                <div
                    class="sales-channel-dot w-1rem h-1rem ml-1"
                    :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                ></div>
            </template>
            <template #body="{data}">
                <span
                    >{{ stripTagsAndTruncate(data.shopwareData?.description,
                    400, '...') }}</span
                >
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('description') !== -1"
            style="min-width: 12rem"
        >
            <template #header>
                {{$t('labels.productDescription.webshopText')}}
                <div
                    class="sales-channel-dot w-1rem h-1rem ml-1"
                    :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                ></div>
            </template>
            <template #body="{data}">
                <span v-if="data.secondShopwareData?.description"
                    >{{
                    stripTagsAndTruncate(data.secondShopwareData?.description,
                    400, '...') }}</span
                >
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('productCategory') !== -1"
            field="productCategory"
            style="min-width: 12rem"
            :filterMatchModeOptions="matchModeWithEmptyFilter"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="isEmptyKeydownCalldown(filterModel, filterCallback)"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.articleCategory')"
                />
            </template>
            <template #header>
                {{$t('labels.articleCategory')}}
                <div
                    class="sales-channel-dot w-1rem h-1rem ml-1"
                    :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                ></div
            ></template>
            <template #body="{data}">
                <ul v-if="data?.shopwareData?.categories">
                    <li v-for="element in data.shopwareData.categories">
                        {{ renderShopwareCategory(element) }}
                    </li>
                </ul>
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('productCategory') !== -1"
            style="min-width: 12rem"
        >
            <template #header>
                {{$t('labels.articleCategory')}}
                <div
                    class="sales-channel-dot w-1rem h-1rem ml-1"
                    :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                ></div
            ></template>
            <template #body="{data}">
                <ul v-if="data?.secondShopwareData?.categories">
                    <li v-for="element in data.secondShopwareData.categories">
                        {{ renderShopwareCategory(element, true) }}
                    </li>
                </ul>
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('productBadges') !== -1"
            field="productBadges"
            :header="$t('labels.badges')"
            style="min-width: 12rem"
            :filterMatchModeOptions="[{label: 'Contains', value: 'contains'}, {label: 'Not contains', value: 'notContains'}]"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.badges')"
                />
            </template>
            <template #body="{data}">
                <ul v-if="data?.shopwareData?.badges">
                    <template v-for="element in data.shopwareData.badges">
                        <li v-if="getBadgeName(element)">
                            {{ getBadgeName(element) }}
                        </li>
                    </template>
                </ul>
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('productDiscounts') !== -1"
            field="productDiscounts"
            :header="$t('labels.discounts') + ' (SW6)'"
            style="min-width: 12rem"
        >
            <template #body="{data}">
                <ul v-if="data?.shopwareData?.promotions">
                    <li
                        v-for="element in data.shopwareData.promotions"
                        class="mb-2"
                    >
                        {{ element.name }}
                    </li>
                </ul>
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('articleCategoryName') !== -1"
            field="articleCategoryName"
            style="min-width: 13rem"
            :sortable="true"
            :header="$t('labels.articleWeclappCategory')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.articleWeclappCategory')"
                />
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('shortDescription1') !== -1"
            :header="$t('labels.productDescription.shortDescription')"
            field="shortDescription1"
            style="min-width: 10rem"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                />
            </template>
        </Column>
        <Column
            :header="$t('labels.stock')"
            field="availableStock"
            style="min-width: 8rem"
            v-if="visibleColumns.indexOf('stock') !== -1"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #body="{data}">
                {{ data.shopwareData?.availableStock }}
            </template>
        </Column>
        <Column
            :header="$t('labels.stockInSalesOrders')"
            style="min-width: 3rem"
            v-if="visibleColumns.indexOf('stockInSalesOrders') !== -1"
        >
            <template #body="{data}">
                {{ stockInSalesOrdersCalculation(data) }}
            </template>
        </Column>
        <Column
            :header="$t('labels.stockInInternalShipments')"
            style="min-width: 3rem"
            v-if="visibleColumns.indexOf('stockInInternalShipments') !== -1"
        >
            <template #body="{data}">
                {{ stockInInternalShipmentsCalculation(data) }}
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('accessories') !== -1"
            field="accessories"
            style="min-width: 13rem"
            :filterMatchModeOptions="[
                        {label: 'Contains', value: 'contains'}
                    ]"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.articleAccessories')"
                />
            </template>
            <template #header>
                {{$t('labels.articleAccessories')}}
                <div
                    class="sales-channel-dot w-1rem h-1rem ml-2"
                    :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                ></div>
            </template>
            <template #body="{data}">
                <div>
                    <span style="vertical-align: middle"
                        ><ul
                            v-if="data.shopwareData?.accessories && data.shopwareData.accessories.length > 0"
                        >
                            <li
                                v-for="element in (data.shopwareData.accessories.slice(0, 5))"
                            >
                                {{ element.name }} [{{element.productNumber}}]
                            </li>
                            <li v-if="data.shopwareData.accessories.length > 5">
                                ...
                                <b
                                    >(Total:
                                    {{data.shopwareData.accessories.length}})</b
                                >
                            </li>
                        </ul>
                    </span>
                </div>
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('accessories') !== -1"
            style="min-width: 13rem"
        >
            <template #header>
                {{$t('labels.articleAccessories')}}
                <div
                    class="sales-channel-dot w-1rem h-1rem ml-2"
                    :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                ></div>
            </template>
            <template #body="{data}">
                <div
                    v-if="data.secondShopwareData?.accessories && data.secondShopwareData.accessories.length > 0"
                >
                    <span style="vertical-align: middle"
                        ><ul>
                            <li
                                v-for="element in (data.secondShopwareData.accessories.slice(0, 5))"
                            >
                                {{ element.name }} [{{element.productNumber}}]
                            </li>
                            <li
                                v-if="data.secondShopwareData.accessories.length > 5"
                            >
                                ...
                                <b
                                    >(Total:
                                    {{data.secondShopwareData.accessories.length}})</b
                                >
                            </li>
                        </ul>
                    </span>
                </div>
            </template>
        </Column>
        <Column
            field="ean"
            v-if="visibleColumns.indexOf('ean') !== -1"
            :sortable="true"
            style="min-width: 10rem"
            :header="$t('labels.ean')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.ean')"
                />
            </template>
            <template #body="{data}">
                <span style="vertical-align: middle"
                    >{{ data.ean || data.shopwareData?.ean }}</span
                >
                <span
                    v-if="data.shopwareData && !isValueEqualCaseInsensitive(data.ean || null, data.shopwareData.ean || null)"
                    class="pi pi-exclamation-triangle"
                    style="margin-left: 0.5rem; vertical-align: middle"
                    v-tooltip.top="$t('labels.inconsistentData') + ': ' + $t('labels.ean')"
                ></span>
            </template>
        </Column>
        <Column
            field="manufacturerPartNumber"
            v-if="visibleColumns.indexOf('manufacturerPartNumber') !== -1"
            :sortable="true"
            style="min-width: 10rem"
            :header="$t('labels.mpn')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.mpn')"
                />
            </template>
            <template #body="{data}">
                <span style="vertical-align: middle"
                    >{{ data.manufacturerPartNumber || data.shopwareData?.mpn
                    }}</span
                >
                <span
                    v-if="data.shopwareData && !isValueEqualCaseInsensitive(data.manufacturerPartNumber || null, data.shopwareData.mpn || null)"
                    class="pi pi-exclamation-triangle"
                    style="margin-left: 0.5rem; vertical-align: middle"
                    v-tooltip.top="$t('labels.inconsistentData') + ': ' + $t('labels.mpn')"
                ></span>
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('manufacturerName') !== -1"
            field="manufacturerName"
            :sortable="true"
            style="min-width: 10rem"
            :header="$t('labels.manufacturer')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.manufacturer')"
                />
            </template>
            <template #body="{data}">
                <span style="vertical-align: middle"
                    >{{ data.manufacturerName || data.shopwareData?.manufacturer
                    }}</span
                >
                <span
                    v-if="data.shopwareData && !isValueEqualCaseInsensitive(data.manufacturerName || null, data.shopwareData.manufacturer || null)"
                    class="pi pi-exclamation-triangle"
                    v-tooltip.top="$t('labels.inconsistentData') + ': ' + $t('labels.manufacturer')"
                ></span>
            </template>
        </Column>
        <Column
            field="sellOut"
            v-if="visibleColumns.indexOf('sellOut') !== -1"
            dataType="boolean"
            bodyClass="text-center"
            style="max-width: 6rem"
            :header="$t('labels.productHome.sellOut')"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.shopwareData?.sellOut, 'text-red-600 pi-times-circle': !(data.shopwareData?.sellOut)}"
                ></i>
            </template>
        </Column>
        <Column
            field="metaTags"
            v-if="visibleColumns.indexOf('metaTags') !== -1"
            bodyClass="text-center"
            style="min-width: 10rem"
            :header="$t('labels.productHome.metaTagsSEO')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    :placeholder="$t('labels.productHome.metaTagsSEO')"
                />
            </template>
            <template #body="{data}">
                <span style="vertical-align: middle"
                    >{{ data.customAttributes?.article_metatags }}</span
                >
            </template>
        </Column>
        <Column
            field="bulkGoods"
            v-if="visibleColumns.indexOf('bulkGoods') !== -1"
            dataType="boolean"
            bodyClass="text-center"
            style="max-width: 6rem"
            :header="$t('labels.productHome.bulkGoods')"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.customAttributes?.article_sperrgut, 'text-red-600 pi-times-circle': !(data.customAttributes?.article_sperrgut)}"
                ></i>
            </template>
        </Column>
        <Column
            field="tempSelect"
            v-if="visibleColumns.indexOf('tempSelect') !== -1"
            dataType="boolean"
            bodyClass="text-center"
            style="max-width: 6rem"
            :header="$t('labels.productHome.tempSelect')"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.customAttributes?.admin_temp_select_article, 'text-red-600 pi-times-circle': !(data.customAttributes?.admin_temp_select_article)}"
                ></i>
            </template>
        </Column>
        <Column
            field="availableInShop"
            v-if="visibleColumns.indexOf('availableInShop') !== -1"
            dataType="boolean"
            bodyClass="text-center"
            style="max-width: 6rem"
            :header="$t('labels.productHome.availableInShop')"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.customAttributes?.article_available_in_shop, 'text-red-600 pi-times-circle': !(data.customAttributes?.article_available_in_shop)}"
                ></i>
            </template>
        </Column>
        <Column
            field="activeInShop"
            v-if="visibleColumns.indexOf('activeInShop') !== -1"
            dataType="boolean"
            bodyClass="text-center"
            style="max-width: 6rem"
            :header="$t('labels.productHome.activeInShop')"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.customAttributes?.article_active_in_shop, 'text-red-600 pi-times-circle': !(data.customAttributes?.article_active_in_shop)}"
                ></i>
            </template>
        </Column>
        <Column
            field="orderPositionCheckStock"
            v-if="visibleColumns.indexOf('orderPositionCheckStock') !== -1"
            dataType="boolean"
            bodyClass="text-center"
            style="max-width: 7rem"
            :header="$t('labels.productHome.orderPositionCheckStock')"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #body="{data}">
                <i
                    v-if="data.customAttributes && typeof data.customAttributes['order_position_check_stock'] !== 'undefined'"
                    class="pi"
                    :class="{'text-green-600 pi-check-circle':
                                data.customAttributes.order_position_check_stock,
                                'text-red-600 pi-times-circle':
                                !(data.customAttributes.order_position_check_stock)}"
                ></i>
            </template>
        </Column>
        <Column
            field="priceOnRequest"
            v-if="visibleColumns.indexOf('priceOnRequest') !== -1"
            bodyClass="text-center"
            style="min-width: 16rem"
            :header="$t('labels.productHome.priceUponRequest')"
        >
            <template #filter="{filterModel, filterCallback}">
                <Dropdown
                    v-model="filterModel.value"
                    :options="[
                                    {
                                        value: '0',
                                        label: $t('labels.productHome.deactivated')
                                    },
                                    {
                                        value: '1',
                                        label: $t('labels.productHome.activated')
                                    },
                                    {
                                        value: '2',
                                        label: $t(
                                            'labels.productHome.individualPriceUponRequest'
                                        )
                                    }
                                ]"
                    placeholder="Any"
                    optionLabel="label"
                    optionValue="value"
                    @change="filterCallback"
                    class="p-column-filter text-xs"
                    :showClear="true"
                >
                </Dropdown>
            </template>
            <template #body="{data}">
                <div v-if="data.shopwareData?.priceOnRequest == '2'">
                    {{ $t('labels.productHome.individualPriceUponRequest') }}
                </div>
                <div v-else-if="data.shopwareData?.priceOnRequest == '1'">
                    {{ $t('labels.productHome.activated') }}
                </div>
                <div v-else>{{ $t('labels.productHome.deactivated') }}</div>
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('vkPrice') !== -1"
            style="min-width: 7rem"
        >
            <template #header>
                {{$t('labels.vkPrice')}}
                <div
                    class="sales-channel-dot sales-channel-teltec w-1rem h-1rem ml-1"
                ></div>
            </template>
            <template #body="{data}">
                <div class="flex align-content-center">
                    <span>{{ vkPriceCalculation(data) }}</span>
                </div>
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('vkPrice') !== -1"
            style="min-width: 7rem"
        >
            <template #header>
                {{$t('labels.vkPrice')}}
                <div
                    class="sales-channel-dot sales-channel-videodata w-1rem h-1rem ml-1"
                ></div>
            </template>
            <template #body="{data}">
                <span v-if="vkPriceCalculation(data, true, 'NET2')"
                    >{{ vkPriceCalculation(data, true, 'NET2') }}</span
                >
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('ekPrice') !== -1"
            :header="$t('labels.ekPrice')"
            style="min-width: 6rem"
        >
            <template #body="{data}"> {{ ekPriceCalculation(data) }} </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('uvpPrice') !== -1"
            :header="$t('labels.productAdd.uvpPrice')"
            style="min-width: 6rem"
        >
            <template #body="{data}">
                {{ uvpPriceCalculation(data) }}
            </template>
        </Column>
        <Column
            v-if="visibleColumns.indexOf('tags') !== -1"
            field="tags"
            :header="$t('labels.tags')"
            style="min-width: 10rem"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel,filterCallback}">
                <p-multiselect
                    class="text-sm"
                    v-model="filterModel.value"
                    :options="availableProductTags"
                    :maxSelectedLabels="3"
                    optionLabel="name"
                    optionValue="name"
                    filter
                    @change="filterCallback()"
                    style="min-width: 5rem; max-width: 20rem"
                >
                    <template #option="{option}">
                        {{option.name.toUpperCase().replace('TTC-PRODUCT-META-',
                        '')}}
                    </template>
                    <template #value="content">
                        {{(content.value || []).map((item) =>
                        item.toUpperCase().replace('TTC-PRODUCT-META-', ''))
                        .join(', ')}}
                    </template>
                </p-multiselect>
            </template>
            <template #body="{data}">
                <ul v-if="data?.tags">
                    <template v-for="tag in data.tags">
                        <li>
                            <span class="font-semibold"
                                >{{tag.toUpperCase().replace('TTC-PRODUCT-META-',
                                '')}}</span
                            >
                        </li>
                    </template>
                </ul>
            </template>
        </Column>

        <Column
            :style="'min-width: ' + (showChangeLogIcon ? 160 : 130) + 'px; max-width: fit-content'"
            :frozen="true"
            alignFrozen="right"
        >
            <template
                #body="{data}"
                v-if="editPermissionAvailable || viewAccessoriesPermissionAvailable || (data.shopwareData?.name && data.shopwareUrl) "
            >
                <a
                    v-if="(data.shopwareData?.active && data.shopwareData?.name && data.shopwareUrl)"
                    target="_blank"
                    :href="resolvedShopwareUrl(data.shopwareUrl, data.shopwareData, resolvedPlatform?.threeLetterId)"
                >
                    <i
                        class="pi pi-shopping-cart mr-2"
                        :class="{'sales-channel-teltec-color':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-videodata-color':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData?.active}"
                    ></i>
                </a>
                <a
                    v-if="(data.secondShopwareData?.active && data.secondShopwareData?.name && data.secondShopwareUrl)"
                    target="_blank"
                    :href="resolvedShopwareUrl(data.secondShopwareUrl, data.secondShopwareData, secondPlatform?.threeLetterId)"
                >
                    <i
                        class="pi pi-shopping-cart mr-2"
                        :class="{'sales-channel-videodata-color':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-teltec-color':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active}"
                    ></i>
                </a>
                <router-link
                    v-if="changelog.length > 0 && changelog.some((item) => item.entityId === data.articleNumber)"
                    :to="{
                                name: 'ProductChangelog',
                                params: {id: data.articleNumber}
                            }"
                    ><i
                        class="pi pi-calendar text-color mr-2"
                        v-tooltip.left="$t('labels.changelog')"
                    ></i
                ></router-link>
                <router-link
                    v-if="editPermissionAvailable"
                    :to="{
                                    name: 'ProductsAddUsedWizard',
                                    state: {originalProduct: JSON.stringify(data)}
                                }"
                    ><i
                        class="fa-box-open fas text-color text-lg mr-2"
                        v-tooltip.left="$t('labels.addUsedProduct')"
                    ></i
                ></router-link>
                <router-link
                    v-if="editPermissionAvailable"
                    :to="{
                                    name: 'CloneExistingProduct',
                                    params: {id: data.articleNumber}
                                }"
                    ><i
                        class="pi pi-clone text-color mr-2"
                        v-tooltip.left="$t('labels.copyProduct')"
                    ></i
                ></router-link>
                <router-link
                    v-if="editPermissionAvailable"
                    :to="{
                                    name: 'ProductDetails',
                                    params: {id: data.articleNumber},
                                    state: {historyFilters: historyFilters}
                                }"
                    ><i
                        class="pi pi-pencil text-color"
                        v-tooltip.left="$t('labels.articleDetails')"
                    ></i
                ></router-link>
            </template>
        </Column>
        <template #expansion="slotProps">
            <div
                v-if="slotProps.data?.shopwareData?.description || slotProps.data?.description"
                v-html="slotProps.data?.shopwareData?.description || slotProps.data?.description"
            ></div>
            <div v-else>No description available.</div>
        </template>
    </DataTable>
</PrimePanel>
<BulkAddAccessories
    :products="mergedSelection"
    :displayResponsive="dialogs.find(item => item.name === 'Accessories').visible"
    :mainProductsSelected="dialogs.find(item => item.name === 'Accessories').mainProductSelected"
    @close-dialog="closeAddUsingSelectedMainProducts"
/>
<BulkAddRemoveBadges
    :products="selectedProductsInShopware"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'Badges').visible"
    :isRemove="dialogs.find(item => item.name === 'Badges').isRemove"
    :isFilterRelated="dialogs.find(item => item.name === 'Badges').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkAddRemoveCategories
    :products="selectedProductsInShopware"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'Categories').visible"
    :isRemove="dialogs.find(item => item.name === 'Categories').isRemove"
    :isFilterRelated="dialogs.find(item => item.name === 'Categories').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkAddRemoveDiscounts
    :products="selectedProductsInShopware"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'Discounts').visible"
    :isRemove="dialogs.find(item => item.name === 'Discounts').isRemove"
    :isFilterRelated="dialogs.find(item => item.name === 'Discounts').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkAddWeclappDocuments
    :products="mergedSelection"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'WeclappDocuments').visible"
    :isFilterRelated="dialogs.find(item => item.name === 'WeclappDocuments').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkAddShopwareDocuments
    :products="selectedProductsInShopware"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'ShopwareDocuments').visible"
    :isFilterRelated="dialogs.find(item => item.name === 'ShopwareDocuments').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkSetShopOnlineStatus
    :products="mergedSelection"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'OnlineStatus').visible"
    :isRemove="dialogs.find(item => item.name === 'OnlineStatus').isRemove"
    :isFilterRelated="dialogs.find(item => item.name === 'OnlineStatus').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkEditPromotions
    :products="mergedSelection"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'Promotions').visible"
    :isFilterRelated="dialogs.find(item => item.name === 'Promotions').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkEditMetatags
    :products="mergedSelection"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'Metatags').visible"
    :isRemove="dialogs.find(item => item.name === 'Metatags').isRemove"
    :isFilterRelated="dialogs.find(item => item.name === 'Metatags').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkEditCompliance
    :products="mergedSelection"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'Compliance').visible"
    :isFilterRelated="dialogs.find(item => item.name === 'Compliance').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkEditTags
    :products="mergedSelection"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'Tags').visible"
    :isRemove="dialogs.find(item => item.name === 'Tags').isRemove"
    :isFilterRelated="dialogs.find(item => item.name === 'Tags').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>
<BulkEditCustomFields
    :products="mergedSelection"
    :productFilters="filters"
    :productTotalRecords="totalRecords"
    :displayDialog="dialogs.find(item => item.name === 'CustomFields').visible"
    :isFilterRelated="dialogs.find(item => item.name === 'CustomFields').usingFilter"
    @close-dialog="onCloseBulkBadgesCategoriesDialog"
/>

<PrimeDialog
    v-model:visible="dialogs.find(item => item.name === 'Comments').visible"
    :header="$t('labels.articleNumber') + ' ' + (comments?.articleNumber || '')"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '50vw'}"
    @hide="comments.value = null;closeDialog()"
    :modal="true"
    :dismissableMask="true"
>
    <PrimePanel
        v-for="comment in (comments?.comments || [])"
        class="small mb-3"
    >
        <template #header>
            <div class="font-weight-bold">
                {{ comment.author }} at {{ comment.createdDate }}
            </div>
        </template>
        <div v-html="comment.htmlComment || comment.comment" />
    </PrimePanel>
</PrimeDialog>
