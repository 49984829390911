import {ref, toRefs, watch} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import {scheduleSingle} from '@/services/crons';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import InputText from 'primevue/inputtext';

export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        'p-button': Button,
        InputText
    },
    props: {
        jobId: Number,
        isMailDialog: Boolean,
        displayDialog: Boolean
    },
    setup(props: any) {
        const {jobId, isMailDialog} = toRefs(props);
        const showDialog = ref(false);
        const confirmationInputField = ref(null);
        const confirmationInputValue = ref(null);
        const toast = useToast();

        const onShowDialog = () => {
            const inputField = confirmationInputField.value
                ?.$el as HTMLInputElement;
            if (inputField) {
                inputField.focus();
            }
        };

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
        });

        const onConfirmClicked = async () => {
            try {
                await scheduleSingle(jobId.value, confirmationInputValue.value);
                toast.success(
                    i18n.global.t('messages.cronJobExecutionScheduled')
                );
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
            } finally {
                showDialog.value = false;
            }
        };

        return {
            isMailDialog,
            showDialog,
            confirmationInputField,
            confirmationInputValue,
            onShowDialog,
            onConfirmClicked
        };
    }
};
