import {computed, onMounted, ref} from 'vue';
import {
    bulkUpdateDisposition,
    getAll,
    updateDisposition
} from '@/services/products';
import LoadingPlugin from 'vue-loading-overlay';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Column from 'primevue/column';
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';
import {useConfirm} from 'primevue/useconfirm';
import Calendar from 'primevue/calendar';
import PrimeDialog from 'primevue/dialog';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import Badge from 'primevue/badge';
import Panel from 'primevue/panel';
import TieredMenu from 'primevue/tieredmenu';

import {
    stripTagsAndTruncate,
    vkPriceCalculation,
    ekPriceCalculation,
    uvpPriceCalculation,
    checkIfArraysAreEqual,
    isValueEqualCaseInsensitive
} from '@/utils/helpers';
import {i18n} from '@/utils/i18n';
import store from '@/store';

export default {
    components: {
        DataTable,
        InputText,
        InputNumber,
        Dropdown,
        'p-button': Button,
        Column,
        Calendar,
        loading: LoadingPlugin,
        PrimeDialog,
        TriStateCheckbox,
        Badge,
        Panel,
        TieredMenu
    },
    setup() {
        onMounted(async () => {
            if (
                localStorage.getItem(
                    'products-delivery-time-list-state-session'
                )
            ) {
                return;
            }

            tableState.value = null;
            filters.value = Object.assign({}, filtersDefinition);

            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'articleNumber',
                sortOrder: 1,
                columns: Object.keys(filtersDefinition).concat(
                    'procurementLeadDays',
                    'supplier'
                ),
                filters: filters.value
            };

            loadLazyData();
        });

        const tableState = ref(null);
        const totalRecords = ref(0);
        const loading = ref(false);
        const products = ref();
        const selectedProducts = ref([]);
        const mergedSelection = ref([]);
        const toast = useToast();
        const confirm = useConfirm();

        const showWbtUpdateDialog = ref(false);
        const bulkWbtUpdateValue = ref(null);

        const dt = ref();
        const lazyParams: any = ref({});

        const bulkMenu = ref();

        const onPage = (event: any) => {
            if (!lazyParams.value) {
                lazyParams.value = event;
            } else {
                lazyParams.value.first = event.first || 0;
                lazyParams.value.rows = event.rows || dt.value.rows;
            }
            lazyParams.value.columns = Object.keys(filtersDefinition).concat(
                'procurementLeadDays',
                'supplier'
            );
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = Object.assign(filters.value, {
                articleType: {
                    value: 'STORABLE',
                    matchMode: FilterMatchMode.EQUALS
                }
            });
            lazyParams.value.first = 0;

            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;

            lazyParams.value.filters = Object.assign(filters.value, {
                articleType: {
                    value: 'STORABLE',
                    matchMode: FilterMatchMode.EQUALS
                }
            });
            lazyParams.value.columns = Object.keys(filtersDefinition).concat(
                'procurementLeadDays',
                'supplier'
            );

            loadLazyData();
        };

        const onStateRestore = (event: any) => {
            tableState.value = Object.assign({}, event);

            const stateFilters = Object.keys(
                tableState.value?.filters || {}
            ).filter((e) => e !== 'view');

            const filtersDefinitionKeys = Object.keys(filtersDefinition)
                .slice()
                .sort();

            if (!checkIfArraysAreEqual(stateFilters, filtersDefinitionKeys)) {
                localStorage.removeItem(
                    'products-delivery-time-list-state-session'
                );
                tableState.value = null;
                filters.value = Object.assign({}, filtersDefinition);
            } else {
                delete event.filters?.view;
                filters.value = Object.assign(
                    {},
                    filtersDefinition,
                    event.filters || {}
                );
            }

            lazyParams.value = {
                first: 0,
                rows: event?.rows || 10,
                sortField: event?.sortField || 'articleNumber',
                sortOrder: event?.sortOrder || 1,
                columns: Object.keys(filtersDefinition).concat(
                    'procurementLeadDays',
                    'supplier'
                ),
                filters: filters.value
            };

            loadLazyData();
        };

        const matchModesNumeric = [
            {label: 'Equals', value: FilterMatchMode.EQUALS},
            {label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS},
            {label: 'Less Than', value: FilterMatchMode.LESS_THAN},
            {
                label: 'Less or Equal',
                value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
            },
            {label: 'Greater Than', value: FilterMatchMode.GREATER_THAN},
            {
                label: 'Greater or Equal',
                value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            }
        ];

        const loadLazyData = () => {
            loading.value = true;
            getAll(lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    products.value = data.data.items;
                    selectedProducts.value = mergedSelection.value.filter(
                        (item: any) => {
                            return products.value.some(
                                (product: any) =>
                                    product.articleNumber === item.articleNumber
                            );
                        }
                    );
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const filtersDefinition: any = {
            articleNumber: {value: null, matchMode: FilterMatchMode.CONTAINS},
            articleType: {value: 'STORABLE', matchMode: FilterMatchMode.EQUALS},
            name: {value: null, matchMode: FilterMatchMode.CONTAINS},
            manufacturerName: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            sku: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            supplierNumber: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            procurementLeadDays: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            wbtDate: {value: null, matchMode: FilterMatchMode.DATE_IS},
            activeInAnyShop: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            }
        };

        const filters = ref(filtersDefinition);

        const onCellEditComplete = (event: {
            data: any;
            field: string;
            value: any;
            newValue: any;
        }) => {
            if (event.value.toString() !== event.newValue.toString()) {
                loading.value = true;

                updateDisposition(event.data.articleNumber, {
                    procurementLeadDays: event.newValue,
                    wbtDate: new Date()
                })
                    .then(() => {
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully'),
                            {
                                timeout: 500
                            }
                        );
                        loadLazyData();
                    })
                    .catch((error) => {
                        loading.value = false;
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    });
            }
        };

        const bulkOptions = [
            {
                label: i18n.global.t('labels.updateWbtDateForSelected'),
                icon: 'pi pi-clock',
                command: () => {
                    if (
                        !mergedSelection.value ||
                        mergedSelection.value.length < 1
                    ) {
                        toast.error(
                            i18n.global.t(
                                'messages.pleaseSelectAtLeastOneProduct'
                            )
                        );
                        return;
                    }

                    confirm.require({
                        message: i18n.global.t(
                            'messages.wbtDateBulkUpdateConfirmation'
                        ),
                        header: i18n.global.t('messages.pleaseConfirm'),
                        icon: 'pi pi-exclamation-triangle',
                        acceptLabel: i18n.global.t('labels.yes'),
                        rejectLabel: i18n.global.t('labels.no'),
                        accept: () => {
                            loading.value = true;
                            bulkUpdateDisposition(mergedSelection.value, {
                                wbtDate: new Date()
                            })
                                .then(() => {
                                    toast.success(
                                        i18n.global.t(
                                            'messages.changesSavedSuccessfully'
                                        ),
                                        {
                                            timeout: 500
                                        }
                                    );
                                    selectedProducts.value = [];
                                    mergedSelection.value = [];
                                    loadLazyData();
                                })
                                .catch((error) => {
                                    loading.value = false;
                                    toast.error(
                                        error.response?.data?.error ||
                                            error.message
                                    );
                                });
                        }
                    });
                }
            },
            {
                label: i18n.global.t('labels.updateWbtForSelected'),
                icon: 'pi pi-pencil',
                command: () => {
                    if (
                        !mergedSelection.value ||
                        mergedSelection.value.length < 1
                    ) {
                        toast.error(
                            i18n.global.t(
                                'messages.pleaseSelectAtLeastOneProduct'
                            )
                        );
                        return;
                    }

                    showWbtUpdateDialog.value = true;
                }
            }
        ];

        const forceUpdateWbtValue = (event: any) => {
            bulkWbtUpdateValue.value = event.value;
        };

        const closeWbtBulkUpdateDialog = (result: boolean = false) => {
            if (result) {
                loading.value = true;
                bulkUpdateDisposition(mergedSelection.value, {
                    procurementLeadDays: bulkWbtUpdateValue.value,
                    wbtDate: new Date()
                })
                    .then(() => {
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully'),
                            {
                                timeout: 500
                            }
                        );
                        selectedProducts.value = [];
                        mergedSelection.value = [];
                        loadLazyData();
                    })
                    .catch((error) => {
                        loading.value = false;
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    })
                    .finally(() => {
                        showWbtUpdateDialog.value = false;
                        bulkWbtUpdateValue.value = null;
                    });
            } else {
                showWbtUpdateDialog.value = false;
                bulkWbtUpdateValue.value = null;
            }
        };

        const onRowSelect = (selection: {data: any; originalEvent: any}) => {
            mergedSelection.value = Object.values(
                [...mergedSelection.value, selection.data].reduce(
                    (acc, obj) => ({...acc, [obj.id]: obj}),
                    {}
                )
            );
        };

        const onRowSelectAll = (selection: {
            data: Array<any>;
            originalEvent: any;
        }) => {
            mergedSelection.value = Object.values(
                [...mergedSelection.value, ...selection.data].reduce(
                    (acc, obj) => ({...acc, [obj.id]: obj}),
                    {}
                )
            );
        };

        const onRowUnselect = (selection: {data: any; originalEvent: any}) => {
            mergedSelection.value = mergedSelection.value.filter(
                (item) => item.id !== selection.data.id
            );
        };

        const onRowUnselectAll = () => {
            mergedSelection.value = mergedSelection.value.filter((item) => {
                return !products.value.some((pr: any) => item.id === pr.id);
            });
        };

        const clearSelection = () => {
            selectedProducts.value = [];
            mergedSelection.value = [];
        };

        const bulkMenuToggle = (event: any) => {
            bulkMenu.value.toggle(event);
        };

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );
        });

        return {
            loading,
            loadLazyData,
            totalRecords,
            products,
            filters,
            selectedProducts,
            mergedSelection,
            dt,
            matchModesNumeric,
            onFilter,
            onPage,
            onSort,
            stripTagsAndTruncate,
            vkPriceCalculation,
            ekPriceCalculation,
            uvpPriceCalculation,
            onStateRestore,
            isValueEqualCaseInsensitive,
            onCellEditComplete,
            bulkOptions,
            showWbtUpdateDialog,
            bulkWbtUpdateValue,
            forceUpdateWbtValue,
            closeWbtBulkUpdateDialog,
            onRowSelect,
            onRowUnselect,
            onRowSelectAll,
            onRowUnselectAll,
            clearSelection,
            bulkMenu,
            bulkMenuToggle,
            resolvedPlatform,
            matchModeWithEmptyFilter: [
                {label: 'Date is', value: FilterMatchMode.DATE_IS},
                {label: 'Date is not', value: FilterMatchMode.DATE_IS_NOT},
                {label: 'Date before', value: FilterMatchMode.DATE_BEFORE},
                {
                    label: 'Date after',
                    value: FilterMatchMode.DATE_AFTER
                },
                {
                    label: 'Is Empty',
                    value: 'isEmpty'
                },
                {
                    label: 'Is Not empty',
                    value: 'isNotEmpty'
                }
            ]
        };
    }
};
