<TabView class="tabview-custom text-sm">
    <TabPanel :disabled="!productDetails?.shopware">
        <template #header>
            <i class="pi pi-tags mr-2"></i>
            <span
                >{{ $t("labels.badges") }}<span
                    v-if="productDetails?.shopware?.badges"
                >
                    ({{ productDetails.shopware.badges.length }})</span
                ></span
            >
            <span
                class="sales-channel-dot w-1rem h-1rem ml-2"
                :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
            ></span>
        </template>
        <Badges
            :shopware-details="productDetails?.shopware"
            @reload-product="loadProductDetails"
        ></Badges>
    </TabPanel>
    <TabPanel :disabled="!productDetails?.secondShopware">
        <template #header>
            <i class="pi pi-tags mr-2"></i>
            <span
                >{{ $t("labels.badges") }}<span
                    v-if="productDetails?.secondShopware?.badges"
                >
                    ({{ productDetails.secondShopware.badges.length }})</span
                ></span
            >
            <span
                class="sales-channel-dot w-1rem h-1rem ml-2"
                :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
            ></span>
        </template>
        <Badges
            :shopware-details="productDetails?.secondShopware"
            @reload-product="loadProductDetails"
            :platform="secondPlatform"
        ></Badges>
    </TabPanel>
</TabView>
