import {IWorkflowItem} from '@/interfaces/jobs/workflow-item';
import {i18n} from '../i18n';
import {IUser} from '@/interfaces/user';

export const workflowOptions: Array<IWorkflowItem> = [
    {
        key: 'weclapp-suppliers',
        label: i18n.global.t('labels.salesOrder.supplier'),
        icon: 'pi pi-box',
        selectable: false,
        isEnabled: (user: IUser): boolean => {
            return (
                user?.permissions &&
                user.permissions.indexOf('products-edit') !== -1
            );
        },
        children: [
            {
                key: 'weclapp-supplier-supplier--switch',
                label: 'Austausch des Lieferanten',
                icon: 'pi pi-arrow-right-arrow-left',
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('products-edit') !== -1
                    );
                }
            }
        ]
    }
];
