import router from '@/router/index';
import {computed, toRefs} from 'vue';
import store from '@/store';

export default {
    props: {
        blockSwitching: {
            type: Boolean,
            default: false
        },
        queryParams: {
            type: Object,
            default: null
        }
    },
    setup(props: any) {
        const {blockSwitching, queryParams} = toRefs(props);

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );
        });

        const changeSalesChannel = (salesChannelsArray: Array<string>) => {
            if (
                blockSwitching.value ||
                !salesChannelsArray.includes(
                    resolvedPlatform.value?.threeLetterId
                )
            ) {
                return;
            }

            let selectedSalesChannel = null;
            switch (resolvedPlatform.value?.threeLetterId) {
                case 'vdd':
                    selectedSalesChannel = 'teltec-demo';
                    break;
                case 'ttd':
                    selectedSalesChannel = 'videodata-demo';
                    break;
                case 'ttl':
                    selectedSalesChannel = 'videodata';
                    break;
                default:
                    selectedSalesChannel = 'teltec';
                    break;
            }

            const value = router.currentRoute.value;

            router.replace('/main');
            store.dispatch('auth/setPlatform', selectedSalesChannel);
            setTimeout(() => {
                router.replace(
                    Object.assign(value, {query: queryParams.value || {}})
                );
            }, 1);
        };

        return {
            resolvedPlatform,
            changeSalesChannel,
            blockSwitching
        };
    }
};
