import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const add = async (
    productNumber: string,
    title: string,
    files: Array<any>
): Promise<AxiosResponse> => {
    const formData = new FormData();

    formData.append('title', title);
    formData.append('file', files[0]);

    return apiClient.post(
        '/api/v1/products/' + productNumber + '/documents',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );
};

export const addFromUrl = async (
    productNumber: string,
    title: string,
    url: string
): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/products/' + productNumber + '/documents', {
        title,
        url
    });
};

export const update = async (
    productNumber: string,
    title: string,
    id: string,
    platform: string | null = null
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/products/' +
            productNumber +
            '/documents/' +
            id +
            (platform ? '?platform=' + platform : ''),
        {
            title
        }
    );
};

export const remove = async (
    productNumber: string,
    id: string,
    platform: string | null = null
): Promise<AxiosResponse> => {
    return apiClient.delete(
        '/api/v1/products/' +
            productNumber +
            '/documents/' +
            id +
            (platform ? '?platform=' + platform : '')
    );
};

export const bulkProcessWeclappDocuments = async (
    products: Array<any>,
    productFilters: any,
    files: Array<any>,
    description: string | null,
    isFilterRelated: boolean
): Promise<AxiosResponse> => {
    const formData = prepareFormDataForFileUpload(
        description,
        files,
        isFilterRelated,
        productFilters,
        products
    );

    return apiClient.post('/api/v1/product-weclapp-documents', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const bulkProcessShopwareDocuments = async (
    products: Array<any>,
    productFilters: any,
    files: Array<any>,
    description: string | null,
    isFilterRelated: boolean
): Promise<AxiosResponse> => {
    const formData = prepareFormDataForFileUpload(
        description,
        files,
        isFilterRelated,
        productFilters,
        products
    );

    return apiClient.post('/api/v1/product-shopware-documents', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

const prepareFormDataForFileUpload = (
    description: string,
    files: any[],
    isFilterRelated: boolean,
    productFilters: any,
    products: any[]
) => {
    const formData = new FormData();

    formData.append('description', description);
    for (let x = 0; x < files.length; x++) {
        formData.append('files[]', files[x]);
    }

    if (isFilterRelated) {
        formData.append(
            'filters',
            JSON.stringify(clearEmptyFilters(productFilters))
        );
    } else {
        formData.append(
            'products',
            JSON.stringify(
                products.map(({id, articleNumber}) => {
                    return {
                        id,
                        articleNumber
                    };
                })
            )
        );
    }
    return formData;
};
