import DataView from 'primevue/dataview';
import LoadingPlugin from 'vue-loading-overlay';
import DeleteDialog from '@/components/dialog/product-videos/delete-video.vue';
import {ref, toRefs, watch} from 'vue';
import ScrollPanel from 'primevue/scrollpanel';

export default {
    emits: ['reload-product'],
    components: {
        DataView,
        loading: LoadingPlugin,
        DeleteDialog,
        ScrollPanel
    },
    props: {
        shopwareDetails: Object,
        platform: {
            type: String,
            default: null
        }
    },
    setup(props: any, context: any) {
        const videos = ref([]);
        const selectedVideo = ref(null);
        const shopwareDetails = ref(null);
        const displayDeleteDialog = ref(false);
        const {platform} = toRefs(props);

        watch(props, () => {
            shopwareDetails.value = props.shopwareDetails;
            videos.value = props.shopwareDetails?.videos;
        });

        const showDeleteDialog = (data: any) => {
            selectedVideo.value = data;
            displayDeleteDialog.value = true;
        };

        const closeDialogListener = (forceRefresh: boolean = false) => {
            displayDeleteDialog.value = false;
            if (forceRefresh) {
                context.emit('reload-product');
            }
        };

        return {
            videos,
            shopwareDetails,
            selectedVideo,
            displayDeleteDialog,
            showDeleteDialog,
            platform,
            closeDialogListener
        };
    }
};
