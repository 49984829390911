import {computed, ref, toRefs, watch} from 'vue';
import {bulkSave} from '@/services/product-accessories';
import Dialog from 'primevue/dialog';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Button from 'primevue/button';
import LoadingPlugin from 'vue-loading-overlay';
import ProductList from '@/components/grid/products/list.vue';
import ProductListLazy from '@/components/grid/products/list-lazy.vue';
import {useToast} from 'vue-toastification';
import Panel from 'primevue/panel';
import ScrollPanel from 'primevue/scrollpanel';
import {i18n} from '@/utils/i18n';

export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        TabPanel,
        TabView,
        ProductList,
        ProductListLazy,
        'p-button': Button,
        LoadingPlugin,
        Panel,
        ScrollPanel
    },
    props: {
        accessories: Array,
        displayResponsive: Boolean,
        platform: {
            type: String,
            default: null
        },
        productDetails: {
            type: Object,
            default: null
        }
    },
    setup(props: any, context: any) {
        const {productDetails, accessories, platform} = toRefs(props);
        const showDialog = ref(false);
        const loading = ref(false);

        const toast = useToast();

        watch(props, (args) => {
            showDialog.value = args.displayResponsive;
        });

        const closeResponsive = (forceResfresh: boolean = false) => {
            context.emit('close-dialog', forceResfresh);
        };

        const showSummaryTab = computed(() => {
            return (
                accessories.value &&
                accessories.value.length > 0 &&
                productDetails.value
            );
        });

        const addArticleAccessories = async () => {
            loading.value = true;
            try {
                await bulkSave(
                    [
                        {
                            id: productDetails.value?.secondShopware?.id,
                            articleNumber:
                                productDetails.value?.secondShopware
                                    ?.productNumber
                        }
                    ],
                    accessories.value,
                    false,
                    platform.value
                );

                toast.success(
                    i18n.global.t('messages.changesSavedSuccessfully')
                );
                closeResponsive(true);
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                loading.value = false;
            }
        };

        return {
            productDetails,
            accessories,
            showDialog,
            loading,
            closeResponsive,
            addArticleAccessories,
            showSummaryTab,
            platform
        };
    }
};
