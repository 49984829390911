import {IAuthState} from '@/interfaces/state';
import {IUser} from '@/interfaces/user';

export default {
    user: (state: IAuthState): IUser => state.user,
    platform: (state: IAuthState): string =>
        state.platform ||
        (localStorage.getItem('platform') &&
        localStorage.getItem('platform').startsWith('ttc-')
            ? localStorage.getItem('platform').replace('ttc-', '')
            : false) ||
        'teltec',
    platforms: (
        state: IAuthState
    ): Array<{label: string; value: string; threeLetterId?: string}> =>
        state.platforms,
    autoSaveProductDescription: (state: IAuthState): boolean => {
        return (
            state.autoSaveProductDescription ||
            (localStorage.getItem('auto-save-product-description') &&
                JSON.parse(
                    localStorage.getItem('auto-save-product-description')
                ))
        );
    }
};
