import apiClient from '@/utils/axios';
import router from '@/router/index';
import {computed, onMounted, ref} from 'vue';
import store from '@/store';
import Button from 'primevue/button';
import OverlayPanel from 'primevue/overlaypanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
    components: {
        OverlayPanel,
        'p-button': Button,
        DataTable,
        Column
    },

    setup() {
        const platforms = ref([]);
        const opPlatforms = ref(null);

        onMounted(async () => {
            const result = await apiClient.get('/api/v1/platforms');
            platforms.value = result.data.filter(
                (item: {value: string}) =>
                    demoPermissionAvailable.value ||
                    !item.value.includes('demo')
            );
            store.dispatch('auth/setPlatforms', result.data);
        });

        const platform = computed(() => {
            return store.getters['auth/platform'];
        });

        const demoPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('demo-environments-view') !== -1;
        });

        const changePlatform = (platform: any) => {
            const value = router.currentRoute.value;
            router.replace('/main');
            store.dispatch('auth/setPlatform', platform.value);
            setTimeout(() => {
                router.replace(value);
            }, 1);
        };

        const toggleDataTable = (event: any) => {
            opPlatforms.value.toggle(event);
        };

        return {
            platform,
            platforms,
            changePlatform,
            toggleDataTable,
            opPlatforms
        };
    }
};
