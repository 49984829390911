import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { style: {"text-transform":"uppercase"} }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { style: {"text-transform":"uppercase"} }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 1,
  class: "mt-2"
}
const _hoisted_8 = { style: {"text-transform":"uppercase"} }
const _hoisted_9 = {
  key: 2,
  class: "mt-2"
}
const _hoisted_10 = { style: {"text-transform":"uppercase"} }
const _hoisted_11 = {
  key: 3,
  class: "mt-2"
}
const _hoisted_12 = { style: {"text-transform":"uppercase"} }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { class: "text-blue-400" }
const _hoisted_17 = { class: "text-green-400" }
const _hoisted_18 = { class: "text-orange-400" }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "text-blue-400" }
const _hoisted_21 = { class: "text-blue-400" }
const _hoisted_22 = { class: "flex align-items-center mt-2" }
const _hoisted_23 = {
  class: "surface-300 border-round overflow-hidden w-10rem lg:w-4rem",
  style: {"height":"8px"}
}
const _hoisted_24 = { class: "text-blue-500 ml-2 font-medium" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = { class: "text-green-400" }
const _hoisted_27 = { class: "text-orange-400" }
const _hoisted_28 = { class: "text-purple-400" }
const _hoisted_29 = { class: "text-blue-400" }
const _hoisted_30 = { class: "font-semibold" }
const _hoisted_31 = { class: "text-xs" }
const _hoisted_32 = { style: {"text-transform":"uppercase"} }
const _hoisted_33 = { class: "font-semibold" }
const _hoisted_34 = { class: "text-xs" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_Tag = _resolveComponent("Tag")
  const _component_Column = _resolveComponent("Column")
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_button = _resolveComponent("p-button")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, {
      header: _ctx.$t('labels.importLog')
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          stripedRows: "",
          paginator: "",
          rows: 10,
          scrollable: "",
          scrollHeight: "calc(100vh - 25rem)",
          lazy: "",
          ref: "dt",
          dataKey: "id",
          totalRecords: _ctx.totalRecords,
          value: _ctx.logs,
          responsiveLayout: "scroll",
          "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
          "rows-per-page-options": [10,20,50],
          "current-page-report-template": "{first} to {last} of {totalRecords}",
          filters: _ctx.filters,
          "onUpdate:filters": _cache[1] || (_cache[1] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          onPage: _cache[2] || (_cache[2] = $event => (_ctx.onPage($event))),
          onFilter: _cache[3] || (_cache[3] = $event => (_ctx.onFilter($event))),
          onSort: _cache[4] || (_cache[4] = $event => (_ctx.onSort($event)))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "isAutomatic",
              style: {"min-width":"10rem"},
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_TriStateCheckbox, {
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onChange: $event => (filterCallback())
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
              ]),
              body: _withCtx(({data}) => [
                (!data?.isAutomatic)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_Tag, { severity: "warning" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('labels.mail.manual')), 1)
                        ]),
                        _: 1
                      })
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_Tag, { severity: "primary" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('labels.mail.automatic')), 1)
                        ]),
                        _: 1
                      })
                    ]))
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "supplierName",
              style: {"min-width":"14rem"},
              header: _ctx.$t('labels.productAdd.supplier'),
              sortable: ""
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              body: _withCtx(({data}) => [
                _createTextVNode(_toDisplayString(data.supplierConfiguration?.supplierName) + " ", 1),
                (data.supplierId && data.supplierConfiguration?.supplierNumber)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                      _createTextVNode("["),
                      _createElementVNode("a", {
                        href: data.url + 'webapp/view/party/PartyDetail.page?entityId=' + data.supplierId,
                        target: "_blank"
                      }, _toDisplayString(data.supplierConfiguration.supplierNumber), 9, _hoisted_6),
                      _createTextVNode("]")
                    ]))
                  : _createCommentVNode("", true),
                (data.manufacturerName)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_Tag, null, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('labels.manufacturer')) + ": " + _toDisplayString(data.manufacturerName), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  : _createCommentVNode("", true),
                (data?.costPriceStartDate )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_Tag, { severity: "info" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_10, "EK AB " + _toDisplayString(data.costPriceStartDate), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  : _createCommentVNode("", true),
                (data?.listPriceStartDate )
                  ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createVNode(_component_Tag, { severity: "info" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_12, "UVP AB " + _toDisplayString(data.listPriceStartDate), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('labels.filename')
            }, {
              body: _withCtx(({data}) => [
                ((data.files || []).length > 0)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(data.files, (file) => {
                      return (_openBlock(), _createElementBlock("ul", null, [
                        _createElementVNode("li", null, [
                          _createTextVNode(_toDisplayString(_ctx.stripTagsAndTruncate(file.filePath, 80, '...')) + " ", 1),
                          _createElementVNode("i", {
                            style: {"cursor":"pointer"},
                            class: "pi pi-download text-sm ml-2",
                            onClick: $event => (_ctx.downloadAttachment(data.id, file.id))
                          }, null, 8, _hoisted_13)
                        ])
                      ]))
                    }), 256))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "status",
              header: _ctx.$t('labels.status'),
              sortable: "",
              filterMatchModeOptions: [{label: 'Equals', value: 'equals'}]
            }, {
              body: _withCtx(({data}) => [
                data
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (!data?.processingStartedAt)
                        ? (_openBlock(), _createBlock(_component_Tag, {
                            key: 0,
                            severity: "warning"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(_ctx.$t('labels.scheduled')), 1)
                            ]),
                            _: 1
                          }))
                        : (!data?.processingEndedAt)
                          ? (_openBlock(), _createBlock(_component_Tag, {
                              key: 1,
                              severity: "info"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('labels.inProgress')), 1)
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_Tag, {
                              key: 2,
                              severity: "success"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('labels.done')), 1)
                              ]),
                              _: 1
                            }))
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('labels.progress'),
              style: {"min-width":"14rem"}
            }, {
              body: _withCtx(({data}) => [
                data
                  ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                      (data?.processingEndedAt)
                        ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_15, [
                            _createElementVNode("span", _hoisted_16, _toDisplayString(data.itemsCount), 1),
                            _createTextVNode(" / "),
                            _createElementVNode("span", _hoisted_17, _toDisplayString(data.statistics?.transformedItemsCount), 1),
                            _createTextVNode(" / "),
                            _createElementVNode("span", _hoisted_18, _toDisplayString(data.statistics?.eolItemsCount), 1)
                          ])), [
                            [
                              _directive_tooltip,
                              _ctx.$t('labels.priceImport.totalSkus') + ' / ' + _ctx.$t('labels.priceImport.mappedSkus') + ' / ' + _ctx.$t('labels.priceImport.eolProducts'),
                              void 0,
                              { left: true }
                            ]
                          ])
                        : _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_19, [
                            _createElementVNode("span", _hoisted_20, _toDisplayString(data.itemsCount + data.weclappProductsCount), 1),
                            _createTextVNode(" / "),
                            _createElementVNode("span", _hoisted_21, _toDisplayString(data.processedItemsCount +
                            data.processedWeclappProductsCount), 1)
                          ])), [
                            [
                              _directive_tooltip,
                              _ctx.$t('labels.importLogsResultHeaderTooltip'),
                              void 0,
                              { left: true }
                            ]
                          ])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", {
                      class: "h-full bg-blue-500",
                      style: _normalizeStyle('width: ' + ((data.weclappProductsCount + data.itemsCount) > 0 ? Math.floor(((data.processedItemsCount +
                            data.processedWeclappProductsCount) * 100) /
                            (data.weclappProductsCount + data.itemsCount)) : 0) + '%')
                    }, null, 4)
                  ]),
                  _createElementVNode("span", _hoisted_24, _toDisplayString((data.weclappProductsCount + data.itemsCount) > 0 ?
                        Math.floor(((data.processedItemsCount +
                        data.processedWeclappProductsCount) * 100) /
                        (data.weclappProductsCount + data.itemsCount)) : 0) + " % ", 1)
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: _ctx.$t('labels.overview'),
              style: {"min-width":"8rem"}
            }, {
              body: _withCtx(({data}) => [
                (data && data.processingEndedAt)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                      _withDirectives((_openBlock(), _createElementBlock("div", null, [
                        _createElementVNode("span", _hoisted_26, _toDisplayString(data.statistics?.nonChangedCount), 1),
                        _createTextVNode(" / "),
                        _createElementVNode("span", _hoisted_27, _toDisplayString(data.statistics?.costPriceChangedCount), 1),
                        _createTextVNode(" / "),
                        _createElementVNode("span", _hoisted_28, _toDisplayString(data.statistics?.listPriceChangedCount), 1),
                        _createTextVNode(" / "),
                        _createElementVNode("span", _hoisted_29, _toDisplayString(data.statistics?.wbtChangedCount), 1)
                      ])), [
                        [
                          _directive_tooltip,
                          _ctx.$t('labels.priceImport.unchangedProducts') + ' / ' + _ctx.$t('labels.priceImport.costPriceChangeProducts') + ' / ' + _ctx.$t('labels.priceImport.listPriceChangeProducts') + ' / ' + _ctx.$t('labels.priceImport.wbtChangeProducts'),
                          void 0,
                          { left: true }
                        ]
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "uploadedBy",
              header: _ctx.$t('labels.uploadedBy'),
              style: {"min-width":"16rem"}
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_30, _toDisplayString(data.uploadedBy), 1)
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_31, _toDisplayString(data.importedAt), 1)
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "lastRunBy",
              header: _ctx.$t('labels.processedBy'),
              style: {"min-width":"16rem"}
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-sm"
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown"])
              ]),
              body: _withCtx(({data}) => [
                (data.importInProgress)
                  ? (_openBlock(), _createBlock(_component_Tag, {
                      key: 0,
                      severity: "danger"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.$t('labels.inProgress')), 1)
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("div", null, [
                        _createElementVNode("span", _hoisted_33, _toDisplayString(data.lastRunBy), 1)
                      ]),
                      _createElementVNode("div", null, [
                        _createElementVNode("span", _hoisted_34, _toDisplayString(data.lastRunAt), 1)
                      ])
                    ], 64))
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, { style: {"min-width":"27rem"} }, {
              body: _withCtx(({data}) => [
                (data?.processingEndedAt)
                  ? (_openBlock(), _createBlock(_component_p_button, {
                      key: 0,
                      class: "text-xs details-button mr-2",
                      label: _ctx.$t('labels.overview'),
                      severity: "success",
                      icon: "fas fa-external-link-alt",
                      onClick: $event => (_ctx.navigateToSummary(data))
                    }, null, 8, ["label", "onClick"]))
                  : _createCommentVNode("", true),
                (data?.processingEndedAt)
                  ? (_openBlock(), _createBlock(_component_p_button, {
                      key: 1,
                      class: "text-xs details-button mr-2",
                      label: "Details",
                      severity: "info",
                      icon: "fas fa-external-link-alt",
                      onClick: $event => (_ctx.navigateToDetails(data))
                    }, null, 8, ["onClick"]))
                  : _createCommentVNode("", true),
                (!data?.importInProgress && data?.processingEndedAt && data?.statistics?.regularItemsCount > data?.statistics?.handledItemsCount)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createVNode(_component_p_button, {
                        class: "text-xs details-button mr-2",
                        label: "",
                        severity: "danger",
                        icon: "fas fa-play",
                        onClick: $event => (_ctx.runManually(data))
                      }, null, 8, ["onClick"]),
                      (!data.lastRunAtTimestamp)
                        ? (_openBlock(), _createBlock(_component_p_button, {
                            key: 0,
                            class: "text-xs details-button",
                            label: _ctx.$t('buttons.rejectImport'),
                            severity: "warning",
                            icon: "fas fa-trash",
                            onClick: $event => (_ctx.rejectImport(data))
                          }, null, 8, ["label", "onClick"]))
                        : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["totalRecords", "value", "filters"])
      ]),
      _: 1
    }, 8, ["header"])
  ], 64))
}