import {ref, onMounted, onUnmounted} from 'vue';
import DataView from 'primevue/dataview';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import LoadingPlugin from 'vue-loading-overlay';
import Panel from 'primevue/panel';
import {useToast} from 'vue-toastification';
import {getAllOpen} from '@/services/shipments';
import {getAll} from '@/services/metadata';
import store from '@/store';
export default {
    components: {
        DataView,
        Column,
        InputText,
        Dropdown,
        Panel,
        loading: LoadingPlugin
    },
    setup() {
        onMounted(() => {
            loadLazyData();

            getAll(['warehouse'])
                .then((data: any) => {
                    warehouses.value = data.data?.warehouse || [];
                })
                .catch((error: any) => {
                    toast.error(error.message);
                });
        });

        onUnmounted(() => {
            if (interval.value !== null) {
                clearInterval(interval.value);
            }
        });

        const dv = ref();
        const loading = ref(false);
        const lazyParams: any = ref({});
        const toast = useToast();
        const interval = ref(null);

        const warehouses = ref([]);

        const allOpenCount = ref(null);

        const shipments = ref([]);
        const newShipments = ref([]);
        const pickups = ref([]);
        const newPickups = ref([]);
        const audio = new Audio(require('@/assets/audio/open-shipments.mp3'));

        const chosenWarehouseId = ref(null);

        const loadLazyData = async () => {
            loading.value = true;

            if (!chosenWarehouseId.value) {
                const openShipmentsInState = await store.dispatch(
                    'shipment/getOpenShipments'
                );

                chosenWarehouseId.value = openShipmentsInState?.warehouseId
                    ? openShipmentsInState.warehouseId
                    : '3874754';
            }

            getAllOpen(chosenWarehouseId.value)
                .then((data) => {
                    const previousShipments = shipments.value.map((item) => ({
                        ...item
                    }));

                    const previousPickups = pickups.value.map((item) => ({
                        ...item
                    }));

                    shipments.value = data.data.items.sort(
                        (a: any, b: any) => sortScore(a) - sortScore(b)
                    );
                    pickups.value = data.data.pickups.sort(
                        (a: any, b: any) => sortScore(a) - sortScore(b)
                    );

                    allOpenCount.value = data.data.allOpenCount;

                    newShipments.value =
                        previousShipments.length < 1
                            ? []
                            : shipments.value.filter((item: any) => {
                                  return !previousShipments.some(
                                      (previousShipment: any) =>
                                          previousShipment.shipmentNumber ===
                                          item.shipmentNumber
                                  );
                              });

                    newPickups.value =
                        previousPickups.length < 1
                            ? []
                            : pickups.value.filter((item: any) => {
                                  return !previousPickups.some(
                                      (previousPickup: any) =>
                                          previousPickup.shipmentNumber ===
                                          item.shipmentNumber
                                  );
                              });

                    if (
                        newShipments.value.length > 0 ||
                        newPickups.value.length > 0
                    ) {
                        const promise = audio.play();
                        if (promise !== undefined) {
                            promise
                                .then(() => {
                                    // Autoplay started
                                })
                                .catch((error: any) => {
                                    toast.error(error.message);
                                });
                        }
                    }

                    loading.value = false;
                })
                .catch((error) => {
                    loading.value = false;
                    toast.error(error.message);
                })
                .finally(() => {
                    if (interval.value !== null) {
                        clearInterval(interval.value);
                    }
                    interval.value = setInterval(() => {
                        loadLazyData();
                    }, 15000);
                });
        };

        const sortScore = (item: any) => {
            return item.transportationOrderStatus === 'COMPLETED'
                ? 2
                : item.transportationOrderStatus === 'IN_TRANSIT'
                ? 1
                : 0;
        };

        const onWarehouseChange = () => {
            store.dispatch('shipment/setOpenShipments', {
                warehouseId: chosenWarehouseId.value
            });

            loadLazyData();
        };

        return {
            shipments,
            dv,
            loading,
            lazyParams,
            pickups,
            newShipments,
            newPickups,
            allOpenCount,
            warehouses,
            chosenWarehouseId,
            onWarehouseChange
        };
    }
};
