import {IUser} from '@/interfaces/user';
import {i18n} from '../i18n';

export const evaluateChildNodes = (
    nodes: Array<{
        key: string;
        label: string;
        selectable?: boolean;
        style?: string;
        children?: Array<any>;
        isEnabled?: any;
    }>,
    user: IUser
) => {
    nodes.forEach((singleNode) => {
        if (singleNode.isEnabled) {
            const isNodeEnabled = singleNode.isEnabled(user);
            singleNode.selectable = isNodeEnabled;
            singleNode.style = isNodeEnabled ? '' : 'color: gray;';
        }

        if (singleNode.children) {
            evaluateChildNodes(singleNode.children, user);
        }
    });

    return nodes;
};

export const wizardOptions = [
    {
        key: '0',
        label: 'Weclapp',
        icon: 'pi pi-fw pi-heart',
        selectable: false,
        children: [
            {
                key: 'weclapp-articles-add',
                label: i18n.global.t('labels.importArticles') + ' [Weclapp]',
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('products-edit') !== -1
                    );
                }
            },
            {
                key: 'weclapp-articles-edit',
                label: i18n.global.t('labels.updateArticles') + ' [Weclapp]',
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('products-edit') !== -1
                    );
                }
            },
            {
                key: 'weclapp-article-supply-sources-edit',
                label: i18n.global.t('labels.updateArticleSupplySources'),
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('products-edit') !== -1
                    );
                }
            },
            {
                key: 'weclapp-images-edit',
                label:
                    i18n.global.t('labels.importProductImages') + ' [Weclapp]',
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('products-edit') !== -1
                    );
                }
            },
            {
                key: 'weclapp-metatags-edit',
                label: i18n.global.t('labels.importMetatags'),
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('products-edit') !== -1
                    );
                }
            },
            {
                key: 'weclapp-tags-edit',
                label: i18n.global.t('labels.importTags'),
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('products-edit') !== -1
                    );
                }
            },
            {
                key: 'weclapp-invoices',
                label: i18n.global.t('labels.accounting'),
                icon: 'pi pi-fw pi-euro',
                selectable: false,
                children: [
                    {
                        key: 'weclapp-paid-invoices-add',
                        label: i18n.global.t('labels.importPaidInvoices'),
                        isEnabled: (user: IUser): boolean => {
                            return (
                                user?.permissions &&
                                user.permissions.indexOf(
                                    'weclapp-invoices-edit'
                                ) !== -1
                            );
                        }
                    },
                    {
                        key: 'weclapp-credit-limit-abc-edit',
                        label: i18n.global.t('labels.updateCreditLimitABC'),
                        isEnabled: (user: IUser): boolean => {
                            return (
                                user?.permissions &&
                                user.permissions.indexOf(
                                    'weclapp-invoices-edit'
                                ) !== -1
                            );
                        }
                    },
                    {
                        key: 'weclapp-credit-limit-datev-edit',
                        label: i18n.global.t('labels.updateCreditLimitDatev'),
                        isEnabled: (user: IUser): boolean => {
                            return (
                                user?.permissions &&
                                user.permissions.indexOf(
                                    'weclapp-invoices-edit'
                                ) !== -1
                            );
                        }
                    },
                    {
                        key: 'weclapp-debtor-data-edit',
                        label: i18n.global.t('labels.compareDebtorData'),
                        isEnabled: (user: IUser): boolean => {
                            return (
                                user?.permissions &&
                                user.permissions.indexOf(
                                    'weclapp-invoices-edit'
                                ) !== -1
                            );
                        }
                    }
                ]
            },
            {
                key: 'weclapp-shipments',
                label: i18n.global.t('labels.shipments'),
                icon: 'fa fa-truck',
                selectable: false,
                children: [
                    {
                        key: 'weclapp-headless-shipments-add',
                        label: i18n.global.t(
                            'labels.importHeadlessShipmentsCustomerAddresses'
                        ),
                        isEnabled: (user: IUser): boolean => {
                            return (
                                user?.permissions &&
                                user.permissions.indexOf(
                                    'headless-shipments-edit'
                                ) !== -1
                            );
                        }
                    }
                ]
            },
            {
                key: 'weclapp-warehouse',
                label: i18n.global.t('labels.shipment.warehouse'),
                icon: 'fas fa-warehouse',
                selectable: false,
                children: [
                    {
                        key: 'weclapp-stock-add',
                        label: i18n.global.t('labels.importStock'),
                        isEnabled: (user: IUser): boolean => {
                            return (
                                user?.permissions &&
                                user.permissions.indexOf(
                                    'weclapp-warehouses-edit'
                                ) !== -1
                            );
                        }
                    },
                    {
                        key: 'weclapp-stock-block-add',
                        label: i18n.global.t('labels.importStockBlock'),
                        isEnabled: (user: IUser): boolean => {
                            return (
                                user?.permissions &&
                                user.permissions.indexOf(
                                    'weclapp-warehouses-edit'
                                ) !== -1
                            );
                        }
                    },
                    {
                        key: 'weclapp-shelf-add',
                        label: i18n.global.t('labels.importShelves'),
                        isEnabled: (user: IUser): boolean => {
                            return (
                                user?.permissions &&
                                user.permissions.indexOf(
                                    'weclapp-warehouses-edit'
                                ) !== -1
                            );
                        }
                    },
                    {
                        key: 'weclapp-barcodes-add',
                        label: i18n.global.t('labels.importBarcodes'),
                        isEnabled: (user: IUser): boolean => {
                            return (
                                user?.permissions &&
                                user.permissions.indexOf(
                                    'weclapp-warehouses-edit'
                                ) !== -1
                            );
                        }
                    },
                    {
                        key: 'weclapp-barcodes-block-add',
                        label: i18n.global.t('labels.importBarcodesBlock'),
                        isEnabled: (user: IUser): boolean => {
                            return (
                                user?.permissions &&
                                user.permissions.indexOf(
                                    'weclapp-warehouses-edit'
                                ) !== -1
                            );
                        }
                    }
                ]
            },
            {
                key: 'weclapp-addresses',
                label: i18n.global.t('labels.addresses'),
                icon: 'pi pi-fw pi-inbox',
                selectable: false,
                children: [
                    {
                        key: 'weclapp-customer-addresses-edit',
                        label: i18n.global.t('labels.importCustomerAddresses'),
                        isEnabled: (user: IUser): boolean => {
                            return (
                                user?.permissions &&
                                user.permissions.indexOf('products-edit') !== -1
                            );
                        }
                    },
                    {
                        key: 'weclapp-supplier-addresses-edit',
                        label: i18n.global.t('labels.importSupplierAddresses'),
                        isEnabled: (user: IUser): boolean => {
                            return (
                                user?.permissions &&
                                user.permissions.indexOf('products-edit') !== -1
                            );
                        }
                    }
                ]
            }
        ]
    },
    {
        key: '1',
        label: 'Shopware',
        selectable: false,
        icon: 'pi pi-fw pi-shopping-cart',
        children: [
            {
                key: 'shopware-accessories-add',
                label:
                    i18n.global.t('labels.importAccessories') + ' [Shopware]',
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('products-edit') !== -1
                    );
                }
            },
            {
                key: 'shopware-badges-add',
                label: i18n.global.t('labels.importBadges'),
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('products-edit') !== -1
                    );
                }
            },

            {
                key: 'shopware-images-add',
                label: i18n.global.t('labels.importProductImages'),
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('products-edit') !== -1
                    );
                }
            },
            {
                key: 'shopware-links-add',
                label: i18n.global.t('labels.importProductLinks'),
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('products-edit') !== -1
                    );
                }
            },
            {
                key: 'shopware-documents-add',
                label: i18n.global.t('labels.importProductDocuments'),
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('products-edit') !== -1
                    );
                }
            },
            {
                key: 'shopware-videos-add',
                label: i18n.global.t('labels.importProductVideos'),
                isEnabled: (user: IUser): boolean => {
                    return (
                        user?.permissions &&
                        user.permissions.indexOf('products-edit') !== -1
                    );
                }
            }
        ]
    }
];
