<div>
    <span
        class="sales-channel-dot sales-channel-teltec"
        v-tooltip.top="'Teltec'"
        :class="{'bg-gray-200':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
        :style="{'cursor':((!blockSwitching && ['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)) ? 'pointer' : 'auto')}"
        @click="{changeSalesChannel(['vdd', 'vdl']);}"
    ></span>
    <span
        class="sales-channel-dot sales-channel-videodata ml-2"
        :class="{'bg-gray-200':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId)}"
        :style="{'cursor':((!blockSwitching && ['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId)) ? 'pointer' : 'auto')}"
        v-tooltip.top="'Videodata'"
        @click="{changeSalesChannel(['ttd', 'ttl']);}"
    ></span>
</div>
