import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const getAllCustomers = (
    input: any,
    platform: string | null = null
): Promise<AxiosResponse> => {
    const filters = Object.assign(
        {
            customer: {
                value: true,
                matchMode: 'equals'
            }
        },
        input.filters
    );
    return apiClient.get(
        '/api/v1/parties?start=' +
            input.first +
            '&end=' +
            (input.first + input.rows) +
            '&sortField=' +
            (input.sortField ?? '') +
            '&sortOrder=' +
            (input.sortOrder === 1 ? 'asc' : 'desc') +
            '&columns=' +
            JSON.stringify(input.columns || []) +
            '&filters=' +
            encodeURIComponent(
                JSON.stringify(clearEmptyFilters(filters || {}))
            ) +
            '&filterConjunction=' +
            (input.filterConjunction || 'or') +
            (platform ? '&platform=' + platform : '')
    );
};

export const getAllCustomersUsingHashedData = (
    company: string | null = null,
    street: string | null = null,
    postalCode: string | null = null,
    countryCode: string | null = null,
    platform: string | null = null
): Promise<AxiosResponse> => {
    const asArray = Object.entries({
        company,
        street,
        postalCode,
        countryCode,
        platform
    });

    const filtered = asArray.filter((value: any) => {
        return (
            value.length === 2 && value[1] !== null && value[1].trim() !== ''
        );
    });

    const params = Object.fromEntries(filtered);

    const queryString = Object.keys(params)
        .map((key) => key + '=' + params[key as keyof typeof params])
        .join('&');

    return apiClient.get('/api/v1/search-weclapp-customer-data?' + queryString);
};

export const recalculateCustomerDataHashes = (
    customerNumbers: string[]
): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/recalculate-customer-data-hash', {
        customerNumbers
    });
};

export const editSingleCustomer = (
    customerNumber: string | string[],
    payload: any,
    platform: string | null = null
): Promise<AxiosResponse> => {
    if (platform) {
        return apiClient.put('/api/v1/parties/' + customerNumber, payload, {
            params: {platform}
        });
    }
    return apiClient.get('/api/v1/parties/' + customerNumber, payload);
};
