import {useRoute} from 'vue-router';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import LinksContainer from './../links/links-container.vue';
import DocumentsContainer from './../documents/documents-container.vue';
import ImagesContainer from './../images/images-container.vue';
import VideosContainer from './../videos/videos-container.vue';
import {computed, ref, watch} from 'vue';

import store from '@/store';

export default {
    emits: ['reload-product'],
    components: {
        TabPanel,
        TabView,
        LinksContainer,
        DocumentsContainer,
        ImagesContainer,
        VideosContainer,
        'p-dialog': Dialog,
        'p-button': Button
    },
    props: {
        productDetails: Object
    },

    setup(props: any, context: any) {
        const route = useRoute();
        const productDetails = ref(null);

        watch(props, () => {
            productDetails.value = props.productDetails;
        });

        const resolvedPlatform = computed(() => {
            const currentPlatform = store.getters['auth/platform'];
            const platforms = store.getters['auth/platforms'];
            return platforms.find(
                (item: any) => item.value === currentPlatform
            );
        });

        const loadProductDetails = () => {
            context.emit('reload-product');
        };

        return {
            productNumber: route.params.id,
            productDetails,
            resolvedPlatform,
            loadProductDetails
        };
    }
};
