import {getOriginalFile, schedulePriceImport} from '@/services/import';
import {i18n} from '@/utils/i18n';
import {computed, ref, watch} from 'vue';
import {useToast} from 'vue-toastification';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import {helpers, required} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import Tag from 'primevue/tag';
import TieredMenu from 'primevue/tieredmenu';
import store from '@/store';
import {ILayoutConfig} from '@/interfaces/layout/config';
import {useConfirm} from 'primevue/useconfirm';
import currency from 'currency.js';
import {
    addTemplate,
    getAllTemplates,
    getSupplierConfiguration
} from '@/services/price-import';
import {FilterMatchMode} from 'primevue/api';
import PriceImportTemplateMenu from '@/components/price-import-template-menu/price-import-template-menu.vue';
import router from '@/router';
import LoadingPlugin from 'vue-loading-overlay';

export default {
    name: 'PriceListUploadStep',
    emits: ['column-mapping-saved', 'back-button-clicked'],
    components: {
        'p-button': Button,
        DataTable,
        Column,
        Tag,
        TieredMenu,
        PriceImportTemplateMenu,
        'p-dropdown': Dropdown,
        LoadingPlugin
    },
    props: {
        savedFileId: {
            type: Number,
            default: null
        },
        chosenSupplier: {
            type: String,
            default: null
        },
        sheetName: {
            type: String,
            default: null
        },
        chosenManufacturer: {
            type: String,
            default: null
        },
        costPriceStartDate: {
            type: Date,
            default: null
        },
        listPriceStartDate: {
            type: Date,
            default: null
        }
    },
    setup(props: any, context: any) {
        const savedFileId = ref(null);

        const submitted = ref(false);
        const chosenSupplier = ref(null);
        const sheetName = ref(null);
        const chosenManufacturer = ref(null);
        const supplierConfiguration = ref(null);
        const costPriceStartDate = ref(null);
        const listPriceStartDate = ref(null);

        const bulkMenu = ref([]);

        const items = ref([]);
        const columns = ref([]);

        const lastColumnIndex = ref(-1);
        const rowsToSkip = ref([]);

        const confirm = useConfirm();
        const toast = useToast();
        const loading = ref(false);

        const state = ref({
            headerRowIndex: -1,
            columnMappingArray: []
        });

        watch(props, async (args) => {
            if (
                args.savedFileId !== null &&
                args.savedFileId !== savedFileId.value
            ) {
                items.value.length = 0;
                columns.value.length = 0;
                savedFileId.value = args.savedFileId;

                loading.value = true;

                getOriginalFile(savedFileId.value)
                    .then((data: any) => {
                        if (data.length > 0) {
                            const colName = (n: number): string => {
                                const ordA = 'a'.charCodeAt(0);
                                const ordZ = 'z'.charCodeAt(0);
                                const len = ordZ - ordA + 1;

                                let s = '';
                                while (n >= 0) {
                                    s =
                                        String.fromCharCode((n % len) + ordA) +
                                        s;
                                    n = Math.floor(n / len) - 1;
                                }
                                return s;
                            };

                            columns.value = (data[0] || []).map(
                                (item: any, index: number) => {
                                    return {
                                        header: colName(index).toUpperCase()
                                    };
                                }
                            );

                            items.value = data.map(
                                (obj: any, rowIndex: number) => ({
                                    rowData: obj,
                                    rowIndex
                                })
                            );
                        }
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    })
                    .finally(() => {
                        loading.value = false;
                    });
            }

            if (
                args.chosenSupplier !== null &&
                args.chosenSupplier !== chosenSupplier.value
            ) {
                chosenSupplier.value = args.chosenSupplier;
                getSupplierConfiguration({
                    filters: {
                        supplierNumber: {
                            value: chosenSupplier.value,
                            matchMode: FilterMatchMode.EQUALS
                        }
                    }
                })
                    .then((data: any) => {
                        supplierConfiguration.value =
                            data?.data?.items && data.data.items.length > 0
                                ? data.data.items[0]
                                : null;
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    });
            }

            if (args.sheetName !== sheetName.value) {
                sheetName.value = args.sheetName;
            }

            if (args.chosenManufacturer !== chosenManufacturer.value) {
                chosenManufacturer.value = args.chosenManufacturer;
            }

            if (args.costPriceStartDate !== costPriceStartDate.value) {
                costPriceStartDate.value = args.costPriceStartDate;
            }

            if (args.listPriceStartDate !== listPriceStartDate.value) {
                listPriceStartDate.value = args.listPriceStartDate;
            }
        });

        const rules = {
            headerRowIndex: {
                required: helpers.withMessage(
                    i18n.global.t('messages.headerRowRequired'),
                    (value: any) => {
                        return value > -1;
                    }
                )
            },
            columnMappingArray: {
                sku: helpers.withMessage(
                    i18n.global.t('messages.skuMappingMissing'),
                    (value: any) => {
                        return value.some(
                            (item: any) =>
                                item.systemColumn === 'SUPPLIER_ARTICLE_NUMBER'
                        );
                    }
                ),
                ek: helpers.withMessage(
                    i18n.global.t('messages.costPriceMappingMissing'),
                    (value: any) => {
                        return (
                            value.some(
                                (item: any) =>
                                    item.systemColumn ===
                                    'SUPPLIER_ARTICLE_COST_PRICE'
                            ) ||
                            (computedCostPriceBaseColumn.value &&
                                (computedCostPriceBaseColumn.value
                                    .merchantDiscount !== null ||
                                    computedDiscountGroupColumn.value))
                        );
                    }
                ),
                $each: helpers.forEach({
                    systemColumn: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    },
                    columnIndex: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    },
                    grossAmount: {},
                    currency: {},
                    merchantDiscount: {},
                    stockDateFormat: {}
                })
            }
        };

        const v$ = useVuelidate(rules, state, {$scope: false});

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                (v$.value?.$silentErrors || []).forEach((silentError) => {
                    toast.error(silentError.$message);
                });
                return;
            }

            confirm.require({
                message: i18n.global.t(
                    'messages.schedulePriceImportConfirmation'
                ),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: async () => {
                    getAllTemplates({
                        supplierNumber: chosenSupplier.value
                    })
                        .then((data: any) => {
                            if (data.data.length < 1) {
                                addTemplate(
                                    chosenSupplier.value,
                                    sheetName.value,
                                    chosenManufacturer.value,
                                    {name: 'default', isPublic: false},
                                    state.value
                                ).catch();
                            }
                        })
                        .catch();

                    schedulePriceImport(
                        savedFileId.value,
                        chosenSupplier.value,
                        chosenManufacturer.value,
                        costPriceStartDate.value,
                        listPriceStartDate.value,
                        state.value,
                        rowsToSkip.value
                    )
                        .then(() => {
                            router.push('/price-import-logs');
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        });
                }
            });
        };

        const setHeaderRow = (index: number) => {
            state.value.headerRowIndex = index;
        };

        const bulkMenuToggle = (event: any, index: number) => {
            lastColumnIndex.value = index;
            bulkMenu.value[index].toggle(event);
        };

        const addBulkItems = computed(() => {
            return [
                {
                    label: i18n.global.t('labels.priceImport.columnMapping'),
                    icon: 'pi pi-link',
                    items: [
                        'SUPPLIER_ARTICLE_NUMBER',
                        'MANUFACTURER_NAME',
                        'SUPPLIER_ARTICLE_NAME',
                        //'SUPPLIER_ARTICLE_EAN',
                        //'SUPPLIER_ARTICLE_MPN',
                        'SUPPLIER_ARTICLE_COST_PRICE',
                        'SUPPLIER_ARTICLE_COST_PRICE_BASE',
                        //'SUPPLIER_ARTICLE_COST_PRICE_BEST',
                        'SUPPLIER_ARTICLE_LIST_PRICE',
                        'SUPPLIER_ARTICLE_QUANTITY',
                        'SUPPLIER_ARTICLE_STOCK_DATE',
                        'SUPPLIER_ARTICLE_DELAY_DAYS',
                        'SUPPLIER_ARTICLE_IN_STOCK',
                        //'SUPPLIER_DISCOUNT_1',
                        //'SUPPLIER_DISCOUNT_2',
                        'SUPPLIER_DISCOUNT_GROUP'
                        //'SUPPLIER_CUSTOMS_NUMBER',
                        //'SUPPLIER_COUNTRY_OF_ORIGIN'
                    ]
                        .concat(
                            supplierConfiguration.value
                                ?.trailingDiscountPossible
                                ? ['SUPPLIER_NSR']
                                : []
                        )
                        .map((systemColumn: string) => {
                            return systemColumn ===
                                'SUPPLIER_ARTICLE_STOCK_DATE'
                                ? {
                                      label: i18n.global.t(
                                          'labels.priceImport.availableColumns.' +
                                              systemColumn
                                      ),
                                      icon: 'pi pi-link',
                                      items: [
                                          {
                                              label: 'TT.MM.JJJJ',
                                              command: () => {
                                                  handleAddingColumnMappingWrapper(
                                                      systemColumn
                                                  );
                                                  handlePropertySettingWrapper(
                                                      lastColumnIndex.value,
                                                      'stockDateFormat',
                                                      'dd.mm.YYYY'
                                                  );
                                              }
                                          },
                                          {
                                              label: 'JJJJ-MM-TT',
                                              command: () => {
                                                  handleAddingColumnMappingWrapper(
                                                      systemColumn
                                                  );
                                              }
                                          }
                                      ]
                                  }
                                : {
                                      label: i18n.global.t(
                                          'labels.priceImport.availableColumns.' +
                                              systemColumn
                                      ),
                                      icon: 'pi pi-link',
                                      command: () => {
                                          handleAddingColumnMappingWrapper(
                                              systemColumn
                                          );
                                      }
                                  };
                        })
                },
                {
                    label: 'Brutto/Netto',
                    icon: 'pi pi-percentage',
                    disabled: !state.value.columnMappingArray.some(
                        (columnMapping) =>
                            columnMapping.columnIndex ===
                                lastColumnIndex.value &&
                            [
                                'SUPPLIER_ARTICLE_COST_PRICE',
                                'SUPPLIER_ARTICLE_COST_PRICE_BASE',
                                'SUPPLIER_ARTICLE_LIST_PRICE'
                            ].includes(columnMapping.systemColumn)
                    ),
                    command: () => {
                        const existing =
                            state.value?.columnMappingArray.findIndex(
                                (item) =>
                                    item?.columnIndex === lastColumnIndex.value
                            );
                        handlePropertySettingWrapper(
                            lastColumnIndex.value,
                            'grossAmount',
                            !state.value.columnMappingArray[existing]
                                .grossAmount
                        );
                    }
                }
            ];
        });

        const handleAddingColumnMappingWrapper = (
            systemColumn: string,
            silent: boolean = false
        ) => {
            if ('SUPPLIER_ARTICLE_NUMBER' === systemColumn) {
                handleSkuColumnMapping(!silent);
            } else if ('SUPPLIER_DISCOUNT_GROUP' === systemColumn) {
                handleDiscountGroupColumnMapping();
            } else {
                handleAddingColumnMapping(systemColumn);
            }
        };

        const handlePropertySettingWrapper = (
            columnIndexValue: number,
            propertyName: string,
            value: any
        ) => {
            const existing = state.value?.columnMappingArray.findIndex(
                (item) => item?.columnIndex === columnIndexValue
            );

            if (existing !== -1) {
                state.value.columnMappingArray[existing][propertyName] = value;
            }
        };

        const handleSkuColumnMapping = (
            includeConfirmation: boolean = true
        ) => {
            let duplicates: any = [];

            const skuValues: any = [];
            items.value.forEach((item, index) => {
                if (index <= state.value.headerRowIndex) {
                    return true;
                }

                const existing = rowsToSkip.value.findIndex(
                    (item) => parseInt(item) === index
                );
                if (existing !== -1) {
                    delete rowsToSkip.value[existing];
                }

                skuValues.push({
                    index,
                    value: item.rowData[lastColumnIndex.value]
                });
            });
            duplicates = skuValues.filter((nnn: any, index: any) => {
                return (
                    skuValues.find(
                        (x: any, ind: any) =>
                            x.value === nnn.value && index !== ind
                    ) || (nnn.value || '').trim() === ''
                );
            });

            if (
                includeConfirmation &&
                duplicates.length > 0 &&
                duplicates.some(
                    (dup: any) => dup.value && dup.value.trim() !== ''
                )
            ) {
                confirm.require({
                    message: i18n.global.t(
                        'messages.chosenColumnContainsDuplicateValues'
                    ),
                    header: i18n.global.t('messages.pleaseConfirm'),
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: i18n.global.t('labels.yes'),
                    rejectLabel: i18n.global.t('labels.no'),
                    accept: () => {
                        handleAddingColumnMapping('SUPPLIER_ARTICLE_NUMBER');
                        duplicates.forEach((duplicate: any) => {
                            rowsToSkip.value.push(parseInt(duplicate.index));
                        });
                    }
                });
            } else {
                handleAddingColumnMapping('SUPPLIER_ARTICLE_NUMBER');
                duplicates.forEach((duplicate: any) => {
                    rowsToSkip.value.push(parseInt(duplicate.index));
                });
            }
        };

        const handleDiscountGroupColumnMapping = () => {
            if (
                (
                    supplierConfiguration.value?.discountGroups.filter(
                        (item: any) => !!item.name
                    ) || []
                ).length < 1
            ) {
                toast.error(
                    i18n.global.t('messages.noDiscountGroupForSupplier')
                );
                return;
            }

            if (
                computedCostPriceBaseColumn.value?.merchantDiscount?.percentage
            ) {
                toast.error(
                    i18n.global.t('messages.merchantDiscountAlreadyApplied')
                );
                return;
            }

            if (
                !computedCostPriceBaseColumn.value ||
                !state.value.columnMappingArray.find(
                    (item) => item.systemColumn === 'SUPPLIER_ARTICLE_NUMBER'
                )
            ) {
                toast.error(
                    i18n.global.t('messages.skuColumnMustBeAlreadyMapped')
                );
                return;
            }

            let foundAtLeastOne = false;

            items.value.forEach((item, index) => {
                if (
                    index <= state.value.headerRowIndex ||
                    rowsToSkip.value.includes(index)
                ) {
                    return true;
                }

                supplierConfiguration.value.discountGroups.forEach(
                    (discountGroup: {name: string; percentage: number}) => {
                        if (
                            !!discountGroup.name &&
                            item.rowData[
                                lastColumnIndex.value
                            ].toUpperCase() === discountGroup.name.toUpperCase()
                        ) {
                            foundAtLeastOne = true;
                            return false;
                        }
                    }
                );

                if (foundAtLeastOne) {
                    return false;
                }
            });

            if (!foundAtLeastOne) {
                toast.error(
                    i18n.global.t('messages.noRowWithSupplierDiscountGroups')
                );
                return;
            }

            items.value.forEach((item, index) => {
                if (
                    index <= state.value.headerRowIndex ||
                    rowsToSkip.value.includes(index)
                ) {
                    return true;
                }

                let foundGroup = false;

                supplierConfiguration.value.discountGroups.forEach(
                    (discountGroup: {name: string; percentage: number}) => {
                        if (
                            !!discountGroup.name &&
                            item.rowData[
                                lastColumnIndex.value
                            ].toUpperCase() === discountGroup.name.toUpperCase()
                        ) {
                            foundGroup = true;
                            return false;
                        }
                    }
                );

                if (!foundGroup) {
                    rowsToSkip.value.push(index);
                }
            });

            handleAddingColumnMapping('SUPPLIER_DISCOUNT_GROUP');
        };

        const getDiscountPercentageForRow = (item: any): number | null => {
            const existingDiscountGroupMapping =
                state.value?.columnMappingArray.find(
                    (item) => item?.systemColumn === 'SUPPLIER_DISCOUNT_GROUP'
                )?.columnIndex;

            if (
                typeof existingDiscountGroupMapping === 'undefined' ||
                existingDiscountGroupMapping === null
            ) {
                return null;
            }

            const group = supplierConfiguration.value.discountGroups.find(
                (discountGroup: {name: string; percentage: number}) => {
                    return (
                        !!discountGroup.name &&
                        item.rowData[
                            existingDiscountGroupMapping
                        ].toUpperCase() === discountGroup.name.toUpperCase()
                    );
                }
            );

            if (typeof group !== 'undefined' && group !== null) {
                return group.percentage;
            }

            return null;
        };

        const handleAddingColumnMapping = (systemColumn: string) => {
            let existing = state.value?.columnMappingArray.findIndex(
                (item) => item?.systemColumn === systemColumn
            );
            if (existing !== -1) {
                delete state.value.columnMappingArray[existing];
            }

            existing = state.value?.columnMappingArray.findIndex(
                (item) => item?.columnIndex === lastColumnIndex.value
            );
            if (existing !== -1) {
                delete state.value.columnMappingArray[existing];
            }

            state.value.columnMappingArray =
                state.value.columnMappingArray.filter((el) => el);

            state.value.columnMappingArray.push({
                systemColumn,
                columnIndex: lastColumnIndex.value,
                grossAmount: resolveGrossAmount(systemColumn),
                currency:
                    systemColumn === 'SUPPLIER_ARTICLE_COST_PRICE' ||
                    systemColumn === 'SUPPLIER_ARTICLE_COST_PRICE_BASE'
                        ? supplierConfiguration.value?.supplierCurrency || 'EUR'
                        : 'EUR',
                merchantDiscount:
                    systemColumn === 'SUPPLIER_ARTICLE_COST_PRICE_BASE' ||
                    (systemColumn === 'SUPPLIER_ARTICLE_LIST_PRICE' &&
                        'EUR' === supplierConfiguration.value?.supplierCurrency)
                        ? resolveMerchantDiscount()
                        : null
            });
        };

        const removeColumnMapping = (columnIndex: number) => {
            const existing = state.value?.columnMappingArray.findIndex(
                (item) => item?.columnIndex === columnIndex
            );
            if (existing === -1) {
                return;
            }

            delete state.value.columnMappingArray[existing];

            state.value.columnMappingArray =
                state.value?.columnMappingArray.filter((el) => el);
        };

        const addRowToImport = (rowIndex: number) => {
            const existing = rowsToSkip.value.findIndex(
                (item) => parseInt(item) === parseInt(rowIndex.toString())
            );
            if (existing === -1) {
                return;
            }

            delete rowsToSkip.value[existing];

            rowsToSkip.value = rowsToSkip.value.filter((el) => el);
        };

        const removeRowFromImport = (rowIndex: number) => {
            if (
                rowsToSkip.value.some(
                    (item) => parseInt(item) === parseInt(rowIndex.toString())
                )
            ) {
                return;
            }

            rowsToSkip.value.push(parseInt(rowIndex.toString()));
        };

        const layoutConfig = computed(() => {
            return store.getters['ui/layoutConfig'] as ILayoutConfig;
        });

        const clearTemplate = () => {
            state.value = {
                headerRowIndex: -1,
                columnMappingArray: []
            };
            rowsToSkip.value.length = 0;
        };

        const resolveMerchantDiscount = () => {
            const result = (
                supplierConfiguration.value?.discountGroups || []
            ).find(
                (item: any) =>
                    !item.name &&
                    item.manufacturerId === chosenManufacturer.value
            );

            if (result) {
                return result;
            }

            return (
                (supplierConfiguration.value?.discountGroups || []).find(
                    (item: any) => !item.name && item.manufacturerId === null
                ) || null
            );
        };

        const resolveGrossAmount = (systemColumn: string): boolean => {
            if (systemColumn === 'SUPPLIER_ARTICLE_COST_PRICE_BASE') {
                return false;
            }

            return systemColumn === 'SUPPLIER_ARTICLE_COST_PRICE'
                ? supplierConfiguration.value?.costPriceInGross || false
                : systemColumn === 'SUPPLIER_ARTICLE_LIST_PRICE'
                ? supplierConfiguration.value?.listPriceInGross || false
                : true;
        };

        const applyTemplate = (definition: {
            headerRowIndex: number;
            columnMappingArray: Array<any>;
        }) => {
            clearTemplate();
            setHeaderRow(definition.headerRowIndex);
            definition.columnMappingArray.forEach(
                (item: {
                    systemColumn: string;
                    columnIndex: number;
                    grossAmount: boolean;
                    currency: string;
                    stockDateFormat: string;
                }) => {
                    lastColumnIndex.value = item.columnIndex;
                    handleAddingColumnMappingWrapper(item.systemColumn, true);
                    handlePropertySettingWrapper(
                        lastColumnIndex.value,
                        'grossAmount',
                        item.grossAmount
                    );
                    handlePropertySettingWrapper(
                        lastColumnIndex.value,
                        'currency',
                        item.currency
                    );
                    if (item.stockDateFormat) {
                        handlePropertySettingWrapper(
                            lastColumnIndex.value,
                            'stockDateFormat',
                            item.stockDateFormat
                        );
                    }
                }
            );
        };

        const transformCurrencyToDecimal = (rIndex: number) => {
            if (!computedCostPriceBaseColumn.value) {
                return null;
            }

            const input = items.value.find(
                (item: {rowIndex: number}) => item.rowIndex === rIndex
            ).rowData[computedCostPriceBaseColumn.value.columnIndex];

            if (!input) {
                return null;
            }

            if (
                computedCostPriceBaseColumn.value.merchantDiscount?.percentage
            ) {
                return transformToDecimal(
                    input,
                    computedCostPriceBaseColumn.value.merchantDiscount
                        .percentage,
                    computedCostPriceBaseColumn.value.grossAmount || false
                );
            }

            return transformToDecimal(
                input,
                getDiscountPercentageForRow(
                    items.value.find(
                        (item: {rowIndex: number}) => item.rowIndex === rIndex
                    )
                ),
                computedCostPriceBaseColumn.value.grossAmount || false
            );
        };

        const transformToDecimal = (
            input: string,
            discountPercentage: number,
            convertToNetAmount: boolean = false
        ) => {
            if (!input) {
                return null;
            }
            if (
                input.lastIndexOf(',') !== -1 &&
                input.lastIndexOf('.') !== -1
            ) {
                const decimal =
                    input.lastIndexOf(',') > input.lastIndexOf('.') ? ',' : '.';
                const separator =
                    input.lastIndexOf(',') > input.lastIndexOf('.') ? '.' : ',';
                return currency(input, {decimal, separator})
                    .divide(convertToNetAmount ? 1.19 : 1)
                    .multiply(1 - 0.01 * discountPercentage).value;
            }

            if (input.lastIndexOf(',') !== -1) {
                return currency(input, {decimal: ','})
                    .divide(convertToNetAmount ? 1.19 : 1)
                    .multiply(1 - 0.01 * discountPercentage).value;
            }

            if (input.lastIndexOf('.') !== -1) {
                return currency(input, {decimal: '.'})
                    .divide(convertToNetAmount ? 1.19 : 1)
                    .multiply(1 - 0.01 * discountPercentage).value;
            }

            return currency(input)
                .divide(convertToNetAmount ? 1.19 : 1)
                .multiply(1 - 0.01 * discountPercentage).value;
        };

        const computedCostPriceBaseColumn = computed(() => {
            const explicitCostPriceBase = state.value.columnMappingArray.find(
                (item) =>
                    item.systemColumn === 'SUPPLIER_ARTICLE_COST_PRICE_BASE'
            );

            if (explicitCostPriceBase) {
                return explicitCostPriceBase;
            }

            return 'EUR' === supplierConfiguration.value?.supplierCurrency
                ? state.value.columnMappingArray.find(
                      (item) =>
                          item.systemColumn === 'SUPPLIER_ARTICLE_LIST_PRICE'
                  )
                : null;
        });

        const computedDiscountGroupColumn = computed(() => {
            return state.value.columnMappingArray.find(
                (item) => item.systemColumn === 'SUPPLIER_DISCOUNT_GROUP'
            );
        });

        return {
            setHeaderRow,
            handleSubmit,
            submitted,
            items,
            columns,
            state,
            v$,
            bulkMenu,
            bulkMenuToggle,
            addBulkItems,
            rowClass: (data: any) => {
                if (state.value.headerRowIndex === -1) {
                    return;
                }

                if (data.rowIndex === state.value.headerRowIndex) {
                    const colorVariant = layoutConfig.value?.darkTheme
                        ? 800
                        : 100;
                    return 'bg-blue-' + colorVariant;
                }

                const colorVariant = layoutConfig.value?.darkTheme ? 800 : 100;

                if (data.rowIndex < state.value.headerRowIndex) {
                    return 'bg-red-' + colorVariant;
                }

                if (
                    rowsToSkip.value.some(
                        (item) =>
                            parseInt(item) ===
                            parseInt(data.rowIndex.toString())
                    )
                ) {
                    const skuColumnIndex = state.value.columnMappingArray.find(
                        (map) => map.systemColumn === 'SUPPLIER_ARTICLE_NUMBER'
                    )?.columnIndex;

                    const isSkuEmpty =
                        typeof data.rowData[skuColumnIndex] !== 'undefined' &&
                        data.rowData[skuColumnIndex].toString().trim() === '';

                    return (
                        'bg-' +
                        (isSkuEmpty ? 'yellow' : 'red') +
                        '-' +
                        colorVariant
                    );
                }
            },
            removeColumnMapping,
            addRowToImport,
            removeRowFromImport,
            rowsToSkip,
            supplierConfiguration,
            getDiscountPercentageForRow,
            chosenSupplier,
            applyTemplate,
            clearTemplate,
            onBackButtonClicked: () => {
                clearTemplate();
                context.emit('back-button-clicked');
            },
            chosenManufacturer,
            sheetName,
            loading,
            savedFileId,
            formatMerchantDiscountColumn: (rowIndex: number) => {
                const currency = supplierConfiguration.value?.supplierCurrency;

                if (!currency) {
                    return null;
                }

                const formatter = new Intl.NumberFormat(i18n.global.locale, {
                    style: 'currency',
                    currency: currency.toUpperCase()
                });

                const transformed = transformCurrencyToDecimal(rowIndex);

                return transformed ? formatter.format(transformed) : null;
            },
            formatRegularPriceColumn: (rowData: any, colIndex: number) => {
                const currency = state.value.columnMappingArray.find(
                    (item) => item.columnIndex === colIndex
                ).currency;

                if (!currency) {
                    return null;
                }

                const formatter = new Intl.NumberFormat(i18n.global.locale, {
                    style: 'currency',
                    currency: currency.toUpperCase()
                });

                const transformed = transformToDecimal(rowData[colIndex], 0);

                return transformed ? formatter.format(transformed) : null;
            },
            computedCostPriceBaseColumn,
            computedDiscountGroupColumn,
            costPriceStartDate,
            listPriceStartDate
        };
    }
};
