import Dialog from 'primevue/dialog';
import {ref, toRefs, watch} from 'vue';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import {helpers, required} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';

export default {
    emits: ['close-dialog'],
    components: {
        InputText,
        'p-dialog': Dialog,
        'app-checkbox': Checkbox,
        'p-button': Button
    },
    props: {
        displayDialog: Boolean,
        savingInProgress: Boolean,
        headerText: {
            type: String,
            default: i18n.global.t('labels.saveFilterAs')
        }
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const submitted = ref(false);
        const savingInProgress = ref(false);
        const {headerText} = toRefs(props);

        const state = ref({
            name: null,
            isPublic: false
        });

        const rules = {
            name: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            isPublic: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            savingInProgress.value = args.savingInProgress;
            if (!args.displayDialog) {
                resetForm();
            }
        });

        const closeDialog = () => {
            resetForm();
            context.emit('close-dialog');
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            context.emit('close-dialog', state.value);
        };

        const resetForm = () => {
            state.value = {
                name: null,
                isPublic: false
            };

            submitted.value = false;
        };

        return {
            showDialog,
            closeDialog,
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            headerText
        };
    }
};
