import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const getAllUnits = (): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/shopware/units');
};

export const getAllOrders = (params: any): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/shopware/orders', {params});
};

export const getSingleOrder = (
    orderNumber: string | string[],
    platform: string | null = null,
    onlyNonSynced: boolean = false
): Promise<AxiosResponse> => {
    if (platform) {
        return apiClient.get('/api/v1/shopware/orders/' + orderNumber, {
            params: {platform, onlyNonSynced}
        });
    }
    return apiClient.get('/api/v1/shopware/orders/' + orderNumber, {
        params: {onlyNonSynced}
    });
};

export const editSingleOrder = (
    orderNumber: string | string[],
    payload: any,
    platform: string | null = null
): Promise<AxiosResponse> => {
    if (platform) {
        return apiClient.put(
            '/api/v1/shopware/orders/' + orderNumber,
            payload,
            {
                params: {platform}
            }
        );
    }
    return apiClient.get('/api/v1/shopware/orders/' + orderNumber, payload);
};
