import {computed, onMounted, ref, toRefs, watch} from 'vue';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import {useVuelidate} from '@vuelidate/core';
import AutoComplete from 'primevue/autocomplete';
import Textarea from 'primevue/textarea';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import {updatePromotions} from '@/services/products';
import {getSome} from '@/services/metadata';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import InputSwitch from 'primevue/inputswitch';
import Editor from '@tinymce/tinymce-vue';
import ScrollPanel from 'primevue/scrollpanel';
import {maxLength} from '@vuelidate/validators';

export default {
    emits: ['reload-product', 'form-dirty'],
    components: {
        'p-button': Button,
        'p-dropdown': Dropdown,
        'p-calendar': Calendar,
        'app-checkbox': Checkbox,
        InputNumber,
        AutoComplete,
        TriStateCheckbox,
        InputSwitch,
        tiny: Editor,
        ScrollPanel,
        'p-textarea': Textarea
    },
    props: {
        productDetails: Object,
        isBulkEdit: Boolean
    },
    setup(props: any, context: any) {
        const {isBulkEdit} = toRefs(props);
        const submitted = ref(false);
        const productDetails = ref(null);
        const savingInProgress = ref(false);
        const toast = useToast();
        const promoCodeOptions = ref([]);

        onMounted(() => {
            getSome('customAttributeDefinition', 'attributeKey', [
                'article_promo_code'
            ])
                .then((data) => {
                    promoCodeOptions.value =
                        data.data['article_promo_code']?.selectableValues?.map(
                            (item: {id: string; value: string}) => {
                                return {
                                    label: item.value,
                                    value: item.id
                                };
                            }
                        ) || [];
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        });

        const state = ref({
            ekPromoSwitch: null,
            vkPromoSwitch: null,
            ekPromoText: null,
            vkPromoText: null,
            internalNote: null,
            internalNoteSupplySource: null,
            ekPromoStart: null,
            ekPromoEnd: null,
            vkPromoStart: null,
            vkPromoEnd: null,
            uvpLockStart: null,
            uvpLockEnd: null,
            blackWeekMax: null,
            promoCode: null,
            promoFlag: null
        });

        const rules = {
            ekPromoSwitch: {},
            vkPromoSwitch: {},
            ekPromoText: {},
            vkPromoText: {},
            internalNote: {},
            internalNoteSupplySource: {maxLength: maxLength(200)},
            ekPromoStart: {},
            ekPromoEnd: {},
            vkPromoStart: {},
            vkPromoEnd: {},
            uvpLockStart: {},
            uvpLockEnd: {},
            blackWeekMax: {},
            promoCode: {},
            promoFlag: {}
        };

        const bulkEditSwitches = ref({
            ekPromoText: false,
            vkPromoText: false,
            internalNote: false,
            internalNoteSupplySource: false,
            ekPromoStart: false,
            ekPromoEnd: false,
            vkPromoStart: false,
            vkPromoEnd: false,
            uvpLockStart: false,
            uvpLockEnd: false,
            blackWeekMax: false,
            promoCode: false
        });

        const mapping = {
            ekPromoSwitch: 'article_promo_ek_switch',
            vkPromoSwitch: 'article_promo_vk_switch',
            ekPromoText: 'article_promo_ek_text',
            vkPromoText: 'article_promo_vk_text',
            ekPromoStart: 'article_promo_ek_start',
            ekPromoEnd: 'article_promo_ek_ende',
            vkPromoStart: 'article_vk_promo_start',
            vkPromoEnd: 'article_vk_promo_end',
            uvpLockStart: 'article_uvp_lock_start',
            uvpLockEnd: 'article_uvp_lock_end',
            blackWeekMax: 'article_bw_vk',
            promoCode: 'article_promo_code',
            promoFlag: 'article_promo_flag'
        };

        const v$ = useVuelidate(rules, state);

        watch(props, () => {
            productDetails.value = props.productDetails;

            const found = (
                productDetails.value.weclapp?.articleSupplySources || []
            ).find((item: any) =>
                productDetails.value.weclapp?.primarySupplySourceId
                    ? item.id.toString() ===
                      productDetails.value.weclapp.primarySupplySourceId.toString()
                    : item.positionNumber === 1
            );

            Object.assign(state.value, {
                internalNote: productDetails.value.weclapp?.internalNote,
                internalNoteSupplySource: found ? found.internalNote : null
            });

            initializeCustomAttributes();
        });

        watch(v$, (args) => {
            if (args.$anyDirty) {
                context.emit('form-dirty', state.value, bulkEditSwitches.value);
            } else {
                context.emit('form-dirty', null);
            }
        });

        watch(bulkEditSwitches.value, () => {
            context.emit('form-dirty', state.value, bulkEditSwitches.value);
        });

        const initializeCustomAttributes = () => {
            if (!productDetails.value) {
                return;
            }

            const flippedMapping = Object.fromEntries(
                Object.entries(mapping).map((a) => a.reverse())
            );

            [
                'article_promo_ek_text',
                'article_promo_vk_text',
                'article_promo_code'
            ].forEach((item) => {
                const temp: {[k: string]: string} = {};
                const attributes =
                    productDetails.value.weclapp?.customAttributes;
                temp[flippedMapping[item]] = attributes[item] || null;

                Object.assign(state.value, temp);
            });

            Object.assign(state.value, {
                blackWeekMax: productDetails.value.weclapp?.customAttributes
                    ?.article_bw_vk
                    ? parseFloat(
                          productDetails.value.weclapp.customAttributes
                              .article_bw_vk
                      )
                    : null
            });

            [
                'article_promo_ek_switch',
                'article_promo_vk_switch',
                'article_promo_flag'
            ].forEach((item) => {
                const temp: {[k: string]: boolean} = {};
                const attributes =
                    productDetails.value.weclapp?.customAttributes;
                temp[flippedMapping[item]] = attributes[item] || false;
                Object.assign(state.value, temp);
            });

            [
                'article_promo_ek_start',
                'article_promo_ek_ende',
                'article_vk_promo_start',
                'article_vk_promo_end',
                'article_uvp_lock_start',
                'article_uvp_lock_end'
            ].forEach((item) => {
                const temp: {[k: string]: Date} = {};
                const attributes =
                    productDetails.value.weclapp?.customAttributes;
                temp[flippedMapping[item]] = attributes[item]
                    ? new Date(attributes[item])
                    : null;
                Object.assign(state.value, temp);
            });
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;
            Object.assign(state.value, temp);
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            updatePromotions(
                productDetails.value.weclapp?.articleNumber ||
                    productDetails.value.shopware?.productNumber,
                state.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    context.emit('reload-product');
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        const labelCharactersLeft = computed(() => {
            return {
                internalNoteSupplySource:
                    200 -
                    (v$.value.internalNoteSupplySource.$model
                        ? v$.value.internalNoteSupplySource.$model.length
                        : 0)
            };
        });

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            isBulkEdit,
            locale: i18n.global.locale,
            promoCodeOptions,
            setDropdownValue,
            bulkEditSwitches,
            labelCharactersLeft,
            tinyUrl: process.env?.VUE_APP_TINYMCE_URL
        };
    }
};
