import {computed, ref, watch} from 'vue';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Images from './images.vue';
import store from '@/store';
import AddDialog from '@/components/dialog/product-images/add-image.vue';
import Button from 'primevue/button';
import {useDropzone} from 'vue3-dropzone';
import {stripTagsAndTruncate} from '@/utils/helpers';
import {add} from '@/services/product-images';
import LoadingPlugin from 'vue-loading-overlay';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';

export default {
    emits: ['reload-product'],
    components: {
        TabPanel,
        TabView,
        Images,
        AddDialog,
        'p-button': Button,
        loading: LoadingPlugin
    },
    props: {
        productDetails: Object
    },
    setup(props: any, context: any) {
        const productDetails = ref(null);
        const displayAddDialog = ref(false);
        const loading = ref(false);
        const toast = useToast();

        watch(props, () => {
            productDetails.value = props.productDetails;
        });

        const loadProductDetails = () => {
            context.emit('reload-product');
        };

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );
        });

        const secondPlatform = computed(() => {
            switch (resolvedPlatform.value?.threeLetterId) {
                case 'ttd':
                    return 'videodata-demo';
                case 'ttl':
                    return 'videodata';
                case 'vdd':
                    return 'teltec-demo';
                default:
                    return 'teltec';
            }
        });

        const closeDialogListener = (forceRefresh: boolean = false) => {
            displayAddDialog.value = false;
            if (forceRefresh) {
                context.emit('reload-product');
            }
        };

        const dropZoneText = computed(() => {
            if (
                !acceptFiles.value ||
                !acceptFiles.value.length ||
                acceptFiles.value.length < 1
            ) {
                return '';
            }
            return stripTagsAndTruncate(
                acceptFiles.value.map((u: any) => u.name).join(', '),
                200,
                '...'
            );
        });

        const onDrop = (files: any, rejectReasons: any) => {
            acceptFiles.value = files.sort(function (a: any, b: any) {
                return a.name.localeCompare(b.name, 'en', {
                    numeric: true,
                    sensitivity: 'base'
                });
            });
            if (rejectReasons.length > 0) {
                toast.error(rejectReasons[0]['errors'][0]['message']);
                acceptFiles.value.length = 0;
            }
        };

        const {getRootProps, getInputProps, ...rest} = useDropzone({
            onDrop,
            accept: ['.jpg', '.png']
        });

        const acceptFiles = ref([]);

        const handleUpload = async () => {
            const productNumber =
                productDetails.value?.shopware?.productNumber ||
                productDetails.value?.weclapp?.articleNumber;
            loading.value = true;

            try {
                await add(productNumber, productNumber, acceptFiles.value);

                toast.success(
                    i18n.global.t('messages.changesSavedSuccessfully'),
                    {
                        timeout: 500
                    }
                );
                acceptFiles.value.length = 0;
                closeDialogListener(true);
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
            } finally {
                loading.value = false;
            }
        };

        const showUploadConfirmButton = computed(() => {
            return acceptFiles.value && acceptFiles.value.length > 0;
        });

        return {
            productDetails,
            loadProductDetails,
            resolvedPlatform,
            secondPlatform,
            displayAddDialog,
            closeDialogListener,
            acceptFiles,
            dropZoneText,
            getRootProps,
            getInputProps,
            ...rest,
            handleUpload,
            showUploadConfirmButton,
            loading
        };
    }
};
