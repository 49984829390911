<i
    class="pi pi-file-import text-purple-400 text-xl"
    style="cursor: pointer"
    @click="toggle"
    aria-haspopup="true"
    aria-controls="overlay_tmenu"
></i>
<TieredMenu ref="menu" id="overlay_tmenu" :model="items" popup />
<AddFilterDialog
    :display-dialog="displayAddFilterDialog"
    :saving-in-progress="savingInProgress"
    :header-text="$t('labels.saveTemplateAs')"
    @close-dialog="onCloseAddDialog"
>
</AddFilterDialog>
