import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import AddEditDialog from '@/components/dialog/product-documents/add-edit-document.vue';
import DeleteDialog from '@/components/dialog/product-documents/delete-document.vue';
import {ref, toRefs, watch} from 'vue';

export default {
    emits: ['reload-product'],
    components: {
        DataTable,
        Column,
        AddEditDialog,
        DeleteDialog
    },
    props: {
        shopwareDetails: Object,
        platform: {
            type: String,
            default: null
        }
    },
    setup(props: any, context: any) {
        const shopwareDetails = ref(null);
        const {platform} = toRefs(props);
        const documents = ref([]);
        const selectedDocument = ref(null);
        const displayAddEditDialog = ref(null);
        const displayDeleteDialog = ref(null);

        watch(props, () => {
            shopwareDetails.value = props.shopwareDetails;
            documents.value = props.shopwareDetails?.documents;
        });

        const showAddEditDialog = (data: any) => {
            selectedDocument.value = data;
            displayAddEditDialog.value = true;
        };

        const showDeleteDialog = (data: any) => {
            selectedDocument.value = data;
            displayDeleteDialog.value = true;
        };

        const closeDialogListener = (forceRefresh: boolean = false) => {
            displayAddEditDialog.value = false;
            displayDeleteDialog.value = false;
            if (forceRefresh) {
                context.emit('reload-product');
            }
        };

        return {
            shopwareDetails,
            documents,
            selectedDocument,
            displayAddEditDialog,
            displayDeleteDialog,
            showAddEditDialog,
            showDeleteDialog,
            closeDialogListener,
            platform
        };
    }
};
