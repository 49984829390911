<loading v-model:active="loading" />

<div class="m-2">
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        :paginator="productLinks && productLinks.length > 20"
        :rows="20"
        :lazy="true"
        :value="productLinks"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50,100,200]"
        current-page-report-template="{first} to {last} of {totalRecords}"
    >
        <Column header="Link" style="min-width: 12rem">
            <template #body="{data}">
                <h5>{{ data.title }}</h5>
                <div>
                    <i class="pi pi-link"></i>
                    <a
                        class="ml-2"
                        :href="data.url.replace(/^(?!https)/, 'https://')"
                        target="_blank"
                        >{{ data.url }}</a
                    >
                </div>
            </template>
        </Column>
        <Column style="min-width: 5rem">
            <template #body="{data}">
                <div class="button-container">
                    <i
                        class="pi pi-trash text-sm mr-2"
                        @click="showDeleteDialog(data)"
                    ></i>
                    <i
                        class="pi pi-pencil text-sm mr-2"
                        @click="showDialog(data)"
                    ></i>
                </div>
            </template>
        </Column>

        <template #empty>{{ $t('messages.noRecordsFound') }}. </template>
    </DataTable>
</div>
<AddEditDialog
    :displayResponsive="displayAddEditDialog"
    :product-number="shopwareDetails?.productNumber"
    :selectedLink="selectedLink"
    @close-dialog="closeDialogListener"
    :platform="platform"
/>
<DeleteDialog
    :displayResponsive="displayDeleteDialog"
    :product-number="shopwareDetails?.productNumber"
    :selectedLink="selectedLink"
    @close-dialog="closeDialogListener"
    :platform="platform"
/>
