import {computed, onMounted, ref} from 'vue';
import LoadingPlugin from 'vue-loading-overlay';
import {useToast} from 'vue-toastification';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Panel from 'primevue/panel';
import {i18n} from '@/utils/i18n';
import {DateTime} from 'luxon';
import InputNumber from 'primevue/inputnumber';
import store from '@/store';
import {
    getAllWithDisposition,
    getSingleWithDisposition,
    updateDisposition
} from '@/services/products';
import {useConfirm} from 'primevue/useconfirm';
import {ekPriceCalculation} from '@/utils/helpers';
import InputText from 'primevue/inputtext';
import Skeleton from 'primevue/skeleton';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import {FilterMatchMode, FilterService} from 'primevue/api';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import {getAll} from '@/services/metadata';
import MultiSelect from 'primevue/multiselect';
import {helpers, requiredIf} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import ScrollPanel from 'primevue/scrollpanel';

export default {
    components: {
        loading: LoadingPlugin,
        DataTable,
        Column,
        'p-dialog': Dialog,
        'p-calendar': Calendar,
        'p-button': Button,
        'p-multiselect': MultiSelect,
        InputNumber,
        Panel,
        InputText,
        Skeleton,
        InputIcon,
        IconField,
        TriStateCheckbox,
        ScrollPanel
    },
    setup() {
        const loading = ref(false);
        const toast = useToast();
        const confirm = useConfirm();
        const foundProducts = ref([]);
        const filteredProducts = ref(null);
        const singleEditInProgress = ref(null);
        const articleComboFilter = ref('articleComboFilter');
        const manufacturerOptions = ref([]);
        const submitted = ref(false);

        const CHUNK_SIZE = 200;

        const dt = ref();

        const getDateFormatted = (rawValue: number) => {
            return rawValue > 0
                ? DateTime.fromMillis(rawValue)
                      .setLocale(i18n.global.locale)
                      .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                      .toLocaleString({
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                      })
                : '';
        };

        onMounted(() => {
            getAll(['manufacturer'], false)
                .then((data: any) => {
                    manufacturerOptions.value = data.data?.manufacturer || [];
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });

            FilterService.register(
                articleComboFilter.value,
                (value, filter) => {
                    if (
                        filter === undefined ||
                        filter === null ||
                        filter.trim() === ''
                    ) {
                        return true;
                    }

                    if (value === undefined || value === null) {
                        return false;
                    }

                    return value.includes(filter);
                }
            );
        });

        const state = ref({
            articleNumber: null,
            articleName: null,
            manufacturer: null,
            activeInAnyShop: null
        });

        const rules = {
            articleNumber: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return (
                            state.value.articleName === null &&
                            state.value.manufacturer === null
                        );
                    })
                )
            },
            articleName: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return (
                            state.value.articleNumber === null &&
                            state.value.manufacturer === null
                        );
                    })
                )
            },
            manufacturer: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return (
                            state.value.articleNumber === null &&
                            state.value.articleName === null
                        );
                    })
                )
            },
            activeInAnyShop: {}
        };

        const v$ = useVuelidate(rules, state);

        const getCurrencyFormatted = (
            rawValue: number,
            currency: string = 'EUR'
        ) => {
            const formatter = new Intl.NumberFormat(i18n.global.locale, {
                style: 'currency',
                currency
            });

            return formatter.format(rawValue);
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('products-edit') !== -1;
        });

        const onCellEditComplete = (eventData: any) => {
            if (eventData.newValue === null) {
                return;
            }

            if (eventData.value === eventData.newValue) {
                toast.warning(i18n.global.t('messages.noChangesDetected'));
                return;
            }

            loading.value = true;
            updateDisposition(eventData.data.articleNumber, {
                [eventData.field]: eventData.newValue
            })
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully')
                    );
                    singleEditInProgress.value = eventData.data.articleNumber;

                    const index = foundProducts.value.findIndex(
                        (prd) =>
                            prd.articleNumber === eventData.data.articleNumber
                    );

                    if (index === -1) {
                        singleEditInProgress.value = null;
                        return;
                    }

                    getAllWithDisposition({
                        articleNumber: eventData.data.articleNumber
                    })
                        .then((data) => {
                            if ((data.data.items || []).length === 1) {
                                const item = data.data.items[0];
                                const alternativeQuantities =
                                    item.articleAlternativeQuantities &&
                                    item.articleAlternativeQuantities.find(
                                        (item: any) =>
                                            item.warehouseName === 'Wiesbaden'
                                    );
                                Object.assign(foundProducts.value[index], {
                                    ...item,
                                    nameNumberCombo:
                                        item.articleNumber + '|' + item.name,
                                    activeInAnyShop:
                                        item.activeInShop || item.activeInShop2,
                                    eol: (item.matchCode || '').includes(
                                        '+EOL+'
                                    ),
                                    minMaxDate: item.customAttributes
                                        ?.art_minmax_datum
                                        ? DateTime.fromMillis(
                                              item.customAttributes
                                                  ?.art_minmax_datum
                                          ).toJSDate()
                                        : null,
                                    minimumStockQuantity:
                                        alternativeQuantities?.minimumStockQuantity
                                            ? parseInt(
                                                  alternativeQuantities?.minimumStockQuantity
                                              )
                                            : null,
                                    targetStockQuantity:
                                        alternativeQuantities?.targetStockQuantity
                                            ? parseInt(
                                                  alternativeQuantities?.targetStockQuantity
                                              )
                                            : null
                                });
                            }
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        })
                        .finally(() => {
                            singleEditInProgress.value = null;
                        });
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const bulkUpdateMinMaxDate = () => {
            confirm.require({
                message: i18n.global.t(
                    'messages.minMaxDateBulkUpdateConfirmation'
                ),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: () => {
                    const resolvedPromisesArray: Array<any> = [];

                    (filteredProducts.value || []).forEach((element: any) => {
                        if (
                            !element.customAttributes?.art_minmax_datum ||
                            DateTime.fromMillis(
                                element.customAttributes.art_minmax_datum
                            ).startOf('day') < DateTime.now().startOf('day')
                        ) {
                            resolvedPromisesArray.push(
                                Promise.resolve(
                                    updateDisposition(element.articleNumber, {
                                        minMaxDate: new Date()
                                    })
                                )
                            );
                        }
                    });

                    if (resolvedPromisesArray.length < 1) {
                        toast.warning(
                            i18n.global.t('messages.noChangesDetected')
                        );
                        return;
                    }
                    loading.value = true;
                    Promise.all(resolvedPromisesArray)
                        .then(() => {
                            toast.success(
                                i18n.global.t(
                                    'messages.changesSavedSuccessfully'
                                )
                            );
                            loading.value = false;
                            handleSearch(true);
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                            loading.value = false;
                        });
                }
            });
        };

        const loadWarehouseData = (productData: Array<any>) => {
            getSingleWithDisposition(productData, true, 'warehouseStock')
                .then((warehouseStockData: any) => {
                    if ((warehouseStockData.data.items || []).length > 0) {
                        warehouseStockData.data.items.forEach(
                            (warehouseStockDataItem: any) => {
                                const index = foundProducts.value.findIndex(
                                    (prd) =>
                                        prd.articleNumber ===
                                        warehouseStockDataItem.articleNumber
                                );

                                foundProducts.value[index].stockQuantity +=
                                    warehouseStockDataItem.quantity;

                                recalculateDispo(foundProducts.value[index]);
                            }
                        );
                    }

                    foundProducts.value.forEach((element: any) => {
                        if (
                            productData.includes(element.articleNumber) &&
                            element.stockQuantity === null
                        ) {
                            element.stockQuantity = 0;
                            recalculateDispo(element);
                        }
                    });
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        };

        const loadSalesOrderData = (productData: Array<any>) => {
            getSingleWithDisposition(productData, true, 'salesOrder')
                .then((salesOrderData: any) => {
                    if ((salesOrderData.data.items || []).length > 0) {
                        salesOrderData.data.items.forEach((salesOrder: any) => {
                            if ((salesOrder.orderItems || []).length > 0) {
                                salesOrder.orderItems.forEach(
                                    (salesOrderItemData: any) => {
                                        const index =
                                            foundProducts.value.findIndex(
                                                (prd) =>
                                                    prd.articleNumber ===
                                                    salesOrderItemData.articleNumber
                                            );
                                        if (index === -1) {
                                            return true;
                                        }

                                        foundProducts.value[
                                            index
                                        ].plannedSalesQuantity +=
                                            salesOrderItemData.quantity -
                                            salesOrderItemData.shippedQuantity;

                                        recalculateDispo(
                                            foundProducts.value[index]
                                        );
                                    }
                                );
                            }
                        });
                    }

                    foundProducts.value.forEach((element: any) => {
                        if (
                            productData.includes(element.articleNumber) &&
                            element.plannedSalesQuantity === null
                        ) {
                            element.plannedSalesQuantity = 0;
                            recalculateDispo(element);
                        }
                    });
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        };

        const loadSalesInvoiceData = (productData: Array<any>) => {
            getSingleWithDisposition(productData, true, 'salesInvoice')
                .then((salesInvoiceData: any) => {
                    const today = new Date();
                    const priorDate = new Date(
                        new Date().setDate(today.getDate() - 90)
                    );

                    if (
                        Object.values(salesInvoiceData.data.items || {})
                            .length > 0
                    ) {
                        Object.values(salesInvoiceData.data.items).forEach(
                            (salesInvoice: any) => {
                                if (
                                    (salesInvoice.invoiceItems || []).length > 0
                                ) {
                                    salesInvoice.invoiceItems.forEach(
                                        (salesInvoiceItemData: any) => {
                                            const index =
                                                foundProducts.value.findIndex(
                                                    (prd) =>
                                                        prd.articleNumber ===
                                                        salesInvoiceItemData.articleNumber
                                                );
                                            if (index === -1) {
                                                return true;
                                            }

                                            foundProducts.value[index]
                                                .salesInvoicesCount++;
                                            foundProducts.value[
                                                index
                                            ].salesInvoicesItemCount +=
                                                parseInt(
                                                    salesInvoiceItemData.quantity
                                                );

                                            if (
                                                priorDate <
                                                DateTime.fromMillis(
                                                    salesInvoice.createdDate
                                                ).toJSDate()
                                            ) {
                                                foundProducts.value[index]
                                                    .salesInvoicesLastThreeMonthsCount++;
                                                foundProducts.value[
                                                    index
                                                ].salesInvoicesLastThreeMonthsItemCount +=
                                                    parseInt(
                                                        salesInvoiceItemData.quantity
                                                    );
                                            }

                                            foundProducts.value[
                                                index
                                            ].salesInvoicesLoaded = true;
                                        }
                                    );
                                }
                            }
                        );
                    }
                    foundProducts.value.forEach((element: any) => {
                        if (
                            productData.includes(element.articleNumber) &&
                            element.salesInvoicesLoaded === false
                        ) {
                            element.salesInvoicesLoaded = true;
                        }
                    });
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        };

        const loadPurchaseOrderData = (
            productData: Array<any>,
            callbackFn: any = null
        ) => {
            getSingleWithDisposition(productData, true, 'purchaseOrder')
                .then((purchaseOrderData: any) => {
                    if ((purchaseOrderData.data.items || []).length > 0) {
                        purchaseOrderData.data.items.forEach(
                            (purchaseOrder: any) => {
                                if (
                                    (purchaseOrder.purchaseOrderItems || [])
                                        .length > 0
                                ) {
                                    purchaseOrder.purchaseOrderItems.forEach(
                                        (purchaseOrderItemData: any) => {
                                            const index =
                                                foundProducts.value.findIndex(
                                                    (prd) =>
                                                        prd.articleNumber ===
                                                        purchaseOrderItemData.articleNumber
                                                );
                                            if (index === -1) {
                                                return true;
                                            }

                                            foundProducts.value[
                                                index
                                            ].orderedQuantity +=
                                                purchaseOrderItemData.quantity -
                                                purchaseOrderItemData.receivedQuantity;

                                            recalculateDispo(
                                                foundProducts.value[index]
                                            );

                                            foundProducts.value[index]
                                                .purchaseOrdersCount++;
                                            foundProducts.value[
                                                index
                                            ].purchaseOrdersItemCount +=
                                                purchaseOrderItemData.quantity;

                                            foundProducts.value[
                                                index
                                            ].purchaseOrdersLastPurchaseOrderDate =
                                                Math.max(
                                                    foundProducts.value[index]
                                                        .purchaseOrdersLastPurchaseOrderDate,
                                                    purchaseOrderItemData.createdDateTimestamp
                                                );

                                            foundProducts.value[
                                                index
                                            ].purchaseOrdersLoaded = true;
                                        }
                                    );
                                }
                            }
                        );
                    }
                    foundProducts.value.forEach((element: any) => {
                        if (
                            productData.includes(element.articleNumber) &&
                            element.orderedQuantity === null
                        ) {
                            element.orderedQuantity = 0;
                            recalculateDispo(element);
                        }
                        if (
                            productData.includes(element.articleNumber) &&
                            element.purchaseOrdersLoaded === false
                        ) {
                            element.purchaseOrdersLoaded = true;
                        }
                    });

                    if (callbackFn) {
                        callbackFn();
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        };

        const recalculateDispo = (element: {
            plannedSalesQuantity: number | null;
            orderedQuantity: number | null;
            stockQuantity: number | null;
            calculatedDispo: number | null;
        }) => {
            if (
                element.plannedSalesQuantity === null ||
                element.orderedQuantity === null ||
                element.stockQuantity === null
            ) {
                element.calculatedDispo = null;
                return;
            }

            element.calculatedDispo =
                element.orderedQuantity +
                element.stockQuantity -
                element.plannedSalesQuantity;
        };

        const parseProductData = (
            productData: Array<any> | null
        ): Array<any> => {
            return (productData || []).map((item: any) => {
                const alternativeQuantities =
                    item.articleAlternativeQuantities &&
                    item.articleAlternativeQuantities.find(
                        (item: any) => item.warehouseName === 'Wiesbaden'
                    );
                return {
                    ...item,
                    nameNumberCombo: item.articleNumber + '|' + item.name,
                    activeInAnyShop: item.activeInShop || item.activeInShop2,
                    eol: (item.matchCode || '').includes('+EOL+'),
                    minMaxDate: item.customAttributes?.art_minmax_datum
                        ? DateTime.fromMillis(
                              item.customAttributes?.art_minmax_datum
                          ).toJSDate()
                        : null,
                    minimumStockQuantity:
                        alternativeQuantities?.minimumStockQuantity
                            ? parseInt(
                                  alternativeQuantities?.minimumStockQuantity
                              )
                            : null,
                    targetStockQuantity:
                        alternativeQuantities?.targetStockQuantity
                            ? parseInt(
                                  alternativeQuantities?.targetStockQuantity
                              )
                            : null,
                    plannedSalesQuantity: null,
                    orderedQuantity: null,
                    stockQuantity: null,
                    calculatedDispo: null,

                    purchaseOrdersLoaded: false,
                    purchaseOrdersCount: 0,
                    purchaseOrdersItemCount: 0,
                    purchaseOrdersLastPurchaseOrderDate: 0,

                    salesInvoicesLoaded: false,
                    salesInvoicesCount: 0,
                    salesInvoicesItemCount: 0,
                    salesInvoicesLastThreeMonthsCount: 0,
                    salesInvoicesLastThreeMonthsItemCount: 0
                };
            });
        };

        const handlePagedSearch = (page: number, maxPage: number) => {
            if (page > maxPage) {
                toast.success('Data loaded', {
                    timeout: 2000
                });
                return;
            }

            toast.warning('Loading page ' + page + '/' + maxPage, {
                timeout: 2000
            });

            getAllWithDisposition({...state.value}, page, CHUNK_SIZE).then(
                (pagedData) => {
                    const productDataPage = parseProductData(
                        pagedData.data.items
                    );
                    foundProducts.value =
                        foundProducts.value.concat(productDataPage);

                    const articleNumberArray = productDataPage.map(
                        (element) => element.articleNumber
                    );

                    loadWarehouseData(articleNumberArray);

                    loadSalesOrderData(articleNumberArray);

                    loadSalesInvoiceData(articleNumberArray);

                    loadPurchaseOrderData(articleNumberArray, () => {
                        handlePagedSearch(++page, maxPage);
                    });
                }
            );
        };

        const handleSearch = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }
            foundProducts.value.length = 0;
            loading.value = true;
            getAllWithDisposition({...state.value}, 1, CHUNK_SIZE)
                .then((data) => {
                    const productData = parseProductData(data.data.items);
                    foundProducts.value = (foundProducts.value || []).concat(
                        productData
                    );

                    if (foundProducts.value.length > 0) {
                        const articleNumberArray = productData.map(
                            (element) => element.articleNumber
                        );

                        toast.warning(
                            'Loading page 1/' +
                                Math.ceil(data.data.total / CHUNK_SIZE),
                            {
                                timeout: 2000
                            }
                        );

                        loadWarehouseData(articleNumberArray);

                        loadSalesOrderData(articleNumberArray);

                        loadSalesInvoiceData(articleNumberArray);

                        loadPurchaseOrderData(articleNumberArray, () => {
                            if (data.data.total > CHUNK_SIZE) {
                                handlePagedSearch(
                                    2,
                                    Math.ceil(data.data.total / CHUNK_SIZE)
                                );
                            } else {
                                toast.success('Data loaded', {
                                    timeout: 2000
                                });
                            }
                        });
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const filters = ref({
            nameNumberCombo: {
                value: null,
                matchMode: articleComboFilter.value
            },
            manufacturerName: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            activeInAnyShop: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            eol: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            minMaxDate: {value: null, matchMode: FilterMatchMode.DATE_IS}
        });

        const onFilter = (event: any) => {
            if (event?.filteredValue) {
                filteredProducts.value = [].concat(event.filteredValue);
            }
        };

        return {
            v$,
            loading,
            dt,
            getDateFormatted,
            onCellEditComplete,
            locale: i18n.global.locale,
            getCurrencyFormatted,
            bulkUpdateMinMaxDate,
            ekPriceCalculation,
            handleSearch,
            foundProducts,
            editPermissionAvailable,
            singleEditInProgress,
            filters,
            articleComboFilter,
            submitted,
            manufacturerOptions,
            onFilter
        };
    }
};
