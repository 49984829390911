<loading v-model:active="loading" />
<Panel>
    <template #header>
        <h5>{{$t('labels.shipment.openShipments')}}</h5>
    </template>
    <template #icons>
        <Dropdown
            v-model="chosenWarehouseId"
            :options="warehouses || []"
            optionLabel="name"
            optionValue="id"
            @change="onWarehouseChange"
        >
        </Dropdown>
    </template>
    <div>
        <div
            v-if="allOpenCount !== null"
            class="grid text-center text-6xl mb-3"
        >
            <div class="col">
                <font-awesome-icon
                    :icon="['fab', 'ups']"
                    style="background-color: #ffb500"
                />

                <span class="ml-3 font-bold">{{allOpenCount.ups}}</span>
            </div>
            <div class="col">
                <font-awesome-icon
                    :icon="['fab', 'dhl']"
                    style="background-color: #ffb500"
                />
                <span class="ml-3 font-bold">{{allOpenCount.dhl}}</span>
            </div>
            <div class="col">
                <font-awesome-icon :icon="['fas', 'truck']" />
                <span class="ml-3 font-bold"
                    >{{allOpenCount.total - allOpenCount.ups - allOpenCount.dhl
                    }}</span
                >
            </div>
        </div>

        <div class="grid">
            <div class="col">
                <h1 class="text-center">
                    {{$t("labels.shipment.internalAndTodayShipments")}}
                </h1>
            </div>
            <div class="col">
                <h1 class="text-center">{{$t("labels.shipment.pickups")}}</h1>
            </div>
        </div>
        <div class="grid">
            <div class="col">
                <DataView
                    layout="list"
                    :rows="200"
                    :value="shipments"
                    :lazy="true"
                    dataKey="id"
                    ref="dv"
                >
                    <template #empty><span></span></template>
                    <template #list="slotProps">
                        <template
                            v-for="(internalItem, index) in slotProps.items"
                            :key="index"
                        >
                            <div
                                class="w-full p-2 mb-3 flex align-items-center justify-content-between"
                                :class="{'new-item-in-the-list': newShipments.some((item) => item.shipmentNumber === internalItem.shipmentNumber), 'bg-red-100' : internalItem.transportationOrderStatus === 'NEW', 'bg-yellow-100': internalItem.transportationOrderStatus === 'IN_TRANSIT',  'bg-green-100' : (internalItem.transportationOrderStatus === 'COMPLETED' || internalItem.isCommissioningComplete)}"
                            >
                                <div>
                                    <font-awesome-icon
                                        v-if="internalItem.isUps"
                                        class="ml-2 text-7xl"
                                        :icon="['fab', 'ups']"
                                        style="background-color: #ffb500"
                                    />

                                    <font-awesome-icon
                                        v-if="internalItem.isDhl"
                                        class="text-7xl"
                                        :icon="['fab', 'dhl']"
                                        style="background-color: #ffb500"
                                    />
                                </div>
                                <div>
                                    <div
                                        class="font-bold text-center"
                                        :class="{'text-7xl': !internalItem.pickingInstructions, 'text-6xl' : internalItem.pickingInstructions}"
                                    >
                                        <div
                                            v-if="internalItem?.salesChannel"
                                            class="sales-channel-dot mr-3 w-2rem h-2rem"
                                            :class="{'sales-channel-teltec': internalItem.salesChannel === 'NET1', 'sales-channel-videodata': internalItem.salesChannel === 'NET2'}"
                                        ></div>
                                        <span
                                            >{{internalItem.shipmentNumber}}
                                        </span>
                                    </div>
                                    <div
                                        v-if="internalItem.pickingInstructions"
                                        class="text-center mb-3"
                                        :class="{'text-6xl': internalItem.pickingInstructions.length < 20, 'text-4xl': internalItem.pickingInstructions.length > 19}"
                                    >
                                        {{internalItem.pickingInstructions}}
                                    </div>
                                </div>
                                <div>
                                    <font-awesome-icon
                                        v-if="'INTERNAL' === internalItem.shipmentType"
                                        class="mr-2 text-7xl text-purple-600"
                                        :icon="['fas', 'house-user']"
                                    />
                                </div>
                            </div>
                        </template>
                    </template>
                </DataView>
            </div>
            <div class="col">
                <DataView
                    layout="list"
                    :rows="200"
                    :value="pickups"
                    :lazy="true"
                    dataKey="id"
                >
                    <template #empty><span></span></template>
                    <template #list="slotProps">
                        <template
                            v-for="(pickupItem, index) in slotProps.items"
                            :key="index"
                        >
                            <div
                                class="mt-2 grid align-items-center"
                                :class="{'new-item-in-the-list': newPickups.some((item) => item.shipmentNumber === pickupItem.shipmentNumber), 'bg-red-100' : pickupItem.transportationOrderStatus === 'NEW', 'bg-yellow-100': pickupItem.transportationOrderStatus === 'IN_TRANSIT',  'bg-green-100' : (pickupItem.transportationOrderStatus === 'COMPLETED' || pickupItem.isCommissioningComplete)}"
                            >
                                <div class="col">
                                    <div
                                        class="text-center font-bold"
                                        :class="{ 'text-7xl': !pickupItem.pickingInstructions, 'text-6xl' : pickupItem.pickingInstructions}"
                                    >
                                        <div
                                            v-if="pickupItem?.salesChannel"
                                            class="sales-channel-dot mr-3 w-2rem h-2rem"
                                            :class="{'sales-channel-teltec': pickupItem.salesChannel === 'NET1', 'sales-channel-videodata': pickupItem.salesChannel === 'NET2'}"
                                        ></div>
                                        <span
                                            >{{pickupItem.shipmentNumber}}</span
                                        >
                                    </div>
                                    <div
                                        v-if="pickupItem.pickingInstructions"
                                        class="text-center mb-3"
                                        :class="{'text-6xl': pickupItem.pickingInstructions.length < 20, 'text-4xl': pickupItem.pickingInstructions.length > 19}"
                                    >
                                        {{pickupItem.pickingInstructions}}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </DataView>
            </div>
        </div>
    </div>
</Panel>
