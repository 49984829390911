import {computed, ref, watch} from 'vue';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Documents from './documents.vue';
import store from '@/store';
import Button from 'primevue/button';
import AddEditDialog from '@/components/dialog/product-documents/add-edit-document.vue';

export default {
    emits: ['reload-product'],
    components: {
        TabPanel,
        TabView,
        Documents,
        'p-button': Button,
        AddEditDialog
    },
    props: {
        productDetails: Object
    },
    setup(props: any, context: any) {
        const productDetails = ref(null);
        const displayAddEditDialog = ref(false);

        const closeDialogListener = (forceRefresh: boolean = false) => {
            displayAddEditDialog.value = false;
            if (forceRefresh) {
                context.emit('reload-product');
            }
        };

        watch(props, () => {
            productDetails.value = props.productDetails;
        });

        const loadProductDetails = () => {
            context.emit('reload-product');
        };

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );
        });

        const secondPlatform = computed(() => {
            switch (resolvedPlatform.value?.threeLetterId) {
                case 'ttd':
                    return 'videodata-demo';
                case 'ttl':
                    return 'videodata';
                case 'vdd':
                    return 'teltec-demo';
                default:
                    return 'teltec';
            }
        });

        return {
            productDetails,
            loadProductDetails,
            resolvedPlatform,
            secondPlatform,
            closeDialogListener,
            displayAddEditDialog
        };
    }
};
