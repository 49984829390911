import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("span", {
      class: _normalizeClass(["sales-channel-dot sales-channel-teltec", {'bg-gray-200':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}]),
      style: _normalizeStyle({'cursor':((!_ctx.blockSwitching && ['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)) ? 'pointer' : 'auto')}),
      onClick: _cache[0] || (_cache[0] = $event => {{_ctx.changeSalesChannel(['vdd', 'vdl']);}})
    }, null, 6), [
      [
        _directive_tooltip,
        'Teltec',
        void 0,
        { top: true }
      ]
    ]),
    _withDirectives(_createElementVNode("span", {
      class: _normalizeClass(["sales-channel-dot sales-channel-videodata ml-2", {'bg-gray-200':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId)}]),
      style: _normalizeStyle({'cursor':((!_ctx.blockSwitching && ['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId)) ? 'pointer' : 'auto')}),
      onClick: _cache[1] || (_cache[1] = $event => {{_ctx.changeSalesChannel(['ttd', 'ttl']);}})
    }, null, 6), [
      [
        _directive_tooltip,
        'Videodata',
        void 0,
        { top: true }
      ]
    ])
  ]))
}