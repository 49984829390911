<form @submit.prevent="handleSubmit(!v$.$invalid)">
    <div class="grid">
        <div class="col">
            <slot>
                <Panel>
                    <template #header>
                        <div
                            class="font-bold flex justify-content-between w-full"
                        >
                            <div>Webshop Teltec</div>
                            <div
                                class="sales-channel-dot sales-channel-teltec"
                            ></div>
                        </div>
                    </template>
                    <div class="formgrid grid p-fluid">
                        <div class="col field">
                            <label
                                >{{ $t('labels.productHome.availableInShop') }}
                            </label>
                            <p-checkbox
                                v-model="v$['availableInShop'].$model"
                                :disabled="v$['activeInShop'].$model"
                                :binary="true"
                                class="ml-2 mb-1"
                            />
                        </div>
                        <div class="col field">
                            <label
                                >{{ $t('labels.productHome.activeInShop') }}
                            </label>
                            <p-checkbox
                                v-model="v$['activeInShop'].$model"
                                :binary="true"
                                class="ml-2 mb-1"
                                @input="onActiveInShopChange('availableInShop', $event)"
                            />
                        </div>
                    </div>
                    <template
                        v-if="v$['activeInShop'].$model || v$['availableInShop'].$model"
                    >
                        <div
                            class="formgrid grid p-fluid"
                            v-if="existingShopwareData"
                        >
                            <div class="col field">
                                <label
                                    >{{ $t('labels.productAdd.copyAccessories',
                                    {count:existingShopwareData.accessories.length
                                    }) }}</label
                                >
                                <p-checkbox
                                    v-model="v$['copyShopAccessories'].$model"
                                    binary
                                    :disabled="existingShopwareData.accessories.length < 1"
                                    class="ml-2 mb-1"
                                />
                            </div>
                            <div class="col field">
                                <label
                                    >{{ $t('labels.productAdd.copyImages',
                                    {count:existingShopwareData.images.length })
                                    }}</label
                                >
                                <p-checkbox
                                    v-model="v$['copyShopImages'].$model"
                                    binary
                                    :disabled="existingShopwareData.images.length < 1"
                                    class="ml-2 mb-1"
                                />
                            </div>
                            <div class="col field">
                                <label
                                    >{{ $t('labels.productAdd.copyVideos',
                                    {count:existingShopwareData.videos.length })
                                    }}</label
                                >
                                <p-checkbox
                                    v-model="v$['copyShopVideos'].$model"
                                    binary
                                    :disabled="existingShopwareData.videos.length < 1"
                                    class="ml-2 mb-1"
                                />
                            </div>
                            <div class="col field">
                                <label
                                    >{{ $t('labels.productAdd.copyLinks',
                                    {count:existingShopwareData.links.length })
                                    }}</label
                                >
                                <p-checkbox
                                    v-model="v$['copyShopLinks'].$model"
                                    binary
                                    :disabled="existingShopwareData.links.length < 1"
                                    class="ml-2 mb-1"
                                />
                            </div>
                        </div>
                        <div class="formgrid grid p-fluid">
                            <div class="col field">
                                <label
                                    >{{
                                    $t('labels.productDescription.webshopText')
                                    }}
                                </label>
                                <span class="p-input-icon-right">
                                    <tiny
                                        :tinymceScriptSrc="tinyUrl"
                                        v-model="v$['webshopText'].$model"
                                        :init="{
                            
                            menubar: false,
                            plugins: 'lists link image emoticons code table anchor charmap fullscreen paste autoresize',
                            toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code save',
                            browser_spellcheck: true,
                            autoresize_bottom_margin: 50,
                            min_height: 500
                        }"
                                    ></tiny>
                                    <small
                                        v-if="submitted"
                                        style="display: block"
                                        v-for="error in v$['webshopText'].$silentErrors"
                                        class="p-error"
                                        :key="error"
                                    >
                                        {{ error.$message }}
                                    </small>
                                </span>
                            </div>
                        </div>
                    </template>
                </Panel>
                <Panel v-if="hasSecondShop" class="mt-3">
                    <template #header>
                        <div
                            class="font-bold flex justify-content-between w-full"
                        >
                            <div>Webshop Video Data</div>
                            <div
                                class="sales-channel-dot sales-channel-videodata"
                            ></div>
                        </div>
                    </template>

                    <div class="formgrid grid p-fluid">
                        <div class="col field">
                            <label
                                >{{ $t('labels.productHome.availableInShop') }}
                            </label>
                            <p-checkbox
                                v-model="v$['availableInShop2'].$model"
                                :disabled="v$['activeInShop2'].$model"
                                :binary="true"
                                class="ml-2 mb-1"
                            />
                        </div>
                        <div class="col field">
                            <label
                                >{{ $t('labels.productHome.activeInShop') }}
                            </label>
                            <p-checkbox
                                v-model="v$['activeInShop2'].$model"
                                :binary="true"
                                class="ml-2 mb-1"
                                @input="onActiveInShopChange('availableInShop2', $event)"
                            />
                        </div>
                    </div>
                    <template
                        v-if="v$['activeInShop2'].$model || v$['availableInShop2'].$model"
                    >
                        <div class="formgrid grid p-fluid">
                            <div class="col field">
                                <label
                                    >{{
                                    $t('labels.productDescription.webshopText')
                                    }}
                                </label>
                                <span class="p-input-icon-right">
                                    <tiny
                                        :tinymceScriptSrc="tinyUrl"
                                        v-model="v$['webshopText2'].$model"
                                        :init="{
                            
                            menubar: false,
                            plugins: 'lists link image emoticons code table anchor charmap fullscreen paste autoresize',
                            toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code save',
                            browser_spellcheck: true,
                            autoresize_bottom_margin: 50,
                            min_height: 500
                        }"
                                    ></tiny>
                                    <small
                                        v-if="submitted"
                                        style="display: block"
                                        v-for="error in v$['webshopText2'].$silentErrors"
                                        class="p-error"
                                        :key="error"
                                    >
                                        {{ error.$message }}
                                    </small>
                                </span>
                            </div>
                        </div>
                    </template>
                </Panel>
                <p-fieldset :legend="$t('labels.description')" class="mt-5">
                    <div class="formgrid grid p-fluid">
                        <div class="col field">
                            <label
                                >{{
                                $t('labels.productDescription.shortDescription')
                                }}
                            </label>
                            <span class="p-input-icon-right">
                                <InputText
                                    autocomplete="off"
                                    :maxLength="100"
                                    v-model="v$['shortDescription'].$model"
                                    :class="{'p-invalid':v$['shortDescription'].$invalid && submitted}"
                                />
                                <small
                                    v-if="submitted"
                                    style="display: block"
                                    v-for="error in v$['shortDescription'].$silentErrors"
                                    class="p-error"
                                    :key="error"
                                >
                                    {{ error.$message }}
                                </small>
                            </span>
                        </div>
                    </div>

                    <div class="formgrid grid p-fluid">
                        <div class="col field">
                            <label
                                >{{ $t('labels.description') }} [Weclapp]
                            </label>
                            <span class="p-input-icon-right">
                                <tiny
                                    :tinymceScriptSrc="tinyUrl"
                                    v-model="v$['longDescription'].$model"
                                    :init="{
                                
                                menubar: false,
                                plugins: 'lists link image emoticons code table anchor charmap fullscreen paste autoresize',
                                toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code save',
                                browser_spellcheck: true,
                                autoresize_bottom_margin: 50,
                                min_height: 500
                            }"
                                ></tiny>
                                <small
                                    v-if="submitted"
                                    style="display: block"
                                    v-for="error in v$['longDescription'].$silentErrors"
                                    class="p-error"
                                    :key="error"
                                >
                                    {{ error.$message }}
                                </small>
                            </span>
                        </div>
                    </div>
                </p-fieldset>
            </slot>
        </div>
    </div>
    <div class="w-full flex justify-content-between mt-4">
        <p-button
            severity="danger"
            v-text="$t('buttons.back')"
            @click="onBackButtonClicked"
        >
        </p-button>

        <p-button severity="success" type="submit" v-text="$t('buttons.next')">
        </p-button>
    </div>
</form>
