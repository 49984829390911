import AutoComplete from 'primevue/autocomplete';
import {getAll} from '@/services/suppliers';
import {ref} from 'vue';
import {useToast} from 'vue-toastification';

export default {
    emits: ['data-entered'],
    components: {
        AutoComplete
    },
    setup(props: any, context: any) {
        const filteredSuppliers = ref([]);
        const toast = useToast();
        const chosenSupplier = ref();

        /*onMounted(() => {
            getAll(['supplier'])
                .then((data: any) => {
                    suppliers.value = (data.data?.supplier || []).map(
                        (item: {
                            id: string;
                            name: string;
                            supplierNumber: string;
                        }) => {
                            return {
                                label:
                                    item.name +
                                    ' [' +
                                    item.supplierNumber +
                                    ']',
                                value: item.supplierNumber
                            };
                        }
                    );
                })
                .catch((error: any) => {
                    toast.error(error.message);
                });
        });*/

        const searchSuppliers = (event: any) => {
            const payload = {rows: 10};
            if (event.query.trim() !== '') {
                Object.assign(payload, {
                    filters: {
                        company: {
                            value: event.query.trim(),
                            matchMode: 'startsWith'
                        },
                        personCompany: {
                            value: event.query.trim(),
                            matchMode: 'startsWith'
                        },
                        supplierNumber: {
                            value: event.query.trim(),
                            matchMode: 'startsWith'
                        }
                    },
                    filterConjunction: 'or'
                });
            }

            getAll(payload)
                .then((data: any) => {
                    filteredSuppliers.value = (data.data?.items || []).map(
                        (item: {
                            id: string;
                            name: string;
                            supplierNumber: string;
                        }) => {
                            return {
                                label:
                                    item.name +
                                    ' [' +
                                    item.supplierNumber +
                                    ']',
                                value: item.supplierNumber
                            };
                        }
                    );
                })
                .catch((error: any) => {
                    toast.error(error.message);
                });
        };

        const setDropdownValue = (event: any) => {
            context.emit(
                'data-entered',

                {
                    supplier: filteredSuppliers.value.find(
                        (item: any) => item.value === event?.value?.value
                    )
                }
            );
        };

        return {
            setDropdownValue,
            filteredSuppliers,
            chosenSupplier,
            searchSuppliers
        };
    }
};
