<TabView class="tabview-custom text-sm">
    <TabPanel :disabled="!productDetails?.shopware">
        <template #header>
            <i class="pi pi-align-justify mr-2"></i>
            <span>{{ $t("labels.description") }} </span>
            <span
                class="sales-channel-dot w-1rem h-1rem ml-2"
                :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
            ></span>
        </template>
        <ProductDescription
            @reload-product="loadProductDetails"
            @auto-save-description="onAutoSaveDescription"
            :shopware-details="productDetails?.shopware"
        />
    </TabPanel>
    <TabPanel :disabled="!productDetails?.secondShopware">
        <template #header>
            <i class="pi pi-align-justify mr-2"></i>
            <span>{{ $t("labels.description") }} </span>
            <span
                class="sales-channel-dot w-1rem h-1rem ml-2"
                :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
            ></span>
        </template>
        <ProductDescription
            @reload-product="loadProductDetails"
            @auto-save-description="onAutoSaveDescription"
            :shopware-details="productDetails?.secondShopware"
            :platform="secondPlatform"
        />
    </TabPanel>
</TabView>
