import Dialog from 'primevue/dialog';
import {onMounted, ref, watch} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import {helpers, required} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import InputNumber from 'primevue/inputnumber';
import {addSupplierConfigurationDiscountGroup} from '@/services/price-import';
import {getAll} from '@/services/metadata';
import Dropdown from 'primevue/dropdown';

export default {
    emits: ['close-dialog'],
    components: {
        'p-dialog': Dialog,
        InputText,
        InputNumber,
        'p-button': Button,
        'p-dropdown': Dropdown
    },
    props: {
        displayResponsive: Boolean,
        supplierConfigurationId: String
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const submitted = ref(false);
        const supplierConfigurationId = ref(null);
        const savingInProgress = ref(false);
        const toast = useToast();
        const manufacturerOptions = ref([]);

        onMounted(() => {
            getAll(['manufacturer'], false)
                .then((data: any) => {
                    manufacturerOptions.value = data.data?.manufacturer || [];
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        });

        const state = ref({
            name: null,
            discount: null,
            manufacturer: null
        });

        const rules = {
            name: {},
            discount: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            manufacturer: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, (args) => {
            showDialog.value = args.displayResponsive;
            resetForm();
            supplierConfigurationId.value = args.supplierConfigurationId;
        });

        const closeDialog = (forceRefresh: boolean = false) => {
            resetForm();
            context.emit('close-dialog', forceRefresh);
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            addSupplierConfigurationDiscountGroup(
                supplierConfigurationId.value,
                state.value.name,
                state.value.discount,
                state.value.manufacturer?.id || null,
                state.value.manufacturer?.name || null
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully')
                    );
                    closeDialog(true);
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        const resetForm = () => {
            state.value.name = null;
            state.value.discount = null;
            state.value.manufacturer = null;
            submitted.value = false;
        };

        return {
            showDialog,
            closeDialog,
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            locale: i18n.global.locale,
            manufacturerOptions
        };
    }
};
