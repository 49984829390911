import {computed, onMounted, ref, toRefs} from 'vue';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import {stripTagsAndTruncate} from '@/utils/helpers';
import Tag from 'primevue/tag';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import MultiSelect from 'primevue/multiselect';
import {getAll as getAllMetadata} from '@/services/metadata';

import {FilterMatchMode, FilterService} from 'primevue/api';
import store from '@/store';

export default {
    components: {
        DataTable,
        Column,
        InputText,
        Tag,
        TriStateCheckbox,
        'p-multiselect': MultiSelect
    },
    props: {
        products: Array,
        selectionMode: String,
        tableHeader: String,
        scrollableHeight: {
            type: String,
            default: null
        },
        platform: {
            type: String,
            default: null
        }
    },
    setup(props: any) {
        const dt = ref();
        const expandedRows = ref([]);
        const availableProductTags = ref([]);

        const availableStockFilter = ref('availableStockFilter');

        const nameComboFilter = ref('nameComboFilter');

        const tagsFilter = ref('tagsFilter');

        const {
            products,
            selectionMode,
            tableHeader,
            scrollableHeight,
            platform
        } = toRefs(props);

        onMounted(() => {
            getAllMetadata(['productTags']).then((data) => {
                availableProductTags.value = data.data?.productTags || [];
            });

            FilterService.register(
                availableStockFilter.value,
                (value, filter) => {
                    return filter ? value > 0 : value < 1;
                }
            );

            FilterService.register(nameComboFilter.value, (value, filter) => {
                return (value || []).some(
                    (item: any) =>
                        item.toUpperCase().indexOf(filter.toUpperCase()) !== -1
                );
            });

            FilterService.register(tagsFilter.value, (value, filter) => {
                if (!filter || filter.length < 1) {
                    return true;
                }

                let foundOne = false;
                filter.forEach((filterItem: any) => {
                    foundOne =
                        foundOne ||
                        (value || []).some(
                            (item: any) =>
                                item
                                    .toUpperCase()
                                    .indexOf(filterItem.toUpperCase()) !== -1
                        );
                });

                return foundOne;
            });
        });

        const filters = ref({
            articleNumber: {value: null, matchMode: FilterMatchMode.CONTAINS},
            nameCombo: {value: null, matchMode: nameComboFilter.value},
            tags: {
                value: null,
                matchMode: tagsFilter.value
            },
            availableStock: {
                value: null,
                matchMode: availableStockFilter.value
            }
        });

        const productsExtended = computed(() => {
            return (products.value || []).map((item: any) => {
                return {
                    ...item,
                    resolvedShopwareData:
                        platform.value &&
                        typeof item?.secondShopwareData !== 'undefined'
                            ? item?.secondShopwareData
                            : item?.shopwareData,
                    availableStock:
                        platform.value &&
                        typeof item?.secondShopwareData !== 'undefined'
                            ? item?.secondShopwareData?.availableStock
                            : item?.shopwareData?.availableStock,
                    nameCombo: [item?.name].concat(item?.tags || [])
                };
            });
        });

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) =>
                    item.value ===
                    (platform.value || store.getters['auth/platform'])
            );
        });

        return {
            filters,
            expandedRows,
            dt,
            productsExtended,
            selectionMode,
            tableHeader,
            scrollableHeight,
            stripTagsAndTruncate,
            availableProductTags,
            availableStockFilter,
            nameComboFilter,
            tagsFilter,
            resolvedPlatform,
            platform
        };
    }
};
