import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3378d8af"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full flex justify-content-center" }
const _hoisted_2 = { class: "mt-2" }
const _hoisted_3 = { class: "flex align-content-center" }
const _hoisted_4 = {
  style: {"vertical-align":"middle"},
  class: "mr-2"
}
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]
const _hoisted_8 = { style: {"vertical-align":"middle"} }
const _hoisted_9 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_10 = { class: "mt-2" }
const _hoisted_11 = { style: {"vertical-align":"middle"} }
const _hoisted_12 = {
  key: 0,
  class: "pi pi-exclamation-triangle",
  style: {"margin-left":"0.5rem","vertical-align":"middle"}
}
const _hoisted_13 = { style: {"vertical-align":"middle"} }
const _hoisted_14 = {
  key: 0,
  class: "pi pi-exclamation-triangle",
  style: {"margin-left":"0.5rem","vertical-align":"middle"}
}
const _hoisted_15 = { class: "flex align-items-center text-center" }
const _hoisted_16 = { class: "w-full flex justify-content-center ml-3" }
const _hoisted_17 = { class: "flex align-items-center text-center" }
const _hoisted_18 = { class: "flex align-items-center text-center" }
const _hoisted_19 = { class: "w-full flex justify-content-center ml-3" }
const _hoisted_20 = { class: "flex align-items-center text-center" }
const _hoisted_21 = { class: "pi pi-pencil text-color" }
const _hoisted_22 = ["innerHTML"]
const _hoisted_23 = { key: 1 }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_SalesChannelSwitcher = _resolveComponent("SalesChannelSwitcher")
  const _component_FilterMenu = _resolveComponent("FilterMenu")
  const _component_Column = _resolveComponent("Column")
  const _component_Badge = _resolveComponent("Badge")
  const _component_TieredMenu = _resolveComponent("TieredMenu")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Tag = _resolveComponent("Tag")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_router_link = _resolveComponent("router-link")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_PrimePanel = _resolveComponent("PrimePanel")
  const _component_BulkAddAccessories = _resolveComponent("BulkAddAccessories")
  const _component_BulkAddRemoveBadges = _resolveComponent("BulkAddRemoveBadges")
  const _component_BulkAddRemoveCategories = _resolveComponent("BulkAddRemoveCategories")
  const _component_BulkAddRemoveDiscounts = _resolveComponent("BulkAddRemoveDiscounts")
  const _component_BulkAddWeclappDocuments = _resolveComponent("BulkAddWeclappDocuments")
  const _component_BulkAddShopwareDocuments = _resolveComponent("BulkAddShopwareDocuments")
  const _component_BulkSetShopOnlineStatus = _resolveComponent("BulkSetShopOnlineStatus")
  const _component_BulkEditPromotions = _resolveComponent("BulkEditPromotions")
  const _component_BulkEditMetatags = _resolveComponent("BulkEditMetatags")
  const _component_BulkEditCompliance = _resolveComponent("BulkEditCompliance")
  const _component_BulkEditTags = _resolveComponent("BulkEditTags")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_PrimePanel, { class: "panel-container-datatable" }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_SalesChannelSwitcher)
          ])
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm text-sm",
          paginator: "",
          rows: 10,
          lazy: "",
          scrollable: "",
          scrollHeight: "calc(100vh - 25rem)",
          ref: "dt",
          dataKey: "id",
          totalRecords: _ctx.totalRecords,
          value: _ctx.products,
          "paginator-template": 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
          "rows-per-page-options": [10,20,50,100,200],
          "current-page-report-template": '{first} to {last} of {totalRecords}',
          filters: _ctx.filters,
          "onUpdate:filters": _cache[3] || (_cache[3] = $event => ((_ctx.filters) = $event)),
          filterDisplay: "row",
          onPage: _cache[4] || (_cache[4] = $event => (_ctx.onPage($event))),
          onFilter: _cache[5] || (_cache[5] = $event => (_ctx.onFilter($event))),
          onSort: _cache[6] || (_cache[6] = $event => (_ctx.onSort($event))),
          onStateRestore: _ctx.onStateRestore,
          expandedRows: _ctx.expandedRows,
          "onUpdate:expandedRows": _cache[7] || (_cache[7] = $event => ((_ctx.expandedRows) = $event)),
          selection: _ctx.selectedProducts,
          "onUpdate:selection": _cache[8] || (_cache[8] = $event => ((_ctx.selectedProducts) = $event)),
          stateStorage: "local",
          stateKey: "products-webshop-view-state-session",
          onRowSelect: _ctx.onRowSelect,
          onRowSelectAll: _ctx.onRowSelectAll,
          onRowUnselect: _ctx.onRowUnselect,
          onRowUnselectAll: _ctx.onRowUnselectAll
        }, {
          expansion: _withCtx((slotProps) => [
            (slotProps.data?.shopwareData?.description || slotProps.data?.description)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  innerHTML: slotProps.data?.shopwareData?.description || slotProps.data?.description
                }, null, 8, _hoisted_22))
              : (_openBlock(), _createElementBlock("div", _hoisted_23, "No description available."))
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              expander: true,
              style: {"min-width":"4rem","max-width":"fit-content"},
              class: "tt-filter-menu-th"
            }, {
              filter: _withCtx(() => [
                _createVNode(_component_FilterMenu, {
                  "parent-component-id": "product-webshop-view-list",
                  "parent-filters": _ctx.filters,
                  onClearFilters: _ctx.clearFilters,
                  onApplyFilters: _ctx.applyFilters
                }, null, 8, ["parent-filters", "onClearFilters", "onApplyFilters"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              selectionMode: "multiple",
              style: {"min-width":"3rem","max-width":"6rem"}
            }, {
              header: _withCtx(() => [
                _createVNode(_component_Badge, {
                  value: _ctx.mergedSelection.length
                }, null, 8, ["value"]),
                (_ctx.mergedSelection.length > 0)
                  ? (_openBlock(), _createElementBlock("i", {
                      key: 0,
                      class: "pi pi-times-circle text-red-600",
                      style: {"padding-left":"7px","cursor":"pointer"},
                      onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.clearSelection && _ctx.clearSelection(...args)))
                    }))
                  : _createCommentVNode("", true),
                _createElementVNode("i", {
                  class: "pi pi-cog p-overlay-badge text-green-600",
                  style: {"padding-left":"7px","cursor":"pointer"},
                  onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.bulkMenuToggle && _ctx.bulkMenuToggle(...args))),
                  "aria-haspopup": "true",
                  "aria-controls": "overlay_tmenu"
                }),
                _createVNode(_component_TieredMenu, {
                  ref: "bulkMenu",
                  id: "overlay_tmenu",
                  model: _ctx.addBulkItems,
                  popup: ""
                }, null, 8, ["model"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "articleNumber",
              sortable: "",
              header: _ctx.$t('labels.articleNumber'),
              style: {"min-width":"13rem"}
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: _ctx.$t('labels.articleNumber')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", _hoisted_4, [
                    _createElementVNode("a", {
                      href: data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id,
                      target: "_blank"
                    }, _toDisplayString(data.articleNumber), 9, _hoisted_5)
                  ]),
                  ((data.shopwareData?.active && data.shopwareData?.name && data.shopwareUrl))
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        target: "_blank",
                        href: _ctx.resolvedShopwareUrl(data.shopwareUrl, data.shopwareData, _ctx.resolvedPlatform?.threeLetterId)
                      }, [
                        _createElementVNode("i", {
                          class: _normalizeClass(["pi pi-shopping-cart mr-2", {'sales-channel-teltec-color':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-videodata-color':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.shopwareData?.active}])
                        }, null, 2)
                      ], 8, _hoisted_6))
                    : _createCommentVNode("", true),
                  ((data.secondShopwareData?.active && data.secondShopwareData?.name && data.secondShopwareUrl))
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 1,
                        target: "_blank",
                        href: _ctx.resolvedShopwareUrl(data.secondShopwareUrl, data.secondShopwareData, _ctx.secondPlatform?.threeLetterId)
                      }, [
                        _createElementVNode("i", {
                          class: _normalizeClass(["pi pi-shopping-cart mr-2", {'sales-channel-videodata-color':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-teltec-color':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active}])
                        }, null, 2)
                      ], 8, _hoisted_7))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "name",
              sortable: "",
              style: {"min-width":"12rem"},
              header: _ctx.$t('labels.articleName')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: _ctx.$t('labels.name')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("span", _hoisted_8, _toDisplayString(data.name || data.shopwareData?.name), 1),
                (data.shopwareData && !_ctx.isValueEqualCaseInsensitive(data.name || null, data.shopwareData.name || null))
                  ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_9, null, 512)), [
                      [
                        _directive_tooltip,
                        _ctx.$t('labels.inconsistentData') + ': ' + _ctx.$t('labels.name'),
                        void 0,
                        { top: true }
                      ]
                    ])
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.tags, (tag) => {
                    return (_openBlock(), _createBlock(_component_Tag, {
                      severity: "info",
                      class: "mt-1 mr-1"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", null, _toDisplayString(tag.toUpperCase().replace('TTC-PRODUCT-META-',
                            '')), 1)
                      ]),
                      _: 2
                    }, 1024))
                  }), 256))
                ])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "ean",
              sortable: "",
              style: {"min-width":"10rem"},
              header: _ctx.$t('labels.ean')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: _ctx.$t('labels.ean')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("span", _hoisted_11, _toDisplayString(data.ean || data.shopwareData?.ean), 1),
                (data.shopwareData && !_ctx.isValueEqualCaseInsensitive(data.ean || null, data.shopwareData.ean || null))
                  ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_12, null, 512)), [
                      [
                        _directive_tooltip,
                        _ctx.$t('labels.inconsistentData') + ': ' + _ctx.$t('labels.ean'),
                        void 0,
                        { top: true }
                      ]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "manufacturerPartNumber",
              sortable: "",
              style: {"min-width":"10rem"},
              header: _ctx.$t('labels.mpn')
            }, {
              filter: _withCtx(({filterModel,filterCallback}) => [
                _createVNode(_component_InputText, {
                  type: "text",
                  modelValue: filterModel.value,
                  "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                  onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                  class: "p-column-filter text-xs",
                  placeholder: _ctx.$t('labels.mpn')
                }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("span", _hoisted_13, _toDisplayString(data.manufacturerPartNumber || data.shopwareData?.mpn), 1),
                (data.shopwareData && !_ctx.isValueEqualCaseInsensitive(data.manufacturerPartNumber || null, data.shopwareData.mpn || null))
                  ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_14, null, 512)), [
                      [
                        _directive_tooltip,
                        _ctx.$t('labels.inconsistentData') + ': ' + _ctx.$t('labels.mpn'),
                        void 0,
                        { top: true }
                      ]
                    ])
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "activeInShopware",
              dataType: "boolean",
              bodyClass: "text-center",
              style: {"min-width":"10rem"}
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.activeInShop')) + " ", 1),
                    _createElementVNode("span", {
                      class: _normalizeClass(["sales-channel-dot w-1rem h-1rem", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                    }, null, 2)
                  ])
                ])
              ]),
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_TriStateCheckbox, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onChange: $event => (filterCallback())
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ])
                ])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.shopwareData?.active, 'text-red-600 pi-times-circle': !(data.shopwareData?.active)}])
                }, null, 2)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "activeInShopware",
              dataType: "boolean",
              bodyClass: "text-center",
              style: {"min-width":"10rem"}
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.productHome.activeInShop')) + " ", 1),
                    _createElementVNode("span", {
                      class: _normalizeClass(["sales-channel-dot w-1rem h-1rem", {'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                    }, null, 2)
                  ])
                ])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.secondShopwareData?.active, 'text-red-600 pi-times-circle': !(data.secondShopwareData?.active)}])
                }, null, 2)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              field: "shopImageExists",
              dataType: "boolean",
              style: {"min-width":"10rem"},
              bodyClass: "text-center"
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.imageExists')) + " ", 1),
                    _createElementVNode("span", {
                      class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-1", {'sales-channel-teltec':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                    }, null, 2)
                  ])
                ])
              ]),
              filter: _withCtx(({filterModel, filterCallback}) => [
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_TriStateCheckbox, {
                      modelValue: filterModel.value,
                      "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                      onChange: $event => (filterCallback())
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                  ])
                ])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': (data?.shopwareData?.imageCount > 0) , 'text-red-600 pi-times-circle': (!(data?.shopwareData?.imageCount)) }])
                }, null, 2)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              style: {"min-width":"10rem"},
              bodyClass: "text-center"
            }, {
              header: _withCtx(() => [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", null, [
                    _createTextVNode(_toDisplayString(_ctx.$t('labels.imageExists')) + " ", 1),
                    _createElementVNode("span", {
                      class: _normalizeClass(["sales-channel-dot w-1rem h-1rem ml-1", {'sales-channel-teltec':['vdd', 'vdl'].includes(_ctx.resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['ttd', 'ttl'].includes(_ctx.resolvedPlatform?.threeLetterId)}])
                    }, null, 2)
                  ])
                ])
              ]),
              body: _withCtx(({data}) => [
                _createElementVNode("i", {
                  class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': (data?.secondShopwareData?.imageCount > 0) , 'text-red-600 pi-times-circle': (!(data?.secondShopwareData?.imageCount)) }])
                }, null, 2)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              frozen: "",
              alignFrozen: "right"
            }, _createSlots({ _: 2 }, [
              (_ctx.editPermissionAvailable)
                ? {
                    name: "body",
                    fn: _withCtx(({data}) => [
                      _createVNode(_component_router_link, {
                        to: {
                                    name: 'ProductDetails',
                                    params: {id: data.articleNumber},
                                    state: {webshopView: true, historyFilters: _ctx.historyFilters}
                                }
                      }, {
                        default: _withCtx(() => [
                          _withDirectives(_createElementVNode("i", _hoisted_21, null, 512), [
                            [
                              _directive_tooltip,
                              _ctx.$t('labels.articleDetails'),
                              void 0,
                              { left: true }
                            ]
                          ])
                        ]),
                        _: 2
                      }, 1032, ["to"])
                    ]),
                    key: "0"
                  }
                : undefined
            ]), 1024)
          ]),
          _: 1
        }, 8, ["totalRecords", "value", "filters", "onStateRestore", "expandedRows", "selection", "onRowSelect", "onRowSelectAll", "onRowUnselect", "onRowUnselectAll"])
      ]),
      _: 1
    }),
    _createVNode(_component_BulkAddAccessories, {
      products: _ctx.mergedSelection,
      displayResponsive: _ctx.dialogs.find(item => item.name === 'Accessories').visible,
      mainProductsSelected: _ctx.dialogs.find(item => item.name === 'Accessories').mainProductSelected,
      onCloseDialog: _ctx.closeAddUsingSelectedMainProducts
    }, null, 8, ["products", "displayResponsive", "mainProductsSelected", "onCloseDialog"]),
    _createVNode(_component_BulkAddRemoveBadges, {
      products: _ctx.selectedProductsInShopware,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'Badges').visible,
      isRemove: _ctx.dialogs.find(item => item.name === 'Badges').isRemove,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'Badges').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isRemove", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkAddRemoveCategories, {
      products: _ctx.selectedProductsInShopware,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'Categories').visible,
      isRemove: _ctx.dialogs.find(item => item.name === 'Categories').isRemove,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'Categories').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isRemove", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkAddRemoveDiscounts, {
      products: _ctx.selectedProductsInShopware,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'Discounts').visible,
      isRemove: _ctx.dialogs.find(item => item.name === 'Discounts').isRemove,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'Discounts').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isRemove", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkAddWeclappDocuments, {
      products: _ctx.mergedSelection,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'WeclappDocuments').visible,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'WeclappDocuments').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkAddShopwareDocuments, {
      products: _ctx.selectedProductsInShopware,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'ShopwareDocuments').visible,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'ShopwareDocuments').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkSetShopOnlineStatus, {
      products: _ctx.mergedSelection,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'OnlineStatus').visible,
      isRemove: _ctx.dialogs.find(item => item.name === 'OnlineStatus').isRemove,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'OnlineStatus').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isRemove", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkEditPromotions, {
      products: _ctx.mergedSelection,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'Promotions').visible,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'Promotions').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkEditMetatags, {
      products: _ctx.mergedSelection,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'Metatags').visible,
      isRemove: _ctx.dialogs.find(item => item.name === 'Metatags').isRemove,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'Metatags').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isRemove", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkEditCompliance, {
      products: _ctx.mergedSelection,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'Compliance').visible,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'Compliance').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isFilterRelated", "onCloseDialog"]),
    _createVNode(_component_BulkEditTags, {
      products: _ctx.mergedSelection,
      productFilters: _ctx.filters,
      productTotalRecords: _ctx.totalRecords,
      displayDialog: _ctx.dialogs.find(item => item.name === 'Tags').visible,
      isRemove: _ctx.dialogs.find(item => item.name === 'Tags').isRemove,
      isFilterRelated: _ctx.dialogs.find(item => item.name === 'Tags').usingFilter,
      onCloseDialog: _ctx.onCloseBulkBadgesCategoriesDialog
    }, null, 8, ["products", "productFilters", "productTotalRecords", "displayDialog", "isRemove", "isFilterRelated", "onCloseDialog"])
  ], 64))
}