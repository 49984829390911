<div class="m-2">
    <ScrollPanel style="height: calc(100vh - 37rem)">
        <DataView :value="videos" layout="grid">
            <template #grid="slotProps">
                <template
                    v-for="(videoItem, index) in slotProps.items"
                    :key="index"
                >
                    <div class="grid align-items-center p-2">
                        <div class="col-2">
                            <img
                                class="item-preview-img"
                                :src="videoItem.imageUrl"
                                :alt="videoItem.name"
                            />
                        </div>
                        <div class="col">
                            <a target="_blank" :href="videoItem.url"
                                >{{ videoItem.source === 'youtube' ?
                                'YouTube-Video': 'Vimeo-Video' }}</a
                            >
                        </div>
                        <div class="col-2 flex justify-content-end">
                            <i
                                class="pi pi-trash text-sm text-red-600 mr-2"
                                style="cursor: pointer"
                                @click="showDeleteDialog(videoItem)"
                            ></i>
                        </div>
                    </div>
                </template>
            </template>
        </DataView>
    </ScrollPanel>
</div>
<DeleteDialog
    :displayResponsive="displayDeleteDialog"
    :product-number="shopwareDetails?.productNumber"
    :selectedVideo="selectedVideo"
    @close-dialog="closeDialogListener"
    :platform="platform"
/>
