import LoadingPlugin from 'vue-loading-overlay';
import OrderList from 'primevue/orderlist';
import PrimeButton from 'primevue/button';
import Image from 'primevue/image';
import Toolbar from 'primevue/toolbar';
import AddDialog from '@/components/dialog/product-images/add-image.vue';
import UseAsCoverDialog from '@/components/dialog/product-images/use-as-cover.vue';
import DeleteDialog from '@/components/dialog/product-images/delete-image.vue';
import {computed, onMounted, ref, toRefs, watch} from 'vue';
import {reorder} from '@/services/product-images';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';

import ScrollPanel from 'primevue/scrollpanel';

export default {
    emits: ['reload-product'],
    components: {
        OrderList,
        'p-button': PrimeButton,
        'p-image': Image,
        AddDialog,
        DeleteDialog,
        UseAsCoverDialog,
        Toolbar,
        loading: LoadingPlugin,
        ScrollPanel
    },
    props: {
        shopwareDetails: Object,
        platform: {
            type: String,
            default: null
        }
    },
    setup(props: any, context: any) {
        const images = ref([]);
        const shopwareDetails = ref(null);
        const selectedImages = ref([]);
        const displayUseAsCoverDialog = ref(false);
        const displayDeleteDialog = ref(false);
        const toast = useToast();
        const selection = ref();
        const loading = ref(false);
        const {platform} = toRefs(props);

        onMounted(() => {
            shopwareDetails.value = Object.assign(
                {},
                props.shopwareDetails || {}
            );
            images.value = [].concat(shopwareDetails.value?.images || []);
        });

        const showUseAsCoverDialog = (data: Array<any>) => {
            selectedImages.value = data;
            displayUseAsCoverDialog.value = true;
        };

        const showDeleteDialog = (data: Array<any>) => {
            selectedImages.value = data;
            displayDeleteDialog.value = true;
        };

        const closeDialogListener = (forceRefresh: boolean = false) => {
            displayUseAsCoverDialog.value = false;
            displayDeleteDialog.value = false;
            if (forceRefresh) {
                context.emit('reload-product');
                selection.value = null;
            }
        };

        const imagesReordered = computed(() => {
            return (
                (shopwareDetails.value?.images || [])
                    .filter((item: any) => item)
                    .map((u: any) => u.id.trim())
                    .join('|') !==
                (images?.value || [])
                    .filter((item: any) => item)
                    .map((u: any) => u.id.trim())
                    .join('|')
            );
        });

        const reorderImages = () => {
            loading.value = true;
            reorder(
                shopwareDetails.value?.productNumber,
                images.value,
                platform.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    closeDialogListener(true);
                })
                .catch((error) => {
                    toast.error(error.message);
                    closeDialogListener();
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        watch(props, () => {
            shopwareDetails.value = Object.assign({}, props.shopwareDetails);
            images.value = [].concat(props.shopwareDetails?.images || []);
        });

        const showMultiDeleteButton = computed(() => {
            return selection.value && selection.value.length > 1;
        });

        const showDeleteAllButton = computed(() => {
            return images.value && images.value.length > 0;
        });

        const copyToClipboard = (data: any, event: any) => {
            navigator.clipboard.writeText(data);
            event.stopPropagation();
            toast.success(i18n.global.t('labels.copied'), {
                timeout: 500
            });
        };

        return {
            images,
            shopwareDetails,
            selectedImages,
            displayUseAsCoverDialog,
            displayDeleteDialog,
            showUseAsCoverDialog,
            showDeleteDialog,
            closeDialogListener,
            reorderImages,
            selection,
            showMultiDeleteButton,
            showDeleteAllButton,
            copyToClipboard,
            loading,
            imagesReordered,
            platform
        };
    }
};
