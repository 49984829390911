<div class="m-2">
    <div class="grid">
        <div class="col-8">
            <div v-for="item in productCategories">
                <Tag class="mb-1">
                    <span>{{ item.breadcrumb.join(' -> ') }}</span>
                    <i
                        class="pi pi-times"
                        style="cursor: pointer"
                        @click="removeSingleCategory(item)"
                    ></i
                ></Tag>
            </div>
        </div>
        <div class="col-4">
            <div class="flex justify-content-end">
                <AutoComplete
                    v-model="searchTerm"
                    :suggestions="filteredResults"
                    :placeholder="searchTermPlaceHolder"
                    @complete="loadData"
                    @item-select="onTermSelected"
                    optionLabel="name"
                    optionValue="id"
                />
            </div>
        </div>
    </div>
    <ScrollPanel style="height: calc(100vh - 38rem)">
        <p-tree
            :value="categories"
            @nodeExpand="onNodeExpand"
            selectionMode="multiple"
            v-model:selectionKeys="selectedCategories"
            :metaKeySelection="false"
            :expandedKeys="expandedKeys"
        ></p-tree>
    </ScrollPanel>
    <div class="mt-3 flex justify-content-end">
        <p-button
            severity="danger"
            :disabled="buttonsDisabled || buttonsHidden"
            @click="discardChanges"
            v-text="$t('labels.revert')"
            class="mr-3"
        >
        </p-button>
        <p-button
            severity="success"
            @click="saveChanges"
            :disabled="buttonsDisabled || buttonsHidden"
            v-text="buttonsDisabled ? $t('labels.inProgress') : $t('labels.save')"
        >
        </p-button>
    </div>
</div>
