import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68cb8a58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-content-end" }

export function render(_ctx, _cache) {
  const _component_SupplierAutoComplete = _resolveComponent("SupplierAutoComplete")
  const _component_p_button = _resolveComponent("p-button")
  const _component_p_dialog = _resolveComponent("p-dialog")

  return (_openBlock(), _createBlock(_component_p_dialog, {
    header: _ctx.$t('labels.addSupplierConfiguration'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[2] || (_cache[2] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw'},
    style: {width: '50vw'},
    onHide: _ctx.closeDialog,
    modal: "",
    class: "text-sm"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        _createVNode(_component_SupplierAutoComplete, {
          onDataEntered: _cache[0] || (_cache[0] = $event => (_ctx.onSupplierChosen($event)))
        }),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_p_button, {
            disabled: _ctx.savingInProgress || !_ctx.state.supplierNumber,
            severity: "success",
            textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.confirm')),
            type: "submit"
          }, null, 8, ["disabled", "textContent"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["header", "visible", "onHide"]))
}