import {computed, ref, toRefs, watch} from 'vue';
import {bulkSaveCrossPlatform} from '@/services/product-accessories';
import Dialog from 'primevue/dialog';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Button from 'primevue/button';
import LoadingPlugin from 'vue-loading-overlay';
import ProductList from '@/components/grid/products/list.vue';
import ProductListLazy from '@/components/grid/products/list-lazy.vue';
import {useToast} from 'vue-toastification';
import Panel from 'primevue/panel';
import ScrollPanel from 'primevue/scrollpanel';
import {i18n} from '@/utils/i18n';

export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        TabPanel,
        TabView,
        ProductList,
        ProductListLazy,
        'p-button': Button,
        LoadingPlugin,
        Panel,
        ScrollPanel
    },
    props: {
        products: Array,
        displayResponsive: Boolean,
        mainProductsSelected: {type: Boolean, default: true}
    },
    setup(props: any, context: any) {
        const {products, mainProductsSelected} = toRefs(props);
        const showDialog = ref(false);
        const selectedProducts = ref([]);
        const loading = ref(false);

        const toast = useToast();

        watch(props, (args) => {
            showDialog.value = args.displayResponsive;
        });

        const closeResponsive = (
            forceResfresh: boolean = false,
            result: any
        ) => {
            selectedProducts.value.length = 0;
            context.emit('close-dialog', forceResfresh, result);
        };

        const showSummaryTab = computed(() => {
            return (
                products.value &&
                products.value.length > 0 &&
                selectedProducts.value &&
                selectedProducts.value.length > 0
            );
        });

        const onSelectionChange = (selection: Array<any>) => {
            selectedProducts.value = selection;
        };

        const addArticleAccessories = async () => {
            loading.value = true;
            try {
                const result = mainProductsSelected.value
                    ? await bulkSaveCrossPlatform(
                          products.value,
                          selectedProducts.value
                      )
                    : await bulkSaveCrossPlatform(
                          selectedProducts.value,
                          products.value
                      );

                toast.success(
                    i18n.global.t('messages.changesSavedSuccessfully')
                );
                closeResponsive(true, result);
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                loading.value = false;
            }
        };

        return {
            products,
            showDialog,
            loading,
            mainProductsSelected,
            selectedProducts,
            closeResponsive,
            addArticleAccessories,
            showSummaryTab,
            onSelectionChange
        };
    }
};
