<PrimeDialog
    :header="$t('labels.transferArticleAccessories')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '85vw'}"
    @hide="closeResponsive(false)"
    :modal="true"
    position="top"
>
    <LoadingPlugin v-model:active="loading" />
    <TabView>
        <TabPanel :header="$t('labels.mainArticle')">
            <ProductListLazy
                @on-selection-change="onSelectionChange"
                selectionMode="multiple"
                :platform="platform"
            />
        </TabPanel>
        <TabPanel :header="$t('labels.articleAccessories')">
            <ProductList :products="products" :platform="platform" />
        </TabPanel>
        <TabPanel :header="$t('labels.summary')" :disabled="!showSummaryTab">
            <ScrollPanel style="height: 65vh" class="w-full">
                <Panel :header="$t('labels.articleAccessories')" class="mb-3">
                    <ProductList :products="products" :platform="platform" />
                </Panel>
                <h1 style="text-align: center">
                    <font-awesome-icon
                        style="margin-left: 30px"
                        :icon="['fas', 'angle-double-down']"
                    /><font-awesome-icon
                        style="margin-left: 30px"
                        :icon="['fas', 'angle-double-down']"
                    /><font-awesome-icon
                        style="margin-left: 30px"
                        :icon="['fas', 'angle-double-down']"
                    />
                </h1>
                <Panel :header="$t('labels.mainArticle')" class="mb-3">
                    <ProductList
                        :products="selectedProducts"
                        :platform="platform"
                    />
                </Panel>
            </ScrollPanel>
            <div class="w-full mt-3 flex justify-content-between">
                <p-button severity="danger" @click="closeResponsive(false)">
                    {{ $t("buttons.cancel") }}
                </p-button>
                <p-button @click="addArticleAccessories" severity="success">
                    {{ $t("buttons.confirm") }}
                </p-button>
            </div>
        </TabPanel>
    </TabView>
</PrimeDialog>
