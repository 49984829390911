<p-dialog
    :header="$t('labels.priceImport.chooseWorkbook')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '20vw'}"
    @hide="closeDialog"
    :closable="false"
    modal
    class="text-sm"
>
    <LoadingPlugin v-model:active="loading" />
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="grid formgrid p-fluid">
            <div class="field col">
                <label>{{$t('labels.priceImport.workbookName')}}</label>
                <p-dropdown
                    v-model="v$.sheetName.$model"
                    :options="spreadsheets || []"
                    :disabled="(spreadsheets?.length || 0) < 2"
                    :class="{'p-invalid':v$.sheetName.$invalid && submitted}"
                    @change="onSpreadsheetChange"
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.sheetName.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid formgrid p-fluid">
            <div class="field col">
                <label>{{$t('labels.manufacturer')}}</label>
                <p-dropdown
                    v-model="v$.manufacturer.$model"
                    :options="manufacturerOptions || []"
                    optionLabel="name"
                    filter
                    showClear
                    :class="{'p-invalid':v$.manufacturer.$invalid && submitted}"
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.manufacturer.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid formgrid p-fluid">
            <div class="field col">
                <label>{{$t('labels.productAdd.ekStartDate')}}</label>
                <p-calendar
                    autocomplete="off"
                    dateFormat="dd.mm.yy"
                    v-model="v$.ekStartDate.$model"
                    :class="{'p-invalid':v$.ekStartDate.$invalid && submitted}"
                    :minDate="new Date()"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.ekStartDate.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid formgrid p-fluid">
            <div class="field col">
                <label>{{$t('labels.productAdd.uvpStartDate')}}</label>
                <p-calendar
                    autocomplete="off"
                    dateFormat="dd.mm.yy"
                    v-model="v$.uvpStartDate.$model"
                    :class="{'p-invalid':v$.uvpStartDate.$invalid && submitted}"
                    :minDate="new Date()"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.uvpStartDate.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="flex justify-content-end">
            <p-button severity="success" type="submit">
                {{$t('buttons.confirm')}}
            </p-button>
        </div>
    </form>
</p-dialog>
