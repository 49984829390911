<PrimeDialog
    :header="$t('labels.ticket.retoure.createCompensationShipment')"
    v-model:visible="showDialog"
    :closable="false"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '640px'}"
    :modal="true"
>
    <loading v-model:active="savingInProgress" />
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="grid p-fluid">
            <div class="col">
                <label> {{$t('labels.shipment.warehouse')}} </label>

                <p-dropdown
                    v-model="v$.warehouse.$model"
                    :options="warehouses || []"
                    optionLabel="name"
                    optionValue="id"
                    :filter="true"
                    class="w-full"
                    @change="setDropdownValue('warehouse', $event)"
                    :showClear="true"
                    :class="{'p-invalid':v$.warehouse.$invalid && submitted}"
                >
                </p-dropdown>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.warehouse.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="grid p-fluid">
            <div class="col field">
                <label>
                    {{$t('labels.ticket.deliveryNote.documentText')}}
                </label>
                <tiny
                    :tinymceScriptSrc="tinyUrl"
                    v-model="v$.documentText.$model"
                    :init="{
                        height: 300,
                        menubar: false,
                        plugins: 'anchor charmap paste template',
                        toolbar: 'undo redo | template | pastetext removeformat',
                        templates : tinyTemplates,
                        template_replace_values: {
                            'ticketOwner': ticketDetails?.customer?.company ||
                                    (ticketDetails?.customer?.firstName +
                                ' ' +
                                ticketDetails?.customer?.lastName),
                            'ticketNumber': ticketDetails.ticketNumber,
                        },
                        browser_spellcheck: true
                    }"
                ></tiny>
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.documentText.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
        <div class="w-full mt-3 flex justify-content-between">
            <p-button severity="danger" @click="onCancelClick">
                {{ $t("buttons.cancel") }}
            </p-button>
            <p-button type="submit" severity="success">
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </form>
</PrimeDialog>
