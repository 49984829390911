import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, withKeys as _withKeys, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50e3bf06"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "font-bold" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col field" }
const _hoisted_4 = {
  key: 0,
  class: "grid p-fluid"
}
const _hoisted_5 = { class: "col field" }
const _hoisted_6 = {
  key: 0,
  class: "p-error"
}
const _hoisted_7 = {
  key: 0,
  class: "col field"
}
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = { class: "col field" }
const _hoisted_10 = {
  key: 1,
  class: "col field"
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 0,
  class: "p-error"
}
const _hoisted_13 = { class: "grid p-fluid" }
const _hoisted_14 = { class: "col field" }
const _hoisted_15 = {
  key: 0,
  class: "p-error"
}
const _hoisted_16 = { class: "grid p-fluid" }
const _hoisted_17 = { class: "col field" }
const _hoisted_18 = {
  key: 0,
  class: "p-error"
}
const _hoisted_19 = { class: "col field" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { class: "grid p-fluid" }
const _hoisted_22 = { class: "col field" }
const _hoisted_23 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_24 = ["src"]
const _hoisted_25 = {
  key: 0,
  class: "p-error"
}
const _hoisted_26 = { class: "grid p-fluid" }
const _hoisted_27 = { class: "col field" }
const _hoisted_28 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_29 = ["src"]
const _hoisted_30 = {
  key: 0,
  class: "p-error"
}
const _hoisted_31 = { class: "grid p-fluid" }
const _hoisted_32 = { class: "col field" }
const _hoisted_33 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_34 = ["src"]
const _hoisted_35 = { class: "grid p-fluid" }
const _hoisted_36 = { class: "col field" }
const _hoisted_37 = { class: "flex justify-content-between flex-wrap" }
const _hoisted_38 = ["src"]
const _hoisted_39 = { class: "w-full flex justify-content-between mt-3" }
const _hoisted_40 = { key: 1 }
const _hoisted_41 = { key: 0 }
const _hoisted_42 = { key: 1 }
const _hoisted_43 = { key: 0 }
const _hoisted_44 = ["innerHTML"]
const _hoisted_45 = {
  key: 0,
  class: "pi pi-exclamation-triangle"
}
const _hoisted_46 = { key: 0 }
const _hoisted_47 = ["innerHTML"]
const _hoisted_48 = ["innerHTML"]
const _hoisted_49 = { key: 2 }
const _hoisted_50 = { key: 0 }
const _hoisted_51 = { key: 1 }
const _hoisted_52 = { class: "button-container" }
const _hoisted_53 = ["onClick"]
const _hoisted_54 = ["onClick"]
const _hoisted_55 = ["onClick"]
const _hoisted_56 = ["onClick"]
const _hoisted_57 = { class: "grid" }
const _hoisted_58 = { class: "col-2 field" }
const _hoisted_59 = { class: "ml-3" }
const _hoisted_60 = { class: "col-10 field" }
const _hoisted_61 = ["src", "onClick"]
const _hoisted_62 = ["src", "onClick"]
const _hoisted_63 = {
  key: 0,
  class: "grid"
}
const _hoisted_64 = { class: "col field" }
const _hoisted_65 = { class: "ml-3" }
const _hoisted_66 = {
  key: 0,
  class: "grid"
}
const _hoisted_67 = { class: "col field" }
const _hoisted_68 = { class: "ml-3" }
const _hoisted_69 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_Checkbox = _resolveComponent("Checkbox")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_MultiSelect = _resolveComponent("MultiSelect")
  const _component_Calendar = _resolveComponent("Calendar")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_divider = _resolveComponent("p-divider")
  const _component_p_textarea = _resolveComponent("p-textarea")
  const _component_InputText = _resolveComponent("InputText")
  const _component_tiny = _resolveComponent("tiny")
  const _component_p_button = _resolveComponent("p-button")
  const _component_p_fieldset = _resolveComponent("p-fieldset")
  const _component_Column = _resolveComponent("Column")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _component_MailLogsOverview = _resolveComponent("MailLogsOverview")
  const _component_CronJobLogsOverview = _resolveComponent("CronJobLogsOverview")
  const _component_ManualRunDialog = _resolveComponent("ManualRunDialog")
  const _directive_tooltip = _resolveDirective("tooltip")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_Panel, null, {
      header: _withCtx(() => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.cronType ? _ctx.$t("labels." + _ctx.cronType + "s") : _ctx.$t("labels.cronjobs")), 1)
      ]),
      default: _withCtx(() => [
        (_ctx.dialogVisible)
          ? (_openBlock(), _createBlock(_component_p_fieldset, {
              key: 0,
              legend: _ctx.selectedRow?.name
            }, {
              default: _withCtx(() => [
                _createElementVNode("form", {
                  onSubmit: _cache[12] || (_cache[12] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.automatic')), 1),
                      _createVNode(_component_Checkbox, {
                        modelValue: _ctx.v$.automatic.$model,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.automatic.$model) = $event)),
                        binary: "",
                        class: "ml-2 mb-1",
                        disabled: _ctx.v$.cycle.$model === '6'
                      }, null, 8, ["modelValue", "disabled"])
                    ])
                  ]),
                  (_ctx.v$.automatic.$model && _ctx.v$.cycle.$model !== '6')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.scheduled')), 1),
                          _createVNode(_component_p_dropdown, {
                            modelValue: _ctx.v$.cycle.$model,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$.cycle.$model) = $event)),
                            optionLabel: "label",
                            optionValue: "value",
                            onChange: _ctx.onCycleChange,
                            options: [
                                        {
                                            label: _ctx.$t('labels.mail.schedule1'),
                                            value: '1'
                                        },
                                    {
                                        label: _ctx.$t('labels.mail.schedule2'),
                                        value: '2'
                                    }, 
                                    {
                                        label: _ctx.$t('labels.mail.schedule3'),
                                        value: '3'
                                    }, 
                                    {
                                        label: _ctx.$t('labels.mail.schedule4'),
                                        value: '4'
                                    }],
                            class: _normalizeClass({'p-invalid':_ctx.v$.cycle.$invalid && _ctx.submitted})
                          }, null, 8, ["modelValue", "onChange", "options", "class"]),
                          ((_ctx.v$.cycle.$invalid && _ctx.submitted) || _ctx.v$.cycle.$pending.$response)
                            ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.v$.cycle.required.$message.replace('Value',
                        _ctx.$t('labels.mail.scheduled'))), 1))
                            : _createCommentVNode("", true)
                        ]),
                        (_ctx.v$.cycle.$model === '3' || _ctx.v$.cycle.$model === '4')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.' + (_ctx.v$.cycle.$model === '3' ?
                        'dayOfWeekLabel' : 'dayOfMonthLabel' ))), 1),
                              _createVNode(_component_MultiSelect, {
                                modelValue: _ctx.v$.daysIndices.$model,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.daysIndices.$model) = $event)),
                                optionLabel: "label",
                                optionValue: "value",
                                options: _ctx.v$.cycle.$model === '3' ? _ctx.daysOfWeekOptions : _ctx.daysOfMonthOptions,
                                class: _normalizeClass({'p-invalid':_ctx.v$.daysIndices.$invalid && _ctx.submitted})
                              }, null, 8, ["modelValue", "options", "class"]),
                              ((_ctx.v$.daysIndices.$invalid && _ctx.submitted) || _ctx.v$.daysIndices.$pending.$response)
                                ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.v$.daysIndices.required.$message.replace('Value',
                        _ctx.$t('labels.mail.' + (_ctx.v$.cycle.$model === '3' ?
                        'dayOfWeekLabel' : 'dayOfMonthLabel' )))), 1))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t(_ctx.v$.cycle.$model === '1' ? 'labels.mail.firstRun'
                        : 'labels.at')), 1),
                          _createVNode(_component_Calendar, {
                            modelValue: _ctx.v$.startTime.$model,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$.startTime.$model) = $event)),
                            showTime: true,
                            timeOnly: true
                          }, null, 8, ["modelValue"])
                        ]),
                        (_ctx.v$.cycle.$model === '1')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                              _createElementVNode("label", {
                                innerHTML: _ctx.$t('labels.mail.minuteInterval',{value: '⇩'})
                              }, null, 8, _hoisted_11),
                              _createVNode(_component_InputNumber, {
                                max: 1440,
                                style: {"max-height":"38px"},
                                autocomplete: "off",
                                modelValue: _ctx.v$.minuteInterval.$model,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$.minuteInterval.$model) = $event)),
                                class: _normalizeClass({'p-invalid':_ctx.v$.minuteInterval.$invalid && _ctx.submitted})
                              }, null, 8, ["modelValue", "class"]),
                              ((_ctx.v$.minuteInterval.$invalid && _ctx.submitted) || _ctx.v$.minuteInterval.$pending.$response)
                                ? (_openBlock(), _createElementBlock("small", _hoisted_12, _toDisplayString(_ctx.v$.minuteInterval.required.$message), 1))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_p_divider),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.isMailOnlyTypeOfJob ? _ctx.$t('labels.mail.toAddress') :
                        _ctx.$t('labels.sendResultTo')) + " (" + _toDisplayString(_ctx.$t('labels.mail.separatedBySemiColon')) + ") ", 1),
                      _createVNode(_component_p_textarea, {
                        rows: "2",
                        placeholder: _ctx.$t('labels.mail.toAddress'),
                        autocomplete: "off",
                        modelValue: _ctx.v$.emailToAddress.$model,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.v$.emailToAddress.$model) = $event)),
                        class: _normalizeClass({'p-invalid':_ctx.v$.emailToAddress.$invalid && _ctx.submitted})
                      }, null, 8, ["placeholder", "modelValue", "class"]),
                      ((_ctx.v$.emailToAddress.$invalid && _ctx.submitted) || _ctx.v$.emailToAddress.$pending.$response)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_15, _toDisplayString(_ctx.v$.emailToAddress.required.$message), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.ccAddress')) + " (" + _toDisplayString(_ctx.$t('labels.mail.separatedBySemiColon')) + ") ", 1),
                      _createVNode(_component_InputText, {
                        autocomplete: "off",
                        modelValue: _ctx.v$.emailCcAddress.$model,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.v$.emailCcAddress.$model) = $event)),
                        class: _normalizeClass({'p-invalid':_ctx.v$.emailCcAddress.$invalid && _ctx.submitted})
                      }, null, 8, ["modelValue", "class"]),
                      ((_ctx.v$.emailCcAddress.$invalid && _ctx.submitted) || _ctx.v$.emailCcAddress.$pending.$response)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_18, _toDisplayString(_ctx.v$.emailCcAddress.required.$message), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.variables')), 1),
                      (_ctx.selectedRow?.variables)
                        ? (_openBlock(), _createElementBlock("ul", _hoisted_20, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedRow?.variables.split(','), (element) => {
                              return (_openBlock(), _createElementBlock("li", null, _toDisplayString(element), 1))
                            }), 256))
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.subject')), 1),
                        _createElementVNode("img", {
                          src: require('@/assets/img/flag_placeholder.png'),
                          class: "flag flag-de mb-3"
                        }, null, 8, _hoisted_24)
                      ]),
                      _createVNode(_component_InputText, {
                        autocomplete: "off",
                        modelValue: _ctx.v$.emailSubject.$model.de,
                        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.v$.emailSubject.$model.de) = $event)),
                        class: _normalizeClass({'p-invalid':_ctx.v$.emailSubject.$invalid && _ctx.submitted})
                      }, null, 8, ["modelValue", "class"]),
                      ((_ctx.v$.emailSubject.$invalid && _ctx.submitted) || _ctx.v$.emailSubject.$pending.$response)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_25, _toDisplayString(_ctx.v$.emailSubject.required.$message), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.body')), 1),
                        _createElementVNode("img", {
                          src: require('@/assets/img/flag_placeholder.png'),
                          class: "flag flag-de mb-3"
                        }, null, 8, _hoisted_29)
                      ]),
                      _createVNode(_component_tiny, {
                        tinymceScriptSrc: _ctx.tinyUrl,
                        modelValue: _ctx.v$.emailBody.$model.de,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$.emailBody.$model.de) = $event)),
                        init: {
                        height: 500,
                        menubar: false,
                        plugins: 'lists link image emoticons code table anchor charmap fullscreen paste',
                        toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code'
                    }
                      }, null, 8, ["tinymceScriptSrc", "modelValue"]),
                      ((_ctx.v$.emailBody.$invalid && _ctx.submitted) || _ctx.v$.emailBody.$pending.$response)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_30, _toDisplayString(_ctx.v$.emailBody.required.$message), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createVNode(_component_p_divider),
                  _createElementVNode("div", _hoisted_31, [
                    _createElementVNode("div", _hoisted_32, [
                      _createElementVNode("div", _hoisted_33, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.subject')), 1),
                        _createElementVNode("img", {
                          src: require('@/assets/img/flag_placeholder.png'),
                          class: "flag flag-gb mb-3"
                        }, null, 8, _hoisted_34)
                      ]),
                      _createVNode(_component_InputText, {
                        autocomplete: "off",
                        modelValue: _ctx.v$.emailSubject.$model.en,
                        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.v$.emailSubject.$model.en) = $event)),
                        class: "mb-3"
                      }, null, 8, ["modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_35, [
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("div", _hoisted_37, [
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.body')), 1),
                        _createElementVNode("img", {
                          src: require('@/assets/img/flag_placeholder.png'),
                          class: "flag flag-gb mb-3"
                        }, null, 8, _hoisted_38)
                      ]),
                      _createVNode(_component_tiny, {
                        tinymceScriptSrc: _ctx.tinyUrl,
                        modelValue: _ctx.v$.emailBody.$model.en,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.v$.emailBody.$model.en) = $event)),
                        init: {
                        height: 500,
                        menubar: false,
                        plugins: 'lists link image emoticons code table anchor charmap fullscreen paste',
                        toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code'
                    }
                      }, null, 8, ["tinymceScriptSrc", "modelValue"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_39, [
                    _createVNode(_component_p_button, {
                      severity: "danger",
                      textContent: _toDisplayString(_ctx.$t('buttons.cancel')),
                      onClick: _ctx.handleDialogClose
                    }, null, 8, ["textContent", "onClick"]),
                    _createVNode(_component_p_button, {
                      disabled: _ctx.savingInProgress,
                      severity: "success",
                      textContent: _toDisplayString(_ctx.savingInProgress ? _ctx.$t('labels.inProgress') : _ctx.$t('buttons.confirm')),
                      type: "submit"
                    }, null, 8, ["disabled", "textContent"])
                  ])
                ], 32)
              ]),
              _: 1
            }, 8, ["legend"]))
          : _createCommentVNode("", true),
        (!_ctx.dialogVisible)
          ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
              _createVNode(_component_DataTable, {
                class: "p-datatable-sm text-sm",
                stripedRows: "",
                paginator: "",
                lazy: "",
                scrollable: "",
                scrollHeight: "calc(100vh - 23rem)",
                rows: 20,
                ref: "dt",
                dataKey: "id",
                totalRecords: _ctx.totalRecords,
                value: _ctx.crons,
                responsiveLayout: "scroll",
                "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
                "rows-per-page-options": [10,20,50],
                "current-page-report-template": "{first} to {last} of {totalRecords}",
                filters: _ctx.filters,
                "onUpdate:filters": _cache[13] || (_cache[13] = $event => ((_ctx.filters) = $event)),
                filterDisplay: "row",
                onPage: _ctx.onPage,
                onFilter: _ctx.onFilter,
                onSort: _ctx.onSort,
                expandedRows: _ctx.expandedRows,
                "onUpdate:expandedRows": _cache[14] || (_cache[14] = $event => ((_ctx.expandedRows) = $event))
              }, {
                expansion: _withCtx((slotProps) => [
                  _createElementVNode("div", _hoisted_57, [
                    _createElementVNode("div", _hoisted_58, [
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.toAddress')), 1),
                      _createElementVNode("span", _hoisted_59, _toDisplayString(slotProps.data?.emailToAddress), 1)
                    ]),
                    _createElementVNode("div", _hoisted_60, [
                      _createElementVNode("img", {
                        src: require('@/assets/img/flag_placeholder.png'),
                        class: "flag flag-de",
                        onClick: $event => (_ctx.switchCronTemplateLanguage(slotProps.data?.id, 'de')),
                        style: _normalizeStyle([('de' !== slotProps.data?.selectedLanguage) ? 'filter: brightness(0.5);cursor:pointer': ''])
                      }, null, 12, _hoisted_61),
                      _createElementVNode("img", {
                        src: require('@/assets/img/flag_placeholder.png'),
                        class: "flag flag-gb ml-3",
                        disabled: "",
                        onClick: $event => (_ctx.switchCronTemplateLanguage(slotProps.data?.id, 'en')),
                        style: _normalizeStyle([('en' !== slotProps.data?.selectedLanguage) ? 'filter: brightness(0.5);cursor:pointer': ''])
                      }, null, 12, _hoisted_62)
                    ])
                  ]),
                  (slotProps.data?.emailCcAddress)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_63, [
                        _createElementVNode("div", _hoisted_64, [
                          _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.ccAddress')), 1),
                          _createElementVNode("span", _hoisted_65, _toDisplayString(slotProps.data?.emailCcAddress), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data?.emailSubject, (subject, language) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (slotProps.data?.selectedLanguage === language)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_66, [
                            _createElementVNode("div", _hoisted_67, [
                              _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.mail.subject')), 1),
                              _createElementVNode("span", _hoisted_68, _toDisplayString(subject), 1)
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 256)),
                  _createVNode(_component_p_divider),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.data?.emailBody, (body, language) => {
                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                      (slotProps.data?.selectedLanguage === language)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            innerHTML: body
                          }, null, 8, _hoisted_69))
                        : _createCommentVNode("", true)
                    ], 64))
                  }), 256))
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    expander: true,
                    headerStyle: "width: 3em"
                  }),
                  (!_ctx.isOneTimeTypeOfJob)
                    ? (_openBlock(), _createBlock(_component_Column, {
                        key: 0,
                        field: "automatic",
                        dataType: "boolean",
                        style: {"width":"8rem"},
                        header: _ctx.$t('labels.mail.execution')
                      }, {
                        filter: _withCtx(({filterModel, filterCallback}) => [
                          _createVNode(_component_TriStateCheckbox, {
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                            onChange: $event => (filterCallback())
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                        ]),
                        body: _withCtx(({data}) => [
                          (data.automatic)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_41, _toDisplayString(_ctx.$t('labels.mail.automatic')), 1))
                            : (_openBlock(), _createElementBlock("div", _hoisted_42, _toDisplayString(_ctx.$t('labels.mail.manual')), 1))
                        ]),
                        _: 1
                      }, 8, ["header"]))
                    : _createCommentVNode("", true),
                  (_ctx.isOneTimeTypeOfJob && _ctx.editPermissionAvailable)
                    ? (_openBlock(), _createBlock(_component_Column, {
                        key: 1,
                        field: "manuallyInitiatedBy",
                        header: _ctx.$t('labels.initiatedBy')
                      }, {
                        filter: _withCtx(({filterModel,filterCallback}) => [
                          _createVNode(_component_InputText, {
                            modelValue: filterModel.value,
                            "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                            onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                            class: "p-column-filter text-sm",
                            placeholder: _ctx.$t('labels.email')
                          }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["header"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_Column, {
                    field: "name",
                    sortable: "",
                    style: {"min-width":"12rem"},
                    header: _ctx.$t('labels.name'),
                    class: "text-bold"
                  }, {
                    filter: _withCtx(({filterModel,filterCallback}) => [
                      _createVNode(_component_InputText, {
                        modelValue: filterModel.value,
                        "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                        onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                        class: "p-column-filter text-sm",
                        placeholder: _ctx.$t('labels.email')
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["header"]),
                  _createVNode(_component_Column, {
                    field: "description",
                    header: _ctx.$t('labels.description')
                  }, null, 8, ["header"]),
                  (!_ctx.isOneTimeTypeOfJob)
                    ? (_openBlock(), _createBlock(_component_Column, {
                        key: 2,
                        header: _ctx.$t('labels.mail.scheduled'),
                        style: {"min-width":"12rem"}
                      }, {
                        body: _withCtx(({data}) => [
                          (data.automatic)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                                _createElementVNode("span", {
                                  innerHTML: 
                                            _ctx.$t('labels.mail.schedule' +
                                            (data.cycle || 2) + 'Desc', {
                                            startTime: data.startTime,
                                            minuteInterval: data.minuteInterval,
                                            label:
                                            _ctx.$t('labels.mail.dayOfMonthLabel'),
                                            daysOfWeek: data.cycle !== 3 ? '' : (data.daysIndices ||
                                            []).map((item)=>
                                            _ctx.$t('labels.mail.dayOfWeek' +
                                            item)).join(', '), 
                                            daysOfMonth: data.cycle !== 4 ? '' :
                                            (data.daysIndices || []).join(', ')
                                            })
                                }, null, 8, _hoisted_44),
                                (data.cycle === 6)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_45))
                                  : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["header"]))
                    : _createCommentVNode("", true),
                  (!_ctx.isOneTimeTypeOfJob)
                    ? (_openBlock(), _createBlock(_component_Column, {
                        key: 3,
                        field: "variables",
                        header: _ctx.$t('labels.mail.variables')
                      }, {
                        body: _withCtx(({data}) => [
                          (data.variables)
                            ? (_openBlock(), _createElementBlock("ul", _hoisted_46, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.variables.split(','), (element) => {
                                  return (_openBlock(), _createElementBlock("li", null, _toDisplayString(element), 1))
                                }), 256))
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["header"]))
                    : _createCommentVNode("", true),
                  (_ctx.isOneTimeTypeOfJob)
                    ? (_openBlock(), _createBlock(_component_Column, {
                        key: 4,
                        style: {"max-width":"16rem"},
                        field: "lastRunAtFormatted",
                        header: _ctx.$t('labels.status'),
                        sortable: ""
                      }, {
                        body: _withCtx(({data}) => [
                          (data.lastRunAtFormatted)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                innerHTML: _ctx.$t('labels.executedAt', {time:
                                    data.lastRunAtFormatted})
                              }, null, 8, _hoisted_47))
                            : (data.startTime)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  innerHTML: _ctx.$t('labels.plannedAt', {time:
                                    data.startTimeFormatted})
                                }, null, 8, _hoisted_48))
                              : (_openBlock(), _createElementBlock("div", _hoisted_49, _toDisplayString(_ctx.$t('labels.mail.scheduled')), 1))
                        ]),
                        _: 1
                      }, 8, ["header"]))
                    : _createCommentVNode("", true),
                  (_ctx.isOneTimeTypeOfJob)
                    ? (_openBlock(), _createBlock(_component_Column, {
                        key: 5,
                        style: {"max-width":"16rem"},
                        field: "payload",
                        header: "Payload"
                      }, {
                        body: _withCtx(({data}) => [
                          _createElementVNode("ul", null, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(JSON.parse(data.payload), (element, index) => {
                              return (_openBlock(), _createElementBlock("li", null, [
                                (typeof element === 'object' && element !== null)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_50, [
                                      _createElementVNode("b", null, _toDisplayString(index), 1),
                                      _createTextVNode(": " + _toDisplayString(_ctx.cleanUpLengthyProperties(element)), 1)
                                    ]))
                                  : (_openBlock(), _createElementBlock("span", _hoisted_51, [
                                      _createElementVNode("b", null, _toDisplayString(index), 1),
                                      _createTextVNode(": " + _toDisplayString(element), 1)
                                    ]))
                              ]))
                            }), 256))
                          ])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_Column, { style: {"min-width":"8rem"} }, {
                    body: _withCtx(({data}) => [
                      _createElementVNode("div", _hoisted_52, [
                        (_ctx.editPermissionAvailable && !data.automatic && !_ctx.isOneTimeTypeOfJob)
                          ? _withDirectives((_openBlock(), _createElementBlock("i", {
                              key: 0,
                              class: "pi pi-forward text-sm mr-2",
                              onClick: $event => (_ctx.handleRunDialogOpen($event, data))
                            }, null, 8, _hoisted_53)), [
                              [
                                _directive_tooltip,
                                _ctx.$t('labels.runJobManually'),
                                void 0,
                                { left: true }
                              ]
                            ])
                          : _createCommentVNode("", true),
                        _withDirectives(_createElementVNode("i", {
                          class: "pi pi-envelope text-sm mr-2",
                          onClick: $event => (_ctx.handleMailLogsClick($event, data))
                        }, null, 8, _hoisted_54), [
                          [
                            _directive_tooltip,
                            _ctx.$t('labels.mailLogs'),
                            void 0,
                            { left: true }
                          ]
                        ]),
                        _withDirectives(_createElementVNode("i", {
                          class: "pi pi-book text-sm mr-2",
                          onClick: $event => (_ctx.handleLogsClick($event, data))
                        }, null, 8, _hoisted_55), [
                          [
                            _directive_tooltip,
                            _ctx.$t('labels.logs'),
                            void 0,
                            { left: true }
                          ]
                        ]),
                        (_ctx.editPermissionAvailable && !_ctx.isOneTimeTypeOfJob)
                          ? _withDirectives((_openBlock(), _createElementBlock("i", {
                              key: 1,
                              class: "pi pi-pencil text-sm mr-2",
                              onClick: $event => (_ctx.handleDialogOpen($event, data))
                            }, null, 8, _hoisted_56)), [
                              [
                                _directive_tooltip,
                                _ctx.$t('buttons.edit'),
                                void 0,
                                { left: true }
                              ]
                            ])
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["totalRecords", "value", "filters", "onPage", "onFilter", "onSort", "expandedRows"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_MailLogsOverview, {
      cron: _ctx.selectedRow,
      "display-logs-dialog": _ctx.mailLogsOverviewDialogVisible,
      onHide: _cache[15] || (_cache[15] = $event => (_ctx.mailLogsOverviewDialogVisible = false))
    }, null, 8, ["cron", "display-logs-dialog"]),
    _createVNode(_component_CronJobLogsOverview, {
      cron: _ctx.selectedRow,
      "display-logs-dialog": _ctx.logsOverviewDialogVisible,
      onHide: _cache[16] || (_cache[16] = $event => (_ctx.logsOverviewDialogVisible = false))
    }, null, 8, ["cron", "display-logs-dialog"]),
    _createVNode(_component_ManualRunDialog, {
      "job-id": _ctx.selectedRow?.id,
      "is-mail-dialog": _ctx.isMailOnlyTypeOfJob,
      "display-dialog": _ctx.runDialogVisible,
      onHide: _cache[17] || (_cache[17] = $event => (_ctx.runDialogVisible = false))
    }, null, 8, ["job-id", "is-mail-dialog", "display-dialog"])
  ], 64))
}