import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67e8cf8e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"text-align":"center"} }
const _hoisted_2 = { class: "w-full mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_LoadingPlugin = _resolveComponent("LoadingPlugin")
  const _component_ProductList = _resolveComponent("ProductList")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_Panel = _resolveComponent("Panel")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")
  const _component_p_button = _resolveComponent("p-button")
  const _component_TabView = _resolveComponent("TabView")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('labels.transferArticleAccessories'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[2] || (_cache[2] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw'},
    style: {width: '85vw'},
    onHide: _cache[3] || (_cache[3] = $event => (_ctx.closeResponsive(false))),
    modal: true,
    position: "top"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LoadingPlugin, {
        active: _ctx.loading,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
      }, null, 8, ["active"]),
      _createVNode(_component_TabView, null, {
        default: _withCtx(() => [
          _createVNode(_component_TabPanel, {
            header: _ctx.$t('labels.mainArticle')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ProductList, {
                products: [{..._ctx.productDetails?.weclapp, shopwareData: _ctx.productDetails?.shopware, secondShopwareData: _ctx.productDetails?.secondShopware}],
                platform: _ctx.platform
              }, null, 8, ["products", "platform"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_TabPanel, {
            header: _ctx.$t('labels.articleAccessories')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ProductList, {
                products: _ctx.accessories,
                platform: _ctx.platform
              }, null, 8, ["products", "platform"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_TabPanel, {
            header: _ctx.$t('labels.summary'),
            disabled: !_ctx.showSummaryTab
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ScrollPanel, {
                style: {"height":"65vh"},
                class: "w-full"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Panel, {
                    header: _ctx.$t('labels.articleAccessories'),
                    class: "mb-3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ProductList, {
                        products: _ctx.accessories,
                        platform: _ctx.platform
                      }, null, 8, ["products", "platform"])
                    ]),
                    _: 1
                  }, 8, ["header"]),
                  _createElementVNode("h1", _hoisted_1, [
                    _createVNode(_component_font_awesome_icon, {
                      style: {"margin-left":"30px"},
                      icon: ['fas', 'angle-double-down']
                    }),
                    _createVNode(_component_font_awesome_icon, {
                      style: {"margin-left":"30px"},
                      icon: ['fas', 'angle-double-down']
                    }),
                    _createVNode(_component_font_awesome_icon, {
                      style: {"margin-left":"30px"},
                      icon: ['fas', 'angle-double-down']
                    })
                  ]),
                  _createVNode(_component_Panel, {
                    header: _ctx.$t('labels.mainArticle'),
                    class: "mb-3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ProductList, {
                        products: [{..._ctx.productDetails?.weclapp, shopwareData: _ctx.productDetails?.shopware, secondShopwareData: _ctx.productDetails?.secondShopware}],
                        platform: _ctx.platform
                      }, null, 8, ["products", "platform"])
                    ]),
                    _: 1
                  }, 8, ["header"])
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_p_button, {
                  severity: "danger",
                  onClick: _cache[1] || (_cache[1] = $event => (_ctx.closeResponsive(false)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_p_button, {
                  onClick: _ctx.addArticleAccessories,
                  severity: "success"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _: 1
          }, 8, ["header", "disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}