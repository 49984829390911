import {IProduct} from '@/interfaces/product/product';
import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

const getError = (error: any) => {
    const message = error.response?.data.error || 'Failed';
    return new Error(message);
};

export const getSingle = (
    id: string | string[],
    input: any,
    platform: string | null = null
): Promise<AxiosResponse> => {
    const params = Object.assign(
        {},
        {
            start: input.first || 0,
            end: (input.first || 0) + (input.rows || 10),
            sortField: input.sortField || '',
            sortOrder: (input.sortOrder || 1) === 1 ? 'asc' : 'desc',
            filters: encodeURIComponent(
                JSON.stringify(
                    clearEmptyFilters(Object.assign(input.filters || {}))
                )
            )
        }
    );

    let queryString = Object.keys(params)
        .map((key) => key + '=' + params[key as keyof typeof params])
        .join('&');

    if (platform) {
        queryString += '&platform=' + platform;
    }

    return apiClient.get(
        '/api/v1/products/' + id + '/accessories?' + queryString
    );
};

export const bulkSaveCrossPlatform = async (
    mainProducts: Array<any>,
    productAccessories: Array<any>
): Promise<AxiosResponse> => {
    return bulkSave(mainProducts, productAccessories, true);
};

export const bulkSave = async (
    mainProducts: Array<any>,
    productAccessories: Array<any>,
    crossPlatform: boolean,
    platform: string | null = null
): Promise<AxiosResponse> => {
    try {
        return apiClient.post(
            '/api/v1/product-accessories' +
                (platform ? '?platform=' + platform : ''),
            Object.assign(
                {
                    products: mainProducts.map(({id, articleNumber}) => {
                        return {
                            id,
                            articleNumber
                        };
                    }),
                    accessories: productAccessories.map(
                        ({id, articleNumber}) => {
                            return {
                                id,
                                articleNumber
                            };
                        }
                    )
                },
                crossPlatform ? {crossPlatform} : {}
            )
        );
    } catch (error: any) {
        throw getError(error);
    }
};

export const bulkReplace = async (
    mainProducts: Array<IProduct>,
    existingAccessory: IProduct,
    newAccessory: IProduct | null,
    platform: string | null = null
): Promise<number> => {
    try {
        const payload = Object.assign(
            {
                existingAccessory: (({id, articleNumber}) => ({
                    id,
                    articleNumber
                }))(existingAccessory),
                products: mainProducts.map(({id, articleNumber}) => {
                    return {
                        id,
                        articleNumber
                    };
                })
            },
            newAccessory
                ? {
                      newAccessory: (({id, articleNumber}) => ({
                          id,
                          articleNumber
                      }))(newAccessory)
                  }
                : {}
        );

        const result = await apiClient.put(
            '/api/v1/product-accessories' +
                (platform ? '?platform=' + platform : ''),
            payload
        );

        return result.status;
    } catch (error: any) {
        throw getError(error);
    }
};

export const bulkDelete = async (
    productId: string | string[],
    productAccessories: Array<any>,
    platform: string | null = null
): Promise<number> => {
    try {
        const ids = productAccessories.map(({articleNumber}) => {
            return 'accessories[]=' + articleNumber;
        });
        const result = await apiClient.delete(
            '/api/v1/products/' +
                productId +
                '/accessories?' +
                ids.join('&') +
                (platform ? '&platform=' + platform : '')
        );

        return result.status;
    } catch (error: any) {
        throw getError(error);
    }
};
