<Panel :header="$t('labels.addArticle')">
    <Stepper linear v-model:activeStep="activeIndex">
        <StepperPanel :header="$t('labels.productAdd.checkDuplicate')">
            <template #content="{ nextCallback }">
                <CheckDuplicateStep
                    @environments-selected="onEnvironmentsSelected($event);nextCallback($event);"
                ></CheckDuplicateStep>
            </template>
        </StepperPanel>
        <StepperPanel :header="$t('labels.productHome.masterData') + ' 1/3'">
            <template #content="{ prevCallback, nextCallback }">
                <GlobalFieldsStep
                    :duplicateCheckValues="duplicateCheckValues"
                    :pairValues="pairValues"
                    @back-button-clicked="prevCallback"
                    @next-button-clicked="onNextButtonClicked($event);nextCallback($event);"
                    @add-wizard-form-dirty="onAddWizardFormDirty"
                ></GlobalFieldsStep
            ></template>
        </StepperPanel>
        <StepperPanel :header="$t('labels.productHome.masterData') + ' 2/3'">
            <template #content="{ prevCallback, nextCallback }">
                <EnvSpecificDescriptionsStep
                    :pairValues="pairValues"
                    @back-button-clicked="onBackButtonClicked($event);prevCallback();"
                    @next-button-clicked="onNextButtonClicked($event);nextCallback($event);"
                    @add-wizard-form-dirty="onAddWizardFormDirty"
                >
                </EnvSpecificDescriptionsStep>
            </template>
        </StepperPanel>
        <StepperPanel :header="$t('labels.productHome.masterData') + ' 3/3'">
            <template #content="{ prevCallback, nextCallback }">
                <EnvSpecificFieldsStep
                    :pairValues="pairValues"
                    @back-button-clicked="onBackButtonClicked($event);prevCallback();"
                    @next-button-clicked="onNextButtonClicked($event);nextCallback($event);"
                    @add-wizard-form-dirty="onAddWizardFormDirty"
                >
                </EnvSpecificFieldsStep>
            </template>
        </StepperPanel>

        <StepperPanel :header="$t('labels.salesOrder.supplySources')">
            <template #content="{ prevCallback, nextCallback }">
                <EnvSpecificSupplySourcesStep
                    :duplicateCheckValues="duplicateCheckValues"
                    :pairValues="pairValues"
                    :savingInProgress="savingInProgress"
                    @back-button-clicked="onBackButtonClicked($event);prevCallback();"
                    @next-button-clicked="onNextButtonClicked($event);nextCallback($event);"
                    @add-wizard-form-dirty="onAddWizardFormDirty"
                >
                </EnvSpecificSupplySourcesStep>
            </template>
        </StepperPanel>
    </Stepper>
</Panel>
<PrimeDialog
    :header="$t('labels.result')"
    v-model:visible="showResultDialog"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '640px'}"
    :modal="true"
    :closable="false"
>
    <div class="col-4">{{resolvedPlatform.label}}</div>
    <div class="col-8">
        <template v-if="saveResult">
            <span v-if="saveResult['error']" class="text-danger">
                {{$t('labels.error')}}: {{ saveResult['error'] }}
            </span>
            <template v-if="saveResult['id']">
                <i class="pi text-green-600 pi-check-circle"></i>

                <span class="ml-3">{{$t('labels.articleNumber')}}:</span>
                <router-link
                    class="ml-3"
                    :to="{
                            name: 'ProductDetails',
                            params: {
                                id: saveResult['id']
                            }
                        }"
                    ><span>{{ saveResult['id'] }}</span>
                </router-link>
            </template>
        </template>
    </div>

    <template #footer>
        <div v-if="hasError" class="w-full flex justify-content-between">
            <p-button severity="warning" @click="onStartOverClick">
                {{ $t("buttons.startOver") }}
            </p-button>
            <router-link
                :to="{
            name: 'Products'
        }"
                ><p-button severity="danger">
                    {{ $t("buttons.cancel") }}
                </p-button></router-link
            >
        </div>
        <div v-else class="flex justify-content-end">
            <router-link
                :to="{
            name: 'Products'
        }"
                ><p-button severity="success">
                    {{ $t("buttons.backToProductList") }}
                </p-button></router-link
            >
        </div>
    </template>
</PrimeDialog>
<ConfirmLeave
    :displayConfirmLeaveDialog="displayConfirmLeaveDialog"
    @choice-confirmed="onChoiceConfirmed"
></ConfirmLeave>
