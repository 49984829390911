<p-dialog
    :header="$t('labels.addSupplierConfiguration')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '50vw'}"
    @hide="closeDialog"
    modal
    class="text-sm"
>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <SupplierAutoComplete @data-entered="onSupplierChosen($event)" />
        <div class="flex justify-content-end">
            <p-button
                :disabled="savingInProgress || !state.supplierNumber"
                severity="success"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
                type="submit"
            >
            </p-button>
        </div>
    </form>
</p-dialog>
