import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, mergeProps as _mergeProps, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62c78556"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-content-between" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { style: {"text-align":"center"} }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "font-bold ml-1" }
const _hoisted_8 = { class: "font-bold ml-1" }

export function render(_ctx, _cache) {
  const _component_ChooseSpreadsheetDialog = _resolveComponent("ChooseSpreadsheetDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _mergeProps(_ctx.dropzone.getRootProps(), { class: "import-wizard-container" }), [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("input", _normalizeProps(_guardReactiveProps(_ctx.dropzone.getInputProps())), null, 16),
            (_ctx.dropzone.acceptedFiles.length > 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.dropzone.acceptedFiles[0].name), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t("labels.importWizardInfoText")), 1)),
            (_ctx.sheetName)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.priceImport.workbookName')) + ":", 1),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.sheetName), 1),
                  (_ctx.chosenManufacturer?.name)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(", "),
                        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.manufacturer')) + ":", 1),
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.chosenManufacturer.name), 1)
                      ], 64))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ])
        ], 16),
        _createTextVNode(" " + _toDisplayString(), 1)
      ])
    ]),
    _createVNode(_component_ChooseSpreadsheetDialog, {
      files: _ctx.acceptFiles,
      displayResponsive: _ctx.showChooseSpreadsheetDialog,
      "chosen-supplier": _ctx.chosenSupplier,
      onCloseDialog: _ctx.onCloseSpreadsheetDialog
    }, null, 8, ["files", "displayResponsive", "chosen-supplier", "onCloseDialog"])
  ], 64))
}