import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ce91d50"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid formgrid p-fluid" }
const _hoisted_2 = { class: "field col" }
const _hoisted_3 = { class: "grid formgrid p-fluid" }
const _hoisted_4 = { class: "field col" }
const _hoisted_5 = { class: "grid formgrid p-fluid" }
const _hoisted_6 = { class: "field col" }
const _hoisted_7 = { class: "grid formgrid p-fluid" }
const _hoisted_8 = { class: "field col" }
const _hoisted_9 = { class: "flex justify-content-end" }

export function render(_ctx, _cache) {
  const _component_LoadingPlugin = _resolveComponent("LoadingPlugin")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_p_calendar = _resolveComponent("p-calendar")
  const _component_p_button = _resolveComponent("p-button")
  const _component_p_dialog = _resolveComponent("p-dialog")

  return (_openBlock(), _createBlock(_component_p_dialog, {
    header: _ctx.$t('labels.priceImport.chooseWorkbook'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[6] || (_cache[6] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw'},
    style: {width: '20vw'},
    onHide: _ctx.closeDialog,
    closable: false,
    modal: "",
    class: "text-sm"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LoadingPlugin, {
        active: _ctx.loading,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
      }, null, 8, ["active"]),
      _createElementVNode("form", {
        onSubmit: _cache[5] || (_cache[5] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.priceImport.workbookName')), 1),
            _createVNode(_component_p_dropdown, {
              modelValue: _ctx.v$.sheetName.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.sheetName.$model) = $event)),
              options: _ctx.spreadsheets || [],
              disabled: (_ctx.spreadsheets?.length || 0) < 2,
              class: _normalizeClass({'p-invalid':_ctx.v$.sheetName.$invalid && _ctx.submitted}),
              onChange: _ctx.onSpreadsheetChange
            }, null, 8, ["modelValue", "options", "disabled", "class", "onChange"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.sheetName.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.manufacturer')), 1),
            _createVNode(_component_p_dropdown, {
              modelValue: _ctx.v$.manufacturer.$model,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$.manufacturer.$model) = $event)),
              options: _ctx.manufacturerOptions || [],
              optionLabel: "name",
              filter: "",
              showClear: "",
              class: _normalizeClass({'p-invalid':_ctx.v$.manufacturer.$invalid && _ctx.submitted})
            }, null, 8, ["modelValue", "options", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.manufacturer.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.ekStartDate')), 1),
            _createVNode(_component_p_calendar, {
              autocomplete: "off",
              dateFormat: "dd.mm.yy",
              modelValue: _ctx.v$.ekStartDate.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.ekStartDate.$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$.ekStartDate.$invalid && _ctx.submitted}),
              minDate: new Date()
            }, null, 8, ["modelValue", "class", "minDate"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.ekStartDate.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.uvpStartDate')), 1),
            _createVNode(_component_p_calendar, {
              autocomplete: "off",
              dateFormat: "dd.mm.yy",
              modelValue: _ctx.v$.uvpStartDate.$model,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$.uvpStartDate.$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$.uvpStartDate.$invalid && _ctx.submitted}),
              minDate: new Date()
            }, null, 8, ["modelValue", "class", "minDate"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.uvpStartDate.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_p_button, {
            severity: "success",
            type: "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('buttons.confirm')), 1)
            ]),
            _: 1
          })
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["header", "visible", "onHide"]))
}