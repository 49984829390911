<loading v-model:active="savingInProgress" />
<div class="m-2">
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <ScrollPanel style="height: calc(100vh - 34rem)">
            <div class="formgrid grid p-fluid mt-3">
                <div class="field col">
                    <label class="font-semibold"
                        >{{ $t('labels.productDescription.shortDescription') }}
                    </label>

                    <InputText
                        :placeholder="$t('labels.productDescription.shortDescription')"
                        autocomplete="off"
                        v-model="v$.shortDescription.$model"
                        v-on:keyup="onKeyUp($event)"
                        :maxLength="100"
                        :class="{'p-invalid':v$.shortDescription.$invalid && submitted}"
                    />
                    <small class="mb-3">
                        {{ $t('messages.numberOfCharsLeft', {value:
                        shortDescriptionCharactersLeft}) }}</small
                    >
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.shortDescription.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="mt-3 formgrid grid p-fluid">
                <div class="field col">
                    <label class="font-semibold"
                        >{{ $t('labels.description') }}
                    </label>

                    <tiny
                        :tinymceScriptSrc="tinyUrl"
                        v-model="v$.longDescription.$model"
                        :init="{
                    
                    menubar: false,
                    plugins: 'lists link image emoticons code table anchor charmap fullscreen paste autoresize save',
                    toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code save',
                    browser_spellcheck: true,
                    autoresize_bottom_margin: 50,
                    min_height: 500,
                    save_onsavecallback: function(inst) {
                        handleSubmit(!v$.$invalid);
                    }
                }"
                    ></tiny>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.longDescription.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
        </ScrollPanel>
        <div class="mt-3 flex justify-content-end">
            <p-button
                severity="success"
                :disabled="savingInProgress"
                type="submit"
                v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
            >
            </p-button>
        </div>
    </form>
</div>
