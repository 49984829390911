<loading v-model:active="savingInProgress" />
<div class="m-2">
    <ScrollPanel style="height: calc(100vh - 14rem)">
        <div class="flex flex-column mb-5">
            <div>
                <span v-if="shopData" class="text-lg font-semibold">
                    {{ shopData.number }}
                    <span class="spacer-dot"></span>{{
                    shopData.paymentMethod?.name}}<span
                        class="spacer-dot"
                    ></span>
                    {{ getCurrencyFormatted(shopData.amountTotal)}}
                    <span class="spacer-dot"></span>
                    {{ $t('labels.createdAt') }}:
                    <b>{{ getDateFormatted(shopData.orderTime)}}</b>
                </span>
            </div>
        </div>
        <Panel header="Potenzielle Matches" class="mb-5">
            <div
                v-if="!potentialMatches || potentialMatches.length < 1"
                class="text-center w-full"
            >
                {{ $t('messages.noWeclappCustomerCouldBeFound') }}
            </div>
            <DataTable
                class="p-datatable-sm text-sm"
                stripedRows
                v-if="potentialMatches.length > 0"
                :rowClass="rowClass"
                :rows="20"
                dataKey="id"
                :value="potentialMatches"
                responsiveLayout="scroll"
            >
                <Column :header="$t('labels.customer')"
                    ><template #body="{data}">
                        [<a
                            :href="data.url + 'webapp/view/party/PartyDetail.page?entityId=' + data.id"
                            target="_blank"
                            >{{data.customerNumber}}</a
                        >]
                        <span v-if="data.company">{{data.company}}</span>
                        <span v-else>{{data.firstName}} {{data.lastName}}</span>
                    </template></Column
                >
                <Column :header="$t('labels.email')" field="email">
                    <template #body="{data}">
                        <div
                            :class="{'font-bold': nonEmptyStringMatch(shopData?.customer?.email, data.email)}"
                        >
                            {{data.email}}
                            <i
                                v-if="nonEmptyStringMatch(shopData?.customer?.email, data.email)"
                                class="pi text-green-600 pi-check-circle ml-1"
                            ></i></div></template
                ></Column>
                <Column :header="'Shop-' + $t('labels.customerNumber')">
                    <template #body="{data}">
                        <div
                            :class="{'font-bold': nonEmptyStringMatch(shopData?.customer?.customerNumber, ['ttd',
                            'ttl'].includes(resolvedPlatform?.threeLetterId) ?
                            data.teltecShopCustomerNumber:
                            data.videodataShopCustomerNumber, true)}"
                        >
                            {{['ttd',
                            'ttl'].includes(resolvedPlatform?.threeLetterId) ?
                            data.teltecShopCustomerNumber:
                            data.videodataShopCustomerNumber}}
                            <i
                                v-if="nonEmptyStringMatch(shopData?.customer?.customerNumber, ['ttd',
                                'ttl'].includes(resolvedPlatform?.threeLetterId) ?
                                data.teltecShopCustomerNumber:
                                data.videodataShopCustomerNumber, true)"
                                class="pi text-green-600 pi-check-circle ml-1"
                            ></i>
                        </div>
                    </template>
                </Column>
                <Column header="USt-ID">
                    <template #body="{data}"
                        ><div
                            :class="{'font-bold': nonEmptyStringMatch(shopData?.customer?.vatNumber, data.taxId)}"
                        >
                            {{data.taxId}}
                            <i
                                v-if="nonEmptyStringMatch(shopData?.customer?.vatNumber, data.taxId)"
                                class="pi text-green-600 pi-check-circle ml-1"
                            ></i></div
                    ></template>
                </Column>
                <Column>
                    <template #header>
                        {{$t('labels.primeAddress')}}
                        <i class="pi pi-home ml-2"></i>
                    </template>
                    <template #body="{data}">
                        <template v-for="address in (data?.addresses || [])">
                            <div
                                v-if="address.primeAddress"
                                :class="{'font-bold': addressMatch(address)}"
                                class="flex justify-content-start"
                            >
                                <div>
                                    <div v-if="address.company">
                                        {{address.company}}
                                    </div>
                                    <div>{{address.street1}}</div>
                                    <div>{{address.street2}}</div>
                                    <div>
                                        {{address.zipcode}} {{address.city}}
                                    </div>
                                    <div>
                                        {{resolveCountryFromCode(address.countryCode)}}
                                    </div>
                                </div>
                                <div
                                    v-if="addressMatch(address)"
                                    class="flex align-items-stretch ml-1"
                                >
                                    <i
                                        v-if="extractHouseNumberFromAddress(shopData?.billingAddress?.street) === extractHouseNumberFromAddress(address.street1)"
                                        class="pi text-green-600 pi-check-circle flex align-items-center"
                                    ></i>
                                    <i
                                        v-else
                                        class="pi pi-exclamation-triangle flex align-items-center"
                                    ></i>
                                </div>
                            </div>
                        </template> </template
                ></Column>
                <Column>
                    <template #header>
                        {{$t('labels.invoiceAddress')}}
                        <i class="pi pi-calculator ml-2"></i>
                    </template>
                    <template #body="{data}">
                        <template v-for="address in (data?.addresses || [])">
                            <div
                                v-if="address.invoiceAddress"
                                :class="{'font-bold': addressMatch(address)}"
                                class="flex justify-content-start"
                            >
                                <div>
                                    <div v-if="address.company">
                                        {{address.company}}
                                    </div>
                                    <div>{{address.street1}}</div>
                                    <div>{{address.street2}}</div>
                                    <div>
                                        {{address.zipcode}} {{address.city}}
                                    </div>
                                    <div>
                                        {{resolveCountryFromCode(address.countryCode)}}
                                    </div>
                                </div>
                                <div
                                    v-if="addressMatch(address)"
                                    class="flex align-items-stretch ml-1"
                                >
                                    <i
                                        v-if="extractHouseNumberFromAddress(shopData?.billingAddress?.street) === extractHouseNumberFromAddress(address.street1)"
                                        class="pi text-green-600 pi-check-circle flex align-items-center"
                                    ></i>
                                    <i
                                        v-else
                                        class="pi pi-exclamation-triangle flex align-items-center"
                                    ></i>
                                </div>
                            </div>
                        </template> </template
                ></Column>
                <Column>
                    <template #header>
                        {{$t('labels.deliveryAddress')}}
                        <i class="pi pi-truck ml-2"></i>
                    </template>
                    <template #body="{data}">
                        <template v-for="address in (data?.addresses || [])">
                            <div
                                v-if="address.deliveryAddress"
                                :class="{'font-bold': addressMatch(address)}"
                                class="flex justify-content-start"
                            >
                                <div>
                                    <div v-if="address.company">
                                        {{address.company}}
                                    </div>
                                    <div>{{address.street1}}</div>
                                    <div>{{address.street2}}</div>
                                    <div>
                                        {{address.zipcode}} {{address.city}}
                                    </div>
                                    <div>
                                        {{resolveCountryFromCode(address.countryCode)}}
                                    </div>
                                </div>
                                <div
                                    v-if="addressMatch(address)"
                                    class="flex align-items-stretch ml-1"
                                >
                                    <i
                                        v-if="extractHouseNumberFromAddress(shopData?.billingAddress?.street) === extractHouseNumberFromAddress(address.street1)"
                                        class="pi text-green-600 pi-check-circle flex align-items-center"
                                    ></i>
                                    <i
                                        v-else
                                        class="pi pi-exclamation-triangle flex align-items-center"
                                    ></i>
                                </div>
                            </div>
                        </template> </template
                ></Column>

                <Column style="min-width: 5rem">
                    <template #body="{data}">
                        <div class="text-sm flex justify-content-end">
                            <i
                                v-if="selectedWeclappCustomer?.id !== data.id"
                                class="pi pi-star text-sm text-yellow-500"
                                style="cursor: pointer"
                                @click="selectedWeclappCustomer = data"
                                v-tooltip.top="$t('labels.useThisCustomer')"
                            ></i>
                            <font-awesome-icon
                                v-if="selectedWeclappCustomer?.id === data.id"
                                :icon="['fas', 'star']"
                                style="color: #ffc107; cursor: pointer"
                                @click="selectedWeclappCustomer = null"
                            />
                        </div>
                    </template>
                </Column>
            </DataTable>
            <div class="flex justify-content-end w-full mt-5">
                <p-button
                    severity="danger"
                    :disabled="savingInProgress"
                    @click="retryMatching"
                >
                    {{$t('buttons.repeatComparison')}}
                </p-button>

                <p-button
                    v-if="selectedWeclappCustomer"
                    class="ml-3"
                    severity="info"
                    :disabled="savingInProgress"
                    @click="displayConfirmationDialog = true;"
                >
                    {{$t('buttons.createOrderWithSelectedCustomer')}}
                </p-button>

                <p-button
                    v-if="showAddButton && !selectedWeclappCustomer"
                    class="ml-3"
                    severity="success"
                    :disabled="savingInProgress"
                    @click="displayConfirmationDialog = true;"
                >
                    {{$t('buttons.createOrderWithShopCustomer')}}
                </p-button>
            </div>
        </Panel>
        <div class="grid">
            <div class="col">
                <ShopDataOverview
                    :shop-data="shopData"
                    :selected-weclapp-customer="selectedWeclappCustomer"
                    @shop-data-saved="retryMatching"
                ></ShopDataOverview>
            </div>
            <div class="col">
                <WeclappDataOverview
                    :shop-data="shopData"
                    :selected-weclapp-customer="selectedWeclappCustomer"
                    :potential-matches="potentialMatches"
                    @weclapp-data-saved="retryMatching"
                >
                </WeclappDataOverview>
            </div>
        </div>
    </ScrollPanel>
    <PrimeDialog
        :header="$t('labels.result')"
        v-model:visible="showResultDialog"
        :breakpoints="{'960px': '75vw', '640px': '90vw'}"
        :style="{width: '640px'}"
        modal
        :closable="!!importResult?.error"
    >
        <div class="grid">
            <div class="col-2 font-semibold">{{resolvedPlatform.label}}</div>
            <div class="col-10">
                <template v-if="importResult">
                    <span v-if="importResult.error" class="text-red-600">
                        {{ importResult.error }}
                    </span>
                    <template v-if="importResult.id">
                        <i class="pi text-green-600 pi-check-circle"></i>

                        <span class="ml-3"
                            >{{$t('labels.salesOrder.orderNumber')}}:</span
                        >
                        <a
                            :href="importResult.url + 'webapp/view/op/order/salesOrderDetail.page?entityId=' + importResult.id"
                            target="_blank"
                            >{{ importResult.orderNumber }}</a
                        >
                    </template>
                </template>
            </div>
        </div>

        <template #footer>
            <div class="flex justify-content-end">
                <router-link
                    :to="{
            name: 'Import Shop Orders'
        }"
                    ><p-button severity="success">
                        {{ $t("buttons.backToList") }}
                    </p-button></router-link
                >
            </div>
        </template>
    </PrimeDialog>
    <CreateOrderOverview
        :displayConfirmationDialog="displayConfirmationDialog"
        :shopData="shopData"
        @choice-confirmed="onChoiceConfirmed"
    ></CreateOrderOverview>
</div>
