import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';
import {FilterMatchMode} from 'primevue/api';

export const getAllInOrderConfirmationPrintedStatus = (
    input: any
): Promise<AxiosResponse> => {
    const originalFilters = {};

    Object.keys(input.filters).forEach(function (key) {
        Object.assign(originalFilters, {
            [key]: {
                value: input.filters[key],
                matchMode: FilterMatchMode.CONTAINS
            }
        });
    });

    Object.assign(originalFilters, {
        status: {
            value: 'ORDER_CONFIRMATION_PRINTED',
            matchMode: FilterMatchMode.EQUALS
        }
    });

    return apiClient.get(
        '/api/v1/sales-orders?start=' +
            input.first +
            '&end=' +
            (input.first + input.rows) +
            '&sortField=' +
            (input.sortField ?? '') +
            '&sortOrder=' +
            (input.sortOrder === 1 ? 'asc' : 'desc') +
            '&filters=' +
            encodeURIComponent(
                JSON.stringify(clearEmptyFilters(originalFilters || {}))
            )
    );
};

export const getAllCandidatesForTicket = (
    input: any
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/sales-orders?start=' +
            input.first +
            '&end=' +
            (input.first + input.rows) +
            '&sortField=' +
            (input.sortField ?? '') +
            '&sortOrder=' +
            (input.sortOrder === 1 ? 'asc' : 'desc') +
            '&columns=' +
            JSON.stringify(input.columns || []) +
            '&filters=' +
            encodeURIComponent(
                JSON.stringify(clearEmptyFilters(input.filters || {}))
            ) +
            '&filterConjunction=or'
    );
};

export const getAll = (input: any): Promise<AxiosResponse> => {
    const rows = input.rows || 10;

    const params = Object.assign(
        {},
        {
            start: input.first || 0,
            end: (input.first || 0) + rows,
            sortField: input.sortField || '',
            sortOrder: (input.sortOrder || 1) === 1 ? 'asc' : 'desc',
            columns: JSON.stringify(input.columns || []),
            filters: JSON.stringify(clearEmptyFilters(input.filters || {})),
            keysToSkip: input.keysToSkip || [],
            filterConjunction: input.filterConjunction || 'and'
        },
        input.platform
            ? {
                  platform: input.platform
              }
            : {}
    );

    return apiClient.get('/api/v1/sales-orders', {params});
};

export const getSingle = (
    orderNumber: string | string[]
): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/sales-orders/' + orderNumber);
};
