import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const getAll = (
    platform: string | null = null
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/products/badges' + (platform ? '?platform=' + platform : '')
    );
};
