import {computed, ref, toRefs} from 'vue';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Accessories from '@/components/grid/products/accessories/list.vue';
import store from '@/store';
import BulkAddAccessories from '@/components/dialog/products/bulk-add-accessories.vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
    emits: ['reload-product'],
    components: {
        TabPanel,
        TabView,
        Accessories,
        BulkAddAccessories,
        'p-button': Button,
        PrimeDialog: Dialog,
        DataTable,
        Column
    },
    props: {
        productDetails: Object
    },
    setup(props: any, context: any) {
        const {productDetails} = toRefs(props);
        const bulkAddAccessoriesDialogVisible = ref(false);
        const gridReloadRequired = ref(false);
        const bulkAddResultVisible = ref(false);
        const bulkAddData = ref(null);

        const loadProductDetails = () => {
            context.emit('reload-product');
        };

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );
        });

        const secondPlatform = computed(() => {
            switch (resolvedPlatform.value?.threeLetterId) {
                case 'ttd':
                    return 'videodata-demo';
                case 'ttl':
                    return 'videodata';
                case 'vdd':
                    return 'teltec-demo';
                default:
                    return 'teltec';
            }
        });

        const closeAddUsingSelectedMainProducts = (
            forceResfresh: boolean = false,
            result: any = null
        ) => {
            bulkAddAccessoriesDialogVisible.value = false;
            if (forceResfresh) {
                loadProductDetails();
                gridReloadRequired.value = true;
                setTimeout(() => {
                    gridReloadRequired.value = false;
                    if (result) {
                        bulkAddData.value = result.data;
                        bulkAddResultVisible.value = true;
                    }
                }, 3000);
            } else if (result) {
                bulkAddData.value = result.data;
                bulkAddResultVisible.value = true;
            }
        };

        return {
            productDetails,
            loadProductDetails,
            resolvedPlatform,
            secondPlatform,
            bulkAddAccessoriesDialogVisible,
            closeAddUsingSelectedMainProducts,
            gridReloadRequired,
            bulkAddResultVisible,
            bulkAddData
        };
    }
};
