import Dialog from 'primevue/dialog';
import {reactive, ref, watch} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import {required, url, requiredIf} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import {useDropzone} from 'vue3-dropzone';
import {add, addFromUrl} from '@/services/product-images';

export default {
    emits: ['close-dialog'],
    components: {
        'p-dialog': Dialog,
        InputText,
        'p-button': Button
    },
    props: {
        displayResponsive: Boolean,
        selectedDocument: Object,
        productNumber: String
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const submitted = ref(false);
        const productNumber = ref(null);
        const savingInProgress = ref(false);
        const toast = useToast();
        const acceptFiles = ref([]);

        const state = reactive({
            title: productNumber.value,
            url: ''
        });

        const rules = {
            title: {required},
            url: {
                url,
                required: requiredIf(() => {
                    return acceptFiles.value.length < 1;
                })
            }
        };

        const v$ = useVuelidate(rules, state);

        watch(props, (args) => {
            showDialog.value = args.displayResponsive;
            resetForm();
            productNumber.value = args.productNumber;
        });

        const closeDialog = (forceRefresh: boolean = false) => {
            resetForm();
            context.emit('close-dialog', forceRefresh);
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            if (acceptFiles.value.length > 0) {
                add(productNumber.value, state.title, acceptFiles.value)
                    .then(() => {
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully')
                        );
                        closeDialog(true);
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    })
                    .finally(() => {
                        savingInProgress.value = false;
                    });
            } else {
                addFromUrl(productNumber.value, state.title, state.url)
                    .then(() => {
                        toast.success(
                            i18n.global.t('messages.changesSavedSuccessfully')
                        );
                        closeDialog(true);
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    })
                    .finally(() => {
                        savingInProgress.value = false;
                    });
            }
        };

        const resetForm = () => {
            state.title = productNumber.value;
            state.url = '';
            submitted.value = false;
            acceptFiles.value.length = 0;
        };

        const onDrop = (files: any, rejectReasons: any) => {
            acceptFiles.value = files;
            state.url = '';
            if (rejectReasons.length > 0) {
                toast.error(rejectReasons[0]['errors'][0]['message']);
                acceptFiles.value.length = 0;
            }
        };

        const {getRootProps, getInputProps, ...rest} = useDropzone({
            onDrop,
            accept: ['.jpg', '.png']
        });

        return {
            showDialog,
            closeDialog,
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            acceptFiles,
            getRootProps,
            getInputProps,
            ...rest
        };
    }
};
