<div class="flex justify-content-between">
    <div class="w-full">
        <div v-bind="dropzone.getRootProps()" class="import-wizard-container">
            <div style="text-align: center">
                <input v-bind="dropzone.getInputProps()" />
                <p v-if="dropzone.acceptedFiles.length > 0">
                    {{ dropzone.acceptedFiles[0].name }}
                </p>
                <p v-else>{{ $t("labels.importWizardInfoText") }}</p>
                <div v-if="sheetName">
                    <label>{{$t('labels.priceImport.workbookName')}}:</label>
                    <span class="font-bold ml-1">{{sheetName}}</span>

                    <template v-if="chosenManufacturer?.name" class="ml-2"
                        >,
                        <label>{{$t('labels.manufacturer')}}:</label>
                        <span class="font-bold ml-1"
                            >{{chosenManufacturer.name}}</span
                        >
                    </template>
                </div>
            </div>
        </div>
        {{}}
    </div>
    <!--<div>
        <p-button
            @click="downloadFileTemplate"
            label="Beispiel-Datei"
            icon="pi pi-file-excel"
            class="w-full text-xs"
        >
        </p-button>
    </div>-->
</div>
<ChooseSpreadsheetDialog
    :files="acceptFiles"
    :displayResponsive="showChooseSpreadsheetDialog"
    :chosen-supplier="chosenSupplier"
    @close-dialog="onCloseSpreadsheetDialog"
></ChooseSpreadsheetDialog>
