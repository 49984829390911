<!-- Content Header (Page header) -->
<loading v-model:active="isLoading" />
<Panel :header="$t('labels.importWizard')">
    <Stepper linear v-model:activeStep="activeIndex">
        <StepperPanel :header="$t('labels.productAdd.supplier')">
            <template #content="{ nextCallback }">
                <SupplierAutoComplete
                    @data-entered="onSupplierChosen($event)"
                />
                <div class="w-full flex justify-content-end mt-5">
                    <p-button
                        severity="success"
                        :disabled="!chosenSupplier"
                        @click="nextCallback"
                        v-text="$t('buttons.next')"
                    >
                    </p-button>
                </div>
            </template>
        </StepperPanel>
        <StepperPanel :header="$t('labels.yourFile')">
            <template #content="{ prevCallback, nextCallback }">
                <file-upload-step
                    @sheet-chosen="onSpreadsheetChosen"
                    :chosen-supplier="chosenSupplier?.value"
                >
                </file-upload-step>

                <div class="w-full flex justify-content-between mt-5">
                    <p-button
                        severity="danger"
                        v-text="$t('buttons.back')"
                        @click="prevCallback"
                    >
                    </p-button>
                    <p-button
                        severity="success"
                        :disabled="!showUploadButton"
                        @click="uploadFile(nextCallback, $event)"
                        v-text="$t('buttons.next')"
                    >
                    </p-button>
                </div>
            </template>
        </StepperPanel>
        <StepperPanel :header="$t('labels.columnMapping')">
            <template #content="{ prevCallback, nextCallback }">
                <price-list-upload-step
                    :saved-file-id="savedFile?.id"
                    :chosen-supplier="chosenSupplier?.value"
                    :sheet-name="chosenSheet"
                    :chosen-manufacturer="chosenManufacturer"
                    :cost-price-start-date="costPriceStartDate"
                    :list-price-start-date="listPriceStartDate"
                    @column-mapping-saved="validateFile()"
                    @back-button-clicked="prevCallback"
                ></price-list-upload-step>
            </template>
        </StepperPanel>
    </Stepper>
</Panel>

<!-- /.content -->
