<form @submit.prevent="handleSubmit(!v$.$invalid)">
    <div class="grid">
        <div class="col">
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label>{{ $t('labels.manufacturer') }} </label>
                    <p-dropdown
                        :filter="true"
                        v-model="v$['manufacturer'].$model"
                        :options="serverData?.manufacturer || []"
                        optionLabel="name"
                        optionValue="id"
                        @change="setDropdownValue(('manufacturer'), $event)"
                        :class="{'p-invalid':v$['manufacturer'].$invalid && submitted}"
                        :showClear="true"
                    >
                    </p-dropdown>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$['manufacturer'].$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label>{{ $t('labels.productHome.countryOfOrigin')}}</label>

                    <p-dropdown
                        :filter="true"
                        v-model="v$['countryOfOrigin'].$model"
                        :options="countryOptions"
                        optionLabel="label"
                        optionValue="value"
                        @change="setDropdownValue(('countryOfOrigin'), $event)"
                        :class="{'p-invalid':v$['countryOfOrigin'].$invalid && submitted}"
                        :showClear="true"
                    >
                    </p-dropdown>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$['countryOfOrigin'].$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label
                        >{{ $t('labels.productHome.customsTariffNumber') }}
                    </label>
                    <p-dropdown
                        v-model="v$['customsTariffNumber'].$model"
                        :options="serverData?.customsTariffNumber || []"
                        filter
                        optionLabel="name"
                        optionValue="id"
                        @change="setDropdownValue(('customsTariffNumber'), $event)"
                        :class="{'p-invalid':v$['customsTariffNumber'].$invalid && submitted}"
                        showClear
                    >
                    </p-dropdown>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$['customsTariffNumber'].$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>

            <div class="formgrid grid p-fluid">
                <template
                    v-if="resolvedPlatform?.threeLetterId === 'ttd' || resolvedPlatform?.threeLetterId === 'ttl'"
                >
                    <div class="col field">
                        <label>{{ $t('labels.productAdd.matchCode') }} </label>
                        <InputText
                            autocomplete="off"
                            v-model="v$['matchCode'].$model"
                            :class="{'p-invalid':v$['matchCode'].$invalid && submitted}"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            v-for="error in v$['matchCode'].$silentErrors"
                            class="p-error"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </template>
                <template
                    v-if="resolvedPlatform?.threeLetterId === 'vdd' || resolvedPlatform?.threeLetterId === 'vdl'"
                >
                    <div class="col field">
                        <label>
                            {{ $t('labels.productHome.metaTagsSEO') }}
                        </label>
                        <Chips
                            v-model="v$['articleMetatags'].$model"
                            :allowDuplicate="false"
                            :placeholder="$t('labels.metatagsSeparatorInfo')"
                            separator=","
                        />
                    </div>
                </template>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label> {{ $t('labels.vkPrice') }} </label>

                    <InputNumber
                        mode="decimal"
                        :locale="locale"
                        :maxFractionDigits="2"
                        autocomplete="off"
                        v-model="v$['vkPrice'].$model"
                        :class="{'p-invalid':v$['vkPrice'].$invalid && submitted}"
                    />
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label> {{ $t('labels.productAdd.vkStartDate') }} </label>

                    <p-calendar
                        autocomplete="off"
                        dateFormat="dd.mm.yy"
                        v-model="v$['vkStartDate'].$model"
                        :class="{'tt-form-input': true, 'p-invalid':v$['vkStartDate'].$invalid && submitted}"
                    />
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label>
                        {{ $t('labels.productHome.procurementLeadDays') }}
                    </label>

                    <InputNumber
                        :locale="locale"
                        autocomplete="off"
                        showButtons
                        :min="0"
                        v-model="v$['procurementLeadDays'].$model"
                        :class="{'p-invalid':v$['procurementLeadDays'].$invalid && submitted}"
                    />
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$['procurementLeadDays'].$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label> {{ $t('labels.minimumStockQuantity') }} </label>

                    <InputNumber
                        :locale="locale"
                        autocomplete="off"
                        v-model="v$['minimumStockQuantity'].$model"
                        :class="{'p-invalid':v$['minimumStockQuantity'].$invalid && submitted}"
                    />
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label> {{ $t('labels.targetStockQuantity') }} </label>

                    <InputNumber
                        :locale="locale"
                        autocomplete="off"
                        v-model="v$['targetStockQuantity'].$model"
                        :class="{'p-invalid':v$['targetStockQuantity'].$invalid && submitted}"
                    />
                </div>
            </div>

            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label>{{ $t('labels.productHome.length') }} </label>
                    <InputNumber
                        mode="decimal"
                        :locale="locale"
                        :minFractionDigits="1"
                        :maxFractionDigits="1"
                        autocomplete="off"
                        v-model="v$['articleLength'].$model"
                        :class="{'p-invalid':v$['articleLength'].$invalid && submitted}"
                    />
                </div>
                <div class="col field">
                    <label>{{ $t('labels.productHome.width') }} </label>
                    <InputNumber
                        mode="decimal"
                        :locale="locale"
                        :minFractionDigits="1"
                        :maxFractionDigits="1"
                        autocomplete="off"
                        v-model="v$['articleWidth'].$model"
                        :class="{'p-invalid':v$['articleWidth'].$invalid && submitted}"
                    />
                </div>
                <div class="col field">
                    <label>{{ $t('labels.productHome.height') }} </label>
                    <InputNumber
                        mode="decimal"
                        :locale="locale"
                        :minFractionDigits="1"
                        :maxFractionDigits="1"
                        autocomplete="off"
                        v-model="v$['articleHeight'].$model"
                        :class="{'p-invalid':v$['articleHeight'].$invalid && submitted}"
                    />
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label>{{ $t('labels.productHome.grossWeight') }} </label>
                    <InputNumber
                        mode="decimal"
                        :locale="locale"
                        :minFractionDigits="1"
                        autocomplete="off"
                        v-model="v$['articleGrossWeight'].$model"
                        :class="{'p-invalid':v$['articleGrossWeight'].$invalid && submitted}"
                    />
                </div>
                <div class="col field">
                    <label>{{ $t('labels.productHome.netWeight') }} </label>
                    <InputNumber
                        mode="decimal"
                        :locale="locale"
                        :minFractionDigits="1"
                        autocomplete="off"
                        v-model="v$['articleNetWeight'].$model"
                        :class="{'p-invalid':v$['articleNetWeight'].$invalid && submitted}"
                    />
                </div>
            </div>
            <div class="formgrid grid p-fluid mt-3">
                <div class="col field">
                    <label
                        >{{ $t('labels.productHome.serialNumberExtern') }}
                    </label>
                    <p-dropdown
                        v-if="resolvedPlatform?.threeLetterId === 'ttd' || resolvedPlatform?.threeLetterId === 'ttl'"
                        v-model="v$['serialNumberRequired'].$model"
                        :options="[{id: true, name: 'ja'}, {id: false, name: 'nein'}]"
                        optionLabel="name"
                        optionValue="id"
                        @change="setDropdownValue(('serialNumberRequired'), $event)"
                        :class="{'p-invalid':v$['serialNumberRequired'].$invalid && submitted}"
                        showClear
                    >
                    </p-dropdown>
                    <p-checkbox
                        v-else
                        v-model="v$['serialNumberRequired'].$model"
                        binary
                        class="ml-2 mb-1"
                    />
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$['serialNumberRequired'].$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
                <div class="col field">
                    <label
                        >{{ $t('labels.productHome.serialNumberIntern') }}
                    </label>
                    <p-checkbox
                        v-model="v$['serialNumberIntern'].$model"
                        :binary="true"
                        class="ml-2 mb-1"
                    />
                </div>
            </div>
            <div class="formgrid grid p-fluid mt-3">
                <div class="col field">
                    <label>{{ $t('labels.articleWeclappCategory') }} </label>

                    <p-dropdown
                        :filter="true"
                        v-model="v$['articleCategory'].$model"
                        :options="serverData?.articleCategory || []"
                        optionLabel="name"
                        optionValue="id"
                        @change="setDropdownValue(['articleCategory'], $event)"
                        :showClear="true"
                    >
                    </p-dropdown>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full flex justify-content-between mt-4">
        <p-button
            severity="danger"
            v-text="$t('buttons.back')"
            @click="onBackButtonClicked"
        >
        </p-button>

        <p-button severity="success" type="submit" v-text="$t('buttons.next')">
        </p-button>
    </div>
</form>
