<TabView class="tabview-custom text-sm">
    <TabPanel :disabled="!productDetails?.shopware">
        <template #header>
            <i class="pi pi-sitemap mr-2"></i>
            <span
                >{{ $t("labels.categories") }}<span
                    v-if="productDetails?.shopware?.categories"
                >
                    ({{ productDetails.shopware.categories.length }})</span
                ></span
            >
            <span
                class="sales-channel-dot w-1rem h-1rem ml-2"
                :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
            ></span>
        </template>
        <Categories
            :shopware-details="productDetails?.shopware"
            @reload-product="loadProductDetails"
        ></Categories>
    </TabPanel>
    <TabPanel :disabled="!productDetails?.secondShopware">
        <template #header>
            <i class="pi pi-sitemap mr-2"></i>
            <span
                >{{ $t("labels.categories") }}<span
                    v-if="productDetails?.secondShopware?.categories"
                >
                    ({{ productDetails.secondShopware.categories.length
                    }})</span
                ></span
            >
            <span
                class="sales-channel-dot w-1rem h-1rem ml-2"
                :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
            ></span>
        </template>
        <Categories
            :shopware-details="productDetails?.secondShopware"
            @reload-product="loadProductDetails"
            :platform="secondPlatform"
        ></Categories>
    </TabPanel>
</TabView>
