import {ref, onMounted} from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import LoadingPlugin from 'vue-loading-overlay';
import Panel from 'primevue/panel';
import {FilterMatchMode} from 'primevue/api';
import apiClient from '@/utils/axios';
import * as papa from 'papaparse';
import {i18n} from '@/utils/i18n';
import currency from 'currency.js';

export default {
    components: {
        DataTable,
        Column,
        InputText,
        Panel,
        loading: LoadingPlugin
    },
    setup() {
        onMounted(() => {
            loadLazyData();
        });

        const dt = ref();
        const loading = ref(false);

        const warehouseStockItems = ref([]);
        const filters = ref({
            bpmArticleNumber: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            teltecArticleNumber: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            bpmName: {value: null, matchMode: FilterMatchMode.CONTAINS},
            manufacturer: {value: null, matchMode: FilterMatchMode.CONTAINS}
        });

        const loadLazyData = async () => {
            loading.value = true;
            const bpmStockData = await apiClient.get(
                '/api/v1/bpm-warehouse-stock'
            );

            papa.parse(bpmStockData.data, {
                delimiter: '|',
                header: true,
                complete: function (results: any) {
                    warehouseStockItems.value = results.data
                        .map((item: any) => {
                            return {
                                teltecArticleNumber: item['TELTEC_ARTNR'],
                                bpmName: item['ARTIKELBEZEICHNUNG1'],
                                bpmArticleNumber: item['ARTIKELNR'],
                                manufacturer: item['HERSTELLER'],
                                stockQuantity: parseInt(
                                    item['VERFUEGBAR'] || '0'
                                ),
                                price: item['EK_VORSCHLAG']
                            };
                        })
                        .filter(
                            (item: any) =>
                                item.teltecArticleNumber &&
                                item.stockQuantity > 0
                        );
                    loading.value = false;
                }
            });
        };

        const formatter = new Intl.NumberFormat(i18n.global.locale, {
            style: 'currency',
            currency: 'EUR'
        });

        const formatPrice = (input: string) => {
            return formatter.format(currency(input, {decimal: ','}).value);
        };

        return {
            warehouseStockItems,
            dt,
            loading,
            filters,
            formatPrice
        };
    }
};
