<loading v-model:active="savingInProgress" />
<Panel>
    <template #header>
        <div class="flex justify-content-between w-full">
            <div class="font-bold">Shop</div>
            <i
                v-if="!isEdit"
                class="pi pi-pencil"
                @click="isEdit = true;"
                style="cursor: pointer"
            ></i>
            <div v-else>
                <i
                    class="pi pi-check text-green-600"
                    style="cursor: pointer"
                    @click="handleSubmit(!v$.$invalid)"
                ></i>
                <i
                    class="pi pi-times-circle text-red-600 ml-3"
                    style="cursor: pointer"
                    @click="resetForm"
                ></i>
            </div>
        </div>
    </template>
    <div class="grid mb-3">
        <div class="col">{{$t('labels.customerNumber')}}</div>
        <div class="col">{{shopData?.customer?.customerNumber}}</div>
    </div>
    <div class="grid" :class="{'mb-5': !isEdit}">
        <div class="col">{{$t('labels.email')}}</div>
        <div class="col">
            <span v-if="!isEdit">{{shopData?.customer?.email}}</span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    v-model.trim="v$.email.$model"
                    class="text-sm"
                    :class="{'p-invalid':v$.email.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.email.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid" :class="{'mb-5': !isEdit}">
        <div class="col">{{$t('labels.taxId')}}</div>
        <div class="col">
            <span v-if="!isEdit">{{shopData?.customer?.vatNumber}}</span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.vatNumber.$model"
                    :class="{'p-invalid':v$.vatNumber.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.vatNumber.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid" :class="{'mb-1': !isEdit}">
        <div class="col">{{$t('labels.company')}}</div>
        <div class="col">
            <span v-if="!isEdit">{{shopData?.billingAddress?.company}}</span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.company.$model"
                    :class="{'p-invalid':v$.company.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.company.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div
        class="grid"
        :class="{'mb-1': !isEdit}"
        v-if="shopData?.billingAddress?.department ||
        selectedWeclappCustomer?.company2 || isEdit === true"
    >
        <div class="col">{{$t('labels.company2')}}</div>
        <div class="col">
            <span v-if="!isEdit">{{shopData?.billingAddress?.department}}</span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.department.$model"
                    :class="{'p-invalid':v$.department.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.department.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div
        class="grid mt-3"
        :class="{'mb-6': (selectedWeclappCustomer?.addresses || []).length > 1, 'mb-3': (selectedWeclappCustomer?.addresses || []).length <= 1, }"
    >
        <div class="col font-bold">{{$t('labels.address')}}</div>
        <div class="col"></div>
    </div>
    <div class="grid mb-1">
        <div class="col">{{$t('labels.street')}}</div>
        <div class="col">
            <span v-if="!isEdit">{{shopData?.billingAddress?.street}}</span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.street.$model"
                    :class="{'p-invalid':v$.street.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.street.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div
        class="grid"
        :class="{'mb-1': !isEdit}"
        v-if="shopData?.billingAddress?.additionalAddressLine1"
    >
        <div class="col">{{$t('labels.street2')}}</div>
        <div class="col">
            <span v-if="!isEdit"
                >{{shopData?.billingAddress?.additionalAddressLine1}}</span
            >
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.street2.$model"
                    :class="{'p-invalid':v$.street2.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.street2.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid" :class="{'mb-1': !isEdit}">
        <div class="col">{{$t('labels.zipCode')}}</div>
        <div class="col">
            <span v-if="!isEdit">{{shopData?.billingAddress?.zipcode}}</span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.zipcode.$model"
                    :class="{'p-invalid':v$.zipcode.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.zipcode.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid" :class="{'mb-1': !isEdit}">
        <div class="col">{{$t('labels.city')}}</div>
        <div class="col">
            <span v-if="!isEdit">{{shopData?.billingAddress?.city}}</span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.city.$model"
                    :class="{'p-invalid':v$.city.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.city.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid" :class="{'mb-3': true}">
        <div class="col">{{$t('labels.country')}}</div>
        <div class="col">
            <span
                >{{resolveCountryFromCode(shopData?.billingAddress?.country)}}</span
            >
            <!--
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model="v$.country.$model"
                    :class="{'p-invalid':v$.country.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.country.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
            -->
        </div>
    </div>
    <div class="grid" :class="{'mb-5': !isEdit}">
        <div class="col">{{$t('labels.phoneNumber')}}</div>
        <div class="col">
            <span v-if="!isEdit">
                {{shopData?.billingAddress?.phoneNumber}}</span
            >
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.phoneNumber.$model"
                    :class="{'p-invalid':v$.phoneNumber.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.phoneNumber.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div
        v-if="!shopData?.hasSameShippingAddress && shopData?.shippingAddress"
        class="grid mt-5 mb-3 h-6rem"
    >
        <div class="col font-bold">
            {{$t('labels.alternativeDeliveryAddress')}}
        </div>
        <div class="col text-sm">
            <div>
                <div v-if="shopData?.shippingAddress?.company">
                    {{shopData?.shippingAddress?.company}}
                </div>
                <div>{{shopData?.shippingAddress?.street}}</div>
                <div v-if="shopData?.shippingAddress?.additionalAddressLine1">
                    {{shopData?.shippingAddress?.additionalAddressLine1}}
                </div>
                <div>
                    {{shopData?.shippingAddress?.zipcode}}
                    {{shopData?.shippingAddress?.city}}
                </div>
                <div v-if="shopData?.shippingAddress?.country">
                    {{resolveCountryFromCode(shopData?.shippingAddress?.country)}}
                    <span
                        class="pi pi-exclamation-triangle"
                        v-if="shopData?.shippingAddress?.country !== shopData?.billingAddress?.country"
                        v-tooltip.top="$t('messages.invoiceAnddeliveryCountryDiffer')"
                    ></span>
                </div>
            </div>
        </div>
    </div>
    <div class="grid mt-5 mb-3">
        <div class="col font-bold">{{$t('labels.contactPersonWcp')}}</div>
    </div>
    <div class="grid mb-1">
        <div class="col">{{$t('labels.salutation')}}</div>
        <div class="col">{{shopData?.customer?.salutation}}</div>
    </div>
    <div class="grid" :class="{'mb-1': !isEdit}">
        <div class="col">{{$t('labels.firstName')}}</div>
        <div class="col">
            <span v-if="!isEdit">{{shopData?.customer?.firstName}}</span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.firstName.$model"
                    :class="{'p-invalid':v$.firstName.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.firstName.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid" :class="{'mb-1': !isEdit}">
        <div class="col">{{$t('labels.lastName')}}</div>
        <div class="col">
            <span v-if="!isEdit">{{shopData?.customer?.lastName}}</span>
            <div v-else>
                <InputText
                    autocomplete="off"
                    class="text-sm"
                    v-model.trim="v$.lastName.$model"
                    :class="{'p-invalid':v$.lastName.$invalid && submitted}"
                />
                <small
                    v-if="submitted"
                    style="display: block"
                    v-for="error in v$.lastName.$silentErrors"
                    class="p-error"
                    :key="error"
                >
                    {{ error.$message }}
                </small>
            </div>
        </div>
    </div>
    <div class="grid mb-1">
        <div class="col">{{$t('labels.email')}}</div>
        <div class="col">{{shopData?.customer?.email}}</div>
    </div>
    <div class="grid mb-1">
        <div class="col">{{$t('labels.phoneNumber')}}</div>
        <div class="col">{{shopData?.billingAddress?.phoneNumber}}</div>
    </div>
</Panel>
