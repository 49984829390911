import {computed, ref, watch} from 'vue';
import {i18n} from '@/utils/i18n';
import Panel from 'primevue/panel';

import countries from 'i18n-iso-countries';
import store from '@/store';
import {useRoute} from 'vue-router';
import Dropdown from 'primevue/dropdown';
import {helpers, required} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import {useConfirm} from 'primevue/useconfirm';
import {useToast} from 'vue-toastification';
import {editSingleCustomer} from '@/services/parties';
import InputText from 'primevue/inputtext';
import LoadingPlugin from 'vue-loading-overlay';

export default {
    emits: ['weclapp-data-saved'],
    components: {
        Panel,
        'p-dropdown': Dropdown,
        InputText,
        loading: LoadingPlugin
    },
    props: {
        shopData: Object,
        selectedWeclappCustomer: Object,
        potentialMatches: Array
    },
    setup(props: any, context: any) {
        const shopData = ref(null);
        const selectedWeclappCustomer = ref(null);
        const potentialMatches = ref(null);
        const isEdit = ref(false);
        const confirm = useConfirm();
        const toast = useToast();

        const route = useRoute();
        const selectedContactId = ref(null);
        const selectedAddressId = ref(null);

        const submitted = ref(false);
        const savingInProgress = ref(false);

        const state = ref({
            email: null,
            taxId: null,
            firstName: null,
            lastName: null,
            company: null,
            department: null,
            street: null,
            street2: null,
            zipcode: null,
            city: null,
            phoneNumber: null,
            contactEmail: null,
            contactPhoneNumber: null
        });

        const rules = {
            email: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            taxId: {},
            firstName: {},
            lastName: {},
            company: {},
            department: {},
            street: {},
            street2: {},
            zipcode: {},
            city: {},
            phoneNumber: {},
            contactEmail: {},
            contactPhoneNumber: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, async (args) => {
            shopData.value = args.shopData;

            selectedWeclappCustomer.value = args.selectedWeclappCustomer;
            if (args.selectedWeclappCustomer) {
                selectedContactId.value =
                    (selectedWeclappCustomer?.value?.contacts || []).length > 0
                        ? selectedWeclappCustomer.value.contacts[0].id
                        : null;

                const index = (
                    selectedWeclappCustomer.value.addresses || []
                ).findIndex((item: any) => item.primeAddress);

                if (index !== -1)
                    selectedAddressId.value =
                        selectedWeclappCustomer.value.addresses[index].id;

                resetForm();
            } else {
                isEdit.value = false;
            }

            if (args.potentialMatches) {
                potentialMatches.value = args.potentialMatches;
            }
        });

        const resolveCountryFromCode = (countryCode: string) => {
            return (
                countries.getName(countryCode, i18n.global.locale) ||
                countries.getName(countryCode, 'en')
            );
        };

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) =>
                    item.value ===
                    (route.query?.platform || store.getters['auth/platform'])
            );
        });

        const resolvedWeclappCustomerNumber = computed(() => {
            return !['ttd', 'ttl'].includes(
                resolvedPlatform.value?.threeLetterId
            )
                ? selectedWeclappCustomer.value?.videodataShopCustomerNumber
                : selectedWeclappCustomer.value?.teltecShopCustomerNumber;
        });

        const selectedWeclappAddress = computed(() => {
            if (
                !selectedAddressId.value ||
                !selectedWeclappCustomer.value ||
                !selectedWeclappCustomer.value.addresses
            ) {
                return null;
            }

            return selectedWeclappCustomer.value.addresses.find(
                (it: any) => it.id === selectedAddressId.value
            );
        });

        const selectedContact = computed(() => {
            if (
                !selectedContactId.value ||
                !selectedWeclappCustomer.value ||
                !selectedWeclappCustomer.value.contacts
            ) {
                return null;
            }

            return selectedWeclappCustomer.value.contacts.find(
                (it: any) => it.id === selectedContactId.value
            );
        });

        const resetForm = () => {
            state.value = {
                email: selectedWeclappCustomer.value?.email,
                taxId: selectedWeclappCustomer.value?.taxId,
                firstName: selectedContact.value?.firstName,
                lastName: selectedContact.value?.lastName,
                company: selectedWeclappCustomer.value?.company,
                department: selectedWeclappCustomer.value?.company2,
                street: selectedWeclappAddress.value?.street1,
                street2: selectedWeclappAddress.value?.street2,
                zipcode: selectedWeclappAddress.value?.zipcode,
                city: selectedWeclappAddress.value?.city,
                phoneNumber: selectedWeclappCustomer.value?.phone,
                contactEmail: selectedContact.value?.email,
                contactPhoneNumber: selectedContact.value?.phone
            };

            isEdit.value = false;
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            if (!v$.value.$anyDirty) {
                isEdit.value = false;
                return;
            }

            confirm.require({
                message: i18n.global.t('messages.saveChangesConfirmation'),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: () => {
                    const payload = Object.keys(state.value)
                        .filter(function (stateKey: string) {
                            return (
                                v$.value[stateKey] && v$.value[stateKey].$dirty
                            );
                        })
                        .reduce(function (obj2: any, key: string) {
                            obj2[key as keyof typeof obj2] =
                                state.value[key as keyof typeof state.value];
                            return obj2;
                        }, {});

                    savingInProgress.value = true;
                    editSingleCustomer(
                        selectedWeclappCustomer.value?.id,
                        Object.assign(payload, {
                            selectedAddressId: selectedAddressId.value,
                            selectedContactId: selectedContactId.value
                        }),
                        resolvedPlatform.value?.value
                    )
                        .then(() => {
                            toast.success(
                                i18n.global.t(
                                    'messages.changesSavedSuccessfully'
                                )
                            );
                            context.emit('weclapp-data-saved');
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        })
                        .finally(() => {
                            savingInProgress.value = false;
                        });
                }
            });
        };

        return {
            shopData,
            selectedWeclappCustomer,
            potentialMatches,
            selectedAddressId,
            selectedWeclappAddress,
            resolvedPlatform,
            selectedContactId,
            selectedContact,
            resolveCountryFromCode,
            formatWeclappSalutation: (wcpSalutation: string | null) => {
                if ((wcpSalutation || '').trim() === '') {
                    return wcpSalutation;
                }

                switch (wcpSalutation.toUpperCase().replaceAll('.', '')) {
                    case 'MR':
                        return 'Herr';
                    case 'MS':
                    case 'MRS':
                        return 'Frau';
                    default:
                        return wcpSalutation;
                }
            },
            resolveBackgroundColorForMatching: (
                term1: string | null,
                term2: string | null
            ) => {
                const colorVariant = 400;
                if (term1 === null || term2 === null) {
                    return term1 === term2
                        ? 'text-green-' + colorVariant
                        : 'text-red-' + colorVariant;
                }

                return term1.trim() === term2.trim()
                    ? 'text-green-' + colorVariant
                    : 'text-red-' + colorVariant;
            },
            showCopyFieldIcon: (
                term1: string | null,
                term2: string | null
            ): boolean => {
                if (term1 === null) {
                    return false;
                }
                return term1 !== term2;
            },
            isEdit,
            state,
            v$,
            handleSubmit,
            submitted,
            resetForm,
            savingInProgress,
            resolvedWeclappCustomerNumber
        };
    }
};
