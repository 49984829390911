<TabView class="tabview-custom text-sm">
    <TabPanel
        :disabled="!productDetails?.shopware && !productDetails?.secondShopware"
    >
        <template #header>
            <i class="pi pi-images"></i>
            <span>{{ $t("labels.images") }}</span>
            <template
                v-if="productDetails?.shopware?.images || productDetails?.secondShopware?.images"
            >
                -
                <template v-if="productDetails?.shopware?.images">
                    ({{ productDetails.shopware.images.length }})
                    <span
                        class="sales-channel-dot w-1rem h-1rem"
                        :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                    ></span>
                </template>
                <template v-if="productDetails?.secondShopware?.images">
                    | ({{ productDetails.secondShopware.images.length }})
                    <span
                        class="sales-channel-dot w-1rem h-1rem"
                        :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                    ></span>
                </template>
            </template>
        </template>
        <ImagesContainer
            :product-details="productDetails"
            @reload-product="loadProductDetails"
        />
    </TabPanel>
    <TabPanel
        :disabled="!productDetails?.shopware && !productDetails?.secondShopware"
    >
        <template #header>
            <i class="pi pi-youtube"></i>
            <span>{{ $t("labels.videos") }}</span>
            <template
                v-if="productDetails?.shopware?.videos || productDetails?.secondShopware?.videos"
            >
                -
                <template v-if="productDetails?.shopware?.videos">
                    ({{ productDetails.shopware.videos.length }})
                    <span
                        class="sales-channel-dot w-1rem h-1rem"
                        :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                    ></span>
                </template>
                <template v-if="productDetails?.secondShopware?.videos">
                    | ({{ productDetails.secondShopware.videos.length }})
                    <span
                        class="sales-channel-dot w-1rem h-1rem"
                        :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                    ></span>
                </template>
            </template>
        </template>
        <VideosContainer
            :product-details="productDetails"
            @reload-product="loadProductDetails"
        />
    </TabPanel>
    <TabPanel
        :disabled="!productDetails?.shopware && !productDetails?.secondShopware"
    >
        <template #header>
            <i class="pi pi-file-pdf"></i>
            <span>{{ $t("labels.documents") }}</span>
            <template
                v-if="productDetails?.shopware?.documents || productDetails?.secondShopware?.documents"
            >
                -
                <template v-if="productDetails?.shopware?.documents">
                    ({{ productDetails.shopware.documents.length }})
                    <span
                        class="sales-channel-dot w-1rem h-1rem"
                        :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                    ></span>
                </template>
                <template v-if="productDetails?.secondShopware?.documents">
                    | ({{ productDetails.secondShopware.documents.length }})
                    <span
                        class="sales-channel-dot w-1rem h-1rem"
                        :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                    ></span>
                </template>
            </template>
        </template>
        <DocumentsContainer
            :product-details="productDetails"
            @reload-product="loadProductDetails"
        />
    </TabPanel>
    <TabPanel
        :disabled="!productDetails?.shopware && !productDetails?.secondShopware"
    >
        <template #header>
            <i class="pi pi-external-link"></i>
            <span>{{ $t("labels.links") }}</span>
            <template
                v-if="productDetails?.shopware?.links || productDetails?.secondShopware?.links"
            >
                -
                <template v-if="productDetails?.shopware?.links">
                    ({{ productDetails.shopware.links.length }})
                    <span
                        class="sales-channel-dot w-1rem h-1rem"
                        :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                    ></span>
                </template>
                <template v-if="productDetails?.secondShopware?.links">
                    | ({{ productDetails.secondShopware.links.length }})
                    <span
                        class="sales-channel-dot w-1rem h-1rem"
                        :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                    ></span>
                </template>
            </template>
        </template>
        <LinksContainer
            :product-details="productDetails"
            @reload-product="loadProductDetails"
        />
    </TabPanel>
</TabView>
