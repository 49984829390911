import Dialog from 'primevue/dialog';
import {reactive, ref, watch} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import {required} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import SupplierAutoComplete from '@/components/export-wizard/weclapp/supplier/supplier.vue';
import {addSupplierConfiguration} from '@/services/price-import';

export default {
    emits: ['close-dialog'],
    components: {
        'p-dialog': Dialog,
        InputText,
        'p-button': Button,
        SupplierAutoComplete
    },
    props: {
        displayResponsive: Boolean
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const submitted = ref(false);
        const savingInProgress = ref(false);
        const toast = useToast();

        const state = reactive({
            supplierName: null,
            supplierNumber: null
        });

        const rules = {
            supplierName: {required},
            supplierNumber: {required}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, (args) => {
            showDialog.value = args.displayResponsive;
            resetForm();
        });

        const closeDialog = (forceRefresh: boolean = false) => {
            resetForm();
            context.emit('close-dialog', forceRefresh);
        };

        const onSupplierChosen = (data: any) => {
            state.supplierName = data?.supplier?.label || null;
            state.supplierNumber = data?.supplier?.value || null;
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            addSupplierConfiguration(
                state.supplierName
                    .replace(state.supplierNumber, '')
                    .replace(']', '')
                    .replace('[', '')
                    .trim(),
                state.supplierNumber
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully')
                    );
                    closeDialog(true);
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        const resetForm = () => {
            state.supplierName = null;
            state.supplierNumber = null;
            submitted.value = false;
        };

        return {
            showDialog,
            closeDialog,
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            onSupplierChosen
        };
    }
};
