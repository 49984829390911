import {onMounted, ref, watch} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import LoadingPlugin from 'vue-loading-overlay';
import useVuelidate from '@vuelidate/core';
import {
    helpers,
    maxLength,
    minLength,
    required,
    requiredIf
} from '@vuelidate/validators';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import {getAll} from '@/services/import-logs';
import {getAll as getAllMeta} from '@/services/metadata';
import InputSwitch from 'primevue/inputswitch';
import InputNumber from 'primevue/inputnumber';
import countries from 'i18n-iso-countries';
import {DateTime} from 'luxon';
import {
    createHeadlessShipment,
    getAvailableCarriersForHeadless
} from '@/services/shipments';
import {euStates} from '@/utils/helpers';

export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        'p-button': Button,
        'p-dropdown': Dropdown,
        InputText,
        loading: LoadingPlugin,
        InputSwitch,
        InputNumber
    },
    props: {
        displayDialog: Boolean
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const toast = useToast();
        const submitted = ref(false);
        const loading = ref(false);
        const singleShipment = ref(true);
        const importOptions = ref([]);
        const warehouses = ref([]);
        const availableCarriers = ref([]);

        const state = ref({
            shippingCompany: null,
            warehouse: null,
            customerName: null,
            referenceNumber: null,
            address: null,
            contactPerson: null,
            phoneNumber: null,
            city: null,
            zipCode: null,
            countryCode: null,
            emailAddress: null,
            weight: null,
            packageCount: null,
            importId: null
        });

        onMounted(() => {
            loading.value = true;
            getAll({
                ownOnly: true,
                allEnvs: false,
                sortOrder: 'desc',
                filters: {
                    subjectWorkflowCombo: {
                        value: {'weclapp-headless-shipments-add': true},
                        matchMode: 'equals'
                    }
                }
            }).then((data) => {
                importOptions.value = data.data.items.map((item: any) => {
                    const formatted = DateTime.fromSeconds(item.importedAt)
                        .setLocale(i18n.global.locale)
                        .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                        .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
                    return {
                        label:
                            item.fileName +
                            ' [' +
                            formatted +
                            '] - ' +
                            i18n.global.t(
                                'labels.shipment.countCustomerAddresses',
                                {
                                    count: item.importedRecords
                                }
                            ),
                        value: item.id
                    };
                });
                loading.value = false;
            });
            getAllMeta(['warehouse'])
                .then((data: any) => {
                    warehouses.value = data.data?.warehouse || [];
                })
                .catch((error: any) => {
                    toast.error(error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
            getAvailableCarriersForHeadless().then((data) => {
                availableCarriers.value = Object.keys(data.data).map(
                    (item: any) => {
                        return {
                            label: item,
                            value: data.data[item]['weclapp-id']
                        };
                    }
                );
                loading.value = false;
            });
        });

        const rules = {
            shippingCompany: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            warehouse: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            customerName: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return singleShipment.value;
                    })
                )
            },
            referenceNumber: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return singleShipment.value;
                    })
                ),
                maxLength: helpers.withMessage(
                    i18n.global.t('messages.valueCantBeLongerThan', {
                        number: 35
                    }),
                    maxLength(35)
                ),
                minLength: helpers.withMessage(
                    i18n.global.t('messages.valueCantBeShorterThan', {
                        number: 8
                    }),
                    minLength(8)
                )
            },
            address: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return singleShipment.value;
                    })
                )
            },
            contactPerson: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return (
                            singleShipment.value &&
                            state.value?.countryCode !== 'DE'
                        );
                    })
                )
            },
            phoneNumber: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return (
                            singleShipment.value &&
                            state.value?.countryCode !== 'DE'
                        );
                    })
                )
            },
            city: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return singleShipment.value;
                    })
                )
            },
            zipCode: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return singleShipment.value;
                    })
                )
            },
            countryCode: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return singleShipment.value;
                    })
                )
            },
            emailAddress: {},
            weight: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return singleShipment.value;
                    })
                )
            },
            packageCount: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return singleShipment.value;
                    })
                )
            },
            importId: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return !singleShipment.value;
                    })
                )
            }
        };

        const v$ = useVuelidate(rules, state);

        watch(props, async (args) => {
            showDialog.value = args.displayDialog;
        });

        const onCancelClick = (event: any) => {
            event.preventDefault();
            showDialog.value = false;
            submitted.value = false;
            state.value = {
                shippingCompany: null,
                warehouse: null,
                customerName: null,
                referenceNumber: null,
                address: null,
                contactPerson: null,
                phoneNumber: null,
                city: null,
                zipCode: null,
                countryCode: null,
                emailAddress: null,
                weight: null,
                packageCount: null,
                importId: null
            };
            context.emit('close-dialog');
        };

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            try {
                loading.value = true;
                await createHeadlessShipment(
                    Object.assign(
                        {
                            shippingCompany: state.value.shippingCompany,
                            warehouse: state.value.warehouse
                        },
                        singleShipment.value
                            ? {
                                  customerName: state.value.customerName,
                                  referenceNumber: state.value.referenceNumber,
                                  address: state.value.address,
                                  contactPerson: state.value.contactPerson,
                                  phoneNumber: state.value.phoneNumber,
                                  city: state.value.city,
                                  zipCode: state.value.zipCode,
                                  countryCode: state.value.countryCode,
                                  emailAddress: state.value.emailAddress,
                                  weight: state.value.weight,
                                  packageCount: state.value.packageCount
                              }
                            : {
                                  importId: state.value.importId
                              }
                    )
                );

                toast.success(
                    i18n.global.t(
                        singleShipment.value
                            ? 'messages.changesSavedSuccessfully'
                            : 'messages.cronJobExecutionScheduled'
                    )
                );

                state.value = {
                    shippingCompany: null,
                    warehouse: null,
                    customerName: null,
                    referenceNumber: null,
                    address: null,
                    contactPerson: null,
                    phoneNumber: null,
                    city: null,
                    zipCode: null,
                    countryCode: null,
                    emailAddress: null,
                    weight: null,
                    packageCount: null,
                    importId: null
                };
                context.emit('close-dialog', true);
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
            } finally {
                submitted.value = false;
                loading.value = false;
            }
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;

            Object.assign(state.value, temp);
        };

        return {
            showDialog,
            loading,
            onCancelClick,
            handleSubmit,
            v$,
            submitted,
            setDropdownValue,
            singleShipment,
            locale: i18n.global.locale,
            countryOptions: Object.entries(
                countries.getNames(i18n.global.locale) ||
                    countries.getNames('en')
            )
                .filter((item) => euStates.includes(item[0]))
                .map((item) => {
                    return {
                        label: item[1],
                        value: item[0]
                    };
                }),
            importOptions,
            warehouses,
            availableCarriers
        };
    }
};
