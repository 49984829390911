import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f9ce296"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid p-fluid mb-2" }
const _hoisted_2 = { class: "col field" }
const _hoisted_3 = { class: "p-input-icon-left" }
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = { class: "col field" }
const _hoisted_6 = { class: "field col-2" }
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = { class: "mt-3" }
const _hoisted_9 = { class: "col-1 w-10rem" }
const _hoisted_10 = { class: "text-sm" }
const _hoisted_11 = ["href"]
const _hoisted_12 = { class: "text-xs" }
const _hoisted_13 = {
  key: 0,
  class: "ml-3"
}
const _hoisted_14 = { class: "w-full flex justify-content-center ml-3" }
const _hoisted_15 = { class: "flex align-items-center text-center" }
const _hoisted_16 = { class: "w-full flex justify-content-center ml-3" }
const _hoisted_17 = { style: {"cursor":"pointer"} }
const _hoisted_18 = { style: {"cursor":"pointer"} }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { key: 1 }
const _hoisted_21 = { key: 1 }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_InputIcon = _resolveComponent("InputIcon")
  const _component_InputText = _resolveComponent("InputText")
  const _component_IconField = _resolveComponent("IconField")
  const _component_p_multiselect = _resolveComponent("p-multiselect")
  const _component_TriStateCheckbox = _resolveComponent("TriStateCheckbox")
  const _component_p_button = _resolveComponent("p-button")
  const _component_Column = _resolveComponent("Column")
  const _component_Skeleton = _resolveComponent("Skeleton")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_calendar = _resolveComponent("p-calendar")
  const _component_DataTable = _resolveComponent("DataTable")
  const _component_Panel = _resolveComponent("Panel")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading, {
      active: _ctx.loading,
      "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
    }, null, 8, ["active"]),
    _createVNode(_component_ScrollPanel, { style: {"height":"calc(100vh - 12rem)"} }, {
      default: _withCtx(() => [
        _createVNode(_component_Panel, {
          class: "panel-container-datatable",
          header: _ctx.$t('labels.salesOrder.disposition')
        }, {
          default: _withCtx(() => [
            _createElementVNode("form", {
              onSubmit: _cache[5] || (_cache[5] = _withModifiers($event => (_ctx.handleSearch(!_ctx.v$.$invalid)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.articleNumber')), 1),
                  _createElementVNode("span", _hoisted_3, [
                    _createVNode(_component_IconField, { iconPosition: "left" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_InputIcon, { class: "pi pi-search" }),
                        _createVNode(_component_InputText, {
                          class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.articleNumber.$invalid && _ctx.submitted}]),
                          modelValue: _ctx.v$.articleNumber.$model,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.articleNumber.$model) = $event)),
                          modelModifiers: { trim: true },
                          placeholder: _ctx.$t('labels.articleNumber') + ', ' + _ctx.$t('labels.commaSeparated')
                        }, null, 8, ["modelValue", "class", "placeholder"])
                      ]),
                      _: 1
                    })
                  ]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.articleNumber.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.name')), 1),
                  _createVNode(_component_InputText, {
                    class: _normalizeClass(["text-sm", {'p-invalid':_ctx.v$.articleName.$invalid && _ctx.submitted}]),
                    modelValue: _ctx.v$.articleName.$model,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$.articleName.$model) = $event)),
                    modelModifiers: { trim: true }
                  }, null, 8, ["modelValue", "class"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.articleName.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.manufacturer')), 1),
                  _createVNode(_component_p_multiselect, {
                    modelValue: _ctx.v$.manufacturer.$model,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.manufacturer.$model) = $event)),
                    class: _normalizeClass({'p-invalid':_ctx.v$.manufacturer.$invalid && _ctx.submitted}),
                    options: _ctx.manufacturerOptions || [],
                    optionLabel: "name",
                    optionValue: "id",
                    filter: ""
                  }, null, 8, ["modelValue", "class", "options"]),
                  (_ctx.submitted)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.manufacturer.$silentErrors, (error) => {
                        return (_openBlock(), _createElementBlock("small", {
                          style: {"display":"block"},
                          class: "p-error",
                          key: error
                        }, _toDisplayString(error.$message), 1))
                      }), 128))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.activeInShop')), 1),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_TriStateCheckbox, {
                        modelValue: _ctx.v$.activeInAnyShop.$model,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$.activeInAnyShop.$model) = $event))
                      }, null, 8, ["modelValue"])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_p_button, {
                    severity: "success",
                    textContent: _toDisplayString(_ctx.$t('buttons.confirm')),
                    type: "submit"
                  }, null, 8, ["textContent"])
                ])
              ])
            ], 32),
            (_ctx.foundProducts?.length > 0)
              ? (_openBlock(), _createBlock(_component_DataTable, {
                  key: 0,
                  class: "p-datatable-sm",
                  stripedRows: "",
                  paginator: "",
                  rows: 20,
                  "paginator-template": 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',
                  "rows-per-page-options": [10,20,50,100,200],
                  "current-page-report-template": '{first} to {last} of {totalRecords}',
                  editMode: "cell",
                  onCellEditComplete: _ctx.onCellEditComplete,
                  ref: "dt",
                  dataKey: "id",
                  value: _ctx.foundProducts,
                  responsiveLayout: "scroll",
                  filters: _ctx.filters,
                  "onUpdate:filters": _cache[7] || (_cache[7] = $event => ((_ctx.filters) = $event)),
                  filterDisplay: "row",
                  onFilter: _ctx.onFilter
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      field: "nameNumberCombo",
                      style: {"min-width":"9rem"},
                      header: _ctx.$t('labels.article'),
                      sortable: "",
                      filterMatchModeOptions: [{label: 'Contains', value: _ctx.articleComboFilter}]
                    }, {
                      filter: _withCtx(({filterModel,filterCallback}) => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: filterModel.value,
                          "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                          onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                          class: "p-column-filter text-xs",
                          placeholder: _ctx.$t('labels.name')
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                      ]),
                      body: _withCtx(({data}) => [
                        _createElementVNode("div", _hoisted_10, [
                          _createElementVNode("span", {
                            class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-teltec':data?.activeInShop, 'sales-channel-teltec-border':data?.availableInShop, 'bg-gray-200': !data?.availableInShop && !data?.activeInShop}])
                          }, null, 2),
                          _createElementVNode("span", {
                            class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-videodata':data?.activeInShop2, 'sales-channel-videodata-border':data?.availableInShop2, 'bg-gray-200': !data?.availableInShop2 && !data?.activeInShop2}])
                          }, null, 2),
                          _createTextVNode(" " + _toDisplayString(data.name) + " [", 1),
                          _createElementVNode("a", {
                            href: data?.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id,
                            target: "_blank"
                          }, _toDisplayString(data.articleNumber), 9, _hoisted_11),
                          _createTextVNode("] ")
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("b", null, [
                            _createTextVNode(_toDisplayString(_ctx.$t('labels.createdAt')) + " " + _toDisplayString(_ctx.getDateFormatted(data?.createdDate)), 1),
                            (_ctx.ekPriceCalculation(data))
                              ? (_openBlock(), _createElementBlock("span", _hoisted_13, "EK " + _toDisplayString(_ctx.ekPriceCalculation(data)), 1))
                              : _createCommentVNode("", true)
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["header", "filterMatchModeOptions"]),
                    _createVNode(_component_Column, {
                      field: "manufacturerName",
                      header: _ctx.$t('labels.manufacturer'),
                      sortable: ""
                    }, {
                      filter: _withCtx(({filterModel,filterCallback}) => [
                        _createVNode(_component_InputText, {
                          type: "text",
                          modelValue: filterModel.value,
                          "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                          onKeydown: _withKeys($event => (filterCallback()), ["enter"]),
                          class: "p-column-filter text-xs",
                          placeholder: _ctx.$t('labels.manufacturer')
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "onKeydown", "placeholder"])
                      ]),
                      _: 1
                    }, 8, ["header"]),
                    _createVNode(_component_Column, {
                      field: "eol",
                      header: "EOL",
                      dataType: "boolean",
                      bodyClass: "text-center",
                      sortable: ""
                    }, {
                      filter: _withCtx(({filterModel, filterCallback}) => [
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", null, [
                            _createVNode(_component_TriStateCheckbox, {
                              modelValue: filterModel.value,
                              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                              onChange: $event => (filterCallback())
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                          ])
                        ])
                      ]),
                      body: _withCtx(({data}) => [
                        _createElementVNode("i", {
                          class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.eol, 'text-red-600 pi-times-circle': !(data.eol)}])
                        }, null, 2)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "activeInAnyShop",
                      dataType: "boolean",
                      bodyClass: "text-center",
                      sortable: ""
                    }, {
                      header: _withCtx(() => [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", null, _toDisplayString(_ctx.$t('labels.productHome.activeInShop')), 1)
                        ])
                      ]),
                      filter: _withCtx(({filterModel, filterCallback}) => [
                        _createElementVNode("div", _hoisted_16, [
                          _createElementVNode("div", null, [
                            _createVNode(_component_TriStateCheckbox, {
                              modelValue: filterModel.value,
                              "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                              onChange: $event => (filterCallback())
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                          ])
                        ])
                      ]),
                      body: _withCtx(({data}) => [
                        _createElementVNode("i", {
                          class: _normalizeClass(["pi", {'text-green-600 pi-check-circle': data.activeInAnyShop, 'text-red-600 pi-times-circle': !(data.activeInAnyShop)}])
                        }, null, 2)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "minimumStockQuantity",
                      header: "Min",
                      sortable: ""
                    }, {
                      body: _withCtx(({data}) => [
                        _createElementVNode("div", _hoisted_17, [
                          (_ctx.singleEditInProgress === data.articleNumber)
                            ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(data.minimumStockQuantity), 1)
                              ], 64))
                        ])
                      ]),
                      editor: _withCtx(({ data, field }) => [
                        _createVNode(_component_InputNumber, {
                          locale: _ctx.locale,
                          modelValue: data[field],
                          "onUpdate:modelValue": $event => ((data[field]) = $event),
                          showButtons: "",
                          min: 0,
                          disabled: !_ctx.editPermissionAvailable
                        }, null, 8, ["locale", "modelValue", "onUpdate:modelValue", "disabled"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "targetStockQuantity",
                      header: "Max",
                      sortable: ""
                    }, {
                      body: _withCtx(({data}) => [
                        _createElementVNode("div", _hoisted_18, [
                          (_ctx.singleEditInProgress === data.articleNumber)
                            ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(data.targetStockQuantity), 1)
                              ], 64))
                        ])
                      ]),
                      editor: _withCtx(({ data, field }) => [
                        _createVNode(_component_InputNumber, {
                          locale: _ctx.locale,
                          modelValue: data[field],
                          "onUpdate:modelValue": $event => ((data[field]) = $event),
                          showButtons: "",
                          min: 0,
                          disabled: !_ctx.editPermissionAvailable
                        }, null, 8, ["locale", "modelValue", "onUpdate:modelValue", "disabled"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      field: "minMaxDate",
                      dataType: "date",
                      sortable: ""
                    }, {
                      header: _withCtx(() => [
                        _createTextVNode(" MinMaxAkt "),
                        _createElementVNode("i", {
                          class: "pi pi-refresh",
                          style: {"padding-left":"7px","cursor":"pointer"},
                          onClick: _cache[6] || (_cache[6] = (...args) => (_ctx.bulkUpdateMinMaxDate && _ctx.bulkUpdateMinMaxDate(...args)))
                        })
                      ]),
                      filter: _withCtx(({filterModel,filterCallback}) => [
                        _createVNode(_component_p_calendar, {
                          class: "p-column-filter text-xs",
                          modelValue: filterModel.value,
                          "onUpdate:modelValue": $event => ((filterModel.value) = $event),
                          dateFormat: "dd.mm.yy",
                          onDateSelect: $event => (filterCallback())
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "onDateSelect"])
                      ]),
                      body: _withCtx(({data}) => [
                        (data.minMaxDate)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                              (_ctx.singleEditInProgress === data.articleNumber)
                                ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createTextVNode(_toDisplayString(data.minMaxDate.toLocaleDateString('de-DE',{
                            year: 'numeric', month: '2-digit', day: '2-digit' })), 1)
                                  ], 64))
                            ]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      header: "Dispo",
                      sortable: "",
                      field: "calculatedDispo"
                    }, {
                      body: _withCtx(({data}) => [
                        (data?.calculatedDispo === null)
                          ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(data.calculatedDispo), 1)
                            ], 64))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      header: "VK-RG (3M)",
                      sortable: "",
                      field: "salesInvoicesLastThreeMonthsCount"
                    }, {
                      body: _withCtx(({data}) => [
                        (!data?.salesInvoicesLoaded)
                          ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(data.salesInvoicesLastThreeMonthsItemCount) + " / " + _toDisplayString(data.salesInvoicesLastThreeMonthsCount), 1)
                            ], 64))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      header: "VK-RG (12M)",
                      sortable: "",
                      field: "salesInvoicesCount"
                    }, {
                      body: _withCtx(({data}) => [
                        (!data?.salesInvoicesLoaded)
                          ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(data.salesInvoicesItemCount) + " / " + _toDisplayString(data.salesInvoicesCount), 1)
                            ], 64))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      header: "BE (12M)",
                      sortable: "",
                      field: "purchaseOrdersItemCount"
                    }, {
                      body: _withCtx(({data}) => [
                        (!data?.purchaseOrdersLoaded)
                          ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                          : (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(data?.purchaseOrdersItemCount), 1))
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      header: "Letzte BE",
                      sortable: "",
                      field: "purchaseOrdersLastPurchaseOrderDate"
                    }, {
                      body: _withCtx(({data}) => [
                        (!data?.purchaseOrdersLoaded)
                          ? (_openBlock(), _createBlock(_component_Skeleton, { key: 0 }))
                          : (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(_ctx.getDateFormatted(data?.purchaseOrdersLastPurchaseOrderDate)), 1))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["onCellEditComplete", "value", "filters", "onFilter"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["header"])
      ]),
      _: 1
    })
  ], 64))
}