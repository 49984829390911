import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b093380"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "formgrid grid p-fluid" }
const _hoisted_2 = { class: "col field" }
const _hoisted_3 = { class: "col field" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { class: "formgrid grid p-fluid" }
const _hoisted_6 = { class: "col field" }
const _hoisted_7 = {
  key: 0,
  class: "p-error"
}
const _hoisted_8 = { class: "col field" }
const _hoisted_9 = { class: "formgrid grid p-fluid" }
const _hoisted_10 = { class: "col field" }
const _hoisted_11 = {
  key: 0,
  class: "p-error"
}
const _hoisted_12 = { class: "col field" }
const _hoisted_13 = { class: "formgrid grid p-fluid" }
const _hoisted_14 = { class: "col field" }
const _hoisted_15 = {
  key: 0,
  class: "p-error"
}
const _hoisted_16 = { class: "col field" }
const _hoisted_17 = { class: "formgrid grid p-fluid" }
const _hoisted_18 = { class: "col field" }
const _hoisted_19 = {
  key: 0,
  class: "p-error"
}
const _hoisted_20 = { class: "col field" }
const _hoisted_21 = {
  key: 0,
  class: "p-error"
}
const _hoisted_22 = { class: "formgrid grid p-fluid" }
const _hoisted_23 = { class: "col field" }
const _hoisted_24 = { class: "col field" }
const _hoisted_25 = {
  key: 0,
  class: "p-error"
}
const _hoisted_26 = { class: "formgrid grid p-fluid" }
const _hoisted_27 = { class: "col field" }
const _hoisted_28 = { class: "col field" }
const _hoisted_29 = { class: "formgrid grid p-fluid" }
const _hoisted_30 = { class: "col field mt-2" }
const _hoisted_31 = { class: "col field mt-2" }
const _hoisted_32 = { class: "field col mt-2" }
const _hoisted_33 = { class: "col field mt-2" }
const _hoisted_34 = { class: "col-3 field mt-2" }
const _hoisted_35 = {
  key: 0,
  class: "formgrid grid p-fluid"
}
const _hoisted_36 = { class: "col field" }

export function render(_ctx, _cache) {
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_calendar = _resolveComponent("p-calendar")
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_Chips = _resolveComponent("Chips")
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[26] || (_cache[26] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.creator')), 1),
        _createVNode(_component_InputText, {
          disabled: true,
          autocomplete: "off",
          modelValue: _ctx.v$.articleCreator.$model,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$.articleCreator.$model) = $event)),
          class: _normalizeClass({'p-invalid':_ctx.v$.articleCreator.$invalid && _ctx.submitted})
        }, null, 8, ["modelValue", "class"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.productType')), 1),
        _createVNode(_component_p_dropdown, {
          modelValue: _ctx.v$.productType.$model,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.productType.$model) = $event)),
          options: _ctx.productTypeOptions,
          optionLabel: "label",
          optionValue: "value",
          onChange: _cache[2] || (_cache[2] = $event => (_ctx.setDropdownValue('productType', $event))),
          class: _normalizeClass({'p-invalid':_ctx.v$.productType.$invalid && _ctx.submitted}),
          showClear: true
        }, null, 8, ["modelValue", "options", "class"]),
        ((_ctx.v$.productType.$invalid && _ctx.submitted) || _ctx.v$.productType.$pending.$response)
          ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.v$.productType.required.$message.replace('Value',
                _ctx.$t('labels.productHome.productType'))), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.articleName')), 1),
        _createVNode(_component_InputText, {
          autocomplete: "off",
          modelValue: _ctx.v$.name.$model,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.name.$model) = $event)),
          class: _normalizeClass({'p-invalid':_ctx.v$.name.$invalid && _ctx.submitted})
        }, null, 8, ["modelValue", "class"]),
        ((_ctx.v$.name.$invalid && _ctx.submitted) || _ctx.v$.name.$pending.$response)
          ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.v$.name.required.$message.replace('Value',
                _ctx.$t('labels.articleName'))), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.mpn')), 1),
        _createVNode(_component_InputText, {
          autocomplete: "off",
          modelValue: _ctx.v$.manufacturerPartNumber.$model,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$.manufacturerPartNumber.$model) = $event)),
          class: _normalizeClass({'p-invalid':_ctx.v$.manufacturerPartNumber.$invalid && _ctx.submitted})
        }, null, 8, ["modelValue", "class"])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.currency')), 1),
        _createVNode(_component_p_dropdown, {
          filter: true,
          modelValue: _ctx.v$.currency.$model,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.v$.currency.$model) = $event)),
          options: _ctx.currencyOptions,
          optionLabel: "label",
          optionValue: "value",
          onChange: _cache[6] || (_cache[6] = $event => (_ctx.setDropdownValue('currency', $event))),
          class: _normalizeClass({'p-invalid':_ctx.v$.currency.$invalid && _ctx.submitted}),
          showClear: true
        }, null, 8, ["modelValue", "options", "class"]),
        ((_ctx.v$.currency.$invalid && _ctx.submitted) || _ctx.v$.currency.$pending.$response)
          ? (_openBlock(), _createElementBlock("small", _hoisted_11, _toDisplayString(_ctx.v$.currency.required.$message.replace('Value',
                _ctx.$t('labels.productHome.currency'))), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ean')), 1),
        _createVNode(_component_InputText, {
          autocomplete: "off",
          modelValue: _ctx.v$.ean.$model,
          "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.v$.ean.$model) = $event)),
          class: _normalizeClass({'p-invalid':_ctx.v$.ean.$invalid && _ctx.submitted})
        }, null, 8, ["modelValue", "class"])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.unit')), 1),
        _createVNode(_component_p_dropdown, {
          modelValue: _ctx.v$.unit.$model,
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.v$.unit.$model) = $event)),
          options: _ctx.unitOptions,
          optionLabel: "label",
          optionValue: "value",
          onChange: _cache[9] || (_cache[9] = $event => (_ctx.setDropdownValue('unit', $event))),
          class: _normalizeClass({'p-invalid':_ctx.v$.unit.$invalid && _ctx.submitted}),
          showClear: true
        }, null, 8, ["modelValue", "options", "class"]),
        ((_ctx.v$.unit.$invalid && _ctx.submitted) || _ctx.v$.unit.$pending.$response)
          ? (_openBlock(), _createElementBlock("small", _hoisted_15, _toDisplayString(_ctx.v$.unit.required.$message.replace('Value',
                _ctx.$t('labels.productHome.unit'))), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.memo')), 1),
        _createVNode(_component_InputText, {
          autocomplete: "off",
          modelValue: _ctx.v$.internalInfo.$model,
          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.v$.internalInfo.$model) = $event)),
          class: _normalizeClass({'p-invalid':_ctx.v$.internalInfo.$invalid && _ctx.submitted})
        }, null, 8, ["modelValue", "class"])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.taxRateType')), 1),
        _createVNode(_component_p_dropdown, {
          modelValue: _ctx.v$.taxRateType.$model,
          "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.v$.taxRateType.$model) = $event)),
          options: _ctx.taxRateTypeOptions,
          optionLabel: "label",
          optionValue: "value",
          onChange: _cache[12] || (_cache[12] = $event => (_ctx.setDropdownValue('taxRateType', $event))),
          showClear: true,
          class: _normalizeClass({'p-invalid':_ctx.v$.taxRateType.$invalid && _ctx.submitted})
        }, null, 8, ["modelValue", "options", "class"]),
        ((_ctx.v$.taxRateType.$invalid && _ctx.submitted) || _ctx.v$.taxRateType.$pending.$response)
          ? (_openBlock(), _createElementBlock("small", _hoisted_19, _toDisplayString(_ctx.v$.taxRateType.required.$message.replace('Value',
                _ctx.$t('labels.productAdd.taxRateType'))), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.uvpPrice')), 1),
        _createVNode(_component_InputNumber, {
          mode: "decimal",
          locale: _ctx.locale,
          maxFractionDigits: 2,
          autocomplete: "off",
          modelValue: _ctx.v$.uvpPrice.$model,
          "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.v$.uvpPrice.$model) = $event)),
          class: _normalizeClass({'p-invalid':_ctx.v$.uvpPrice.$invalid && _ctx.submitted})
        }, null, 8, ["locale", "modelValue", "class"]),
        ((_ctx.v$.uvpPrice.$invalid && _ctx.submitted) || _ctx.v$.uvpPrice.$pending.$response)
          ? (_openBlock(), _createElementBlock("small", _hoisted_21, _toDisplayString(_ctx.v$.uvpPrice.required.$message.replace('The value',
                _ctx.$t('labels.productAdd.uvpPrice'))), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_22, [
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.sellFromDate')), 1),
        _createVNode(_component_p_calendar, {
          placeholder: _ctx.$t('labels.productHome.sellFromDate'),
          autocomplete: "off",
          dateFormat: "dd.mm.yy",
          modelValue: _ctx.v$.sellFromDate.$model,
          "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.v$.sellFromDate.$model) = $event)),
          class: _normalizeClass({'tt-form-input': true, 'p-invalid':_ctx.v$.sellFromDate.$invalid && _ctx.submitted})
        }, null, 8, ["placeholder", "modelValue", "class"])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.uvpStartDate')), 1),
        _createVNode(_component_p_calendar, {
          autocomplete: "off",
          dateFormat: "dd.mm.yy",
          modelValue: _ctx.v$.uvpStartDate.$model,
          "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => ((_ctx.v$.uvpStartDate.$model) = $event)),
          class: _normalizeClass({'tt-form-input': true, 'p-invalid':_ctx.v$.uvpStartDate.$invalid && _ctx.submitted})
        }, null, 8, ["modelValue", "class"]),
        ((_ctx.v$.uvpStartDate.$invalid && _ctx.submitted) || _ctx.v$.uvpStartDate.$pending.$response)
          ? (_openBlock(), _createElementBlock("small", _hoisted_25, _toDisplayString(_ctx.v$.uvpStartDate.required.$message.replace('The value',
                _ctx.$t('labels.productAdd.uvpStartDate'))), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_26, [
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.weeeClasification')), 1),
        _createVNode(_component_p_dropdown, {
          modelValue: _ctx.v$.articleWeee.$model,
          "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => ((_ctx.v$.articleWeee.$model) = $event)),
          options: _ctx.weeeOptions,
          optionLabel: "label",
          optionValue: "value",
          onChange: _cache[17] || (_cache[17] = $event => (_ctx.setDropdownValue('articleWeee', $event))),
          class: _normalizeClass({'p-invalid':_ctx.v$.articleWeee.$invalid && _ctx.submitted}),
          showClear: true
        }, null, 8, ["modelValue", "options", "class"])
      ]),
      _createElementVNode("div", _hoisted_28, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.warrantyTime')), 1),
        _createVNode(_component_p_dropdown, {
          modelValue: _ctx.v$.articleWarrantyTime.$model,
          "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => ((_ctx.v$.articleWarrantyTime.$model) = $event)),
          options: _ctx.warrantyTimeOptions,
          optionLabel: "label",
          optionValue: "value",
          onChange: _cache[19] || (_cache[19] = $event => (_ctx.setDropdownValue('articleWarrantyTime', $event))),
          class: _normalizeClass({'p-invalid':_ctx.v$.articleWarrantyTime.$invalid && _ctx.submitted}),
          showClear: ""
        }, null, 8, ["modelValue", "options", "class"])
      ])
    ]),
    _createElementVNode("div", _hoisted_29, [
      _createElementVNode("div", _hoisted_30, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.oversized')), 1),
        _createVNode(_component_p_checkbox, {
          modelValue: _ctx.v$.articleOversized.$model,
          "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => ((_ctx.v$.articleOversized.$model) = $event)),
          binary: "",
          class: "ml-2 mb-1"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_31, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.bulkGoods')), 1),
        _createVNode(_component_p_checkbox, {
          modelValue: _ctx.v$.articleBulkGoods.$model,
          "onUpdate:modelValue": _cache[21] || (_cache[21] = $event => ((_ctx.v$.articleBulkGoods.$model) = $event)),
          binary: "",
          class: "ml-2 mb-1"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.battg')), 1),
        _createVNode(_component_p_checkbox, {
          modelValue: _ctx.v$.articleBattg.$model,
          "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => ((_ctx.v$.articleBattg.$model) = $event)),
          binary: "",
          class: "ml-2 mb-1"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_33, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.sellOut')), 1),
        _createVNode(_component_p_checkbox, {
          modelValue: _ctx.v$.articleSellOut.$model,
          "onUpdate:modelValue": _cache[23] || (_cache[23] = $event => ((_ctx.v$.articleSellOut.$model) = $event)),
          binary: "",
          class: "ml-2 mb-1"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_34, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.dangerousGoods')), 1),
        _createVNode(_component_p_checkbox, {
          modelValue: _ctx.v$.articleDangerousGoods.$model,
          "onUpdate:modelValue": _cache[24] || (_cache[24] = $event => ((_ctx.v$.articleDangerousGoods.$model) = $event)),
          binary: "",
          class: "ml-2 mb-1"
        }, null, 8, ["modelValue"])
      ])
    ]),
    (_ctx.tagsPermissionAvailable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.tags')), 1),
            _createVNode(_component_Chips, {
              modelValue: _ctx.v$.tags.$model,
              "onUpdate:modelValue": _cache[25] || (_cache[25] = $event => ((_ctx.v$.tags.$model) = $event)),
              allowDuplicate: false,
              placeholder: _ctx.$t('labels.metatagsSeparatorInfo'),
              separator: ","
            }, null, 8, ["modelValue", "placeholder"])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["w-full mt-3 flex", {'justify-content-between' : !_ctx.hideBackButton, 'justify-content-end' : _ctx.hideBackButton }])
    }, [
      (!_ctx.hideBackButton)
        ? (_openBlock(), _createBlock(_component_p_button, {
            key: 0,
            severity: "danger",
            textContent: _toDisplayString(_ctx.$t('buttons.back')),
            onClick: _ctx.onBackButtonClicked
          }, null, 8, ["textContent", "onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_p_button, {
        severity: "success",
        type: "submit",
        textContent: _toDisplayString(_ctx.$t('buttons.next'))
      }, null, 8, ["textContent"])
    ], 2)
  ], 32))
}