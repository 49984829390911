<PrimeDialog
    :header="$t('messages.pleaseConfirm')"
    v-model:visible="showDialog"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '450px'}"
    modal
    :closable="false"
>
    <div class="confirmation-content">
        <div class="grid mb-1">
            <div class="col font-bold">Belegadresse/E-Mail</div>
        </div>
        <div class="grid mb-1" v-if="shopData?.billingAddress?.company">
            <div class="col text-gray-400 font-semibold">
                {{shopData?.billingAddress?.company}}
                <span v-if="shopData?.billingAddress?.department"
                    >, {{shopData?.billingAddress?.department}}</span
                >
            </div>
        </div>
        <div class="grid mb-1" v-if="shopData?.billingAddress?.firstName">
            <div class="col">
                {{shopData?.billingAddress?.salutation}}
                {{shopData?.billingAddress?.firstName}}
                {{shopData?.billingAddress?.lastName}}
            </div>
        </div>
        <div class="grid mb-1">
            <div class="col">
                {{shopData?.billingAddress?.street}},<span
                    v-if="shopData?.billingAddress?.additionalAddressLine1"
                    >,
                    {{shopData?.billingAddress?.additionalAddressLine1}}</span
                >
                {{shopData?.billingAddress?.zipcode}},
                {{shopData?.billingAddress?.city}},
                {{resolveCountryFromCode(shopData?.billingAddress?.country)}}
            </div>
        </div>
        <div class="grid mb-1" v-if="shopData?.billingAddress?.phoneNumber">
            <div class="col">{{shopData.billingAddress.phoneNumber}}</div>
        </div>
        <div class="grid mb-1">
            <div class="col">{{shopData.customer?.email}}</div>
        </div>
        <template v-if="shopData?.hasSameShippingAddress === false">
            <p-divider></p-divider>
            <div class="grid mb-1">
                <div class="col font-bold">
                    Abweichende Lieferadresse/E-Mail
                </div>
            </div>
            <div class="grid mb-1" v-if="shopData?.shippingAddress?.company">
                <div class="col text-gray-400 font-semibold">
                    {{shopData?.shippingAddress?.company}}
                    <span v-if="shopData?.shippingAddress?.department"
                        >, {{shopData?.shippingAddress?.department}}</span
                    >
                </div>
            </div>
            <div class="grid mb-1" v-if="shopData?.shippingAddress?.firstName">
                <div class="col">
                    {{shopData?.shippingAddress?.salutation}}
                    {{shopData?.shippingAddress?.firstName}}
                    {{shopData?.shippingAddress?.lastName}}
                </div>
            </div>
            <div class="grid mb-1">
                <div class="col">
                    {{shopData?.shippingAddress?.street}},<span
                        v-if="shopData?.shippingAddress?.additionalAddressLine1"
                        >,
                        {{shopData?.shippingAddress?.additionalAddressLine1}}</span
                    >
                    {{shopData?.shippingAddress?.zipcode}},
                    {{shopData?.shippingAddress?.city}},
                    {{resolveCountryFromCode(shopData?.shippingAddress?.country)}}
                </div>
            </div>
            <div
                class="grid mb-1"
                v-if="shopData?.shippingAddress?.phoneNumber"
            >
                <div class="col">{{shopData.shippingAddress.phoneNumber}}</div>
            </div>
            <div class="grid mb-1">
                <div class="col">{{shopData.customer?.email}}</div>
            </div></template
        >
    </div>
    <template #footer>
        <div class="w-full mt-3 flex justify-content-between">
            <p-button severity="danger" @click="handleAnswer(false, $event)">
                {{ $t("buttons.cancel") }}
            </p-button>
            <p-button severity="success" @click="handleAnswer(true, $event)">
                {{ $t("buttons.confirm") }}
            </p-button>
        </div>
    </template>
</PrimeDialog>
