import {computed, onMounted, ref} from 'vue';
import Button from 'primevue/button';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import ScrollPanel from 'primevue/scrollpanel';
import LoadingPlugin from 'vue-loading-overlay';
import Panel from 'primevue/panel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import store from '@/store';
import {getSingleOrder} from '@/services/shopware';
import {useRoute} from 'vue-router';
import {FilterMatchMode} from 'primevue/api';
import {
    getAllCustomers,
    getAllCustomersUsingHashedData,
    recalculateCustomerDataHashes
} from '@/services/parties';
import countries from 'i18n-iso-countries';
import {ILayoutConfig} from '@/interfaces/layout/config';
import {getSome} from '@/services/metadata';
import {DateTime} from 'luxon';
import {importShopOrder} from '@/services/import';
import Dialog from 'primevue/dialog';

import CreateOrderOverview from '@/components/dialog/import-wizard/shop-orders/create-order-overview.vue';
import ShopDataOverview from '@/components/import-wizard/shop-orders/shop-data-overview.vue';
import WeclappDataOverview from '@/components/import-wizard/shop-orders/weclapp-data-overview.vue';

export default {
    emits: ['reload-product', 'auto-save-description'],
    components: {
        ScrollPanel,
        'p-button': Button,

        Panel,
        loading: LoadingPlugin,
        DataTable,
        Column,
        PrimeDialog: Dialog,
        CreateOrderOverview,
        ShopDataOverview,
        WeclappDataOverview
    },
    setup() {
        const submitted = ref(false);
        const savingInProgress = ref(false);
        const shopData = ref(null);
        const potentialMatches = ref([]);
        const toast = useToast();
        const route = useRoute();
        const selectedWeclappCustomer = ref(null);
        const showAddButton = ref(true);
        const showResultDialog = ref(false);
        const importResult = ref(null);
        const displayConfirmationDialog = ref(false);

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) =>
                    item.value ===
                    (route.query?.platform || store.getters['auth/platform'])
            );
        });

        onMounted(() => {
            tryMatching();
        });

        const retryMatching = async () => {
            if (potentialMatches.value.length < 1) {
                tryMatching();
                return;
            }
            savingInProgress.value = true;
            await recalculateCustomerDataHashes(
                potentialMatches.value.map((item) => item.customerNumber)
            );
            tryMatching();
        };

        const tryMatching = () => {
            savingInProgress.value = true;
            getSingleOrder(
                route.params.id,
                route.query?.platform || resolvedPlatform.value?.value || null,
                true
            )
                .then(async (singleOrderData: any) => {
                    shopData.value = {
                        ...singleOrderData.data,
                        paymentMethodName:
                            singleOrderData.data?.paymentMethod?.name
                    };

                    let customerData = null;
                    let foundInAddressHash = false;

                    try {
                        customerData = await getAllCustomers(
                            {
                                first: 0,
                                rows: 5,
                                filters: {
                                    teltecShopCustomerNumber: {
                                        value: !['vdd', 'vdl'].includes(
                                            resolvedPlatform.value.threeLetterId
                                        )
                                            ? shopData.value?.customer
                                                  ?.customerNumber
                                            : null,
                                        matchMode: FilterMatchMode.CONTAINS
                                    },
                                    videodataShopCustomerNumber: {
                                        value: ['vdd', 'vdl'].includes(
                                            resolvedPlatform.value.threeLetterId
                                        )
                                            ? shopData.value?.customer
                                                  ?.customerNumber
                                            : null,
                                        matchMode: FilterMatchMode.CONTAINS
                                    },
                                    email: {
                                        value:
                                            singleOrderData.data?.customer
                                                ?.email || '',
                                        matchMode: FilterMatchMode.EQUALS
                                    },
                                    taxId: {
                                        value:
                                            singleOrderData.data?.customer
                                                ?.vatNumber || '',
                                        matchMode: FilterMatchMode.EQUALS
                                    }
                                },
                                filterConjunction: 'or'
                            },
                            route.query?.platform ||
                                resolvedPlatform.value?.value ||
                                null
                        );

                        if ((customerData?.data?.items || []).length < 1) {
                            const hashedMatches =
                                await getAllCustomersUsingHashedData(
                                    shopData.value?.billingAddress?.company ||
                                        null,
                                    shopData.value?.billingAddress?.street ||
                                        null,
                                    shopData.value?.billingAddress?.zipcode ||
                                        null,
                                    shopData.value?.billingAddress?.country ||
                                        null,
                                    route.query?.platform ||
                                        resolvedPlatform.value?.value ||
                                        null
                                );

                            if (
                                hashedMatches.data &&
                                hashedMatches.data.length > 0
                            ) {
                                customerData = await getAllCustomers(
                                    {
                                        first: 0,
                                        rows: 20,
                                        filters: {
                                            customerNumber: {
                                                value: hashedMatches.data,
                                                matchMode: FilterMatchMode.IN
                                            }
                                        }
                                    },
                                    route.query?.platform ||
                                        resolvedPlatform.value?.value ||
                                        null
                                );
                                foundInAddressHash = true;
                            }
                        }
                    } catch (error: any) {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    } finally {
                        savingInProgress.value = false;
                    }

                    potentialMatches.value = (
                        customerData?.data?.items || []
                    ).filter((party: any) => {
                        return (
                            (singleOrderData.data?.customer?.email || '-1') ===
                                party.email ||
                            (singleOrderData.data?.customer?.vatNumber ||
                                '-1') === party.taxId ||
                            (['ttd', 'ttl'].includes(
                                resolvedPlatform.value.threeLetterId
                            ) &&
                                (!party.teltecShopCustomerNumber ||
                                    party.teltecShopCustomerNumber.includes(
                                        shopData.value?.customer?.customerNumber
                                    ))) ||
                            (['vdd', 'vdl'].includes(
                                resolvedPlatform.value.threeLetterId
                            ) &&
                                (!party.videodataShopCustomerNumber ||
                                    party.videodataShopCustomerNumber.includes(
                                        shopData.value?.customer?.customerNumber
                                    )))
                        );
                    });

                    /*showAddButton.value =
                        potentialMatches.value.length < 1 ||
                        !potentialMatches.value.some((pot: any) => {
                            return (
                                (['ttd', 'ttl'].includes(
                                    resolvedPlatform.value.threeLetterId
                                ) &&
                                    (!pot.teltecShopCustomerNumber ||
                                        pot.teltecShopCustomerNumber ===
                                            shopData.value?.customer
                                                ?.customerNumber)) ||
                                (['vdd', 'vdl'].includes(
                                    resolvedPlatform.value.threeLetterId
                                ) &&
                                    (!pot.videodataShopCustomerNumber ||
                                        pot.videodataShopCustomerNumber ===
                                            shopData.value?.customer
                                                ?.customerNumber))
                            );
                        });*/

                    const contactIds: string[] = [];
                    potentialMatches.value.forEach((pot: any) => {
                        pot.contacts.forEach((cnt: any) => {
                            contactIds.push(cnt.id);
                        });
                    });

                    if (contactIds.length > 0) {
                        getSome(
                            'contact',
                            'id',
                            contactIds,
                            false,
                            [],
                            route.query?.platform ||
                                resolvedPlatform.value?.value ||
                                null
                        )
                            .then((contactData: any) => {
                                Object.values(contactData.data || {}).forEach(
                                    (cntdt: any) => {
                                        potentialMatches.value.forEach(
                                            (pot: any) => {
                                                const idx =
                                                    pot.contacts.findIndex(
                                                        (cnt: any) =>
                                                            cnt.id === cntdt.id
                                                    );
                                                if (idx !== -1) {
                                                    pot.contacts[idx] = {
                                                        ...cntdt
                                                    };
                                                }
                                            }
                                        );
                                    }
                                );
                            })
                            .catch((error) => {
                                toast.error(
                                    error.response?.data?.error || error.message
                                );
                            });
                    }

                    if (
                        potentialMatches.value.length === 1 &&
                        !foundInAddressHash
                    ) {
                        selectedWeclappCustomer.value =
                            potentialMatches.value[0];
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    savingInProgress.value = false;
                });
        };

        const resolveCountryFromCode = (countryCode: string) => {
            return (
                countries.getName(countryCode, i18n.global.locale) ||
                countries.getName(countryCode, 'en')
            );
        };

        const layoutConfig = computed(() => {
            return store.getters['ui/layoutConfig'] as ILayoutConfig;
        });

        const onChoiceConfirmed = (confirmed: boolean) => {
            displayConfirmationDialog.value = false;
            if (confirmed) {
                savingInProgress.value = true;
                importShopOrder(
                    selectedWeclappCustomer.value ? 're-use' : 'create',
                    shopData.value.number,
                    selectedWeclappCustomer.value?.id || null,
                    route.query?.platform ||
                        resolvedPlatform.value?.value ||
                        null
                )
                    .then((data: any) => {
                        importResult.value = data;
                        showResultDialog.value = true;
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );

                        importResult.value = null;
                    })
                    .finally(() => {
                        savingInProgress.value = false;
                    });
            }
        };

        const extractHouseNumberFromAddress = (street: string): string => {
            if (street.lastIndexOf(' ') !== -1) {
                return street.substring(street.lastIndexOf(' ')).trim();
            }

            if (street.lastIndexOf('.') !== -1) {
                return street.substring(street.lastIndexOf('.')).trim();
            }

            return '';
        };

        const addressMatch = (address: {
            street1: string;
            zipcode: string;
            countryCode: string;
        }) => {
            return (
                shopData.value?.billingAddress?.country ===
                    address.countryCode &&
                shopData.value?.billingAddress?.zipcode === address.zipcode &&
                (extractHouseNumberFromAddress(
                    shopData.value?.billingAddress?.street
                ) === extractHouseNumberFromAddress(address.street1) ||
                    shopData.value.billingAddress.street.substring(0, 8) ===
                        address.street1.substring(0, 8))
            );
        };

        const nonEmptyStringMatch = (
            string1: string | null,
            string2: string | null,
            containsMatchAllowed: boolean = false
        ): boolean => {
            if (
                (string1 || '').trim() === '' ||
                (string2 || '').trim() === ''
            ) {
                return false;
            }

            return (
                string1.trim() === string2.trim() ||
                (containsMatchAllowed &&
                    string2.trim().includes(string1.trim()))
            );
        };

        return {
            submitted,
            savingInProgress,
            shopData,
            potentialMatches,
            resolveCountryFromCode,
            showAddButton,
            selectedWeclappCustomer,
            rowClass: (data: any) => {
                if (data.id === selectedWeclappCustomer.value?.id) {
                    const colorVariant = layoutConfig.value?.darkTheme
                        ? 800
                        : 100;
                    return 'bg-blue-' + colorVariant;
                }
            },
            resolvedPlatform,
            getCurrencyFormatted: (
                rawValue: number,
                currency: string = 'EUR'
            ) => {
                const formatter = new Intl.NumberFormat(i18n.global.locale, {
                    style: 'currency',
                    currency
                });

                return formatter.format(rawValue);
            },
            getDateFormatted: (rawValue: number) => {
                return rawValue > 0
                    ? DateTime.fromSeconds(rawValue)
                          .setLocale(i18n.global.locale)
                          .setZone(process.env?.VUE_APP_DEFAULT_TIME_ZONE)
                          .toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
                    : '';
            },
            addressMatch,
            extractHouseNumberFromAddress,
            retryMatching,
            nonEmptyStringMatch,
            showResultDialog,
            importResult,
            displayConfirmationDialog,
            onChoiceConfirmed,
            onShopDataSaved: () => {
                retryMatching();
            }
        };
    }
};
