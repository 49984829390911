<LoadingPlugin v-model:active="loading" />
<DataTable
    class="p-datatable-sm text-sm"
    stripedRows
    paginator
    scrollable
    :scrollHeight="'calc(100vh - 36rem)'"
    :rows="10"
    lazy
    ref="dt"
    dataKey="id"
    :totalRecords="totalRecords"
    :value="products"
    responsiveLayout="scroll"
    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rows-per-page-options="[10,20,50,100,200]"
    current-page-report-template="{first} to {last} of {totalRecords}"
    v-model:filters="filters"
    filterDisplay="row"
    @page="onPage($event)"
    @filter="onFilter($event)"
    @sort="onSort($event)"
    v-model:expandedRows="expandedRows"
    v-model:selection="selectedProducts"
    @rowSelect="onRowSelect"
    @rowSelectAll="onRowSelectAll"
    @rowUnselect="onRowUnselect"
    @rowUnselectAll="onRowUnselectAll"
>
    <Column :expander="true" headerStyle="width: 3em" />
    <Column selectionMode="multiple" headerStyle="width: 3em"
        ><template #header>
            <Badge :value="mergedSelection.length"></Badge>
            <i
                v-if="mergedSelection.length > 0"
                class="pi pi-times-circle text-red-600"
                style="padding-left: 7px; cursor: pointer"
                @click="clearSelection"
            ></i
            ><i
                v-if="editAccessoriesPermissionAvailable && products && products.length > 0"
                class="pi pi-cog text-green-600"
                style="padding-left: 7px; cursor: pointer"
                @click="bulkMenuToggle"
                aria-haspopup="true"
                aria-controls="overlay_tmenu"
            ></i>
            <TieredMenu
                ref="bulkMenu"
                id="overlay_tmenu"
                :model="bulkItems"
                popup /></template
    ></Column>
    <Column
        field="articleNumber"
        :sortable="true"
        :header="$t('labels.articleNumber')"
        style="min-width: 6rem"
        :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
    >
        <template #filter="{filterModel,filterCallback}">
            <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter text-sm"
                :placeholder="$t('labels.articleNumber')"
            />
        </template>
        <template #body="{data}">
            <div class="flex align-content-center">
                <div
                    class="sales-channel-dot w-1rem h-1rem mr-1"
                    :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData && !data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.shopwareData && !data.shopwareData.active, 'bg-gray-200': !data?.shopwareData?.active}"
                ></div>
                <div
                    class="sales-channel-dot w-1rem h-1rem mr-1"
                    :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData && !data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.secondShopwareData && !data.secondShopwareData.active, 'bg-gray-200': !data?.secondShopwareData?.active}"
                ></div>
                <span style="vertical-align: middle"
                    ><a
                        :href="data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id"
                        target="_blank"
                        >{{data.articleNumber}}</a
                    ></span
                >
                <span
                    v-if="data.shopwareData && data.articleNumber !== data.shopwareData.productNumber"
                    class="pi pi-exclamation-triangle"
                    v-tooltip.top="$t('labels.inconsistentData') + ': ' + $t('labels.articleNumber')"
                ></span>
            </div>
        </template>
    </Column>
    <Column
        field="name"
        :sortable="true"
        style="min-width: 6rem"
        :header="$t('labels.name')"
    >
        <template #filter="{filterModel,filterCallback}">
            <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter text-sm"
                :placeholder="$t('labels.name')"
            />
        </template>
        <template #body="{data}">
            <span style="vertical-align: middle"
                >{{ data.name || data.shopwareData?.name }}</span
            >
            <span
                v-if="data.shopwareData && data?.name !== data?.shopwareData?.name"
                class="pi pi-exclamation-triangle"
                v-tooltip.top="$t('labels.inconsistentData') + ': ' + $t('labels.name')"
            ></span>
            <div class="mt-2">
                <Tag v-for="tag in data.tags" severity="info" class="mt-1 mr-1">
                    <span
                        >{{tag.toUpperCase().replace('TTC-PRODUCT-META-',
                        '')}}</span
                    >
                </Tag>
            </div>
        </template>
    </Column>
    <Column
        field="description"
        style="max-width: 20rem"
        :header="$t('labels.productDescription.webshopText')"
    >
        <template #filter="{filterModel,filterCallback}">
            <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter text-sm"
                :placeholder="$t('labels.productDescription.webshopText')"
            />
        </template>
        <template #body="{data}">
            <div @click="expandRow(data)" style="cursor: pointer">
                {{ stripTagsAndTruncate(data.shopwareData?.description, 400,
                '...') }}
            </div>
        </template>
    </Column>
    <Column field="productCategory" :header="$t('labels.articleCategory')">
        <template #filter="{filterModel,filterCallback}">
            <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter text-sm"
                :placeholder="$t('labels.articleCategory')"
            />
        </template>
        <template #body="{data}">
            <ul v-if="data?.shopwareData?.categories">
                <li v-for="element in data.shopwareData.categories">
                    {{ element.name }}
                </li>
            </ul>
        </template>
    </Column>
    <Column
        field="manufacturerName"
        :sortable="true"
        :header="$t('labels.manufacturer')"
    >
        <template #body="{data}">
            <span style="vertical-align: middle"
                >{{ data.manufacturerName || data.shopwareData?.manufacturer
                }}</span
            >
            <span
                v-if="data.shopwareData && (data.manufacturerName || data.shopwareData.manufacturer) && (data.manufacturerName !== data.shopwareData.manufacturer)"
                class="pi pi-exclamation-triangle"
                v-tooltip.top="$t('labels.inconsistentData') + ': ' + $t('labels.manufacturer')"
            ></span>
        </template>
    </Column>
    <Column
        :header="$t('labels.stock')"
        field="availableStock"
        style="min-width: 8rem"
        :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
    >
        <template #filter="{filterModel, filterCallback}">
            <TriStateCheckbox
                v-model="filterModel.value"
                @change="filterCallback()"
            />
        </template>
        <template #body="{data}">
            {{ data.shopwareData?.availableStock }}
        </template>
    </Column>
    <Column
        field="tags"
        :header="$t('labels.tags')"
        style="min-width: 10rem"
        :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
    >
        <template #filter="{filterModel,filterCallback}">
            <p-multiselect
                class="text-sm"
                v-model="filterModel.value"
                :options="availableProductTags"
                :maxSelectedLabels="3"
                optionLabel="name"
                optionValue="name"
                filter
                @change="filterCallback()"
                style="min-width: 5rem; max-width: 20rem"
            >
                <template #option="{option}">
                    {{option.name.toUpperCase().replace('TTC-PRODUCT-META-',
                    '')}}
                </template>
                <template #value="content">
                    {{(content.value || []).map((item) =>
                    item.toUpperCase().replace('TTC-PRODUCT-META-', ''))
                    .join(', ')}}
                </template>
            </p-multiselect>
        </template>
        <template #body="{data}">
            <ul v-if="data?.tags">
                <template v-for="tag in data.tags">
                    <li>
                        <span class="font-semibold"
                            >{{tag.toUpperCase().replace('TTC-PRODUCT-META-',
                            '')}}</span
                        >
                    </li>
                </template>
            </ul>
        </template>
    </Column>
    <Column style="min-width: 5rem" v-if="editAccessoriesPermissionAvailable">
        <template #body="{data}">
            <div style="cursor: pointer" @click="openReplaceDialog(data)">
                <i
                    class="fas fa-exchange-alt mr-1"
                    v-tooltip.left="$t('labels.replace')"
                ></i>
            </div>
        </template>
    </Column>

    <template #expansion="slotProps">
        <div
            v-if="slotProps.data?.shopwareData?.description || slotProps.data?.description"
            v-html="slotProps.data?.shopwareData?.description || slotProps.data?.description"
        ></div>
        <div v-else>No description available.</div>
    </template>
</DataTable>

<PrimeDialog
    :header="$t('labels.deleteSelected') + '...'"
    v-model:visible="showDeleteDialog"
    :breakpoints="{'960px': '75vw'}"
    :style="{width: '50vw'}"
    @hide="closeTransferDialog"
    :modal="true"
>
    <div class="card">
        <div class="card-body">
            <ProductList :products="selectedProducts" :platform="platform" />
            <div class="w-full mt-3 flex justify-content-between">
                <p-button severity="danger" @click="showDeleteDialog = false">
                    {{ $t("buttons.cancel") }}
                </p-button>
                <p-button @click="removeArticleAccessories" severity="success">
                    {{ $t("buttons.confirm") }}
                </p-button>
            </div>
        </div>
    </div>
</PrimeDialog>
<BulkTransferAccessories
    :products="selectedProducts"
    :displayResponsive="showTransferDialog"
    @close-dialog="closeTransferDialog"
    :platform="platform"
/>
<BulkTransferAccessoriesToOtherShop
    :product-details="productDetails"
    :accessories="selectedProducts.filter(item => item.secondShopwareData)"
    :displayResponsive="showTransferToOtherShopDialog"
    @close-dialog="closeTransferToOtherShopDialog"
    :platform="crossPlatform?.value"
/>
<ReplaceAccessory
    :oldAccessory="oldAccessory"
    :showReplaceDialog="showReplaceDialog"
    :platform="platform"
    @close-dialog="closeReplaceDialog"
/>
