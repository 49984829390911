import {computed, onUnmounted, ref, toRefs, watch} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Fieldset from 'primevue/fieldset';
import {required} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import {updateShopDescription} from '@/services/products';
import Editor from '@tinymce/tinymce-vue';
import InputSwitch from 'primevue/inputswitch';
import store from '@/store';
import ScrollPanel from 'primevue/scrollpanel';
import LoadingPlugin from 'vue-loading-overlay';

export default {
    emits: ['reload-product', 'auto-save-description'],
    components: {
        'p-fieldset': Fieldset,
        tiny: Editor,
        InputSwitch,
        ScrollPanel,
        'p-button': Button,
        InputText,
        loading: LoadingPlugin
    },
    props: {
        shopwareDetails: Object,
        platform: {
            type: String,
            default: null
        }
    },
    setup(props: any, context: any) {
        onUnmounted(() => {
            if (autoSaveInterval.value !== null) {
                clearInterval(autoSaveInterval.value);
            }
        });

        const submitted = ref(false);
        const shopwareDetails = ref(null);
        const savingInProgress = ref(false);
        const toast = useToast();
        const autoSave = ref(store.getters['auth/autoSaveProductDescription']);
        const autoSaveInterval = ref(null);
        const {platform} = toRefs(props);

        const autoSaveBody = async () => {
            if (!v$.value || v$.value.$invalid) {
                return;
            }

            context.emit(
                'auto-save-description',
                shopwareDetails.value?.productNumber,
                state.value,
                platform.value
            );
        };

        if (autoSave.value) {
            if (autoSaveInterval.value !== null) {
                clearInterval(autoSaveInterval.value);
            }
            autoSaveInterval.value = setInterval(autoSaveBody, 10000);
        }

        const state = ref({
            webshopText: '',
            longDescription: null
        });

        const rules = {
            webshopText: {required},
            longDescription: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, () => {
            shopwareDetails.value = props.shopwareDetails;
            state.value.webshopText = props.shopwareDetails?.description;
            state.value.longDescription = isShopwareSix.value
                ? props.shopwareDetails?.longDescription || null
                : null;
        });

        const isShopwareSix = computed(() => {
            return (
                ['vdd', 'vdl'].includes(
                    resolvedPlatform.value?.threeLetterId
                ) || ['videodata', 'videodata-demo'].includes(platform.value)
            );
        });

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            updateShopDescription(
                shopwareDetails.value?.productNumber,
                state.value,
                platform.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    v$.value.$reset();
                    context.emit('reload-product');
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        const toogleAutoSave = () => {
            store.dispatch(
                'auth/setAutoSaveProductDescription',
                autoSave.value
            );

            if (autoSaveInterval.value !== null) {
                clearInterval(autoSaveInterval.value);
            }

            if (autoSave.value) {
                autoSaveInterval.value = setInterval(autoSaveBody, 10000);
            }
        };

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );
        });

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            autoSave,
            toogleAutoSave,
            resolvedPlatform,
            isShopwareSix,
            tinyUrl: process.env?.VUE_APP_TINYMCE_URL
        };
    }
};
