import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7da3004e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid p-fluid" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "grid p-fluid" }
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = { class: "w-full mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_tiny = _resolveComponent("tiny")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('labels.ticket.retoure.createCompensationShipment'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[5] || (_cache[5] = $event => ((_ctx.showDialog) = $event)),
    closable: false,
    breakpoints: {'960px': '75vw', '640px': '90vw'},
    style: {width: '640px'},
    modal: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_loading, {
        active: _ctx.savingInProgress,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.savingInProgress) = $event))
      }, null, 8, ["active"]),
      _createElementVNode("form", {
        onSubmit: _cache[4] || (_cache[4] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.shipment.warehouse')), 1),
            _createVNode(_component_p_dropdown, {
              modelValue: _ctx.v$.warehouse.$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.v$.warehouse.$model) = $event)),
              options: _ctx.warehouses || [],
              optionLabel: "name",
              optionValue: "id",
              filter: true,
              class: _normalizeClass(["w-full", {'p-invalid':_ctx.v$.warehouse.$invalid && _ctx.submitted}]),
              onChange: _cache[2] || (_cache[2] = $event => (_ctx.setDropdownValue('warehouse', $event))),
              showClear: true
            }, null, 8, ["modelValue", "options", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.warehouse.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.deliveryNote.documentText')), 1),
            _createVNode(_component_tiny, {
              tinymceScriptSrc: _ctx.tinyUrl,
              modelValue: _ctx.v$.documentText.$model,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.v$.documentText.$model) = $event)),
              init: {
                        height: 300,
                        menubar: false,
                        plugins: 'anchor charmap paste template',
                        toolbar: 'undo redo | template | pastetext removeformat',
                        templates : _ctx.tinyTemplates,
                        template_replace_values: {
                            'ticketOwner': _ctx.ticketDetails?.customer?.company ||
                                    (_ctx.ticketDetails?.customer?.firstName +
                                ' ' +
                                _ctx.ticketDetails?.customer?.lastName),
                            'ticketNumber': _ctx.ticketDetails.ticketNumber,
                        },
                        browser_spellcheck: true
                    }
            }, null, 8, ["tinymceScriptSrc", "modelValue", "init"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$.documentText.$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_p_button, {
            severity: "danger",
            onClick: _ctx.onCancelClick
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_p_button, {
            type: "submit",
            severity: "success"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
            ]),
            _: 1
          })
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}