<DataTable
    class="p-datatable-sm text-sm"
    stripedRows
    paginator
    :rows="10"
    :scrollable="scrollableHeight !== null"
    :scrollHeight="scrollableHeight"
    ref="dt"
    dataKey="id"
    :value="productsExtended"
    responsiveLayout="scroll"
    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rows-per-page-options="[10,20,50,100,200]"
    current-page-report-template="{first} to {last} of {totalRecords}"
    v-model:filters="filters"
    filterDisplay="row"
>
    <template v-if="tableHeader" #header>
        <div class="table-header">{{ tableHeader }}</div>
    </template>
    <Column
        v-if="selectionMode"
        :selectionMode="selectionMode"
        headerStyle="width: 3em"
    ></Column>
    <Column
        field="articleNumber"
        :sortable="true"
        :header="$t('labels.articleNumber')"
        style="min-width: 10rem"
        :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
    >
        <template #filter="{filterModel,filterCallback}">
            <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter text-xs"
                placeholder="#"
            />
        </template>
        <template #body="{data}">
            <div class="flex align-content-center">
                <slot
                    name="product-platfrom-circle"
                    v-bind="{data, resolvedPlatform}"
                >
                    <div
                        class="sales-channel-dot w-1rem h-1rem mr-1"
                        :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.resolvedShopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.resolvedShopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId) && data?.resolvedShopwareData && !data.resolvedShopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId) && data?.resolvedShopwareData && !data.resolvedShopwareData.active, 'bg-gray-200': !data?.resolvedShopwareData?.active}"
                    ></div>
                </slot>
                <span style="vertical-align: middle" v-if="data"
                    ><a
                        :href="data.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id"
                        target="_blank"
                        >{{data.articleNumber}}</a
                    ></span
                >
                <span
                    v-if="data?.resolvedShopwareData && data.articleNumber !== data.resolvedShopwareData.productNumber"
                    class="pi pi-exclamation-triangle"
                    v-tooltip.top="$t('labels.inconsistentData') + ': ' + $t('labels.articleNumber')"
                ></span>
            </div>
        </template>
    </Column>
    <Column
        field="nameCombo"
        :sortable="true"
        style="min-width: 12rem"
        :header="$t('labels.name')"
        :filterMatchModeOptions="[{label: 'Contains', value: 'contains'}]"
    >
        <template #filter="{filterModel,filterCallback}">
            <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter text-xs"
                :placeholder="$t('labels.name')"
            />
        </template>
        <template #body="{data}">
            <span style="vertical-align: middle" v-if="data"
                >{{ data.name || data.resolvedShopwareData?.name }}</span
            >
            <span
                v-if="data?.resolvedShopwareData && data?.name !== data?.resolvedShopwareData?.name"
                class="pi pi-exclamation-triangle"
                v-tooltip.top="$t('labels.inconsistentData') + ': ' + $t('labels.name')"
            ></span>
            <div class="mt-2">
                <Tag v-for="tag in data.tags" severity="info" class="mt-1 mr-1">
                    <span
                        >{{tag.toUpperCase().replace('TTC-PRODUCT-META-',
                        '')}}</span
                    >
                </Tag>
            </div>
        </template>
    </Column>
    <Column
        field="description"
        style="max-width: 30rem"
        :header="$t('labels.productDescription.webshopText')"
    >
        <template #body="{data}">
            <div v-if="data">
                {{ stripTagsAndTruncate(data.resolvedShopwareData?.description,
                200, '...') }}
            </div>
        </template>
    </Column>
    <Column
        field="productCategory"
        :header="$t('labels.articleCategory')"
        style="min-width: 12rem"
    >
        <template #body="{data}">
            <ul v-if="data?.resolvedShopwareData?.categories">
                <li v-for="element in data.resolvedShopwareData.categories">
                    {{ element.name }}
                </li>
            </ul>
        </template>
    </Column>
    <Column
        field="manufacturerName"
        :sortable="true"
        :header="$t('labels.manufacturer')"
        style="min-width: 12rem"
    >
        <template #body="{data}">
            <span style="vertical-align: middle" v-if="data"
                >{{ data.manufacturerName ||
                data.resolvedShopwareData?.manufacturer }}</span
            >
            <span
                v-if="data?.resolvedShopwareData && (data.manufacturerName || data.resolvedShopwareData.manufacturer) && (data.manufacturerName !== data.resolvedShopwareData.manufacturer)"
                class="pi pi-exclamation-triangle"
                v-tooltip.top="$t('labels.inconsistentData') + ': ' + $t('labels.manufacturer')"
            ></span>
        </template>
    </Column>
    <Column
        :header="$t('labels.stock')"
        field="availableStock"
        style="min-width: 8rem"
        :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
    >
        <template #filter="{filterModel, filterCallback}">
            <TriStateCheckbox
                v-model="filterModel.value"
                @change="filterCallback()"
            />
        </template>
        <template #body="{data}">
            {{ data?.resolvedShopwareData?.availableStock }}
        </template>
    </Column>
    <Column
        field="tags"
        :header="$t('labels.tags')"
        style="min-width: 10rem"
        :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
    >
        <template #filter="{filterModel,filterCallback}">
            <p-multiselect
                class="text-sm"
                v-model="filterModel.value"
                :options="availableProductTags"
                :maxSelectedLabels="3"
                optionLabel="name"
                optionValue="name"
                filter
                @change="filterCallback()"
                style="min-width: 5rem; max-width: 20rem"
            >
                <template #option="{option}">
                    {{option.name.toUpperCase().replace('TTC-PRODUCT-META-',
                    '')}}
                </template>
                <template #value="content">
                    {{(content.value || []).map((item) =>
                    item.toUpperCase().replace('TTC-PRODUCT-META-', ''))
                    .join(', ')}}
                </template>
            </p-multiselect>
        </template>
        <template #body="{data}">
            <ul v-if="data?.tags">
                <template v-for="tag in data.tags">
                    <li>
                        <span class="font-semibold"
                            >{{tag.toUpperCase().replace('TTC-PRODUCT-META-',
                            '')}}</span
                        >
                    </li>
                </template>
            </ul>
        </template>
    </Column>
</DataTable>
