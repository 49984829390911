import Dialog from 'primevue/dialog';
import {ref, watch} from 'vue';
import Button from 'primevue/button';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import {remove} from '@/services/product-documents';

export default {
    emits: ['close-dialog'],
    components: {
        'p-dialog': Dialog,
        'p-button': Button
    },
    props: {
        displayResponsive: Boolean,
        selectedDocument: Object,
        productNumber: String,
        platform: {
            type: String,
            default: null
        }
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const platform = ref(null);
        const productNumber = ref(null);
        const selectedDocument = ref(null);
        const toast = useToast();
        const savingInProgress = ref(false);

        watch(props, (args) => {
            showDialog.value = args.displayResponsive;
            productNumber.value = args.productNumber;
            selectedDocument.value = props.selectedDocument;
            if (args.platform) {
                platform.value = args.platform;
            }
        });

        const closeDialog = (forceRefresh: boolean = false) => {
            savingInProgress.value = false;
            context.emit('close-dialog', forceRefresh);
        };

        const confirmDelete = () => {
            savingInProgress.value = true;
            remove(
                productNumber.value,
                selectedDocument.value.productMediaId,
                platform.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully')
                    );
                    closeDialog(true);
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                    closeDialog();
                });
        };

        return {
            showDialog,
            closeDialog,
            confirmDelete,
            selectedDocument,
            savingInProgress
        };
    }
};
