import {computed, onMounted, ref, watch} from 'vue';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import Fieldset from 'primevue/fieldset';
import Dropdown from 'primevue/dropdown';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';
import {getAll} from '@/services/metadata';
import {stripTagsAndTruncate} from '@/utils/helpers';
import {helpers, required} from '@vuelidate/validators';
import countries from 'i18n-iso-countries';
import Chips from 'primevue/chips';
import store from '@/store';

export default {
    emits: [
        'back-button-clicked',
        'next-button-clicked',
        'add-wizard-form-dirty'
    ],
    props: {
        pairValues: {
            type: Object,
            default: null
        }
    },
    components: {
        InputText,
        'p-button': Button,
        'p-checkbox': Checkbox,
        'p-calendar': Calendar,
        'p-fieldset': Fieldset,
        'p-dropdown': Dropdown,
        InputNumber,
        Chips
    },
    setup(props: any, context: any) {
        const pairValues = ref(null);
        const submitted = ref(false);
        const serverData = ref({});

        onMounted(() => {
            getAll(
                ['articleCategory', 'manufacturer', 'customsTariffNumber'],
                false
            ).then((data: any) => {
                const test = data.data['customsTariffNumber'].map(
                    (item: {
                        id: string;
                        name: string;
                        description: string | null;
                    }) => {
                        return {
                            name: item.description
                                ? stripTagsAndTruncate(
                                      item.description,
                                      100,
                                      '...'
                                  ) +
                                  ' ( ' +
                                  item.name +
                                  ' ) '
                                : item.name,
                            id: item.id
                        };
                    }
                );
                data.data['customsTariffNumber'] = test;
                serverData.value = data.data;
            });
        });

        const state = ref({
            countryOfOrigin: null,
            matchCode: null,
            vkPrice: null,
            vkStartDate: new Date(),
            procurementLeadDays: null,
            minimumStockQuantity: null,
            targetStockQuantity: null,
            articleCategory: null,
            manufacturer: null,
            customsTariffNumber: null,
            serialNumberRequired: null,
            serialNumberIntern: false,
            articleWidth: null,
            articleHeight: null,
            articleLength: null,
            articleNetWeight: null,
            articleGrossWeight: null,
            articleMetatags: []
        });

        const rules = computed(() => {
            const localRules: any = {};

            const platform = store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );

            /*['procurementLeadDays'].forEach((item: string) => {
                    const key = item + '-' + platform;
                    localRules[key as keyof typeof localRules] = {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    };
                });*/
            /*localRules['vkPrice-' + platform] = {
                    required: requiredIf(() => {
                        return state.value['vkStartDate-' + platform] !== null;
                    })
                };

                localRules['vkStartDate-' + platform] = {
                    required: requiredIf(() => {
                        return state.value['vkPrice-' + platform] !== null;
                    })
                };*/

            [
                'customsTariffNumber',
                'countryOfOrigin',
                'serialNumberRequired'
            ].forEach((item: string) => {
                localRules[item as keyof typeof localRules] =
                    platform?.threeLetterId === 'ttl' ||
                    platform?.threeLetterId === 'ttd'
                        ? {
                              required: helpers.withMessage(
                                  i18n.global.t('messages.valueIsRequired'),
                                  required
                              )
                          }
                        : {};
            });

            [
                'manufacturer',
                'vkPrice',
                'vkStartDate',
                'matchCode',
                'minimumStockQuantity',
                'targetStockQuantity',
                'articleCategory',
                //'customsTariffNumber',
                //'serialNumberRequired',
                'serialNumberIntern',
                'articleWidth',
                'articleHeight',
                'articleLength',
                'articleNetWeight',
                'articleGrossWeight',
                'articleMetatags',
                'procurementLeadDays'
            ].forEach((item: string) => {
                localRules[item as keyof typeof localRules] = {};
            });

            return localRules;
        });

        const v$ = useVuelidate(rules, state);

        const prePopulateForm = () => {
            const platform = store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );

            state.value['serialNumberRequired'] =
                platform?.threeLetterId === 'vdl' ||
                platform?.threeLetterId === 'vdd'
                    ? true
                    : state.value['serialNumberRequired'];

            state.value['vkPrice'] =
                pairValues.value?.vkPrice || state.value['vkPrice'];

            state.value['countryOfOrigin'] =
                pairValues.value?.countryOfOriginCode || null;

            state.value['articleCategory'] =
                pairValues.value?.articleCategoryId || null;

            state.value['customsTariffNumber'] =
                pairValues.value?.customsTariffNumberId || null;

            state.value['manufacturer'] =
                pairValues.value?.manufacturerId || null;

            state.value['procurementLeadDays'] =
                pairValues.value?.procurementLeadDays || null;

            state.value['minimumStockQuantity'] =
                pairValues.value?.minimumStockQuantity || 0;

            state.value['targetStockQuantity'] =
                pairValues.value?.targetStockQuantity || 0;

            state.value['matchCode'] = pairValues.value?.matchCode || null;

            if (
                pairValues.value &&
                typeof pairValues.value.serialNumberRequired !== 'undefined'
            ) {
                state.value['serialNumberRequired'] =
                    !!pairValues.value?.serialNumberRequired;
            }

            if (
                pairValues.value &&
                typeof pairValues.value.customAttributes !== 'undefined'
            ) {
                state.value['serialNumberIntern'] =
                    !!pairValues.value?.customAttributes?.SN_AUTO_GENERATE;
            }

            state.value['articleGrossWeight'] =
                pairValues.value?.articleGrossWeight || null;

            state.value['articleNetWeight'] =
                pairValues.value?.articleNetWeight || null;

            state.value['articleLength'] = pairValues.value?.articleLength
                ? pairValues.value?.articleLength * 100
                : null;

            state.value['articleWidth'] = pairValues.value?.articleWidth
                ? pairValues.value?.articleWidth * 100
                : null;

            state.value['articleHeight'] = pairValues.value?.articleHeight
                ? pairValues.value?.articleHeight * 100
                : null;

            for (const key in state.value) {
                if (
                    Object.keys(pairValues.value).includes(key) &&
                    (!key.startsWith('vkStartDate') ||
                        pairValues.value[key] > 0) &&
                    !key.startsWith('customsTariffNumber')
                ) {
                    state.value[key as keyof typeof state.value] =
                        pairValues.value[key];
                }
            }
        };

        watch(props, (args) => {
            if (args.pairValues && pairValues.value === null) {
                pairValues.value = args.pairValues;
                prePopulateForm();
            }
        });

        watch(v$, (args) => {
            if (args.$anyDirty) {
                context.emit('add-wizard-form-dirty', {});
            }
        });

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            context.emit('next-button-clicked', {
                stepIndex: 3,
                formValues: state.value
            });
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;
            Object.assign(state.value, temp);
        };

        const onBackButtonClicked = (event: any) => {
            event.preventDefault();
            context.emit('back-button-clicked', 3);
        };

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );
        });

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            setDropdownValue,
            onBackButtonClicked,
            serverData,
            locale: i18n.global.locale,
            countryOptions: Object.entries(
                countries.getNames(i18n.global.locale) ||
                    countries.getNames('en')
            ).map((item) => {
                return {
                    label: item[1],
                    value: item[0]
                };
            }),
            resolvedPlatform
        };
    }
};
