import {computed, ref, watch} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Fieldset from 'primevue/fieldset';
import {maxLength, required} from '@vuelidate/validators';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';
import {useToast} from 'vue-toastification';
import {updateWeclappDescription} from '@/services/products';
import Editor from '@tinymce/tinymce-vue';
import InputSwitch from 'primevue/inputswitch';
import ConfirmLeave from '@/components/dialog/general/confirm-leave.vue';
import ScrollPanel from 'primevue/scrollpanel';
import LoadingPlugin from 'vue-loading-overlay';

export default {
    emits: ['reload-product', 'auto-save-description'],
    components: {
        'p-fieldset': Fieldset,
        tiny: Editor,
        ConfirmLeave: ConfirmLeave,
        InputSwitch,
        ScrollPanel,
        'p-button': Button,
        InputText,
        loading: LoadingPlugin
    },
    props: {
        weclappDetails: Object
    },
    setup(props: any, context: any) {
        const submitted = ref(false);
        const weclappDetails = ref(null);
        const savingInProgress = ref(false);
        const toast = useToast();

        const state = ref({
            shortDescription: '',
            longDescription: ''
        });

        const rules = {
            shortDescription: {required, maxLength: maxLength(100)},
            longDescription: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, () => {
            weclappDetails.value = props.weclappDetails;
            state.value.shortDescription =
                props.weclappDetails?.shortDescription1;
            state.value.longDescription = props.weclappDetails?.description;
        });

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            updateWeclappDescription(
                weclappDetails.value?.articleNumber,
                state.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    v$.value.$reset();
                    context.emit('reload-product');
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        const onKeyUp = (event: any) => {
            const newValue = event.target.value;
            v$.value.shortDescription.$model = null;
            v$.value.shortDescription.$model = newValue;
        };

        const shortDescriptionCharactersLeft = computed(() => {
            return (
                100 -
                (v$.value.shortDescription.$model
                    ? v$.value.shortDescription.$model.length
                    : 0)
            );
        });

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            savingInProgress,
            shortDescriptionCharactersLeft,
            onKeyUp,
            tinyUrl: process.env?.VUE_APP_TINYMCE_URL
        };
    }
};
