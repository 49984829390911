<loading v-model:active="loading" />
<Panel>
    <template #header>
        <div class="w-full flex justify-content-start">
            <div class="flex align-items-center">
                <span class="font-bold">
                    {{$t('labels.supplierConfiguration')}}
                </span>
                <p-button
                    class="ml-3"
                    @click="displayAddDialog = true"
                    size="small"
                    severity="success"
                >
                    {{ $t("buttons.addNewGeneric") }}
                </p-button>
            </div>
        </div></template
    >
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        paginator
        :rows="10"
        scrollable
        scrollHeight="calc(100vh - 25rem)"
        ref="dt"
        dataKey="id"
        :totalRecords="totalRecords"
        :value="logs"
        v-model:editingRows="editingRows"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="row"
        editMode="row"
        @row-edit-save="onRowEditComplete"
    >
        <Column
            field="supplierName"
            style="max-width: 10rem"
            :header="$t('labels.productAdd.supplier')"
            sortable
            ><template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                />
            </template>
            <template #body="{data}">
                {{data.supplierName}}
                <span v-if="data.supplierId"
                    >[<a
                        :href="data.url + 'webapp/view/party/PartyDetail.page?entityId=' + data.supplierId"
                        target="_blank"
                        >{{data.supplierNumber}}</a
                    >]</span
                >
                <span v-else> [{{data.supplierNumber}}]</span>
            </template>
        </Column>
        <Column
            field="costPriceInGross"
            dataType="boolean"
            sortable
            style="max-width: 6rem"
            header="EK als Bruttopreis in der PL"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #editor="{ data, field }">
                <p-checkbox v-model="data[field]" binary> </p-checkbox>
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.costPriceInGross, 'text-red-600 pi-times-circle': !(data.costPriceInGross)}"
                ></i>
            </template>
        </Column>
        <Column
            field="listPriceInGross"
            dataType="boolean"
            sortable
            style="max-width: 6rem"
            header="UVP als Bruttopreis in der PL"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #editor="{ data, field }">
                <p-checkbox v-model="data[field]" binary> </p-checkbox>
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.listPriceInGross, 'text-red-600 pi-times-circle': !(data.listPriceInGross)}"
                ></i>
            </template>
        </Column>
        <Column
            field="trailingDiscountPossible"
            dataType="boolean"
            sortable
            style="max-width: 6rem"
            header="NSR möglich"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #editor="{ data, field }">
                <p-checkbox v-model="data[field]" binary> </p-checkbox>
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.trailingDiscountPossible, 'text-red-600 pi-times-circle': !(data.trailingDiscountPossible)}"
                ></i>
            </template>
        </Column>
        <Column
            field="trailingDiscountPercentage"
            style="min-width: 8rem"
            :header="$t('labels.priceImport.trailingDiscountPercentage')"
            ><template #editor="{ data, field }">
                <InputNumber
                    v-model="data[field]"
                    suffix=" %"
                    mode="decimal"
                    :locale="locale"
                    :maxFractionDigits="3"
                    class="p-column-filter text-sm"
                />
            </template>
            <template #body="{data}">
                <div v-if="data.trailingDiscountPercentage">
                    {{formatter.format(data.trailingDiscountPercentage)}} %
                </div>
            </template>
        </Column>
        <Column
            field="automaticImportActive"
            dataType="boolean"
            sortable
            style="max-width: 6rem"
            header="Automatischer Import"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #editor="{ data, field }">
                <p-checkbox v-model="data[field]" binary> </p-checkbox>
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.automaticImportActive, 'text-red-600 pi-times-circle': !(data.automaticImportActive)}"
                ></i>
            </template>
        </Column>
        <Column style="max-width: 12rem" :header="$t('labels.discountGroups')">
            <template #body="{data}">
                <span v-for="element in data.discountGroups">
                    <Tag
                        class="ml-1 mb-1"
                        style="text-transform: uppercase"
                        :severity="element?.name ? 'primary': 'warning'"
                        >{{element?.name ? element?.name + ' : ' :
                        ''}}{{element?.percentage}}% {{element.manufacturerName
                        ? ('(' + element.manufacturerName + ')') : '' }}
                        <i
                            class="pi pi-trash text-xs ml-2"
                            @click="removeDiscountGroup(data.id, element)"
                            style="cursor: pointer"
                        ></i
                    ></Tag>
                </span>
                <div
                    style="cursor: pointer"
                    @click="selectedConfiguration = data.id.toString();displayAddDiscountGroupDialog = true"
                >
                    <i
                        class="pi pi-plus-circle ml-1 text-green-600"
                        style="margin-top: 2px"
                    ></i>
                </div>
            </template>
        </Column>

        <Column
            field="importConfirmationPercentageThreshold"
            style="max-width: 10rem"
            header="Schwellenwert für die manuelle Verarbeitungsbestätigung (EK/UVP Diff > ±15%)"
            sortable
            ><template #editor="{ data, field }">
                <InputNumber
                    v-model="data[field]"
                    mode="decimal"
                    :locale="locale"
                    :maxFractionDigits="3"
                    class="p-column-filter text-sm"
                    inputClass="w-8rem"
                />
            </template>
            <template #body="{data}">
                <div v-if="data.importConfirmationPercentageThreshold">
                    {{formatter.format(data.importConfirmationPercentageThreshold)}}
                    %
                </div>
            </template>
        </Column>
        <Column
            field="forceSellPriceToListPrice"
            dataType="boolean"
            sortable
            style="max-width: 6rem"
            header="VK = UVP"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel, filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #editor="{ data, field }">
                <p-checkbox v-model="data[field]" binary> </p-checkbox>
            </template>
            <template #body="{data}">
                <i
                    class="pi"
                    :class="{'text-green-600 pi-check-circle': data.forceSellPriceToListPrice, 'text-red-600 pi-times-circle': !(data.forceSellPriceToListPrice)}"
                ></i>
            </template>
        </Column>

        <Column
            rowEditor
            style="width: 10%; min-width: 5rem"
            bodyStyle="text-align:center"
        ></Column>
    </DataTable>
    <Crons :cron-type="'price-import'" :reloadRequired="reloadRequired"></Crons>
</Panel>
<AddSupplierDialog
    :displayResponsive="displayAddDialog"
    @close-dialog="closeDialogListener"
/>
<AddDiscountGroupDialog
    :displayResponsive="displayAddDiscountGroupDialog"
    :supplier-configuration-id="selectedConfiguration"
    @close-dialog="closeDialogListener"
/>
