<loading v-model:active="loading" />

<div class="mt-2">
    <div class="flex align-items-center mb-3 mt-3">
        <div class="flex-none flex mr-5">
            <p-button
                class="text-sm"
                severity="success"
                @click="displayAddDialog = true;"
                ><div class="flex align-content-center">
                    <span
                        class="p-button-icon p-button-icon-left pi pi-plus"
                        data-pc-section="icon"
                    ></span
                    ><span class="p-button-label"
                        >{{$t('buttons.addNewGeneric')}}
                    </span>
                    <span
                        v-if="productDetails?.shopware"
                        class="sales-channel-dot w-1rem h-1rem ml-2"
                        :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                    ></span>
                    <span
                        v-if="productDetails?.secondShopware"
                        class="sales-channel-dot w-1rem h-1rem ml-1"
                        :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
                    ></span></div
            ></p-button>
        </div>

        <div class="flex flex-grow-1">
            <div
                v-bind="getRootProps()"
                class="import-wizard-container font-medium text-sm flex-grow-1 flex"
            >
                <input v-bind="getInputProps()" />
                <p v-if="acceptedFiles.length > 0" class="text-center w-full">
                    {{ dropZoneText }}
                </p>
                <p v-else class="text-center w-full">
                    {{ $t("labels.importWizardInfoText") }}
                </p>
            </div>
        </div>

        <div v-if="showUploadConfirmButton" class="flex-none flex ml-5">
            <p-button
                class="p-button-sm p-button-success"
                :label="$t('buttons.confirm')"
                @click="handleUpload"
            ></p-button>

            <p-button
                style="margin-left: 5px"
                class="p-button-sm p-button-danger"
                :label="$t('buttons.cancel')"
                @click="acceptedFiles.length = 0"
            ></p-button>
        </div>
    </div>
</div>

<TabView class="tabview-custom text-sm">
    <TabPanel :disabled="!productDetails?.shopware">
        <template #header>
            <i class="pi pi-images"></i>
            <span
                class="sales-channel-dot w-1rem h-1rem ml-2"
                :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
            ></span>
        </template>
        <Images
            :shopware-details="productDetails?.shopware"
            @reload-product="loadProductDetails"
        ></Images>
    </TabPanel>
    <TabPanel :disabled="!productDetails?.secondShopware">
        <template #header>
            <i class="pi pi-images"></i>
            <span
                class="sales-channel-dot w-1rem h-1rem ml-2"
                :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
            ></span>
        </template>
        <Images
            :shopware-details="productDetails?.secondShopware"
            @reload-product="loadProductDetails"
            :platform="secondPlatform"
        ></Images>
    </TabPanel>
</TabView>
<AddDialog
    :displayResponsive="displayAddDialog"
    :product-number="productDetails?.shopware?.productNumber"
    @close-dialog="closeDialogListener"
/>
