import {useDropzone} from 'vue3-dropzone';
import {
    saveFiles,
    runValidation,
    getFileTempate,
    removeFile
} from '@/services/import';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import TreeSelect from 'primevue/treeselect';
import Dialog from 'primevue/dialog';
import {ref, computed, onMounted, watch} from 'vue';
import {useToast} from 'vue-toastification';
import Loading from 'vue-loading-overlay';
import ValidationGrid from '@/components/grid/import-wizard/validation-grid.vue';
import HeaderMappingGrid from '@/components/grid/import-wizard/header-mapping-grid.vue';
import {i18n} from '@/utils/i18n';
import router from '@/router';
import FileSaver from 'file-saver';
import {evaluateChildNodes, wizardOptions} from '@/utils/import-wizard/options';
import store from '@/store';
import Panel from 'primevue/panel';

export default {
    name: 'ImportWizard',
    components: {
        'p-button': Button,
        InputText,
        loading: Loading,
        ValidationGrid,
        HeaderMappingGrid,
        TreeSelect,
        PrimeDialog: Dialog,
        Panel
    },
    setup() {
        const toast = useToast();
        const identifier = ref(null);
        const acceptFiles = ref([]);
        const importResult = ref(false);

        const savedFileId = ref(null);

        const rowsToSkip = ref([]);

        const isLoading = ref(false);
        const fileScheduledForImportTextPanel = ref(null);

        const showPlatformConfirmationDialog = ref(false);

        const confirmationInputField = ref(null);
        const confirmationInputValue = ref('');

        onMounted(() => {
            fileScheduledForImportTextPanel.value.children[0].addEventListener(
                'click',
                function () {
                    router.push('/import-logs');
                },
                false
            );
        });

        const onDrop = (files: any, rejectReasons: any) => {
            acceptFiles.value = files;
            savedFileId.value = null;
            rowsToSkip.value.length = 0;
            importResult.value = false;
            if (rejectReasons.length > 0) {
                toast.error(rejectReasons[0]['errors'][0]['message']);
                acceptFiles.value.length = 0;
            }
        };

        const uploadFile = async () => {
            isLoading.value = true;
            try {
                const response = await saveFiles(
                    acceptFiles.value,
                    Object.keys(identifier.value)[0]
                );

                savedFileId.value = response.saveResult.id;
            } catch (error: any) {
                toast.error(error.message);
                acceptFiles.value.length = 0;
                savedFileId.value = null;
            } finally {
                isLoading.value = false;
            }
        };

        const validateFile = async () => {
            isLoading.value = true;
            try {
                const response = await runValidation(savedFileId.value);
                rowsToSkip.value = response.rowsToSkip;
                if (
                    response.rowsToSkip &&
                    Object.keys(response.rowsToSkip).length > 0
                ) {
                    toast.warning(
                        i18n.global.t('messages.validationErrorsInFile')
                    );
                } else {
                    toast.success(
                        i18n.global.t('messages.fileValidatedSuccessfully')
                    );
                    showPlatformConfirmationDialog.value = true;
                }
            } catch (error: any) {
                toast.error(error.message);
                savedFileId.value = null;
                acceptFiles.value.length = 0;
            } finally {
                isLoading.value = false;
            }
        };

        const downloadFileTemplate = async () => {
            if (identifier.value === null) {
                toast.error('Please select one workflow.');
                return;
            }

            const workflowIdentifier = Object.keys(identifier.value)[0];

            const file = await getFileTempate(workflowIdentifier);
            const filename = file.headers['content-disposition']
                .split('filename=')[1]
                .split(';')[0];

            FileSaver.saveAs(
                new Blob([file.data], {
                    type: file.headers['content-type']
                }),
                filename
            );
        };

        const importResultReceived = (result: boolean) => {
            if (result) {
                showPlatformConfirmationDialog.value = true;
                return;
            }

            discardFile();
        };

        const handleConfirmationResult = (result: boolean) => {
            importResult.value = result;
            savedFileId.value = null;
            rowsToSkip.value.length = 0;
            acceptFiles.value.length = 0;
            if (result) {
                toast.success(i18n.global.t('messages.fileScheduledForImport'));
            }
        };

        const onChange = () => {
            savedFileId.value = null;
            rowsToSkip.value.length = 0;
            acceptFiles.value.length = 0;
            importResult.value = false;

            fileExtension.value =
                [
                    'weclapp-paid-invoices-add',
                    'weclapp-debtor-data-edit',
                    'weclapp-headless-shipments-add'
                ].indexOf(Object.keys(identifier.value)[0]) !== -1
                    ? '.xlsx'
                    : '.csv';
        };

        const fileExtension = ref('.csv');

        watch(fileExtension, () => {
            dropzone.value = useDropzone({
                onDrop,
                accept: fileExtension.value
            });
        });

        const dropzone = ref(null);
        dropzone.value = useDropzone({
            onDrop,
            accept: fileExtension.value
        });

        const showUploadButton = computed(() => {
            return (
                identifier.value !== null &&
                acceptFiles.value.length > 0 &&
                savedFileId.value === null &&
                rowsToSkip.value.length < 1
            );
        });

        const importWizardOptions = computed(() => {
            const currentUser = store.getters['auth/user'];
            return evaluateChildNodes(wizardOptions, currentUser);
        });

        const closePlatformConfirmationDialog = (
            event: any,
            result: boolean = false
        ) => {
            showPlatformConfirmationDialog.value = false;
            confirmationInputValue.value = '';
            if (result) {
                handleConfirmationResult(true);
            } else {
                discardFile();
            }
        };

        const importPlatform = computed(() => {
            const currentPlatform = store.getters['auth/platform'];
            const platforms = store.getters['auth/platforms'];
            return platforms.find(
                (item: any) => item.value === currentPlatform
            );
        });

        const discardFile = async () => {
            isLoading.value = true;
            try {
                await removeFile(savedFileId.value);
                toast.success(i18n.global.t('messages.fileDiscarded'));
            } catch (error: any) {
                toast.error(error.message);
            } finally {
                handleConfirmationResult(false);
                isLoading.value = false;
            }
        };

        const isConfimationInputNeeded = computed(() => {
            return (
                identifier.value &&
                Object.keys(identifier.value)[0] !== 'weclapp-paid-invoices-add'
            );
        });

        const confirmButtonEnabled = computed(() => {
            return (
                !isConfimationInputNeeded.value ||
                importPlatform.value?.threeLetterId ===
                    confirmationInputValue.value.toLowerCase().trim()
            );
        });

        const onShowConfirmationDialog = () => {
            if (isConfimationInputNeeded.value) {
                const inputField = confirmationInputField.value
                    ?.$el as HTMLInputElement;
                if (inputField) {
                    inputField.focus();
                }
            }
        };

        return {
            dropzone,
            showUploadButton,
            savedFileId,
            uploadFile,
            validateFile,
            importResultReceived,
            onChange,
            importResult,
            rowsToSkip,
            identifier,
            isLoading,
            importWizardOptions,
            fileScheduledForImportTextPanel,
            downloadFileTemplate,
            showPlatformConfirmationDialog,
            closePlatformConfirmationDialog,
            importPlatform,
            isConfimationInputNeeded,
            confirmationInputField,
            confirmationInputValue,
            confirmButtonEnabled,
            onShowConfirmationDialog
        };
    }
};
