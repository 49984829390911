import apiClient from '@/utils/axios';
import {AxiosResponse} from 'axios';

export const getAll = (
    parentId: string | null = null,
    platform: string | null = null
): Promise<AxiosResponse> => {
    const queryStringParams = [];

    if (parentId) {
        queryStringParams.push('parentId=' + parentId);
    }

    if (platform) {
        queryStringParams.push('platform=' + platform);
    }

    return apiClient.get(
        '/api/v1/products/categories' +
            (queryStringParams.length > 0
                ? '?' + queryStringParams.join('&')
                : '')
    );
};

export const search = (
    searchTerm: string,
    platform: string | null = null
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/products/categories?searchTerm=' +
            searchTerm +
            (platform ? '&platform=' + platform : '')
    );
};
