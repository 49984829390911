<!-- Content Header (Page header) -->
<loading v-model:active="loading" />
<ScrollPanel style="height: calc(100vh - 12rem)">
    <Panel
        class="panel-container-datatable"
        :header="$t('labels.salesOrder.disposition')"
    >
        <form @submit.prevent="handleSearch(!v$.$invalid)">
            <div class="grid p-fluid mb-2">
                <div class="col field">
                    <label>{{$t('labels.articleNumber')}}</label>
                    <span class="p-input-icon-left">
                        <IconField iconPosition="left">
                            <InputIcon class="pi pi-search"> </InputIcon>
                            <InputText
                                class="text-sm"
                                v-model.trim="v$.articleNumber.$model"
                                :class="{'p-invalid':v$.articleNumber.$invalid && submitted}"
                                :placeholder="$t('labels.articleNumber') + ', ' + $t('labels.commaSeparated')"
                            />
                        </IconField>
                    </span>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.articleNumber.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
                <div class="col field">
                    <label>{{$t('labels.name')}}</label>
                    <InputText
                        class="text-sm"
                        v-model.trim="v$.articleName.$model"
                        :class="{'p-invalid':v$.articleName.$invalid && submitted}"
                    />
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.articleName.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
                <div class="col field">
                    <label>{{$t('labels.manufacturer')}}</label>
                    <p-multiselect
                        v-model="v$.manufacturer.$model"
                        :class="{'p-invalid':v$.manufacturer.$invalid && submitted}"
                        :options="manufacturerOptions || []"
                        optionLabel="name"
                        optionValue="id"
                        filter
                    >
                    </p-multiselect>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.manufacturer.$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>

                <div class="field col-2">
                    <div class="mb-3">
                        <label>
                            {{$t('labels.productHome.activeInShop')}}
                        </label>
                        <div class="mt-3">
                            <TriStateCheckbox
                                v-model="v$.activeInAnyShop.$model"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-1 w-10rem">
                    <p-button
                        severity="success"
                        v-text="$t('buttons.confirm')"
                        type="submit"
                    >
                    </p-button>
                </div>
            </div>
        </form>
        <DataTable
            v-if="foundProducts?.length > 0"
            class="p-datatable-sm"
            stripedRows
            paginator
            :rows="20"
            :paginator-template="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'"
            :rows-per-page-options="[10,20,50,100,200]"
            :current-page-report-template="'{first} to {last} of {totalRecords}'"
            editMode="cell"
            @cell-edit-complete="onCellEditComplete"
            ref="dt"
            dataKey="id"
            :value="foundProducts"
            responsiveLayout="scroll"
            v-model:filters="filters"
            filterDisplay="row"
            @filter="onFilter"
        >
            <Column
                field="nameNumberCombo"
                style="min-width: 9rem"
                :header="$t('labels.article')"
                sortable
                :filterMatchModeOptions="[{label: 'Contains', value: articleComboFilter}]"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-xs"
                        :placeholder="$t('labels.name')"
                    />
                </template>
                <template #body="{data}">
                    <div class="text-sm">
                        <span
                            class="sales-channel-dot w-1rem h-1rem mr-1"
                            :class="{'sales-channel-teltec':data?.activeInShop, 'sales-channel-teltec-border':data?.availableInShop, 'bg-gray-200': !data?.availableInShop && !data?.activeInShop}"
                        ></span>
                        <span
                            class="sales-channel-dot w-1rem h-1rem mr-1"
                            :class="{'sales-channel-videodata':data?.activeInShop2, 'sales-channel-videodata-border':data?.availableInShop2, 'bg-gray-200': !data?.availableInShop2 && !data?.activeInShop2}"
                        ></span>
                        {{data.name}} [<a
                            :href="data?.url + 'webapp/view/products/articles/ArticleDetail.page?entityId=' + data.id"
                            target="_blank"
                            >{{ data.articleNumber }}</a
                        >]
                    </div>
                    <div class="text-xs">
                        <b
                            >{{$t('labels.createdAt')}}
                            {{getDateFormatted(data?.createdDate) }}<span
                                v-if="ekPriceCalculation(data)"
                                class="ml-3"
                                >EK {{ekPriceCalculation(data)}}</span
                            ></b
                        >
                    </div>
                </template>
            </Column>
            <Column
                field="manufacturerName"
                :header="$t('labels.manufacturer')"
                sortable
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        type="text"
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-xs"
                        :placeholder="$t('labels.manufacturer')"
                    />
                </template>
            </Column>
            <Column
                field="eol"
                header="EOL"
                dataType="boolean"
                bodyClass="text-center"
                sortable
            >
                <template #filter="{filterModel, filterCallback}">
                    <div class="w-full flex justify-content-center ml-3">
                        <div>
                            <TriStateCheckbox
                                v-model="filterModel.value"
                                @change="filterCallback()"
                            />
                        </div>
                    </div>
                </template>
                <template #body="{data}">
                    <i
                        class="pi"
                        :class="{'text-green-600 pi-check-circle': data.eol, 'text-red-600 pi-times-circle': !(data.eol)}"
                    ></i>
                </template>
            </Column>
            <Column
                field="activeInAnyShop"
                dataType="boolean"
                bodyClass="text-center"
                sortable
            >
                <template #header>
                    <div class="flex align-items-center text-center">
                        <div>{{$t('labels.productHome.activeInShop')}}</div>
                    </div></template
                >
                <template #filter="{filterModel, filterCallback}">
                    <div class="w-full flex justify-content-center ml-3">
                        <div>
                            <TriStateCheckbox
                                v-model="filterModel.value"
                                @change="filterCallback()"
                            />
                        </div>
                    </div>
                </template>
                <template #body="{data}">
                    <i
                        class="pi"
                        :class="{'text-green-600 pi-check-circle': data.activeInAnyShop, 'text-red-600 pi-times-circle': !(data.activeInAnyShop)}"
                    ></i>
                </template>
            </Column>
            <Column field="minimumStockQuantity" header="Min" sortable>
                <template #body="{data}">
                    <div style="cursor: pointer">
                        <Skeleton
                            v-if="singleEditInProgress === data.articleNumber"
                        ></Skeleton>
                        <template v-else>
                            {{ data.minimumStockQuantity }}
                        </template>
                    </div>
                </template>
                <template #editor="{ data, field }">
                    <InputNumber
                        :locale="locale"
                        v-model="data[field]"
                        showButtons
                        :min="0"
                        :disabled="!editPermissionAvailable"
                    />
                </template>
            </Column>
            <Column field="targetStockQuantity" header="Max" sortable>
                <template #body="{data}">
                    <div style="cursor: pointer">
                        <Skeleton
                            v-if="singleEditInProgress === data.articleNumber"
                        ></Skeleton>
                        <template v-else>
                            {{ data.targetStockQuantity }}
                        </template>
                    </div>
                </template>
                <template #editor="{ data, field }">
                    <InputNumber
                        :locale="locale"
                        v-model="data[field]"
                        showButtons
                        :min="0"
                        :disabled="!editPermissionAvailable"
                    />
                </template>
            </Column>
            <Column field="minMaxDate" dataType="date" sortable>
                <template #header>
                    MinMaxAkt
                    <i
                        class="pi pi-refresh"
                        style="padding-left: 7px; cursor: pointer"
                        @click="bulkUpdateMinMaxDate"
                    ></i>
                </template>
                <template #filter="{filterModel,filterCallback}">
                    <p-calendar
                        class="p-column-filter text-xs"
                        v-model="filterModel.value"
                        dateFormat="dd.mm.yy"
                        @date-select="filterCallback()"
                    />
                </template>
                <template #body="{data}">
                    <div v-if="data.minMaxDate">
                        <Skeleton
                            v-if="singleEditInProgress === data.articleNumber"
                        ></Skeleton>
                        <template v-else>
                            {{ data.minMaxDate.toLocaleDateString('de-DE',{
                            year: 'numeric', month: '2-digit', day: '2-digit' })
                            }}
                        </template>
                    </div>
                </template>
            </Column>
            <Column header="Dispo" sortable field="calculatedDispo">
                <template #body="{data}">
                    <Skeleton v-if="data?.calculatedDispo === null"></Skeleton>
                    <template v-else> {{data.calculatedDispo}} </template>
                </template>
            </Column>
            <Column
                header="VK-RG (3M)"
                sortable
                field="salesInvoicesLastThreeMonthsCount"
            >
                <template #body="{data}">
                    <Skeleton v-if="!data?.salesInvoicesLoaded"></Skeleton>
                    <template v-else>
                        {{data.salesInvoicesLastThreeMonthsItemCount}} /
                        {{data.salesInvoicesLastThreeMonthsCount}}
                    </template>
                </template>
            </Column>
            <Column header="VK-RG (12M)" sortable field="salesInvoicesCount">
                <template #body="{data}">
                    <Skeleton v-if="!data?.salesInvoicesLoaded"></Skeleton>
                    <template v-else>
                        {{data.salesInvoicesItemCount}} /
                        {{data.salesInvoicesCount}}
                    </template>
                </template>
            </Column>
            <Column header="BE (12M)" sortable field="purchaseOrdersItemCount">
                <template #body="{data}">
                    <Skeleton v-if="!data?.purchaseOrdersLoaded"></Skeleton>
                    <template v-else>
                        <div>{{data?.purchaseOrdersItemCount}}</div>
                    </template>
                </template>
            </Column>
            <Column
                header="Letzte BE"
                sortable
                field="purchaseOrdersLastPurchaseOrderDate"
            >
                <template #body="{data}">
                    <Skeleton v-if="!data?.purchaseOrdersLoaded"></Skeleton>
                    <template v-else>
                        <div>
                            {{getDateFormatted(data?.purchaseOrdersLastPurchaseOrderDate)}}
                        </div>
                    </template>
                </template>
            </Column>
        </DataTable>
    </Panel>
</ScrollPanel>
