import {getAll, remove} from '@/services/boomarks';
import store from '@/store';
import {computed, onMounted, ref} from 'vue';
import OverlayPanel from 'primevue/overlaypanel';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Badge from 'primevue/badge';
import router from '@/router';

export default {
    components: {
        OverlayPanel,
        'p-button': Button,
        DataTable,
        Column,
        Badge
    },

    setup() {
        const opBookmarks = ref(null);
        onMounted(() => {
            getAll()
                .then(async (data) => {
                    data.data.forEach((obj: any) => {
                        store.dispatch('ui/addBookmark', obj);
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        });

        const bookmarks = computed(() => {
            return store.getters['ui/bookmarks'].map((obj: any) => ({
                ...obj,
                localizedTitle: 'bookmarks.' + obj.entityName
            }));
        });

        const navigateToBookmark = (path: string) => {
            router.push(path);
        };

        const removeBookmark = (bookmarkId: string) => {
            remove(bookmarkId).then(() => {
                store.dispatch('ui/deleteBookmark', {id: bookmarkId});
            });
        };

        const toggleDataTable = (event: any) => {
            if (bookmarks.value.length > 0) {
                opBookmarks.value.toggle(event);
            }
        };

        return {
            bookmarks,
            navigateToBookmark,
            removeBookmark,
            toggleDataTable,
            opBookmarks
        };
    }
};
