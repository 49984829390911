<div class="p-fluid mb-3">
    <AutoComplete
        v-model="chosenSupplier"
        :placeholder="$t('labels.productAdd.supplier')"
        :suggestions="filteredSuppliers || []"
        @complete="searchSuppliers($event)"
        @item-select="setDropdownValue"
        dropdown
        optionLabel="label"
        optionValue="value"
        forceSelection
        class="w-full"
    />
</div>
