import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0380f73e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "formgrid grid p-fluid" }
const _hoisted_4 = { class: "col field" }
const _hoisted_5 = { class: "formgrid grid p-fluid" }
const _hoisted_6 = { class: "col field" }
const _hoisted_7 = { class: "formgrid grid p-fluid" }
const _hoisted_8 = { class: "col field" }
const _hoisted_9 = { class: "formgrid grid p-fluid" }
const _hoisted_10 = {
  key: 0,
  class: "col field"
}
const _hoisted_11 = {
  key: 1,
  class: "col field"
}
const _hoisted_12 = { class: "formgrid grid p-fluid" }
const _hoisted_13 = { class: "col field" }
const _hoisted_14 = { class: "formgrid grid p-fluid" }
const _hoisted_15 = { class: "col field" }
const _hoisted_16 = { class: "formgrid grid p-fluid" }
const _hoisted_17 = { class: "col field" }
const _hoisted_18 = { class: "formgrid grid p-fluid" }
const _hoisted_19 = { class: "col field" }
const _hoisted_20 = { class: "formgrid grid p-fluid" }
const _hoisted_21 = { class: "col field" }
const _hoisted_22 = { class: "formgrid grid p-fluid" }
const _hoisted_23 = { class: "col field" }
const _hoisted_24 = { class: "col field" }
const _hoisted_25 = { class: "col field" }
const _hoisted_26 = { class: "formgrid grid p-fluid" }
const _hoisted_27 = { class: "col field" }
const _hoisted_28 = { class: "col field" }
const _hoisted_29 = { class: "formgrid grid p-fluid mt-3" }
const _hoisted_30 = { class: "col field" }
const _hoisted_31 = { class: "col field" }
const _hoisted_32 = { class: "formgrid grid p-fluid mt-3" }
const _hoisted_33 = { class: "col field" }
const _hoisted_34 = { class: "w-full flex justify-content-between mt-4" }

export function render(_ctx, _cache) {
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_InputText = _resolveComponent("InputText")
  const _component_Chips = _resolveComponent("Chips")
  const _component_InputNumber = _resolveComponent("InputNumber")
  const _component_p_calendar = _resolveComponent("p-calendar")
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[24] || (_cache[24] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.manufacturer')), 1),
            _createVNode(_component_p_dropdown, {
              filter: true,
              modelValue: _ctx.v$['manufacturer'].$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$['manufacturer'].$model) = $event)),
              options: _ctx.serverData?.manufacturer || [],
              optionLabel: "name",
              optionValue: "id",
              onChange: _cache[1] || (_cache[1] = $event => (_ctx.setDropdownValue(('manufacturer'), $event))),
              class: _normalizeClass({'p-invalid':_ctx.v$['manufacturer'].$invalid && _ctx.submitted}),
              showClear: true
            }, null, 8, ["modelValue", "options", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['manufacturer'].$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.countryOfOrigin')), 1),
            _createVNode(_component_p_dropdown, {
              filter: true,
              modelValue: _ctx.v$['countryOfOrigin'].$model,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$['countryOfOrigin'].$model) = $event)),
              options: _ctx.countryOptions,
              optionLabel: "label",
              optionValue: "value",
              onChange: _cache[3] || (_cache[3] = $event => (_ctx.setDropdownValue(('countryOfOrigin'), $event))),
              class: _normalizeClass({'p-invalid':_ctx.v$['countryOfOrigin'].$invalid && _ctx.submitted}),
              showClear: true
            }, null, 8, ["modelValue", "options", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['countryOfOrigin'].$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.customsTariffNumber')), 1),
            _createVNode(_component_p_dropdown, {
              modelValue: _ctx.v$['customsTariffNumber'].$model,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$['customsTariffNumber'].$model) = $event)),
              options: _ctx.serverData?.customsTariffNumber || [],
              filter: "",
              optionLabel: "name",
              optionValue: "id",
              onChange: _cache[5] || (_cache[5] = $event => (_ctx.setDropdownValue(('customsTariffNumber'), $event))),
              class: _normalizeClass({'p-invalid':_ctx.v$['customsTariffNumber'].$invalid && _ctx.submitted}),
              showClear: ""
            }, null, 8, ["modelValue", "options", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['customsTariffNumber'].$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_ctx.resolvedPlatform?.threeLetterId === 'ttd' || _ctx.resolvedPlatform?.threeLetterId === 'ttl')
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.matchCode')), 1),
                _createVNode(_component_InputText, {
                  autocomplete: "off",
                  modelValue: _ctx.v$['matchCode'].$model,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.v$['matchCode'].$model) = $event)),
                  class: _normalizeClass({'p-invalid':_ctx.v$['matchCode'].$invalid && _ctx.submitted})
                }, null, 8, ["modelValue", "class"]),
                (_ctx.submitted)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['matchCode'].$silentErrors, (error) => {
                      return (_openBlock(), _createElementBlock("small", {
                        style: {"display":"block"},
                        class: "p-error",
                        key: error
                      }, _toDisplayString(error.$message), 1))
                    }), 128))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.resolvedPlatform?.threeLetterId === 'vdd' || _ctx.resolvedPlatform?.threeLetterId === 'vdl')
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.metaTagsSEO')), 1),
                _createVNode(_component_Chips, {
                  modelValue: _ctx.v$['articleMetatags'].$model,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.v$['articleMetatags'].$model) = $event)),
                  allowDuplicate: false,
                  placeholder: _ctx.$t('labels.metatagsSeparatorInfo'),
                  separator: ","
                }, null, 8, ["modelValue", "placeholder"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.vkPrice')), 1),
            _createVNode(_component_InputNumber, {
              mode: "decimal",
              locale: _ctx.locale,
              maxFractionDigits: 2,
              autocomplete: "off",
              modelValue: _ctx.v$['vkPrice'].$model,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.v$['vkPrice'].$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$['vkPrice'].$invalid && _ctx.submitted})
            }, null, 8, ["locale", "modelValue", "class"])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productAdd.vkStartDate')), 1),
            _createVNode(_component_p_calendar, {
              autocomplete: "off",
              dateFormat: "dd.mm.yy",
              modelValue: _ctx.v$['vkStartDate'].$model,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$['vkStartDate'].$model) = $event)),
              class: _normalizeClass({'tt-form-input': true, 'p-invalid':_ctx.v$['vkStartDate'].$invalid && _ctx.submitted})
            }, null, 8, ["modelValue", "class"])
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.procurementLeadDays')), 1),
            _createVNode(_component_InputNumber, {
              locale: _ctx.locale,
              autocomplete: "off",
              showButtons: "",
              min: 0,
              modelValue: _ctx.v$['procurementLeadDays'].$model,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.v$['procurementLeadDays'].$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$['procurementLeadDays'].$invalid && _ctx.submitted})
            }, null, 8, ["locale", "modelValue", "class"]),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.v$['procurementLeadDays'].$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.minimumStockQuantity')), 1),
            _createVNode(_component_InputNumber, {
              locale: _ctx.locale,
              autocomplete: "off",
              modelValue: _ctx.v$['minimumStockQuantity'].$model,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.v$['minimumStockQuantity'].$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$['minimumStockQuantity'].$invalid && _ctx.submitted})
            }, null, 8, ["locale", "modelValue", "class"])
          ])
        ]),
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.targetStockQuantity')), 1),
            _createVNode(_component_InputNumber, {
              locale: _ctx.locale,
              autocomplete: "off",
              modelValue: _ctx.v$['targetStockQuantity'].$model,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => ((_ctx.v$['targetStockQuantity'].$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$['targetStockQuantity'].$invalid && _ctx.submitted})
            }, null, 8, ["locale", "modelValue", "class"])
          ])
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.length')), 1),
            _createVNode(_component_InputNumber, {
              mode: "decimal",
              locale: _ctx.locale,
              minFractionDigits: 1,
              maxFractionDigits: 1,
              autocomplete: "off",
              modelValue: _ctx.v$['articleLength'].$model,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.v$['articleLength'].$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$['articleLength'].$invalid && _ctx.submitted})
            }, null, 8, ["locale", "modelValue", "class"])
          ]),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.width')), 1),
            _createVNode(_component_InputNumber, {
              mode: "decimal",
              locale: _ctx.locale,
              minFractionDigits: 1,
              maxFractionDigits: 1,
              autocomplete: "off",
              modelValue: _ctx.v$['articleWidth'].$model,
              "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.v$['articleWidth'].$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$['articleWidth'].$invalid && _ctx.submitted})
            }, null, 8, ["locale", "modelValue", "class"])
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.height')), 1),
            _createVNode(_component_InputNumber, {
              mode: "decimal",
              locale: _ctx.locale,
              minFractionDigits: 1,
              maxFractionDigits: 1,
              autocomplete: "off",
              modelValue: _ctx.v$['articleHeight'].$model,
              "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => ((_ctx.v$['articleHeight'].$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$['articleHeight'].$invalid && _ctx.submitted})
            }, null, 8, ["locale", "modelValue", "class"])
          ])
        ]),
        _createElementVNode("div", _hoisted_26, [
          _createElementVNode("div", _hoisted_27, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.grossWeight')), 1),
            _createVNode(_component_InputNumber, {
              mode: "decimal",
              locale: _ctx.locale,
              minFractionDigits: 1,
              autocomplete: "off",
              modelValue: _ctx.v$['articleGrossWeight'].$model,
              "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => ((_ctx.v$['articleGrossWeight'].$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$['articleGrossWeight'].$invalid && _ctx.submitted})
            }, null, 8, ["locale", "modelValue", "class"])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.netWeight')), 1),
            _createVNode(_component_InputNumber, {
              mode: "decimal",
              locale: _ctx.locale,
              minFractionDigits: 1,
              autocomplete: "off",
              modelValue: _ctx.v$['articleNetWeight'].$model,
              "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => ((_ctx.v$['articleNetWeight'].$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$['articleNetWeight'].$invalid && _ctx.submitted})
            }, null, 8, ["locale", "modelValue", "class"])
          ])
        ]),
        _createElementVNode("div", _hoisted_29, [
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.serialNumberExtern')), 1),
            (_ctx.resolvedPlatform?.threeLetterId === 'ttd' || _ctx.resolvedPlatform?.threeLetterId === 'ttl')
              ? (_openBlock(), _createBlock(_component_p_dropdown, {
                  key: 0,
                  modelValue: _ctx.v$['serialNumberRequired'].$model,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => ((_ctx.v$['serialNumberRequired'].$model) = $event)),
                  options: [{id: true, name: 'ja'}, {id: false, name: 'nein'}],
                  optionLabel: "name",
                  optionValue: "id",
                  onChange: _cache[19] || (_cache[19] = $event => (_ctx.setDropdownValue(('serialNumberRequired'), $event))),
                  class: _normalizeClass({'p-invalid':_ctx.v$['serialNumberRequired'].$invalid && _ctx.submitted}),
                  showClear: ""
                }, null, 8, ["modelValue", "class"]))
              : (_openBlock(), _createBlock(_component_p_checkbox, {
                  key: 1,
                  modelValue: _ctx.v$['serialNumberRequired'].$model,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => ((_ctx.v$['serialNumberRequired'].$model) = $event)),
                  binary: "",
                  class: "ml-2 mb-1"
                }, null, 8, ["modelValue"])),
            (_ctx.submitted)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.v$['serialNumberRequired'].$silentErrors, (error) => {
                  return (_openBlock(), _createElementBlock("small", {
                    style: {"display":"block"},
                    class: "p-error",
                    key: error
                  }, _toDisplayString(error.$message), 1))
                }), 128))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.productHome.serialNumberIntern')), 1),
            _createVNode(_component_p_checkbox, {
              modelValue: _ctx.v$['serialNumberIntern'].$model,
              "onUpdate:modelValue": _cache[21] || (_cache[21] = $event => ((_ctx.v$['serialNumberIntern'].$model) = $event)),
              binary: true,
              class: "ml-2 mb-1"
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_32, [
          _createElementVNode("div", _hoisted_33, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.articleWeclappCategory')), 1),
            _createVNode(_component_p_dropdown, {
              filter: true,
              modelValue: _ctx.v$['articleCategory'].$model,
              "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => ((_ctx.v$['articleCategory'].$model) = $event)),
              options: _ctx.serverData?.articleCategory || [],
              optionLabel: "name",
              optionValue: "id",
              onChange: _cache[23] || (_cache[23] = $event => (_ctx.setDropdownValue(['articleCategory'], $event))),
              showClear: true
            }, null, 8, ["modelValue", "options"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_34, [
      _createVNode(_component_p_button, {
        severity: "danger",
        textContent: _toDisplayString(_ctx.$t('buttons.back')),
        onClick: _ctx.onBackButtonClicked
      }, null, 8, ["textContent", "onClick"]),
      _createVNode(_component_p_button, {
        severity: "success",
        type: "submit",
        textContent: _toDisplayString(_ctx.$t('buttons.next'))
      }, null, 8, ["textContent"])
    ])
  ], 32))
}