import TreeSelect from 'primevue/treeselect';
import {ref, computed, defineAsyncComponent} from 'vue';
import {useToast} from 'vue-toastification';
import Loading from 'vue-loading-overlay';
import {evaluateChildNodes} from '@/utils/export-wizard/options';
import {workflowOptions} from '@/utils/jobs/workflows';
import store from '@/store';
import {createNew} from '@/services/crons';
import {i18n} from '@/utils/i18n';
import Button from 'primevue/button';
import Panel from 'primevue/panel';
import {IWorkflowItem} from '@/interfaces/jobs/workflow-item';
import {useConfirm} from 'primevue/useconfirm';

export default {
    name: 'WorkflowWizard',
    components: {
        'p-button': Button,
        loading: Loading,
        TreeSelect,
        Panel
    },
    setup() {
        const toast = useToast();
        const identifier = ref(null);
        const isLoading = ref(false);
        const componentName = ref();

        const startButtonEnabled = ref(false);
        const requiredData = ref();

        const confirm = useConfirm();

        const findNodeBasedOnKey = (
            nodes: Array<IWorkflowItem>,
            key: string
        ): IWorkflowItem | null => {
            if (
                nodes.some((singleNode: any) => {
                    return singleNode.key === key;
                })
            ) {
                return nodes.find((singleNode: any) => {
                    return singleNode.key === key;
                });
            }

            let result: IWorkflowItem | null = null;

            nodes.forEach((singleNode) => {
                if (result === null && singleNode.children) {
                    result = findNodeBasedOnKey(singleNode.children, key);
                }
            });

            return result;
        };

        const runWorkflow = async () => {
            const optionNode = findNodeBasedOnKey(
                workflowOptions,
                Object.keys(identifier.value)[0]
            );

            confirm.require({
                message: i18n.global.t('messages.runWorkflowConfirmation', {
                    name: optionNode?.label
                }),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: async () => {
                    isLoading.value = true;

                    const optionNode = findNodeBasedOnKey(
                        workflowOptions,
                        Object.keys(identifier.value)[0]
                    );

                    try {
                        await createNew('Workflow Wizard', optionNode?.label, {
                            id: Object.keys(identifier.value)[0],
                            data: requiredData.value
                        });
                        toast.success(
                            i18n.global.t('messages.workflowExecutionScheduled')
                        );

                        identifier.value = null;
                    } catch (error: any) {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    } finally {
                        isLoading.value = false;
                    }
                }
            });
        };

        const workflowWizardOptions = computed(() => {
            const currentUser = store.getters['auth/user'];
            return evaluateChildNodes(workflowOptions, currentUser);
        });

        const importPlatform = computed(() => {
            const currentPlatform = store.getters['auth/platform'];
            const platforms = store.getters['auth/platforms'];
            return platforms.find(
                (item: any) => item.value === currentPlatform
            );
        });

        const comp = computed(() => {
            const test = componentName.value;

            return defineAsyncComponent(
                () => import(`@/components/job-workflows/${test}.vue`)
            );
        });

        const onChange = (value: any) => {
            startButtonEnabled.value = false;
            requiredData.value = null;

            if (!value) {
                componentName.value = null;
                return;
            }

            componentName.value = Object.keys(value)[0]
                .replaceAll('--', '++')
                .replaceAll('-', '/')
                .replaceAll('++', '-');
        };

        const onRequiredDataEntered = (value: any) => {
            startButtonEnabled.value = !!value;
            requiredData.value = value || null;
        };

        return {
            runWorkflow,
            startButtonEnabled,
            identifier,
            isLoading,
            workflowWizardOptions,
            importPlatform,
            componentName,
            comp,
            onChange,
            onRequiredDataEntered
        };
    }
};
