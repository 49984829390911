import {computed, onMounted, ref, toRefs, watch} from 'vue';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import {useVuelidate} from '@vuelidate/core';
import {required} from '@vuelidate/validators';
import {getAll, getSome} from '@/services/metadata';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import Chips from 'primevue/chips';
import store from '@/store';
import {DateTime} from 'luxon';

export default {
    emits: [
        'back-button-clicked',
        'next-button-clicked',
        'add-wizard-form-dirty'
    ],
    props: {
        duplicateCheckValues: {
            type: Object,
            default: null
        },
        pairValues: {
            type: Object,
            default: null
        },
        hideBackButton: {
            type: Boolean,
            default: false
        }
    },
    components: {
        InputText,
        'p-button': Button,
        'p-checkbox': Checkbox,
        'p-calendar': Calendar,
        'p-dropdown': Dropdown,
        InputNumber,
        Chips
    },
    setup(props: any, context: any) {
        const {hideBackButton, duplicateCheckValues} = toRefs(props);
        const pairValues = ref(null);
        const submitted = ref(false);
        const toast = useToast();
        const unitOptions = ref([]);
        const currencyOptions = ref([]);
        const weeeOptions = ref([]);
        const warrantyTimeOptions = ref([]);

        onMounted(() => {
            getAll(['unit', 'currency'])
                .then((data) => {
                    if (data.data?.unit) {
                        unitOptions.value = parseIntoDropdownList(
                            data.data.unit
                        );
                    }
                    if (data.data?.currency) {
                        currencyOptions.value = parseIntoDropdownList(
                            data.data.currency
                        );
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });

            getSome(
                'customAttributeDefinition',
                'attributeKey',
                ['article_weee_relevant', 'article_garantiezeit'],
                false
            )
                .then((data) => {
                    weeeOptions.value = (weeeOptions.value || [])
                        .concat(
                            data.data[
                                'article_weee_relevant'
                            ]?.selectableValues?.map(
                                (item: {id: string; value: string}) => {
                                    return {
                                        id: item.id,
                                        label: item.value,
                                        value: item.value
                                    };
                                }
                            ) || []
                        )
                        .filter(
                            (value, index, self) =>
                                index ===
                                self.findIndex((t) => t.value === value.value)
                        );

                    warrantyTimeOptions.value = (
                        warrantyTimeOptions.value || []
                    )
                        .concat(
                            data.data[
                                'article_garantiezeit'
                            ]?.selectableValues?.map(
                                (item: {id: string; value: string}) => {
                                    return {
                                        id: item.id,
                                        label: item.value,
                                        value: item.value
                                    };
                                }
                            ) || []
                        )
                        .filter(
                            (value, index, self) =>
                                index ===
                                self.findIndex((t) => t.value === value.value)
                        );
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        });

        const loggedInUser = computed(() => {
            return store.getters['auth/user']?.username;
        });

        const prePopulateForm = () => {
            const sourceObj = pairValues.value;

            state.value.articleCreator = loggedInUser.value;
            state.value.name =
                sourceObj?.name || duplicateCheckValues.value?.name || null;
            state.value.ean =
                sourceObj?.ean || duplicateCheckValues.value?.ean || null;
            state.value.manufacturerPartNumber =
                sourceObj?.manufacturerPartNumber ||
                duplicateCheckValues.value?.manufacturerPartNumber ||
                null;
            state.value.uvpPrice = sourceObj?.uvpPrice || null;

            state.value.productGroupId = sourceObj?.productGroupId || null;

            state.value.articleOversized =
                sourceObj?.customAttributes?.article_ueberlaenge || false;

            state.value.articleBulkGoods =
                sourceObj?.customAttributes?.article_sperrgut || false;

            state.value.articleBattg =
                sourceObj?.customAttributes?.article_batteriegesetz_relevant ||
                false;

            if (sourceObj?.customAttributes?.article_weee_relevant) {
                const weeeOption = (weeeOptions.value || []).find(
                    (item: {id: string; value: string}) => {
                        return (
                            item.id ==
                            sourceObj?.customAttributes?.article_weee_relevant
                        );
                    }
                );

                if (weeeOption) {
                    state.value.articleWeee = weeeOption.value;
                }
            }

            if (sourceObj?.customAttributes?.article_garantiezeit) {
                const warrantyTimeOption = (
                    warrantyTimeOptions.value || []
                ).find((item: {id: string; value: string}) => {
                    return (
                        item.id ==
                            sourceObj?.customAttributes?.article_garantiezeit ||
                        item.value ==
                            sourceObj?.customAttributes?.article_garantiezeit
                    );
                });

                if (warrantyTimeOption) {
                    state.value.articleWarrantyTime = warrantyTimeOption.value;
                }
            }

            state.value.articleDangerousGoods =
                sourceObj?.customAttributes?.article_flag_akku_groesser_100wh ||
                false;

            state.value.articleSellOut =
                sourceObj?.customAttributes?.article_sell_out || false;

            state.value.internalInfo =
                sourceObj?.customAttributes?.article_memo || null;

            for (const key in state.value) {
                if (Object.keys(sourceObj).includes(key)) {
                    if (!['sellFromDate', 'uvpStartDate'].includes(key)) {
                        state.value[key] = sourceObj[key];
                    } else if (
                        /^\d+$/.test(sourceObj[key]) &&
                        sourceObj[key] > 0
                    ) {
                        state.value[key] = DateTime.fromMillis(
                            sourceObj[key]
                        ).toJSDate();
                    }
                }
            }
        };

        watch(props, (args) => {
            if (args?.pairValues && pairValues.value === null) {
                pairValues.value = args.pairValues;
                prePopulateForm();
            }
        });

        const formInitialState: any = {
            name: null,
            manufacturerPartNumber: null,
            currency: 'EUR',
            ean: null,
            unit: 'Stk.',
            taxRateType: 'STANDARD',
            sellFromDate: null,
            articleDangerousGoods: false,
            articleBulkGoods: false,
            articleOversized: false,
            articleBattg: false,
            productType: 'STORABLE',
            articleWeee: null,
            articleWarrantyTime: null,
            uvpPrice: null,
            uvpStartDate: new Date(),
            internalInfo: null,
            articleCreator: null,
            articleSellOut: false,
            productGroupId: null,
            tags: []
        };

        const state = ref(Object.assign({}, formInitialState));

        const rules = {
            name: {required},
            manufacturerPartNumber: {},
            currency: {required},
            ean: {},
            unit: {required},
            taxRateType: {required},
            sellFromDate: {},
            articleDangerousGoods: {},
            articleSellOut: {},
            articleBulkGoods: {},
            articleOversized: {},
            articleBattg: {},
            productType: {required},
            articleWeee: {},
            articleWarrantyTime: {},
            uvpPrice: {},
            uvpStartDate: {},
            internalInfo: {},
            articleCreator: {required},
            productGroupId: {},
            tags: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(v$, (args) => {
            if (args.$anyDirty) {
                context.emit('add-wizard-form-dirty', {});
            }
        });

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            context.emit('next-button-clicked', {
                stepIndex: 1,
                formValues: state.value
            });
        };

        const parseIntoDropdownList = (data: any) => {
            return data.map((item: {id: string; name: string}) => {
                return {
                    label: item.name,
                    value: item.name
                };
            });
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;
            Object.assign(state.value, temp);
        };

        const onBackButtonClicked = (event: any) => {
            event.preventDefault();
            //Object.assign(state.value, {}, formInitialState);
            context.emit('back-button-clicked', 1);
        };

        const tagsPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('tags-edit') !== -1;
        });

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            setDropdownValue,
            onBackButtonClicked,
            unitOptions,
            currencyOptions,
            weeeOptions,
            warrantyTimeOptions,
            productTypeOptions: [
                {
                    value: 'STORABLE',
                    label: i18n.global.t(
                        'labels.productHome.productTypeOptions.storable'
                    )
                },
                {
                    value: 'BASIC',
                    label: i18n.global.t(
                        'labels.productHome.productTypeOptions.basic'
                    )
                },
                {
                    value: 'SERVICE',
                    label: i18n.global.t(
                        'labels.productHome.productTypeOptions.service'
                    )
                },
                {
                    value: 'SALES_BILL_OF_MATERIAL',
                    label: i18n.global.t(
                        'labels.productHome.productTypeOptions.salesBillOfMaterial'
                    )
                },
                {
                    value: 'SHIPPING_COST',
                    label: i18n.global.t(
                        'labels.productHome.productTypeOptions.shippingCost'
                    )
                }
            ],
            taxRateTypeOptions: [
                'STANDARD',
                'REDUCED',
                'ZERO',
                'SLIGHTLY_REDUCED',
                'SUPER_REDUCED'
            ].map((item: string) => {
                return {
                    value: item,
                    label: i18n.global.t(
                        'labels.productAdd.taxRateTypeOptions.' + item
                    )
                };
            }),
            locale: i18n.global.locale,
            hideBackButton,
            tagsPermissionAvailable
        };
    }
};
