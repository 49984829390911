<form @submit.prevent="handleSubmit(!v$.$invalid)">
    <div class="grid">
        <div class="col">
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label>{{ $t('labels.productAdd.supplier') }} </label>

                    <p-dropdown
                        v-model="v$['supplier'].$model"
                        :options="suppliers['supplier'] || []"
                        optionLabel="label"
                        optionValue="value"
                        :filter="true"
                        @change="setDropdownValue(('supplier'), $event)"
                        :showClear="true"
                        :class="{'p-invalid':v$['supplier'].$invalid && submitted}"
                    >
                        <template #option="slotProps">
                            <div class="flex align-items-center">
                                <div>
                                    {{ slotProps?.option?.label }}
                                    ({{slotProps?.option?.supplierNumber}})
                                    <span
                                        class="font-bold text-red-400 ml-2"
                                        v-if="slotProps?.option?.referenceNumber"
                                        >{{slotProps.option.referenceNumber}}</span
                                    >
                                </div>
                            </div>
                        </template>
                    </p-dropdown>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$['supplier'].$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label>{{ $t('labels.productAdd.sku') }} </label>

                    <InputText
                        :placeholder="$t('labels.productAdd.sku')"
                        autocomplete="off"
                        v-model="v$['sku'].$model"
                        :class="{'p-invalid':v$['sku'].$invalid && submitted}"
                    />
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$['sku'].$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label> {{ $t('labels.ekPrice') }} </label>

                    <InputNumber
                        mode="decimal"
                        :locale="locale"
                        :min="0"
                        :maxFractionDigits="2"
                        autocomplete="off"
                        v-model="v$['ekPrice'].$model"
                        :class="{'p-invalid':v$['ekPrice'].$invalid && submitted}"
                    />
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$['ekPrice'].$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label>{{ $t('labels.productHome.currency') }} </label>

                    <InputText
                        disabled
                        :placeholder="$t('labels.productHome.currency')"
                        autocomplete="off"
                        v-model="v$['supplierCurrency'].$model"
                        :class="{'p-invalid':v$['supplierCurrency'].$invalid && submitted}"
                    />
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$['supplierCurrency'].$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label> {{ $t('labels.productAdd.ekStartDate') }} </label>

                    <p-calendar
                        autocomplete="off"
                        dateFormat="dd.mm.yy"
                        v-model="v$['ekStartDate'].$model"
                        :class="{'tt-form-input': true, 'p-invalid':v$['ekStartDate'].$invalid && submitted}"
                    />
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$['ekStartDate'].$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label>{{ $t('labels.productAdd.taxRateType') }} </label>

                    <p-dropdown
                        v-model="v$['ekTaxRateType'].$model"
                        :options="taxRateTypeOptions"
                        optionLabel="label"
                        optionValue="value"
                        @change="setDropdownValue('ekTaxRateType', $event)"
                        :showClear="true"
                        :class="{'p-invalid':v$['ekTaxRateType'].$invalid && submitted}"
                    >
                    </p-dropdown>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$['ekTaxRateType'].$silentErrors"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label>
                        {{ $t('labels.productAdd.discount') }} 1 [%]
                    </label>

                    <InputNumber
                        mode="decimal"
                        :locale="locale"
                        :min="0"
                        :max="100"
                        :maxFractionDigits="2"
                        autocomplete="off"
                        v-model="v$['discount1'].$model"
                        :class="{'p-invalid':v$['discount1'].$invalid && submitted}"
                    />
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label>
                        {{ $t('labels.productAdd.discount') }} 2 [%]
                    </label>

                    <InputNumber
                        mode="decimal"
                        :locale="locale"
                        :min="0"
                        :max="100"
                        :maxFractionDigits="2"
                        autocomplete="off"
                        v-model="v$['discount2'].$model"
                        :class="{'p-invalid':v$['discount2'].$invalid && submitted}"
                    />
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label>{{ $t('labels.productAdd.discountGroup') }} </label>

                    <InputText
                        :placeholder="$t('labels.productAdd.discountGroup')"
                        autocomplete="off"
                        v-model="v$['discountGroup'].$model"
                        :class="{'p-invalid':v$['discountGroup'].$invalid && submitted}"
                    />
                </div>
            </div>
            <div class="formgrid grid p-fluid">
                <div class="col field">
                    <label>
                        {{ $t('labels.productAdd.trailingDiscount') }} [EUR]
                    </label>

                    <InputNumber
                        mode="decimal"
                        :locale="locale"
                        :maxFractionDigits="2"
                        autocomplete="off"
                        v-model="v$['trailingDiscount'].$model"
                        :class="{'p-invalid':v$['trailingDiscount'].$invalid && submitted}"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="w-full flex justify-content-between mt-4">
        <p-button
            severity="danger"
            v-text="$t('buttons.back')"
            @click="onBackButtonClicked"
        >
        </p-button>

        <p-button
            :disabled="savingInProgress"
            severity="success"
            type="submit"
            v-text="savingInProgress ? $t('labels.inProgress') : $t('labels.save')"
        >
        </p-button>
    </div>
</form>
