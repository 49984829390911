<loading v-model:active="loading" />
<Panel :header="$t('labels.importLog')">
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        paginator
        :rows="10"
        scrollable
        scrollHeight="calc(100vh - 25rem)"
        lazy
        ref="dt"
        dataKey="id"
        :totalRecords="totalRecords"
        :value="logs"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[10,20,50]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="row"
        @page="onPage($event)"
        @filter="onFilter($event)"
        @sort="onSort($event)"
    >
        <Column
            field="isAutomatic"
            style="min-width: 10rem"
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
        >
            <template #filter="{filterModel,filterCallback}">
                <TriStateCheckbox
                    v-model="filterModel.value"
                    @change="filterCallback()"
                />
            </template>
            <template #body="{data}">
                <div v-if="!data?.isAutomatic">
                    <Tag severity="warning">
                        <span style="text-transform: uppercase"
                            >{{$t('labels.mail.manual')}}</span
                        >
                    </Tag>
                </div>
                <div v-else>
                    <Tag severity="primary">
                        <span style="text-transform: uppercase"
                            >{{$t('labels.mail.automatic')}}</span
                        >
                    </Tag>
                </div>
            </template>
        </Column>
        <Column
            field="supplierName"
            style="min-width: 14rem"
            :header="$t('labels.productAdd.supplier')"
            sortable
            ><template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                />
            </template>
            <template #body="{data}">
                {{data.supplierConfiguration?.supplierName}}
                <span
                    v-if="data.supplierId && data.supplierConfiguration?.supplierNumber"
                    >[<a
                        :href="data.url + 'webapp/view/party/PartyDetail.page?entityId=' + data.supplierId"
                        target="_blank"
                        >{{data.supplierConfiguration.supplierNumber}}</a
                    >]</span
                >
                <div v-if="data.manufacturerName" class="mt-2">
                    <Tag
                        ><span style="text-transform: uppercase"
                            >{{$t('labels.manufacturer')}}:
                            {{data.manufacturerName}}</span
                        ></Tag
                    >
                </div>
                <div v-if="data?.costPriceStartDate " class="mt-2">
                    <Tag severity="info"
                        ><span style="text-transform: uppercase"
                            >EK AB {{data.costPriceStartDate}}</span
                        ></Tag
                    >
                </div>
                <div v-if="data?.listPriceStartDate " class="mt-2">
                    <Tag severity="info"
                        ><span style="text-transform: uppercase"
                            >UVP AB {{data.listPriceStartDate}}</span
                        ></Tag
                    >
                </div>
            </template></Column
        >

        <Column :header="$t('labels.filename')">
            <template #body="{data}">
                <ul
                    v-if="(data.files || []).length > 0"
                    v-for="file in data.files"
                >
                    <li>
                        {{ stripTagsAndTruncate(file.filePath, 80, '...') }}
                        <i
                            style="cursor: pointer"
                            class="pi pi-download text-sm ml-2"
                            @click="downloadAttachment(data.id, file.id)"
                        ></i>
                        <!--<i
                            style="cursor: pointer"
                            class="pi pi-download text-sm ml-2"
                            @click="downloadAttachment(data.id, file.id, true)"
                        ></i>-->
                    </li>
                </ul>
            </template>
        </Column>
        <Column
            field="status"
            :header="$t('labels.status')"
            sortable
            :filterMatchModeOptions="[{label: 'Equals', value: 'equals'}]"
            ><template #body="{data}">
                <template v-if="data">
                    <Tag v-if="!data?.processingStartedAt" severity="warning">
                        <span>{{$t('labels.scheduled')}}</span>
                    </Tag>
                    <Tag v-else-if="!data?.processingEndedAt" severity="info">
                        <span>{{$t('labels.inProgress')}}</span>
                    </Tag>
                    <Tag v-else severity="success">
                        <span>{{$t('labels.done')}}</span>
                    </Tag>
                </template>
            </template>
        </Column>
        <Column :header="$t('labels.progress')" style="min-width: 14rem"
            ><template #body="{data}">
                <div v-if="data">
                    <div
                        v-if="data?.processingEndedAt"
                        v-tooltip.left="$t('labels.priceImport.totalSkus') + ' / ' + $t('labels.priceImport.mappedSkus') + ' / ' + $t('labels.priceImport.eolProducts')"
                    >
                        <span class="text-blue-400">{{ data.itemsCount }}</span>
                        /
                        <span class="text-green-400">
                            {{ data.statistics?.transformedItemsCount}}
                        </span>
                        /
                        <span class="text-orange-400">
                            {{ data.statistics?.eolItemsCount }}</span
                        >
                    </div>
                    <div
                        v-else
                        v-tooltip.left="$t('labels.importLogsResultHeaderTooltip')"
                    >
                        <span class="text-blue-400"
                            >{{ data.itemsCount + data.weclappProductsCount
                            }}</span
                        >
                        /
                        <span class="text-blue-400"
                            >{{ data.processedItemsCount +
                            data.processedWeclappProductsCount }}</span
                        >
                    </div>
                </div>
                <div class="flex align-items-center mt-2">
                    <div
                        class="surface-300 border-round overflow-hidden w-10rem lg:w-4rem"
                        style="height: 8px"
                    >
                        <div
                            class="h-full bg-blue-500"
                            :style="'width: ' + ((data.weclappProductsCount + data.itemsCount) > 0 ? Math.floor(((data.processedItemsCount +
                            data.processedWeclappProductsCount) * 100) /
                            (data.weclappProductsCount + data.itemsCount)) : 0) + '%'"
                        ></div>
                    </div>
                    <span class="text-blue-500 ml-2 font-medium">
                        {{ (data.weclappProductsCount + data.itemsCount) > 0 ?
                        Math.floor(((data.processedItemsCount +
                        data.processedWeclappProductsCount) * 100) /
                        (data.weclappProductsCount + data.itemsCount)) : 0 }} %
                    </span>
                </div>
            </template></Column
        >
        <Column :header="$t('labels.overview')" style="min-width: 8rem"
            ><template #body="{data}">
                <div v-if="data && data.processingEndedAt">
                    <div
                        v-tooltip.left="$t('labels.priceImport.unchangedProducts') + ' / ' + $t('labels.priceImport.costPriceChangeProducts') + ' / ' + $t('labels.priceImport.listPriceChangeProducts') + ' / ' + $t('labels.priceImport.wbtChangeProducts')"
                    >
                        <span class="text-green-400"
                            >{{ data.statistics?.nonChangedCount }}</span
                        >
                        /
                        <span class="text-orange-400">
                            {{ data.statistics?.costPriceChangedCount}}
                        </span>
                        /
                        <span class="text-purple-400">
                            {{ data.statistics?.listPriceChangedCount }}</span
                        >
                        /
                        <span class="text-blue-400">
                            {{ data.statistics?.wbtChangedCount }}</span
                        >
                    </div>
                </div>
            </template></Column
        >
        <Column
            field="uploadedBy"
            :header="$t('labels.uploadedBy')"
            style="min-width: 16rem"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                />
            </template>
            <template #body="{data}">
                <div>
                    <span class="font-semibold"> {{data.uploadedBy}}</span>
                </div>
                <div>
                    <span class="text-xs"> {{data.importedAt}}</span>
                </div>
            </template>
        </Column>
        <Column
            field="lastRunBy"
            :header="$t('labels.processedBy')"
            style="min-width: 16rem"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-sm"
                />
            </template>
            <template #body="{data}">
                <template v-if="data.importInProgress"
                    ><Tag severity="danger">
                        <span style="text-transform: uppercase"
                            >{{$t('labels.inProgress')}}</span
                        >
                    </Tag>
                </template>
                <template v-else>
                    <div>
                        <span class="font-semibold"> {{data.lastRunBy}}</span>
                    </div>
                    <div>
                        <span class="text-xs"> {{data.lastRunAt}}</span>
                    </div>
                </template>
            </template>
        </Column>
        <Column style="min-width: 27rem">
            <template #body="{data}">
                <p-button
                    v-if="data?.processingEndedAt"
                    class="text-xs details-button mr-2"
                    :label="$t('labels.overview')"
                    severity="success"
                    icon="fas fa-external-link-alt"
                    @click="navigateToSummary(data)"
                />
                <p-button
                    v-if="data?.processingEndedAt"
                    class="text-xs details-button mr-2"
                    label="Details"
                    severity="info"
                    icon="fas fa-external-link-alt"
                    @click="navigateToDetails(data)"
                />
                <template
                    v-if="!data?.importInProgress && data?.processingEndedAt && data?.statistics?.regularItemsCount > data?.statistics?.handledItemsCount"
                >
                    <p-button
                        class="text-xs details-button mr-2"
                        label=""
                        severity="danger"
                        icon="fas fa-play"
                        @click="runManually(data)"
                    />
                    <p-button
                        v-if="!data.lastRunAtTimestamp"
                        class="text-xs details-button"
                        :label="$t('buttons.rejectImport')"
                        severity="warning"
                        icon="fas fa-trash"
                        @click="rejectImport(data)"
                    />
                </template>
            </template>
        </Column>
    </DataTable>
</Panel>
