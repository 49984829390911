import {ref, watch} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import {i18n} from '@/utils/i18n';
import countries from 'i18n-iso-countries';
import Divider from 'primevue/divider';

export default {
    emits: ['choice-confirmed'],
    components: {
        PrimeDialog: Dialog,
        'p-button': Button,
        'p-divider': Divider
    },
    props: {
        displayConfirmationDialog: Boolean,
        shopData: Object
    },
    setup(props: any, context: any) {
        const showDialog = ref(false);
        const shopData = ref(null);

        watch(props, (args) => {
            showDialog.value = args.displayConfirmationDialog;
            shopData.value = args.shopData;
        });

        const handleAnswer = (confirmed: boolean) => {
            showDialog.value = false;
            context.emit('choice-confirmed', confirmed);
        };

        const resolveCountryFromCode = (countryCode: string) => {
            return (
                countries.getName(countryCode, i18n.global.locale) ||
                countries.getName(countryCode, 'en')
            );
        };

        return {
            showDialog,
            shopData,
            handleAnswer,
            resolveCountryFromCode
        };
    }
};
