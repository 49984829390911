<div class="mt-2">
    <p-button
        class="text-sm"
        @click="bulkAddAccessoriesDialogVisible = true; bulkAddDialogPlatform = secondPlatform;"
        severity="success"
    >
        <div class="flex align-content-center">
            <span
                class="p-button-icon p-button-icon-left pi pi-plus"
                data-pc-section="icon"
            ></span
            ><span class="p-button-label"
                >{{$t('buttons.addNewGeneric')}}
            </span>
            <span
                v-if="productDetails?.shopware"
                class="sales-channel-dot w-1rem h-1rem ml-2"
                :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
            ></span>
            <span
                v-if="productDetails?.secondShopware"
                class="sales-channel-dot w-1rem h-1rem"
                :class="{'ml-1': productDetails?.shopware, 'ml-2': !productDetails?.shopware, 'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
            ></span>
        </div>
    </p-button>
</div>
<TabView class="tabview-custom text-sm">
    <TabPanel :disabled="!productDetails?.shopware">
        <template #header>
            <i class="pi pi-sitemap mr-2"></i>
            <span>{{ $t("labels.articleAccessories") }}</span>
            <span
                class="sales-channel-dot w-1rem h-1rem ml-2"
                :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
            ></span>
        </template>
        <Accessories
            v-if="productDetails?.shopware?.productNumber"
            :product-number="productDetails.shopware.productNumber"
            :reloadRequired="gridReloadRequired"
            @cross-platform-grid-reload="closeAddUsingSelectedMainProducts(true)"
        ></Accessories>
    </TabPanel>
    <TabPanel :disabled="!productDetails?.secondShopware">
        <template #header>
            <i class="pi pi-sitemap mr-2"></i>
            <span>{{ $t("labels.articleAccessories") }}</span>
            <span
                class="sales-channel-dot w-1rem h-1rem ml-2"
                :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
            ></span>
        </template>
        <Accessories
            v-if="productDetails?.secondShopware?.productNumber"
            :product-number="productDetails.secondShopware.productNumber"
            :platform="secondPlatform"
            :reloadRequired="gridReloadRequired"
            @cross-platform-grid-reload="closeAddUsingSelectedMainProducts(true)"
        ></Accessories>
    </TabPanel>
</TabView>

<BulkAddAccessories
    :products="[{...productDetails?.weclapp, shopwareData: productDetails?.shopware, secondShopwareData: productDetails?.secondShopware}]"
    :displayResponsive="bulkAddAccessoriesDialogVisible"
    @close-dialog="closeAddUsingSelectedMainProducts"
/>
<PrimeDialog
    v-model:visible="bulkAddResultVisible"
    :header="$t('labels.result')"
    @hide="bulkAddAccessoriesDialogVisible = false;"
    modal
>
    <TabView class="tabview-custom text-sm">
        <TabPanel v-for="platform in Object.keys(bulkAddData)"
            ><template #header
                ><span
                    class="sales-channel-dot"
                    :class="{'sales-channel-teltec':['teltec', 'teltec-demo'].includes(platform), 'sales-channel-videodata':['videodata', 'videodata-demo'].includes(platform)}"
                ></span>
            </template>
            <div
                class="flex justify-content-between"
                v-for="productNumber in Object.keys(bulkAddData[platform])"
            >
                <DataTable
                    class="p-datatable-sm text-sm mr-5"
                    stripedRows
                    paginator
                    dataKey="id"
                    :rows="10"
                    :value="bulkAddData[platform][productNumber]['oldAccessories'] || []"
                    responsiveLayout="scroll"
                    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rows-per-page-options="[10,20,50,100,200]"
                    current-page-report-template="{first} to {last} of {totalRecords}"
                >
                    <template #header>
                        {{"$t('labels.previousArticleAccessories')"}}
                    </template>
                    <Column
                        field="productNumber"
                        sortable
                        :header="$t('labels.articleNumber')"
                    ></Column>
                    <Column
                        field="name"
                        sortable
                        :header="$t('labels.articleName')"
                    ></Column>
                </DataTable>
                <DataTable
                    class="p-datatable-sm text-sm"
                    stripedRows
                    paginator
                    dataKey="id"
                    :rows="10"
                    :value="bulkAddData[platform][productNumber]['newAccessories'] || []"
                    responsiveLayout="scroll"
                    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    :rows-per-page-options="[10,20,50,100,200]"
                    current-page-report-template="{first} to {last} of {totalRecords}"
                >
                    <template #header>
                        {{"$t('labels.currentArticleAccessories')"}}
                    </template>
                    <Column
                        field="productNumber"
                        sortable
                        :header="$t('labels.articleNumber')"
                    ></Column>
                    <Column
                        field="name"
                        sortable
                        :header="$t('labels.articleName')"
                    ></Column>
                </DataTable>
            </div>
        </TabPanel>
    </TabView>
</PrimeDialog>
