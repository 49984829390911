import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0370f0a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full flex justify-content-between mt-3" }

export function render(_ctx, _cache) {
  const _component_loading = _resolveComponent("loading")
  const _component_tiny = _resolveComponent("tiny")
  const _component_p_button = _resolveComponent("p-button")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('labels.addComment'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[2] || (_cache[2] = $event => ((_ctx.showDialog) = $event)),
    closable: false,
    breakpoints: {'960px': '75vw', '640px': '90vw'},
    style: {width: '640px'},
    modal: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_loading, {
        active: _ctx.savingInProgress,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.savingInProgress) = $event))
      }, null, 8, ["active"]),
      (_ctx.ticketDetails)
        ? (_openBlock(), _createBlock(_component_tiny, {
            key: 0,
            tinymceScriptSrc: _ctx.tinyUrl,
            modelValue: _ctx.comment,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.comment) = $event)),
            init: {
                    height: 300,
                    menubar: false,
                    plugins: 'lists link image emoticons code table anchor charmap fullscreen paste template',
                    toolbar: 'undo redo | styleselect | template | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code',
                    templates : _ctx.tinyTemplates,
                    template_replace_values: {
                        'ticketOwner': _ctx.ticketDetails?.customer?.company ||
                                (_ctx.ticketDetails?.customer?.firstName +
                            ' ' +
                            _ctx.ticketDetails?.customer?.lastName),
                        'ticketNumber': _ctx.ticketDetails.ticketNumber,
                    },
                    browser_spellcheck: true
                }
          }, null, 8, ["tinymceScriptSrc", "modelValue", "init"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_p_button, {
          severity: "danger",
          onClick: _ctx.onCancelClick
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_p_button, {
          disabled: !_ctx.comment,
          severity: "success",
          onClick: _ctx.onConfirmClicked
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}