import {onMounted, ref, toRefs, watch} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import {useVuelidate} from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';
import {i18n} from '@/utils/i18n';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import InputNumber from 'primevue/inputnumber';

import {
    ekPriceCalculationForSupplySource,
    stripTagsAndTruncate
} from '@/utils/helpers';
import {getAll, getSome} from '@/services/metadata';

export default {
    emits: [
        'back-button-clicked',
        'next-button-clicked',
        'add-wizard-form-dirty'
    ],
    props: {
        pairValues: Object,
        savingInProgress: Boolean
    },
    components: {
        InputText,
        'p-button': Button,
        'p-checkbox': Checkbox,
        'p-dropdown': Dropdown,
        'p-calendar': Calendar,
        InputNumber,
        PrimeDialog: Dialog
    },
    setup(props: any, context: any) {
        const {savingInProgress} = toRefs(props);
        const submitted = ref(false);
        const existingSupplySources = ref([]);
        const allSuppliers = ref([]);
        const productSource = ref(null);
        const pairValues = ref(null);

        onMounted(() => {
            getAll(['supplier'], false).then((data: any) => {
                allSuppliers.value = (data.data['supplier'] || [])
                    .map(
                        (item: {
                            id: string;
                            name: string;
                            supplierNumber: string;
                            company2: string;
                        }) => {
                            return {
                                label:
                                    stripTagsAndTruncate(
                                        [item.name, item.company2].join(' '),
                                        150,
                                        '...'
                                    ) +
                                    ' (' +
                                    item.supplierNumber +
                                    ') ',
                                value: item.id,
                                name: item.name
                            };
                        }
                    )
                    .sort((a: any, b: any) => {
                        const nameA = a.label.toUpperCase(); // ignore upper and lowercase
                        const nameB = b.label.toUpperCase(); // ignore upper and lowercase
                        if (nameA < nameB) {
                            return -1;
                        }

                        return nameA > nameB ? 1 : 0;
                    });
            });
        });

        const prePopulateForm = () => {
            if (
                pairValues.value?.productSource &&
                productSource.value !== pairValues.value?.productSource
            ) {
                productSource.value = pairValues.value?.productSource;
                if (pairValues.value?.supplySources) {
                    existingSupplySources.value =
                        pairValues.value?.supplySources;
                    state.value = Object.assign(
                        {},
                        {
                            supplySources: pairValues.value?.supplySources.map(
                                (obj: any) => ({
                                    id: obj.id,
                                    articleNumber: pairValues.value
                                        ?.productUsedType
                                        ? resolveSku(
                                              pairValues.value.productUsedType,
                                              obj.articleNumber
                                          )
                                        : obj.articleNumber,
                                    name: obj.name,
                                    supplier: obj.supplier,
                                    ekPrice:
                                        ekPriceCalculationForSupplySource(obj)
                                })
                            )
                        }
                    );
                }

                if (pairValues.value?.productUsedType) {
                    getSome(
                        'supplier',
                        'company',
                        ['Teltec Hamburg'],
                        false
                    ).then((data: any) => {
                        if (Object.values(data.data).length === 1) {
                            const ttHamburg: any = Object.values(data.data)[0];
                            if (
                                state.value.supplySources &&
                                state.value.supplySources.findIndex(
                                    (item: any) =>
                                        item.supplier === ttHamburg.id
                                ) === -1
                            ) {
                                state.value.supplySources.unshift({
                                    id: null,
                                    articleNumber: null,
                                    name: null,
                                    supplier: ttHamburg.id,
                                    ekPrice: null,
                                    ekStartDate: new Date()
                                });
                            }
                        }
                    });
                }
            }
        };

        const state = ref({supplySources: []});

        const resolveSku = (productUsedType: string, originalSku: string) => {
            let suffix = '';
            switch (productUsedType) {
                case 'OpenBox':
                    suffix = '.opb';
                    break;
                case 'Used-3-Stars':
                case 'Used-2-Stars':
                case 'Used-1-Star':
                    suffix = '.used';
                    break;
                case 'DEMO-POOL':
                    suffix = '.demo';
                    break;
                case 'ExDemo-Grade-A':
                case 'ExDemo-Grade-B':
                    suffix = '.exdemo';
                    break;
                case 'KOMMISSIONSWARE':
                    suffix = '.loa';
                    break;
            }

            return originalSku + suffix;
        };

        const rules = {
            supplySources: {
                $each: helpers.forEach({
                    id: {},
                    articleNumber: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        ),
                        notSameAsSource: helpers.withMessage(
                            i18n.global.t('messages.valueCantBeSame'),
                            (newValue: string) => {
                                return !existingSupplySources.value.some(
                                    (existingSupplySource: any) =>
                                        existingSupplySource.articleNumber ==
                                        newValue
                                );
                            }
                        )
                    },
                    name: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    },
                    supplier: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    },
                    ekPrice: {
                        required: helpers.withMessage(
                            i18n.global.t('messages.valueIsRequired'),
                            required
                        )
                    },
                    ekStartDate: {}
                })
            }
        };

        watch(props, (args) => {
            if (args.pairValues && pairValues.value === null) {
                pairValues.value = args.pairValues;
                prePopulateForm();
            }
        });

        const v$ = useVuelidate(rules, state);

        watch(v$, (args) => {
            if (args.$anyDirty) {
                context.emit('add-wizard-form-dirty', {});
            }
        });

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            context.emit('next-button-clicked', {
                stepIndex: 4,
                formValues: {
                    supplySources: state.value.supplySources
                }
            });
        };

        const onBackButtonClicked = (event: any) => {
            event.preventDefault();
            context.emit('back-button-clicked', 4);
        };

        const addSupplySource = () => {
            state.value.supplySources.push({
                id: null,
                articleNumber: null,
                name: null,
                supplier: null,
                ekPrice: null,
                ekStartDate: new Date()
            });
        };
        const removeSupplySource = (index: number) => {
            state.value.supplySources.splice(index, 1);
        };

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            onBackButtonClicked,
            locale: i18n.global.locale,
            savingInProgress,
            addSupplySource,
            removeSupplySource,
            allSuppliers
        };
    }
};
