<loading v-model:active="loading" />
<Panel>
    <template #header>
        <span class="font-bold">
            {{ cronType ? $t("labels." + cronType + "s") : $t("labels.cronjobs")
            }}
        </span>
    </template>
    <p-fieldset v-if="dialogVisible" :legend="selectedRow?.name">
        <form @submit.prevent="handleSubmit(!v$.$invalid)">
            <div class="grid">
                <div class="col field">
                    <label>{{ $t('labels.mail.automatic') }} </label>
                    <Checkbox
                        v-model="v$.automatic.$model"
                        binary
                        class="ml-2 mb-1"
                        :disabled="v$.cycle.$model === '6'"
                    />
                </div>
            </div>
            <div
                class="grid p-fluid"
                v-if="v$.automatic.$model && v$.cycle.$model !== '6'"
            >
                <div class="col field">
                    <label>{{ $t('labels.mail.scheduled') }} </label>
                    <p-dropdown
                        v-model="v$.cycle.$model"
                        optionLabel="label"
                        optionValue="value"
                        @change="onCycleChange"
                        :options="[
                                        {
                                            label: $t('labels.mail.schedule1'),
                                            value: '1'
                                        },
                                    {
                                        label: $t('labels.mail.schedule2'),
                                        value: '2'
                                    }, 
                                    {
                                        label: $t('labels.mail.schedule3'),
                                        value: '3'
                                    }, 
                                    {
                                        label: $t('labels.mail.schedule4'),
                                        value: '4'
                                    }]"
                        :class="{'p-invalid':v$.cycle.$invalid && submitted}"
                    />
                    <small
                        v-if="(v$.cycle.$invalid && submitted) || v$.cycle.$pending.$response"
                        class="p-error"
                        >{{v$.cycle.required.$message.replace('Value',
                        $t('labels.mail.scheduled'))}}</small
                    >
                </div>
                <div
                    class="col field"
                    v-if="v$.cycle.$model === '3' || v$.cycle.$model === '4'"
                >
                    <label
                        >{{ $t('labels.mail.' + (v$.cycle.$model === '3' ?
                        'dayOfWeekLabel' : 'dayOfMonthLabel' )) }}
                    </label>

                    <MultiSelect
                        v-model="v$.daysIndices.$model"
                        optionLabel="label"
                        optionValue="value"
                        :options="v$.cycle.$model === '3' ? daysOfWeekOptions : daysOfMonthOptions"
                        :class="{'p-invalid':v$.daysIndices.$invalid && submitted}"
                    />
                    <small
                        v-if="(v$.daysIndices.$invalid && submitted) || v$.daysIndices.$pending.$response"
                        class="p-error"
                        >{{v$.daysIndices.required.$message.replace('Value',
                        $t('labels.mail.' + (v$.cycle.$model === '3' ?
                        'dayOfWeekLabel' : 'dayOfMonthLabel' )))}}</small
                    >
                </div>
                <div class="col field">
                    <label
                        >{{ $t(v$.cycle.$model === '1' ? 'labels.mail.firstRun'
                        : 'labels.at') }}
                    </label>

                    <Calendar
                        v-model="v$.startTime.$model"
                        :showTime="true"
                        :timeOnly="true"
                    />
                </div>
                <div class="col field" v-if="v$.cycle.$model === '1'">
                    <label
                        v-html="$t('labels.mail.minuteInterval',{value: '⇩'})"
                    />

                    <InputNumber
                        :max="1440"
                        style="max-height: 38px"
                        autocomplete="off"
                        v-model="v$.minuteInterval.$model"
                        :class="{'p-invalid':v$.minuteInterval.$invalid && submitted}"
                    />

                    <small
                        v-if="(v$.minuteInterval.$invalid && submitted) || v$.minuteInterval.$pending.$response"
                        class="p-error"
                        >{{ v$.minuteInterval.required.$message }}
                    </small>
                </div>
            </div>
            <p-divider />
            <div class="grid p-fluid">
                <div class="col field">
                    <label
                        >{{ isMailOnlyTypeOfJob ? $t('labels.mail.toAddress') :
                        $t('labels.sendResultTo') }} ({{
                        $t('labels.mail.separatedBySemiColon') }})
                    </label>
                    <p-textarea
                        rows="2"
                        :placeholder="$t('labels.mail.toAddress')"
                        autocomplete="off"
                        v-model="v$.emailToAddress.$model"
                        :class="{'p-invalid':v$.emailToAddress.$invalid && submitted}"
                    />
                    <small
                        v-if="(v$.emailToAddress.$invalid && submitted) || v$.emailToAddress.$pending.$response"
                        class="p-error"
                        >{{v$.emailToAddress.required.$message}}</small
                    >
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="col field">
                    <label
                        >{{ $t('labels.mail.ccAddress') }} ({{
                        $t('labels.mail.separatedBySemiColon') }})
                    </label>

                    <InputText
                        autocomplete="off"
                        v-model="v$.emailCcAddress.$model"
                        :class="{'p-invalid':v$.emailCcAddress.$invalid && submitted}"
                    />
                    <small
                        v-if="(v$.emailCcAddress.$invalid && submitted) || v$.emailCcAddress.$pending.$response"
                        class="p-error"
                        >{{v$.emailCcAddress.required.$message}}</small
                    >
                </div>
                <div class="col field">
                    <label>{{ $t('labels.mail.variables') }} </label>

                    <ul v-if="selectedRow?.variables">
                        <li
                            v-for="element in selectedRow?.variables.split(',')"
                        >
                            {{ element }}
                        </li>
                    </ul>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="col field">
                    <div class="flex justify-content-between flex-wrap">
                        <label>{{ $t('labels.mail.subject') }} </label>
                        <img
                            :src="require('@/assets/img/flag_placeholder.png')"
                            class="flag flag-de mb-3"
                        />
                    </div>
                    <InputText
                        autocomplete="off"
                        v-model="v$.emailSubject.$model.de"
                        :class="{'p-invalid':v$.emailSubject.$invalid && submitted}"
                    />
                    <small
                        v-if="(v$.emailSubject.$invalid && submitted) || v$.emailSubject.$pending.$response"
                        class="p-error"
                        >{{v$.emailSubject.required.$message}}</small
                    >
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="col field">
                    <div class="flex justify-content-between flex-wrap">
                        <label>{{ $t('labels.mail.body') }} </label>
                        <img
                            :src="require('@/assets/img/flag_placeholder.png')"
                            class="flag flag-de mb-3"
                        />
                    </div>
                    <tiny
                        :tinymceScriptSrc="tinyUrl"
                        v-model="v$.emailBody.$model.de"
                        :init="{
                        height: 500,
                        menubar: false,
                        plugins: 'lists link image emoticons code table anchor charmap fullscreen paste',
                        toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code'
                    }"
                    ></tiny>
                    <small
                        v-if="(v$.emailBody.$invalid && submitted) || v$.emailBody.$pending.$response"
                        class="p-error"
                        >{{v$.emailBody.required.$message}}</small
                    >
                </div>
            </div>
            <p-divider />
            <div class="grid p-fluid">
                <div class="col field">
                    <div class="flex justify-content-between flex-wrap">
                        <label>{{ $t('labels.mail.subject') }} </label>

                        <img
                            :src="require('@/assets/img/flag_placeholder.png')"
                            class="flag flag-gb mb-3"
                        />
                    </div>
                    <InputText
                        autocomplete="off"
                        v-model="v$.emailSubject.$model.en"
                        class="mb-3"
                    />
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="col field">
                    <div class="flex justify-content-between flex-wrap">
                        <label>{{ $t('labels.mail.body') }} </label>

                        <img
                            :src="require('@/assets/img/flag_placeholder.png')"
                            class="flag flag-gb mb-3"
                        />
                    </div>
                    <tiny
                        :tinymceScriptSrc="tinyUrl"
                        v-model="v$.emailBody.$model.en"
                        :init="{
                        height: 500,
                        menubar: false,
                        plugins: 'lists link image emoticons code table anchor charmap fullscreen paste',
                        toolbar: 'undo redo | styleselect | backcolor forecolor | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code'
                    }"
                    ></tiny>
                </div>
            </div>
            <div class="w-full flex justify-content-between mt-3">
                <p-button
                    severity="danger"
                    v-text="$t('buttons.cancel')"
                    @click="handleDialogClose"
                >
                </p-button>
                <p-button
                    :disabled="savingInProgress"
                    severity="success"
                    v-text="savingInProgress ? $t('labels.inProgress') : $t('buttons.confirm')"
                    type="submit"
                >
                </p-button>
            </div>
        </form>
    </p-fieldset>

    <div v-if="!dialogVisible">
        <DataTable
            class="p-datatable-sm text-sm"
            stripedRows
            paginator
            lazy
            scrollable
            scrollHeight="calc(100vh - 23rem)"
            :rows="20"
            ref="dt"
            dataKey="id"
            :totalRecords="totalRecords"
            :value="crons"
            responsiveLayout="scroll"
            paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rows-per-page-options="[10,20,50]"
            current-page-report-template="{first} to {last} of {totalRecords}"
            v-model:filters="filters"
            filterDisplay="row"
            @page="onPage"
            @filter="onFilter"
            @sort="onSort"
            v-model:expandedRows="expandedRows"
        >
            <Column :expander="true" headerStyle="width: 3em" />
            <Column
                v-if="!isOneTimeTypeOfJob"
                field="automatic"
                dataType="boolean"
                style="width: 8rem"
                :header="$t('labels.mail.execution')"
            >
                <template #filter="{filterModel, filterCallback}">
                    <TriStateCheckbox
                        v-model="filterModel.value"
                        @change="filterCallback()"
                    />
                </template>
                <template #body="{data}">
                    <div v-if="data.automatic">
                        {{ $t('labels.mail.automatic') }}
                    </div>
                    <div v-else>{{ $t('labels.mail.manual') }}</div>
                </template>
            </Column>
            <Column
                v-if="isOneTimeTypeOfJob && editPermissionAvailable"
                field="manuallyInitiatedBy"
                :header="$t('labels.initiatedBy')"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-sm"
                        :placeholder="$t('labels.email')"
                    />
                </template>
            </Column>
            <Column
                field="name"
                sortable
                style="min-width: 12rem"
                :header="$t('labels.name')"
                class="text-bold"
            >
                <template #filter="{filterModel,filterCallback}">
                    <InputText
                        v-model="filterModel.value"
                        @keydown.enter="filterCallback()"
                        class="p-column-filter text-sm"
                        :placeholder="$t('labels.email')"
                    />
                </template>
            </Column>
            <Column field="description" :header="$t('labels.description')" />
            <Column
                v-if="!isOneTimeTypeOfJob"
                :header="$t('labels.mail.scheduled')"
                style="min-width: 12rem"
            >
                <template #body="{data}">
                    <div v-if="data.automatic">
                        <span
                            v-html="
                                            $t('labels.mail.schedule' +
                                            (data.cycle || 2) + 'Desc', {
                                            startTime: data.startTime,
                                            minuteInterval: data.minuteInterval,
                                            label:
                                            $t('labels.mail.dayOfMonthLabel'),
                                            daysOfWeek: data.cycle !== 3 ? '' : (data.daysIndices ||
                                            []).map((item)=>
                                            $t('labels.mail.dayOfWeek' +
                                            item)).join(', '), 
                                            daysOfMonth: data.cycle !== 4 ? '' :
                                            (data.daysIndices || []).join(', ')
                                            })"
                        ></span>
                        <span
                            v-if="data.cycle === 6"
                            class="pi pi-exclamation-triangle"
                        ></span>
                    </div>
                </template>
            </Column>
            <Column
                v-if="!isOneTimeTypeOfJob"
                field="variables"
                :header="$t('labels.mail.variables')"
            >
                <template #body="{data}">
                    <ul v-if="data.variables">
                        <li v-for="element in data.variables.split(',')">
                            {{ element }}
                        </li>
                    </ul>
                </template>
            </Column>
            <Column
                style="max-width: 16rem"
                v-if="isOneTimeTypeOfJob"
                field="lastRunAtFormatted"
                :header="$t('labels.status')"
                sortable
            >
                <template #body="{data}">
                    <div
                        v-if="data.lastRunAtFormatted"
                        v-html="$t('labels.executedAt', {time:
                                    data.lastRunAtFormatted})"
                    />
                    <div
                        v-else-if="data.startTime"
                        v-html="$t('labels.plannedAt', {time:
                                    data.startTimeFormatted})"
                    />
                    <div v-else>{{ $t('labels.mail.scheduled') }}</div>
                </template>
            </Column>
            <Column
                style="max-width: 16rem"
                v-if="isOneTimeTypeOfJob"
                field="payload"
                header="Payload"
            >
                <template #body="{data}">
                    <ul>
                        <li
                            v-for="(element, index) in JSON.parse(data.payload)"
                        >
                            <span
                                v-if="typeof element === 'object' && element !== null"
                            >
                                <b>{{index}}</b>:
                                {{cleanUpLengthyProperties(element)}}
                            </span>
                            <span v-else> <b>{{index}}</b>: {{element}} </span>
                        </li>
                    </ul>
                </template>
            </Column>
            <Column style="min-width: 8rem">
                <template #body="{data}">
                    <div class="button-container">
                        <i
                            v-if="editPermissionAvailable && !data.automatic && !isOneTimeTypeOfJob"
                            class="pi pi-forward text-sm mr-2"
                            v-tooltip.left="$t('labels.runJobManually')"
                            @click="handleRunDialogOpen($event, data)"
                        ></i>
                        <i
                            class="pi pi-envelope text-sm mr-2"
                            v-tooltip.left="$t('labels.mailLogs')"
                            @click="handleMailLogsClick($event, data)"
                        ></i>
                        <i
                            class="pi pi-book text-sm mr-2"
                            v-tooltip.left="$t('labels.logs')"
                            @click="handleLogsClick($event, data)"
                        ></i>

                        <i
                            v-if="editPermissionAvailable && !isOneTimeTypeOfJob"
                            class="pi pi-pencil text-sm mr-2"
                            v-tooltip.left="$t('buttons.edit')"
                            @click="handleDialogOpen($event, data)"
                        ></i>
                    </div>
                </template>
            </Column>
            <template #expansion="slotProps">
                <div class="grid">
                    <div class="col-2 field">
                        <label>{{ $t('labels.mail.toAddress') }} </label>
                        <span class="ml-3">
                            {{slotProps.data?.emailToAddress}}</span
                        >
                    </div>
                    <div class="col-10 field">
                        <img
                            :src="require('@/assets/img/flag_placeholder.png')"
                            class="flag flag-de"
                            @click="switchCronTemplateLanguage(slotProps.data?.id, 'de')"
                            :style="[('de' !== slotProps.data?.selectedLanguage) ? 'filter: brightness(0.5);cursor:pointer': '']"
                        />

                        <img
                            :src="require('@/assets/img/flag_placeholder.png')"
                            class="flag flag-gb ml-3"
                            disabled
                            @click="switchCronTemplateLanguage(slotProps.data?.id, 'en')"
                            :style="[('en' !== slotProps.data?.selectedLanguage) ? 'filter: brightness(0.5);cursor:pointer': '']"
                        />
                    </div>
                </div>
                <div v-if="slotProps.data?.emailCcAddress" class="grid">
                    <div class="col field">
                        <label>{{ $t('labels.mail.ccAddress') }} </label>
                        <span class="ml-3">
                            {{slotProps.data?.emailCcAddress}}</span
                        >
                    </div>
                </div>
                <template
                    v-for="(subject, language) in slotProps.data?.emailSubject"
                >
                    <div
                        class="grid"
                        v-if="slotProps.data?.selectedLanguage === language"
                    >
                        <div class="col field">
                            <label>{{ $t('labels.mail.subject') }} </label>
                            <span class="ml-3"> {{subject}}</span>
                        </div>
                    </div>
                </template>
                <p-divider />
                <template v-for="(body, language) in slotProps.data?.emailBody">
                    <div
                        v-if="slotProps.data?.selectedLanguage === language"
                        v-html="body"
                    ></div>
                </template>
            </template>
        </DataTable>
    </div>
</Panel>
<MailLogsOverview
    :cron="selectedRow"
    :display-logs-dialog="mailLogsOverviewDialogVisible"
    @hide="mailLogsOverviewDialogVisible = false"
/>
<CronJobLogsOverview
    :cron="selectedRow"
    :display-logs-dialog="logsOverviewDialogVisible"
    @hide="logsOverviewDialogVisible = false"
/>
<ManualRunDialog
    :job-id="selectedRow?.id"
    :is-mail-dialog="isMailOnlyTypeOfJob"
    :display-dialog="runDialogVisible"
    @hide="runDialogVisible = false"
/>
