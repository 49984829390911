import {computed, ref} from 'vue';
import {useToast} from 'vue-toastification';
import CheckDuplicateStep from '@/components/steps/add-product/check-duplicate-step.vue';
import GlobalFieldsStep from '@/components/steps/add-product/global-fields-step.vue';
import EnvSpecificFieldsStep from '@/components/steps/add-product/env-specific-fields-step.vue';
import EnvSpecificDescriptionsStep from '@/components/steps/add-product/env-specific-descriptions-step.vue';
import EnvSpecificSupplySourcesStep from '@/components/steps/add-product/env-specific-supply-sources-step.vue';
import {addNew} from '@/services/products';
import store from '@/store';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import {onBeforeRouteLeave} from 'vue-router';
import ConfirmLeave from '@/components/dialog/general/confirm-leave.vue';
import router from '@/router';
import Panel from 'primevue/panel';
import Stepper from 'primevue/stepper';
import StepperPanel from 'primevue/stepperpanel';

export default {
    components: {
        CheckDuplicateStep,
        GlobalFieldsStep,
        EnvSpecificFieldsStep,
        EnvSpecificDescriptionsStep,
        EnvSpecificSupplySourcesStep,
        PrimeDialog: Dialog,
        ConfirmLeave: ConfirmLeave,
        Panel,
        'p-button': Button,
        Stepper,
        StepperPanel
    },
    props: {
        displayDialog: Boolean
    },
    setup() {
        onBeforeRouteLeave((to: any) => {
            if (addWizardFormDirty.value) {
                displayConfirmLeaveDialog.value = true;
                addWizardFormDirty.value = false;
                nextRoute.value = to;
                return false;
            }
        });

        const toast = useToast();
        const activeIndex = ref(0);
        const duplicateCheckValues = ref({});
        const pairValues = ref({});
        const savingInProgress = ref(false);
        const formValues = ref({
            step1: {},
            step2: {},
            step3: {}
        });

        const showResultDialog = ref(false);
        const displayConfirmLeaveDialog = ref(false);

        const nextRoute = ref(null);

        const addWizardFormDirty = ref(false);
        const saveResult = ref();

        const onBackButtonClicked = () => {
            switch (activeIndex.value) {
                case 2:
                    Object.assign(pairValues.value, formValues.value.step1);
                    break;
                default:
                    Object.assign(pairValues.value, formValues.value.step2);
                    break;
            }
        };

        const onChoiceConfirmed = (choice: boolean) => {
            if (choice) {
                router.push(nextRoute.value);
            } else {
                displayConfirmLeaveDialog.value = false;
                addWizardFormDirty.value = true;
            }
        };

        const hasError = computed(() => {
            return saveResult.value && !!saveResult.value['error'];
        });

        const onNextButtonClicked = (event: {formValues: any}) => {
            switch (activeIndex.value) {
                case 1:
                    Object.assign(formValues.value, {
                        step1: event.formValues
                    });
                    break;
                case 2:
                case 3:
                    Object.assign(
                        formValues.value,
                        Object.assign(formValues.value, {
                            step2: {
                                ...formValues.value.step2,
                                ...event.formValues
                            }
                        })
                    );
                    break;
                case 4:
                    Object.assign(formValues.value, {
                        step3: event.formValues
                    });
                    break;
            }

            if (activeIndex.value === 4) {
                savingInProgress.value = true;
                addNew(formValues.value)
                    .then((data: any) => {
                        saveResult.value = data.data;
                        showResultDialog.value = true;
                        addWizardFormDirty.value = false;
                    })
                    .catch((error) => {
                        toast.error(
                            error.response?.data?.error || error.message
                        );
                    })
                    .finally(() => {
                        savingInProgress.value = false;
                    });
            }
        };

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );
        });

        const onEnvironmentsSelected = (event: any) => {
            duplicateCheckValues.value = event.duplicateCheckValues || {};
            pairValues.value = Object.assign({}, event.pairValues || {});
        };

        const onStartOverClick = () => {
            showResultDialog.value = false;
        };

        const onAddWizardFormDirty = () => {
            addWizardFormDirty.value = true;
        };

        return {
            duplicateCheckValues,
            pairValues,
            onEnvironmentsSelected,
            onBackButtonClicked,
            onNextButtonClicked,
            onAddWizardFormDirty,
            activeIndex,
            savingInProgress,
            showResultDialog,
            resolvedPlatform,
            saveResult,
            hasError,
            onStartOverClick,
            displayConfirmLeaveDialog,
            onChoiceConfirmed
        };
    }
};
