export default {
    setShopOrdersInTeltec: (
        state: {
            shopOrdersInTeltec: boolean;
            shopOrdersInVideodata: boolean;
        },
        payload: boolean
    ): void => {
        state.shopOrdersInTeltec = payload;
    },
    setShopOrdersInVideodata: (
        state: {
            shopOrdersInTeltec: boolean;
            shopOrdersInVideodata: boolean;
        },
        payload: boolean
    ): void => {
        state.shopOrdersInVideodata = payload;
    }
};
