import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-85f6cdba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "m-2" }
const _hoisted_2 = { class: "col-2" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "col" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "col-2 flex justify-content-end" }
const _hoisted_7 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_DataView = _resolveComponent("DataView")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")
  const _component_DeleteDialog = _resolveComponent("DeleteDialog")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ScrollPanel, { style: {"height":"calc(100vh - 37rem)"} }, {
        default: _withCtx(() => [
          _createVNode(_component_DataView, {
            value: _ctx.videos,
            layout: "grid"
          }, {
            grid: _withCtx((slotProps) => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.items, (videoItem, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "grid align-items-center p-2"
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("img", {
                      class: "item-preview-img",
                      src: videoItem.imageUrl,
                      alt: videoItem.name
                    }, null, 8, _hoisted_3)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("a", {
                      target: "_blank",
                      href: videoItem.url
                    }, _toDisplayString(videoItem.source === 'youtube' ?
                                'YouTube-Video': 'Vimeo-Video'), 9, _hoisted_5)
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("i", {
                      class: "pi pi-trash text-sm text-red-600 mr-2",
                      style: {"cursor":"pointer"},
                      onClick: $event => (_ctx.showDeleteDialog(videoItem))
                    }, null, 8, _hoisted_7)
                  ])
                ]))
              }), 128))
            ]),
            _: 1
          }, 8, ["value"])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_DeleteDialog, {
      displayResponsive: _ctx.displayDeleteDialog,
      "product-number": _ctx.shopwareDetails?.productNumber,
      selectedVideo: _ctx.selectedVideo,
      onCloseDialog: _ctx.closeDialogListener,
      platform: _ctx.platform
    }, null, 8, ["displayResponsive", "product-number", "selectedVideo", "onCloseDialog", "platform"])
  ], 64))
}