import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-449c9a82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"text-align":"center"} }
const _hoisted_2 = { class: "w-full mt-3 flex justify-content-between" }
const _hoisted_3 = { style: {"text-align":"center"} }
const _hoisted_4 = { class: "w-full mt-3 flex justify-content-between" }

export function render(_ctx, _cache) {
  const _component_LoadingPlugin = _resolveComponent("LoadingPlugin")
  const _component_ProductList = _resolveComponent("ProductList")
  const _component_TabPanel = _resolveComponent("TabPanel")
  const _component_ProductListLazy = _resolveComponent("ProductListLazy")
  const _component_Panel = _resolveComponent("Panel")
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")
  const _component_p_button = _resolveComponent("p-button")
  const _component_TabView = _resolveComponent("TabView")
  const _component_PrimeDialog = _resolveComponent("PrimeDialog")

  return (_openBlock(), _createBlock(_component_PrimeDialog, {
    header: _ctx.$t('labels.addArticleAccessories'),
    visible: _ctx.showDialog,
    "onUpdate:visible": _cache[3] || (_cache[3] = $event => ((_ctx.showDialog) = $event)),
    breakpoints: {'960px': '75vw'},
    style: {width: '85vw'},
    onHide: _cache[4] || (_cache[4] = $event => (_ctx.closeResponsive(false))),
    modal: true,
    position: "top"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_LoadingPlugin, {
        active: _ctx.loading,
        "onUpdate:active": _cache[0] || (_cache[0] = $event => ((_ctx.loading) = $event))
      }, null, 8, ["active"]),
      (_ctx.mainProductsSelected)
        ? (_openBlock(), _createBlock(_component_TabView, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_TabPanel, {
                header: _ctx.$t('labels.mainArticle')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ProductList, {
                    products: _ctx.products,
                    scrollableHeight: '55vh'
                  }, {
                    "product-platfrom-circle": _withCtx((foo) => [
                      _createElementVNode("div", {
                        class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-teltec':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'bg-gray-200': !foo.data?.shopwareData?.active}])
                      }, null, 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-videodata':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'bg-gray-200': !foo.data?.secondShopwareData?.active}])
                      }, null, 2)
                    ]),
                    _: 1
                  }, 8, ["products"])
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_TabPanel, {
                header: _ctx.$t('labels.articleAccessories')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ProductListLazy, {
                    onOnSelectionChange: _ctx.onSelectionChange,
                    selectionMode: "multiple",
                    scrollableHeight: '55vh'
                  }, {
                    "product-platfrom-circle": _withCtx((foo) => [
                      _createElementVNode("div", {
                        class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-teltec':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'bg-gray-200': !foo.data?.shopwareData?.active}])
                      }, null, 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-videodata':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'bg-gray-200': !foo.data?.secondShopwareData?.active}])
                      }, null, 2)
                    ]),
                    _: 1
                  }, 8, ["onOnSelectionChange"])
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_TabPanel, {
                header: _ctx.$t('labels.summary'),
                disabled: !_ctx.showSummaryTab
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ScrollPanel, {
                    style: {"height":"65vh"},
                    class: "w-full"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Panel, {
                        header: _ctx.$t('labels.articleAccessories'),
                        class: "mb-3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ProductList, { products: _ctx.selectedProducts }, {
                            "product-platfrom-circle": _withCtx((foo) => [
                              _createElementVNode("div", {
                                class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-teltec':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'bg-gray-200': !foo.data?.shopwareData?.active}])
                              }, null, 2),
                              _createElementVNode("div", {
                                class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-videodata':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'bg-gray-200': !foo.data?.secondShopwareData?.active}])
                              }, null, 2)
                            ]),
                            _: 1
                          }, 8, ["products"])
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createElementVNode("h1", _hoisted_1, [
                        _createVNode(_component_font_awesome_icon, {
                          style: {"margin-left":"30px"},
                          icon: ['fas', 'angle-double-down']
                        }),
                        _createVNode(_component_font_awesome_icon, {
                          style: {"margin-left":"30px"},
                          icon: ['fas', 'angle-double-down']
                        }),
                        _createVNode(_component_font_awesome_icon, {
                          style: {"margin-left":"30px"},
                          icon: ['fas', 'angle-double-down']
                        })
                      ]),
                      _createVNode(_component_Panel, {
                        header: _ctx.$t('labels.mainArticle')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ProductList, { products: _ctx.products }, {
                            "product-platfrom-circle": _withCtx((foo) => [
                              _createElementVNode("div", {
                                class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-teltec':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'bg-gray-200': !foo.data?.shopwareData?.active}])
                              }, null, 2),
                              _createElementVNode("div", {
                                class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-videodata':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'bg-gray-200': !foo.data?.secondShopwareData?.active}])
                              }, null, 2)
                            ]),
                            _: 1
                          }, 8, ["products"])
                        ]),
                        _: 1
                      }, 8, ["header"])
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_p_button, {
                      severity: "danger",
                      onClick: _cache[1] || (_cache[1] = $event => (_ctx.closeResponsive(false)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_p_button, {
                      onClick: _ctx.addArticleAccessories,
                      severity: "success"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ]),
                _: 1
              }, 8, ["header", "disabled"])
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_TabView, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_TabPanel, {
                header: _ctx.$t('labels.mainArticle')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ProductListLazy, {
                    onOnSelectionChange: _ctx.onSelectionChange,
                    selectionMode: "multiple"
                  }, {
                    "product-platfrom-circle": _withCtx((foo) => [
                      _createElementVNode("div", {
                        class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-teltec':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'bg-gray-200': !foo.data?.shopwareData?.active}])
                      }, null, 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-videodata':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'bg-gray-200': !foo.data?.secondShopwareData?.active}])
                      }, null, 2)
                    ]),
                    _: 1
                  }, 8, ["onOnSelectionChange"])
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_TabPanel, {
                header: _ctx.$t('labels.articleAccessories')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ProductList, { products: _ctx.products }, {
                    "product-platfrom-circle": _withCtx((foo) => [
                      _createElementVNode("div", {
                        class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-teltec':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'bg-gray-200': !foo.data?.shopwareData?.active}])
                      }, null, 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-videodata':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'bg-gray-200': !foo.data?.secondShopwareData?.active}])
                      }, null, 2)
                    ]),
                    _: 1
                  }, 8, ["products"])
                ]),
                _: 1
              }, 8, ["header"]),
              _createVNode(_component_TabPanel, {
                header: _ctx.$t('labels.summary'),
                disabled: !_ctx.showSummaryTab
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ScrollPanel, {
                    style: {"height":"65vh"},
                    class: "w-full"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Panel, {
                        header: _ctx.$t('labels.articleAccessories'),
                        class: "mb-3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ProductList, { products: _ctx.products }, {
                            "product-platfrom-circle": _withCtx((foo) => [
                              _createElementVNode("div", {
                                class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-teltec':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'bg-gray-200': !foo.data?.shopwareData?.active}])
                              }, null, 2),
                              _createElementVNode("div", {
                                class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-videodata':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'bg-gray-200': !foo.data?.secondShopwareData?.active}])
                              }, null, 2)
                            ]),
                            _: 1
                          }, 8, ["products"])
                        ]),
                        _: 1
                      }, 8, ["header"]),
                      _createElementVNode("h1", _hoisted_3, [
                        _createVNode(_component_font_awesome_icon, {
                          style: {"margin-left":"30px"},
                          icon: ['fas', 'angle-double-down']
                        }),
                        _createVNode(_component_font_awesome_icon, {
                          style: {"margin-left":"30px"},
                          icon: ['fas', 'angle-double-down']
                        }),
                        _createVNode(_component_font_awesome_icon, {
                          style: {"margin-left":"30px"},
                          icon: ['fas', 'angle-double-down']
                        })
                      ]),
                      _createVNode(_component_Panel, {
                        header: _ctx.$t('labels.mainArticle'),
                        class: "mb-3"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ProductList, { products: _ctx.selectedProducts }, {
                            "product-platfrom-circle": _withCtx((foo) => [
                              _createElementVNode("div", {
                                class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-teltec':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-videodata':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData?.active, 'sales-channel-teltec-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'sales-channel-videodata-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.shopwareData && !foo.data.shopwareData.active, 'bg-gray-200': !foo.data?.shopwareData?.active}])
                              }, null, 2),
                              _createElementVNode("div", {
                                class: _normalizeClass(["sales-channel-dot w-1rem h-1rem mr-1", {'sales-channel-videodata':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-teltec':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData?.active, 'sales-channel-videodata-border':['ttd', 'ttl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'sales-channel-teltec-border':['vdd', 'vdl'].includes(foo.resolvedPlatform?.threeLetterId) && foo.data?.secondShopwareData && !foo.data.secondShopwareData.active, 'bg-gray-200': !foo.data?.secondShopwareData?.active}])
                              }, null, 2)
                            ]),
                            _: 1
                          }, 8, ["products"])
                        ]),
                        _: 1
                      }, 8, ["header"])
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_p_button, {
                      severity: "danger",
                      onClick: _cache[2] || (_cache[2] = $event => (_ctx.closeResponsive(false)))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("buttons.cancel")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_p_button, {
                      onClick: _ctx.addArticleAccessories,
                      severity: "success"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t("buttons.confirm")), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                ]),
                _: 1
              }, 8, ["header", "disabled"])
            ]),
            _: 1
          }))
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}