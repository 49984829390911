import {computed, onMounted, ref, toRefs, watch} from 'vue';
import {getSingle as getSingleProduct} from '@/services/products';
import {getSingle} from '@/services/product-accessories';
import {getAll as getAllMetadata} from '@/services/metadata';
import LoadingPlugin from 'vue-loading-overlay';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Column from 'primevue/column';
import Toolbar from 'primevue/toolbar';
import Button from 'primevue/button';
import SplitButton from 'primevue/splitbutton';
import Dialog from 'primevue/dialog';
import BulkTransferAccessories from '@/components/dialog/products/bulk-transfer-accessories.vue';
import BulkTransferAccessoriesToOtherShop from '@/components/dialog/products/bulk-transfer-accessories-to-other-shop.vue';
import ProductList from '@/components/grid/products/list.vue';
import ReplaceAccessory from '@/components/dialog/products/replace-accessory.vue';
import {stripTagsAndTruncate} from '@/utils/helpers';
import Badge from 'primevue/badge';
import TieredMenu from 'primevue/tieredmenu';
import MultiSelect from 'primevue/multiselect';

import {i18n} from '@/utils/i18n';
import {FilterMatchMode} from 'primevue/api';
import {useToast} from 'vue-toastification';
import {bulkDelete} from '@/services/product-accessories';
import store from '@/store';
import Tag from 'primevue/tag';
import TriStateCheckbox from 'primevue/tristatecheckbox';

export default {
    emits: ['cross-platform-grid-reload'],
    components: {
        DataTable,
        Column,
        InputText,
        Toolbar,
        'p-button': Button,
        SplitButton,
        BulkTransferAccessories,
        BulkTransferAccessoriesToOtherShop,
        PrimeDialog: Dialog,
        ProductList,
        ReplaceAccessory,
        LoadingPlugin,
        Badge,
        TieredMenu,
        Tag,
        'p-multiselect': MultiSelect,
        TriStateCheckbox
    },
    props: {
        productNumber: String,
        platform: {
            type: String,
            default: null
        },
        reloadRequired: {
            type: Boolean,
            default: false
        }
    },
    setup(props: any, context: any) {
        const toast = useToast();

        const {productNumber, platform} = toRefs(props);

        const bulkMenu = ref();
        const reloadRequired = ref(false);
        const productDetails = ref(null);

        watch(props, (args) => {
            if (reloadRequired.value !== args.reloadRequired) {
                reloadRequired.value = args.reloadRequired;
                if (reloadRequired.value) {
                    loadLazyData();
                }
            }
        });

        onMounted(() => {
            getAllMetadata(['productTags'], false, [], platform.value).then(
                (data) => {
                    availableProductTags.value = data.data?.productTags || [];
                }
            );

            getSingleProduct(productNumber.value, platform.value)
                .then((data: any) => {
                    if (data?.data?.shopware || data?.data?.weclapp) {
                        productDetails.value = data.data;
                    } else {
                        toast.error(i18n.global.t('messages.noSuchProduct'));
                    }
                })
                .catch((error: any) => {
                    toast.error(error.message);
                });

            filters.value = Object.assign({}, filtersDefinition);

            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'articleNumber',
                sortOrder: 1,
                filters: filters.value,
                allEnvs: false
            };

            loadLazyData();
        });

        const dt = ref();
        const lazyParams: any = ref({});
        const expandedRows = ref([]);
        const showTransferDialog = ref(false);
        const showTransferToOtherShopDialog = ref(false);
        const showDeleteDialog = ref(false);
        const showReplaceDialog = ref(false);
        const oldAccessory = ref();
        const availableProductTags = ref([]);

        const onPage = (event: any) => {
            if (!lazyParams.value) {
                lazyParams.value = event;
            } else {
                lazyParams.value.first = event.first || 0;
                lazyParams.value.rows = event.rows || dt.value.rows;
            }
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = Object.assign(filters.value, {});
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;

            lazyParams.value.filters = Object.assign(filters.value, {});

            loadLazyData();
        };

        const totalRecords = ref(0);
        const loading = ref(false);
        const products = ref();
        const selectedProducts = ref([]);
        const mergedSelection = ref([]);

        const loadLazyData = () => {
            loading.value = true;

            getSingle(productNumber.value, lazyParams.value, platform.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    products.value = data.data.items;
                    selectedProducts.value = mergedSelection.value.filter(
                        (item: any) => {
                            return products.value.some(
                                (product: any) =>
                                    product.articleNumber === item.articleNumber
                            );
                        }
                    );
                })
                .catch((error) => {
                    toast.error(error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const filtersDefinition: any = {
            articleNumber: {value: null, matchMode: FilterMatchMode.CONTAINS},
            name: {value: null, matchMode: FilterMatchMode.CONTAINS},
            productCategory: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            description: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            tags: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            availableStock: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            }
        };

        const filters = ref(filtersDefinition);

        const closeTransferDialog = (refreshGrid: boolean = false) => {
            showTransferDialog.value = false;
            if (refreshGrid) {
                selectedProducts.value = [];
                mergedSelection.value = [];
                loadLazyData();
            }
        };

        const closeTransferToOtherShopDialog = (
            refreshGrid: boolean = false
        ) => {
            showTransferToOtherShopDialog.value = false;
            if (refreshGrid) {
                selectedProducts.value = [];
                mergedSelection.value = [];
                context.emit('cross-platform-grid-reload');
            }
        };

        const openReplaceDialog = (data: any) => {
            oldAccessory.value = data;
            showReplaceDialog.value = true;
        };

        const closeReplaceDialog = (refreshGrid: boolean = false) => {
            oldAccessory.value = null;
            showReplaceDialog.value = false;
            if (refreshGrid) {
                selectedProducts.value = [];
                mergedSelection.value = [];
                loadLazyData();
            }
        };

        const removeArticleAccessories = async () => {
            loading.value = true;
            try {
                await bulkDelete(
                    productNumber.value,
                    mergedSelection.value,
                    platform.value
                );
                toast.success(
                    i18n.global.t('messages.changesSavedSuccessfully'),
                    {
                        timeout: 500
                    }
                );
                showDeleteDialog.value = false;
                selectedProducts.value = [];
                mergedSelection.value = [];
                loadLazyData();
            } catch (error: any) {
                toast.error(error.message);
                loading.value = false;
            }
        };

        const editAccessoriesPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return (
                user?.permissions?.indexOf('product-accessories-edit') !== -1
            );
        });

        const expandRow = (data: any) => {
            if (
                expandedRows.value?.length > 0 &&
                expandedRows.value.find((item: any) => {
                    return item.id === data.id;
                }) !== null
            ) {
                expandedRows.value = [];
            } else {
                expandedRows.value = products.value.filter(
                    (p: any) => p.id === data.id
                );
            }
        };

        const onRowSelect = (selection: {data: any; originalEvent: any}) => {
            mergedSelection.value = Object.values(
                [...mergedSelection.value, selection.data].reduce(
                    (acc, obj) => ({...acc, [obj.id]: obj}),
                    {}
                )
            );
        };

        const onRowSelectAll = (selection: {
            data: Array<any>;
            originalEvent: any;
        }) => {
            mergedSelection.value = Object.values(
                [...mergedSelection.value, ...selection.data].reduce(
                    (acc, obj) => ({...acc, [obj.id]: obj}),
                    {}
                )
            );
        };

        const onRowUnselect = (selection: {data: any; originalEvent: any}) => {
            mergedSelection.value = mergedSelection.value.filter(
                (item) => item.id !== selection.data.id
            );
        };

        const onRowUnselectAll = () => {
            mergedSelection.value = mergedSelection.value.filter((item) => {
                return !products.value.some((pr: any) => item.id === pr.id);
            });
        };

        const clearSelection = () => {
            selectedProducts.value = [];
            mergedSelection.value = [];
        };

        const bulkMenuToggle = (event: any) => {
            bulkMenu.value.toggle(event);
        };

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) =>
                    item.value ===
                    (platform.value || store.getters['auth/platform'])
            );
        });

        const crossPlatform = computed(() => {
            let crossPlatform = 'videodata';

            switch (platform.value || resolvedPlatform.value?.value) {
                case 'videodata':
                    crossPlatform = 'teltec';
                    break;
                case 'videodata-demo':
                    crossPlatform = 'teltec-demo';
                    break;
                case 'teltec-demo':
                    crossPlatform = 'videodata-demo';
                    break;
            }

            return store.getters['auth/platforms'].find(
                (item: any) => item.value === crossPlatform
            );
        });

        const bulkItems = computed(() => {
            return [
                {
                    label: i18n.global.t('labels.deleteSelected') + '...',
                    icon: 'pi pi-trash',
                    command: () => {
                        if (
                            !mergedSelection.value ||
                            mergedSelection.value.length < 1
                        ) {
                            toast.error(
                                i18n.global.t(
                                    'messages.pleaseSelectAtLeastOneProduct'
                                )
                            );
                            return;
                        }
                        showDeleteDialog.value = true;
                    }
                },
                {
                    label: i18n.global.t('labels.transferSelected'),
                    icon: 'pi pi-reply',
                    command: () => {
                        if (
                            !mergedSelection.value ||
                            mergedSelection.value.length < 1
                        ) {
                            toast.error(
                                i18n.global.t(
                                    'messages.pleaseSelectAtLeastOneProduct'
                                )
                            );
                            return;
                        }
                        showTransferDialog.value = true;
                    }
                },
                {
                    label: i18n.global.t('labels.transferSelectedToOtherShop', {
                        platform: crossPlatform.value?.label
                    }),
                    icon: 'pi pi-reply',
                    disabled: !productDetails.value?.secondShopware,
                    command: () => {
                        if (
                            !mergedSelection.value ||
                            mergedSelection.value.length < 1
                        ) {
                            toast.error(
                                i18n.global.t(
                                    'messages.pleaseSelectAtLeastOneProduct'
                                )
                            );
                            return;
                        }
                        showTransferToOtherShopDialog.value = true;
                    }
                }
            ];
        });

        return {
            productDetails,
            loading,
            filters,
            expandedRows,
            dt,
            onFilter,
            onPage,
            onSort,
            loadLazyData,
            closeTransferDialog,
            closeTransferToOtherShopDialog,
            openReplaceDialog,
            closeReplaceDialog,
            totalRecords,
            products,
            selectedProducts,
            mergedSelection,
            bulkItems,
            showTransferDialog,
            showTransferToOtherShopDialog,
            showDeleteDialog,
            showReplaceDialog,
            oldAccessory,
            removeArticleAccessories,
            editAccessoriesPermissionAvailable,
            stripTagsAndTruncate,
            expandRow,
            onRowSelect,
            onRowUnselect,
            onRowSelectAll,
            onRowUnselectAll,
            clearSelection,
            bulkMenu,
            bulkMenuToggle,
            availableProductTags,
            resolvedPlatform,
            crossPlatform
        };
    }
};
