<Panel :header="$t('labels.addUsedProduct')">
    <Stepper linear v-model:activeStep="activeIndex">
        <StepperPanel :header="$t('labels.sourceProduct')">
            <template #content="{ nextCallback }">
                <SourceProductStep
                    @environments-selected="onEnvironmentsSelected($event);nextCallback($event);"
                ></SourceProductStep>
            </template>
        </StepperPanel>
        <StepperPanel :header="$t('labels.productHome.masterData') + ' 1/3'">
            <template #content="{ prevCallback, nextCallback }">
                <GlobalFieldsStep
                    :duplicateCheckValues="duplicateCheckValues"
                    :pairValues="pairValues"
                    @back-button-clicked="prevCallback"
                    @next-button-clicked="onNextButtonClicked($event);nextCallback($event);"
                    @add-wizard-form-dirty="onAddWizardFormDirty"
                ></GlobalFieldsStep>
            </template>
        </StepperPanel>
        <StepperPanel :header="$t('labels.productHome.masterData') + ' 2/3'">
            <template #content="{ prevCallback, nextCallback }">
                <EnvSpecificDescriptionsStep
                    :pairValues="pairValues"
                    @back-button-clicked="onBackButtonClicked();prevCallback()"
                    @next-button-clicked="onNextButtonClicked($event);nextCallback($event);"
                    @add-wizard-form-dirty="onAddWizardFormDirty"
                >
                </EnvSpecificDescriptionsStep>
            </template>
        </StepperPanel>
        <StepperPanel :header="$t('labels.productHome.masterData') + ' 3/3'">
            <template #content="{ prevCallback, nextCallback }">
                <EnvSpecificFieldsStep
                    :pairValues="pairValues"
                    @back-button-clicked="onBackButtonClicked();prevCallback()"
                    @next-button-clicked="onNextButtonClicked($event);nextCallback($event);"
                    @add-wizard-form-dirty="onAddWizardFormDirty"
                >
                </EnvSpecificFieldsStep>
            </template>
        </StepperPanel>
        <StepperPanel :header="$t('labels.salesOrder.supplySources')">
            <template #content="{ prevCallback, nextCallback }">
                <CloneSupplySourcesStep
                    :pairValues="pairValues"
                    :savingInProgress="savingInProgress"
                    @back-button-clicked="onBackButtonClicked();prevCallback()"
                    @next-button-clicked="onNextButtonClicked($event);nextCallback($event);"
                    @add-wizard-form-dirty="onAddWizardFormDirty"
                >
                </CloneSupplySourcesStep>
            </template>
        </StepperPanel>
    </Stepper>
</Panel>
<PrimeDialog
    :header="$t('labels.result')"
    v-model:visible="showResultDialog"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '640px'}"
    :modal="true"
    :closable="false"
>
    <div class="col-3">{{resolvedPlatform?.label}}</div>
    <div class="col">
        <template v-if="saveResult">
            <span v-if="saveResult['error']" class="text-danger">
                {{$t('labels.error')}}: {{ saveResult['error'] }}
            </span>
            <template v-if="saveResult['id']">
                <i class="pi text-green-600 pi-check-circle"></i>

                <span class="ml-3">{{$t('labels.articleNumber')}}:</span>
                <router-link
                    class="ml-3"
                    :to="{
                            name: 'ProductDetails',
                            params: {
                                id: saveResult['id']
                            }
                        }"
                    ><span>{{ saveResult['id'] }}</span>
                </router-link>
                <div class="ml-5 text-sm" v-if="saveResult['name']">
                    {{saveResult['name']}}
                </div>
            </template>
        </template>
    </div>
    <div class="col">
        <template v-if="saveResult && !saveResult['error']">
            <template v-if="saveResult['shop'] && saveResult['shop']['id']">
                <i class="pi text-green-600 pi-check-circle"></i>
                <span class="ml-3">Shop: {{ saveResult['shop']['id'] }}</span>
                <div class="ml-5 text-sm">
                    <span v-if="saveResult['shop']['accessories']" class="mr-1">
                        {{$t('labels.articleAccessories')}}:
                        {{saveResult['shop']['accessories']}}
                    </span>
                    <span v-if="saveResult['shop']['images']" class="mr-1">
                        {{$t('labels.images')}}:
                        {{saveResult['shop']['images']}}
                    </span>
                    <span v-if="saveResult['shop']['videos']" class="mr-1">
                        {{$t('labels.videos')}}:
                        {{saveResult['shop']['videos']}}
                    </span>
                    <span v-if="saveResult['shop']['links']">
                        {{$t('labels.links')}}: {{saveResult['shop']['links']}}
                    </span>
                </div>
            </template>
        </template>
    </div>

    <template #footer>
        <div v-if="hasError" class="w-full flex justify-content-between">
            <p-button severity="warning" @click="onStartOverClick">
                {{ $t("buttons.startOver") }}
            </p-button>
            <router-link
                :to="{
            name: 'UsedProducts'
        }"
                ><p-button severity="danger">
                    {{ $t("buttons.cancel") }}
                </p-button></router-link
            >
        </div>
        <div v-else class="flex justify-content-end">
            <router-link
                :to="{
            name: 'UsedProducts'
        }"
                ><p-button severity="success">
                    {{ $t("buttons.backToProductList") }}
                </p-button></router-link
            >
        </div>
    </template>
</PrimeDialog>
<ConfirmLeave
    :displayConfirmLeaveDialog="displayConfirmLeaveDialog"
    @choice-confirmed="onChoiceConfirmed"
></ConfirmLeave>
