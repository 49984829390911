export default {
    shopOrdersInTeltec: (state: {
        shopOrdersInTeltec: boolean;
        shopOrdersInVideodata: boolean;
    }): boolean => {
        return state.shopOrdersInTeltec;
    },

    shopOrdersInVideodata: (state: {
        shopOrdersInTeltec: boolean;
        shopOrdersInVideodata: boolean;
    }): boolean => {
        return state.shopOrdersInVideodata;
    }
};
