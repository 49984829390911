<div class="mt-2">
    <p-button
        class="text-sm"
        @click="displayAddEditDialog = true;"
        severity="success"
        ><div class="flex align-content-center">
            <span
                class="p-button-icon p-button-icon-left pi pi-plus"
                data-pc-section="icon"
            ></span
            ><span class="p-button-label"
                >{{$t('buttons.addNewGeneric')}}
            </span>
            <span
                v-if="productDetails?.shopware"
                class="sales-channel-dot w-1rem h-1rem ml-2"
                :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
            ></span>
            <span
                v-if="productDetails?.secondShopware"
                class="sales-channel-dot w-1rem h-1rem ml-1"
                :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
            ></span></div
    ></p-button>
</div>

<TabView class="tabview-custom text-sm">
    <TabPanel :disabled="!productDetails?.shopware">
        <template #header>
            <i class="pi pi-file-pdf"></i>
            <span
                class="sales-channel-dot w-1rem h-1rem ml-2"
                :class="{'sales-channel-teltec':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-videodata':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
            ></span>
        </template>
        <Documents
            :shopware-details="productDetails?.shopware"
            @reload-product="loadProductDetails"
        />
    </TabPanel>
    <TabPanel :disabled="!productDetails?.secondShopware">
        <template #header>
            <i class="pi pi-file-pdf"></i>
            <span
                class="sales-channel-dot w-1rem h-1rem ml-2"
                :class="{'sales-channel-videodata':['ttd', 'ttl'].includes(resolvedPlatform?.threeLetterId), 'sales-channel-teltec':['vdd', 'vdl'].includes(resolvedPlatform?.threeLetterId)}"
            ></span>
        </template>
        <Documents
            :shopware-details="productDetails?.secondShopware"
            @reload-product="loadProductDetails"
            :platform="secondPlatform"
        />
    </TabPanel>
</TabView>
<AddEditDialog
    :displayResponsive="displayAddEditDialog"
    :product-number="productDetails?.shopware?.productNumber"
    @close-dialog="closeDialogListener"
/>
