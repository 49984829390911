import {i18n} from '@/utils/i18n';
import TieredMenu from 'primevue/tieredmenu';
import {computed, ref, toRefs, watch} from 'vue';
import AddFilterDialog from '../dialog/filter/add-filter.vue';
import {useToast} from 'vue-toastification';
import store from '@/store';
import {useConfirm} from 'primevue/useconfirm';
import {
    addTemplate,
    getAllTemplates,
    removeTemplate
} from '@/services/price-import';

export default {
    name: 'filter-menu',
    emits: ['clear-template', 'apply-template'],
    components: {
        TieredMenu,
        AddFilterDialog
    },
    props: {
        supplierNumber: {
            type: String,
            default: null
        },
        sheetName: {
            type: String,
            default: null
        },
        mappingDefinition: {
            type: Object,
            default: null
        },
        chosenManufacturer: {
            type: String,
            default: null
        }
    },
    setup(props: any, context: any) {
        const displayAddFilterDialog = ref(false);
        const savingInProgress = ref(false);
        const personalTemplates = ref([]);
        const publicTemplates = ref([]);
        const {supplierNumber, sheetName, chosenManufacturer} = toRefs(props);
        const mappingDefinition = ref(null);

        const toast = useToast();
        const confirm = useConfirm();

        const user = computed(() => {
            return store.getters['auth/user'];
        });

        watch(props, async (args) => {
            if (args.mappingDefinition) {
                mappingDefinition.value = args.mappingDefinition;
            }
        });

        const loadFilters = (applyAutomatically: boolean = true) => {
            getAllTemplates({
                supplierNumber: supplierNumber.value
            })
                .then((data) => {
                    personalTemplates.value = data.data
                        .filter(
                            (item: {
                                public: boolean;
                                sheetName: string | null;
                            }) => {
                                return (
                                    !item.public &&
                                    (item.sheetName === null ||
                                        item.sheetName === sheetName.value)
                                );
                            }
                        )
                        .sort((a: any, b: any) => a.name.localeCompare(b.name));

                    publicTemplates.value = data.data
                        .filter(
                            (item: {
                                public: boolean;
                                sheetName: string | null;
                            }) => {
                                return (
                                    item.public &&
                                    (item.sheetName === null ||
                                        item.sheetName === sheetName.value)
                                );
                            }
                        )
                        .sort((a: any, b: any) => a.name.localeCompare(b.name));

                    if (!applyAutomatically) {
                        return;
                    }

                    if (personalTemplates.value.length === 1) {
                        context.emit(
                            'apply-template',
                            personalTemplates.value[0].definition
                        );
                    }

                    if (
                        personalTemplates.value.length === 0 &&
                        publicTemplates.value.length === 1
                    ) {
                        context.emit(
                            'apply-template',
                            publicTemplates.value[0].definition
                        );
                    }
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                });
        };

        setTimeout(loadFilters, 100);

        const menu = ref();
        const items = computed(() => {
            return [
                {
                    label: i18n.global.t('buttons.savedTemplates'),
                    icon: 'pi pi-history',
                    disabled:
                        personalTemplates.value.length < 1 &&
                        publicTemplates.value.length < 1,
                    items: [
                        {
                            label: i18n.global.t('labels.personal'),
                            icon: 'pi pi-fw pi-user',
                            disabled: personalTemplates.value.length < 1,
                            items: personalTemplates.value.map((item) => {
                                return {
                                    label: item.name,
                                    items: [
                                        {
                                            label: i18n.global.t(
                                                'buttons.apply'
                                            ),
                                            icon: 'pi pi-check',
                                            command: () => {
                                                context.emit(
                                                    'apply-template',
                                                    item.definition
                                                );
                                            }
                                        },
                                        {
                                            label: i18n.global.t(
                                                'buttons.clear'
                                            ),
                                            icon: 'pi pi-times-circle',
                                            command: () => {
                                                confirm.require({
                                                    message: i18n.global.t(
                                                        'messages.deleteConfirmation',
                                                        {
                                                            item: item.name
                                                        }
                                                    ),
                                                    header: i18n.global.t(
                                                        'messages.pleaseConfirm'
                                                    ),
                                                    icon: 'pi pi-exclamation-triangle',
                                                    acceptLabel:
                                                        i18n.global.t(
                                                            'labels.yes'
                                                        ),
                                                    rejectLabel:
                                                        i18n.global.t(
                                                            'labels.no'
                                                        ),
                                                    accept: () => {
                                                        removeTemplate(item.id)
                                                            .then(() => {
                                                                toast.success(
                                                                    i18n.global.t(
                                                                        'messages.changesSavedSuccessfully'
                                                                    )
                                                                );
                                                                loadFilters(
                                                                    false
                                                                );
                                                            })
                                                            .catch((error) => {
                                                                toast.error(
                                                                    error
                                                                        .response
                                                                        ?.data
                                                                        ?.error ||
                                                                        error.message
                                                                );
                                                            });
                                                    }
                                                });
                                            }
                                        }
                                    ]
                                };
                            })
                        },
                        {
                            label: i18n.global.t('labels.public'),
                            icon: 'pi pi-fw pi-building',
                            disabled: publicTemplates.value.length < 1,
                            items: publicTemplates.value.map((item) => {
                                return {
                                    label:
                                        item.name + ' (' + item.username + ')',
                                    items: [
                                        {
                                            label: i18n.global.t(
                                                'buttons.apply'
                                            ),
                                            icon: 'pi pi-check',
                                            disabled: false,
                                            command: () => {
                                                context.emit(
                                                    'apply-template',
                                                    item.definition
                                                );
                                            }
                                        },
                                        {
                                            label: i18n.global.t(
                                                'buttons.clear'
                                            ),
                                            icon: 'pi pi-times-circle',
                                            disabled:
                                                item.username !==
                                                user.value?.username,
                                            command: () => {
                                                confirm.require({
                                                    message: i18n.global.t(
                                                        'messages.deleteConfirmation',
                                                        {
                                                            item: item.name
                                                        }
                                                    ),
                                                    header: i18n.global.t(
                                                        'messages.pleaseConfirm'
                                                    ),
                                                    icon: 'pi pi-exclamation-triangle',
                                                    acceptLabel:
                                                        i18n.global.t(
                                                            'labels.yes'
                                                        ),
                                                    rejectLabel:
                                                        i18n.global.t(
                                                            'labels.no'
                                                        ),
                                                    accept: () => {
                                                        removeTemplate(item.id)
                                                            .then(() => {
                                                                toast.success(
                                                                    i18n.global.t(
                                                                        'messages.changesSavedSuccessfully'
                                                                    )
                                                                );
                                                                loadFilters(
                                                                    false
                                                                );
                                                            })
                                                            .catch((error) => {
                                                                toast.error(
                                                                    error
                                                                        .response
                                                                        ?.data
                                                                        ?.error ||
                                                                        error.message
                                                                );
                                                            });
                                                    }
                                                });
                                            }
                                        }
                                    ]
                                };
                            })
                        }
                    ]
                },
                {
                    label: i18n.global.t('buttons.saveCurrentTemplate'),
                    icon: 'pi pi-save',
                    disabled:
                        !mappingDefinition?.value?.columnMappingArray ||
                        (mappingDefinition.value.columnMappingArray || [])
                            .length < 1,
                    command: () => {
                        displayAddFilterDialog.value = true;
                    }
                },
                {
                    separator: true
                },
                {
                    label: i18n.global.t('buttons.emptyCurrentTemplate'),
                    icon: 'pi pi-trash',
                    disabled:
                        !mappingDefinition?.value?.columnMappingArray ||
                        (mappingDefinition.value.columnMappingArray || [])
                            .length < 1,
                    command: () => {
                        context.emit('clear-template');
                    }
                }
            ];
        });

        const toggle = (event: any) => {
            menu.value.toggle(event);
        };

        const executeAdding = (payload: any) => {
            savingInProgress.value = true;

            addTemplate(
                supplierNumber.value,
                sheetName.value,
                chosenManufacturer.value,
                payload,
                mappingDefinition.value
            )
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully')
                    );
                    displayAddFilterDialog.value = false;
                    loadFilters(false);
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        const onCloseAddDialog = (payload: any) => {
            if (!payload) {
                displayAddFilterDialog.value = false;
                return;
            }

            if (
                !publicTemplates.value.some(
                    (publicFilter: any) =>
                        publicFilter.name === payload?.name &&
                        publicFilter.username === user.value?.username
                ) &&
                !personalTemplates.value.some(
                    (personalFilter: any) =>
                        personalFilter.name === payload?.name
                )
            ) {
                executeAdding(payload);
                return;
            }

            confirm.require({
                message: i18n.global.t('messages.filterExists', {
                    item: payload.name
                }),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: () => {
                    executeAdding(payload);
                }
            });
        };

        return {
            menu,
            items,
            toggle,
            displayAddFilterDialog,
            savingInProgress,
            onCloseAddDialog
        };
    }
};
