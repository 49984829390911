<loading v-model:active="loading" />
<Panel :header="$t('labels.warehouseStockBPM')">
    <DataTable
        class="p-datatable-sm text-sm"
        stripedRows
        paginator
        scrollable
        scrollHeight="calc(100vh - 23rem)"
        :rows="500"
        ref="dt"
        dataKey="id"
        :value="warehouseStockItems"
        responsiveLayout="scroll"
        paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rows-per-page-options="[20,50,100,200,500]"
        current-page-report-template="{first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="row"
    >
        <Column
            field="teltecArticleNumber"
            sortable
            style="min-width: 7rem"
            :header="'Teltec ' + $t('labels.articleNumber')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    placeholder="#"
                />
            </template>
        </Column>
        <Column
            field="bpmName"
            sortable
            style="min-width: 7rem"
            :header="'BPM ' + $t('labels.name')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                />
            </template>
        </Column>
        <Column
            field="bpmArticleNumber"
            sortable
            style="min-width: 7rem"
            :header="'BPM ' + $t('labels.articleNumber')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    placeholder="#"
                />
            </template>
        </Column>
        <Column
            field="manufacturer"
            sortable
            style="min-width: 7rem"
            :header="$t('labels.manufacturer')"
        >
            <template #filter="{filterModel,filterCallback}">
                <InputText
                    type="text"
                    v-model="filterModel.value"
                    @keydown.enter="filterCallback()"
                    class="p-column-filter text-xs"
                    placeholder="#"
                />
            </template>
        </Column>
        <Column
            field="stockQuantity"
            sortable
            style="min-width: 7rem"
            :header="'BPM Lagerbestand'"
        >
        </Column>
        <Column
            field="price"
            sortable
            style="min-width: 8rem"
            :header="'EK Vorschlag'"
        >
            <template #body="{data}"
                ><span v-if="data.price"
                    >{{formatPrice(data.price)}}</span
                ></template
            >
        </Column>
    </DataTable>
</Panel>
