<PrimeDialog
    :header="$t('labels.addComment')"
    v-model:visible="showDialog"
    :closable="false"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '640px'}"
    :modal="true"
>
    <loading v-model:active="savingInProgress" />

    <tiny
        v-if="ticketDetails"
        :tinymceScriptSrc="tinyUrl"
        v-model="comment"
        :init="{
                    height: 300,
                    menubar: false,
                    plugins: 'lists link image emoticons code table anchor charmap fullscreen paste template',
                    toolbar: 'undo redo | styleselect | template | bold italic superscript charmap | pastetext removeformat | alignleft aligncenter alignright alignjustify | bullist numlist | table | anchor fullscreen | link image | outdent indent | code',
                    templates : tinyTemplates,
                    template_replace_values: {
                        'ticketOwner': ticketDetails?.customer?.company ||
                                (ticketDetails?.customer?.firstName +
                            ' ' +
                            ticketDetails?.customer?.lastName),
                        'ticketNumber': ticketDetails.ticketNumber,
                    },
                    browser_spellcheck: true
                }"
    ></tiny>
    <div class="w-full flex justify-content-between mt-3">
        <p-button severity="danger" @click="onCancelClick">
            {{ $t("buttons.cancel") }}
        </p-button>
        <p-button
            :disabled="!comment"
            severity="success"
            @click="onConfirmClicked"
        >
            {{ $t("buttons.confirm") }}
        </p-button>
    </div>
</PrimeDialog>
