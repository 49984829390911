import {computed, ref, watch} from 'vue';
import {i18n} from '@/utils/i18n';
import Panel from 'primevue/panel';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

import countries from 'i18n-iso-countries';
import {helpers, required} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import {editSingleOrder} from '@/services/shopware';
import store from '@/store';
import {useRoute} from 'vue-router';
import {useConfirm} from 'primevue/useconfirm';
import {useToast} from 'vue-toastification';
import LoadingPlugin from 'vue-loading-overlay';

export default {
    emits: ['shop-data-saved'],
    components: {
        Panel,
        InputText,
        'p-button': Button,
        loading: LoadingPlugin
    },
    props: {
        shopData: Object,
        selectedWeclappCustomer: Object
    },
    setup(props: any, context: any) {
        const shopData = ref(null);
        const selectedWeclappCustomer = ref(null);

        const isEdit = ref(false);
        const submitted = ref(false);
        const savingInProgress = ref(false);

        const route = useRoute();
        const confirm = useConfirm();
        const toast = useToast();

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) =>
                    item.value ===
                    (route.query?.platform || store.getters['auth/platform'])
            );
        });

        const state = ref({
            email: null,
            firstName: null,
            lastName: null,
            vatNumber: null,
            company: null,
            department: null,
            street: null,
            street2: null,
            zipcode: null,
            city: null,
            country: null,
            phoneNumber: null
        });

        const rules = {
            email: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            firstName: {},
            lastName: {},
            vatNumber: {},
            company: {},
            department: {},
            street: {},
            street2: {},
            zipcode: {},
            city: {},
            country: {},
            phoneNumber: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, async (args) => {
            if (args.shopData) {
                shopData.value = args.shopData;

                resetForm();
            }
            if (args.selectedWeclappCustomer) {
                selectedWeclappCustomer.value = args.selectedWeclappCustomer;
            }
        });

        const resolveCountryFromCode = (countryCode: string) => {
            return (
                countries.getName(countryCode, i18n.global.locale) ||
                countries.getName(countryCode, 'en')
            );
        };

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            if (!v$.value.$anyDirty) {
                isEdit.value = false;
                return;
            }

            confirm.require({
                message: i18n.global.t('messages.saveChangesConfirmation'),
                header: i18n.global.t('messages.pleaseConfirm'),
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: i18n.global.t('labels.yes'),
                rejectLabel: i18n.global.t('labels.no'),
                accept: () => {
                    const payload = Object.keys(state.value)
                        .filter(function (stateKey: string) {
                            return (
                                v$.value[stateKey] && v$.value[stateKey].$dirty
                            );
                        })
                        .reduce(function (obj2: any, key: string) {
                            obj2[key as keyof typeof obj2] =
                                state.value[key as keyof typeof state.value];
                            return obj2;
                        }, {});
                    savingInProgress.value = true;
                    editSingleOrder(
                        shopData.value?.number,
                        payload,
                        resolvedPlatform.value?.value
                    )
                        .then(() => {
                            toast.success(
                                i18n.global.t(
                                    'messages.changesSavedSuccessfully'
                                )
                            );
                            context.emit('shop-data-saved');
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        })
                        .finally(() => {
                            savingInProgress.value = false;
                        });
                }
            });
        };

        const resetForm = () => {
            state.value = {
                email: shopData.value.customer?.email,
                firstName: shopData.value.customer?.firstName,
                lastName: shopData.value.customer?.lastName,
                vatNumber: shopData.value.customer?.vatNumber,
                company: shopData.value.billingAddress?.company,
                department: shopData.value.billingAddress?.department,
                street: shopData.value.billingAddress?.street,
                street2: shopData.value.billingAddress?.additionalAddressLine1,
                zipcode: shopData.value.billingAddress?.zipcode,
                city: shopData.value.billingAddress?.city,
                country: shopData.value.billingAddress?.country,
                phoneNumber: shopData.value.billingAddress?.phoneNumber
            };

            isEdit.value = false;
        };

        return {
            shopData,
            selectedWeclappCustomer,
            isEdit,
            resolveCountryFromCode,
            state,
            v$,
            handleSubmit,
            submitted,
            resetForm,
            savingInProgress
        };
    }
};
